import * as React from 'react';
import PropTypes from 'prop-types';
import tokens from '../../Tokens/tokens';
const { colors, spacing } = tokens;

const IconThumbsDown = ({ title, titleId, ...props }) => {
    const sizeArray = Object.entries(spacing.icons);
    const colorArray = Object.entries(colors.icons);
    const sizeOptions = new Map(sizeArray);
    const displaySize = sizeOptions.get(props.size);
    const colorOptions = new Map(colorArray);
    const displayColor = colorOptions.get(props.color);
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 16 16'
            fill={props.color && displayColor}
            aria-hidden={!title}
            role={title ? 'img' : null}
            width={props.size && displaySize}
            height={props.size && displaySize}
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <path d='M1 0a1 1 0 00-1 1v8a1 1 0 102 0V1a1 1 0 00-1-1zm11 0H5a1 1 0 00-1 1v8a1 1 0 00.11.45l3 6A1 1 0 008 16a3 3 0 003-3v-2h2a3 3 0 003-3 1.36 1.36 0 000-.2l-1-4.91A3 3 0 0012 0zM6 2h6.05A1 1 0 0113 3a1.36 1.36 0 000 .2l1 4.88A1 1 0 0113 9h-3a1 1 0 00-1 1v3a1 1 0 01-.46.84L6 8.76V2z' />
        </svg>
    );
};

IconThumbsDown.propTypes = {
    title: PropTypes.string,
    titleId: PropTypes.string,
    size: PropTypes.oneOf(['sm', 'md']),
    color: PropTypes.oneOf(['primaryBlue', 'secondaryBlue', 'darkGrey', 'white']),
};
IconThumbsDown.defaultProps = {
    size: 'sm',
    color: 'darkGrey',
    focusable: false,
};
export default IconThumbsDown;
