import styled from 'styled-components/macro';
import EtdbButton from '../EtdbButton.styled';

const PrimaryButton = styled(EtdbButton)`
    border-radius: 8px;
    background-color: #c60c30;
    color: #ffffff;
    border: 2px solid #a71930;
    text-decoration: none;
    :hover {
        background-color: #e80229;
        border-color: transparent;
    }
    :active {
        background-color: #a71930;
        border-color: transparent;
    }
    :after {
        filter: brightness(100);
    }
    :focus {
        outline: none;
    }
`;

export default PrimaryButton;
