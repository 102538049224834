/* eslint-disable max-len */
export const errorMessagesArray = [
    ['required', 'Please enter a value'],
    ['minLength', 'The value is too short'],
    ['dateNotInbetween', 'Date is not in expected range'],
    ['emailFormat', 'Invalid email address. Please use the format example@email.com'],
    ['bankAccountNumberFormat', 'Your bank account number must consist of only digits and be from 3-9 digits and contain no spaces'],
    ['bankAccountNameFormat', 'Your bank account name must consist of only A-Z digits 0-9 or a space'],
    ['notAlphaName', 'You may enter only alphabetic, diacritic and limited punctuation (\',-.). No digits 0-9 or other punctuation'],
    ['allWhitespace', 'You must enter a value (other than spaces)'],
    ['notAustralianPhoneNumber', 'You must enter a valid Australian phone number'],
    ['notAustralianPostcode', 'You must enter a valid Australian postcode'],
];

export const errorMessages = new Map(errorMessagesArray);

export const getErrorMessage = key => errorMessages.get(key);

const ErrorMessages = {
    getErrorMessage
};
export default ErrorMessages;
