import styled from 'styled-components/macro';

export const Separator = styled.div`
    width: 100%;
    margin-top: auto;
    margin-bottom: auto;
    border-top: 1px solid #dee3e5;
`;

export const SeparatorContainer = styled.div`
    padding: 1.5rem 1.5rem 0 1.5rem;
    display: flex;
    ${Separator} {
        margin-left: 0.5rem;
    }
`;
