import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { trackMe } from '../ComponentAnalytics/componentAnalytics';
import Field from '../Form/Field.styled';

const InlineError = ({ id, errorMessage }) => {
    useEffect(() => {
        trackMe('InlineError [GEL]');
    }, []);

    return (
        <Field.Error id={ id }>{errorMessage}</Field.Error>
    );
};

InlineError.propTypes = {
    id: PropTypes.string,
    errorMessage: PropTypes.string.isRequired,
};

export default InlineError;
