import * as React from 'react';
import PropTypes from 'prop-types';
import tokens from '../../Tokens/tokens';
const { spacing } = tokens;

const BrandIconIdentity = ({ title, titleId, ...props }) => {
    const sizeArray = Object.entries(spacing.icons);
    const sizeOptions = new Map(sizeArray);
    const displaySize = sizeOptions.get(props.size);
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 48 48'
            fill={props.color && displayColor}
            aria-hidden={!title}
            role={title ? 'img' : null}
            width={props.size && displaySize}
            height={props.size && displaySize}
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <defs>
                <style>{'.Identity_svg__cls-1{fill:#002664}'}</style>
            </defs>
            <path
                className='Identity_svg__cls-1'
                d='M18.71 26.81C18 32.32 15.86 37.89 10.2 45.4a1 1 0 00.2 1.4 1 1 0 00.6.2 1 1 0 00.8-.4c5.41-7.18 8.08-12.92 8.91-19.67A5.69 5.69 0 0120 27a5 5 0 01-1.29-.19zm15.17 12.47a1 1 0 01-.71-.3l-1.33-1.32a10.2 10.2 0 01-3-6.22 6.41 6.41 0 011.68-5.1c2.71-2.7 7.89-2.1 11.31 1.33L43.16 29a1 1 0 01.2 1.14c-3.34 7.08-8.93 9-9.17 9.1a1.12 1.12 0 01-.31.04zm1.06-12.63a4.2 4.2 0 00-3 1.11 4.51 4.51 0 00-1.1 3.52 8.15 8.15 0 002.43 5l.85.85a16 16 0 007.13-7.19l-.82-.82a8.08 8.08 0 00-5.49-2.47z'
            />
            <path
                className='Identity_svg__cls-1'
                d='M26.62 31.56a8.45 8.45 0 012.31-6.81c3.59-3.59 10-3 14.3 1.28L48 30.8V28l-3.35-3.35c-5.08-5.11-12.76-5.65-17.14-1.31a10.44 10.44 0 00-2.89 8.38 14.05 14.05 0 004.17 8.75l7.48 7.45h2.82l-8.88-8.85a12 12 0 01-3.59-7.51zm-2.69 5.33a15.27 15.27 0 01-1.13-3.69 22.13 22.13 0 01-2.62 7.16 39.48 39.48 0 01-3.93 6 1 1 0 00.09 1.41 1 1 0 001.41-.09A40.74 40.74 0 0022 41.25a32.07 32.07 0 001.93-4.36zM20 12h-.59A10 10 0 0010 22c0 .14.93 13.24-2.59 18.12a1 1 0 00.23 1.4 1.05 1.05 0 00.58.19 1 1 0 00.78-.35c4-5.47 3-18.87 3-19.36a8 8 0 0115.85-1.5 12.41 12.41 0 011.82-1A10 10 0 0020 12z'
            />
            <path
                className='Identity_svg__cls-1'
                d='M20 0h-.59A20.91 20.91 0 003.7 7.24a30.62 30.62 0 00-2.08 3c-.5.79-1 1.54-1.42 2.14a1 1 0 00.2 1.4A1 1 0 001 14a1 1 0 00.8-.4c.5-.67 1-1.45 1.51-2.27a27.84 27.84 0 011.93-2.81A19 19 0 0120 2a20 20 0 0119.79 17.25 15.38 15.38 0 012.13.92A22 22 0 0020 0z'
            />
            <path
                className='Identity_svg__cls-1'
                d='M20 4h-.59A18 18 0 002 22c0 1.11-.71 5.29-1.71 6.29a1 1 0 000 1.42 1 1 0 001.42 0C3.4 28 4 23 4 22a16 16 0 0131.58-3.57h.31a13.52 13.52 0 011.79.25A18 18 0 0020 4z'
            />
            <path
                className='Identity_svg__cls-1'
                d='M20 8h-.59A14 14 0 006 22a28 28 0 01-2.87 12.51 1 1 0 00.38 1.36A1 1 0 004 36a1 1 0 00.87-.51A29.51 29.51 0 008 22a12 12 0 0123.56-3.17 11.7 11.7 0 012-.34A14 14 0 0020 8z'
            />
            <path d='M25 22a5 5 0 11-5-5 5 5 0 015 5z' fill='#d7153a' />
        </svg>
    );
};

BrandIconIdentity.propTypes = {
    title: PropTypes.string,
    titleId: PropTypes.string,
    size: PropTypes.oneOf(['lg', 'xl', 'xxl']),
};
BrandIconIdentity.defaultProps = {
    size: 'xl',
    focusable: false,
};
export default BrandIconIdentity;
