import styled from 'styled-components/macro';
import { getSpacing, pxToRem, getBreakpoint } from '../../utils';
import tokens from '../../Tokens/tokens';
import { skeletonAnimation } from './SkeletonContainer.styled';

const { font } = tokens;

export const SkeletonParagraph = styled.div`
    ${skeletonAnimation};
    height: ${pxToRem(font.size.base)};
    margin-bottom: ${pxToRem(8)};

    :last-child {
        margin-bottom: 0;
    }

    :last-child:not(:first-child) {
        max-width: ${props => props.widthLast && pxToRem(props.widthLast)};
    }
`;

export const SkeletonParagraphContainer = styled.div`
    width: 100%;
    max-width: ${props => props.width && pxToRem(props.width)};
    margin-top: ${getSpacing('sm')};
    margin-bottom: ${getSpacing('sm')};

    ${getBreakpoint.md`
        margin-top: ${getSpacing('md')};
        margin-bottom: ${getSpacing('md')};
    `};

    ${props => props.noMargin && 'margin: 0 !important'};
`;

export const StyledSkeletonText = styled.div`
    ${skeletonAnimation};
    width: 100%;
    max-width: ${props => props.width && pxToRem(props.width)};
    height: ${pxToRem(font.size.base)};
    margin-top: ${getSpacing('sm')};
    margin-bottom: ${getSpacing('sm')};

    ${getBreakpoint.md`
        margin-top: ${getSpacing('md')};
        margin-bottom: ${getSpacing('md')};
    `};

    ${props => props.noMargin && 'margin: 0 !important'};
`;
