import * as React from 'react';
import PropTypes from 'prop-types';
import tokens from '../../Tokens/tokens';
const { spacing } = tokens;

const BrandIconClothingRetail = ({ title, titleId, ...props }) => {
    const sizeArray = Object.entries(spacing.icons);
    const sizeOptions = new Map(sizeArray);
    const displaySize = sizeOptions.get(props.size);
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 48 48'
            fill={props.color && displayColor}
            aria-hidden={!title}
            role={title ? 'img' : null}
            width={props.size && displaySize}
            height={props.size && displaySize}
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <defs>
                <style>{'.ClothingRetail_svg__cls-1{fill:#002664}'}</style>
            </defs>
            <path
                className='ClothingRetail_svg__cls-1'
                d='M41.71 33.29a1 1 0 00-1.42 0l-9 9a1 1 0 000 1.42 1 1 0 001.42 0l9-9a1 1 0 000-1.42zm-1.42 5l-4 4a1 1 0 000 1.42 1 1 0 001.42 0l4-4a1 1 0 00-1.42-1.42zm7.64-27.63a.36.36 0 000-.09v-.08l-6-10A1 1 0 0041 0H7a1 1 0 00-.86.49l-6 10A1 1 0 000 11v4a5.5 5.5 0 0010.07 3.14 5.56 5.56 0 009.21 0 5.6 5.6 0 009.26 0 5.61 5.61 0 004.63 2.45 5.67 5.67 0 004.68-2.46 5.66 5.66 0 004.68 2.46A5.46 5.46 0 0048 15v-4a1 1 0 00-.07-.34zm-5.4 7.92a3.67 3.67 0 01-3.68-3.66 1 1 0 00-2 0 3.66 3.66 0 11-7.31 0 1 1 0 00-2 0 3.63 3.63 0 11-7.26 0 1 1 0 00-2 0 3.61 3.61 0 11-7.21 0 1 1 0 00-1-1 1 1 0 00-.75.35A1 1 0 009 15a3.47 3.47 0 01-3.51 3.58A3.46 3.46 0 012 15v-3.72L7.57 2h5l-1.52 4.68A1 1 0 0011.68 8 1.25 1.25 0 0012 8a1 1 0 001-.68L14.72 2h5.1L19 6.84A1 1 0 0019.84 8H20a1 1 0 001-.84L21.85 2h4.3L27 7.16A1 1 0 0028 8h.16A1 1 0 0029 6.84L28.18 2h5.1l1.77 5.32A1 1 0 0036 8a1.25 1.25 0 00.32 0A1 1 0 0037 6.68L35.39 2h5l4.8 8H7a1 1 0 000 2h39v3a3.45 3.45 0 01-3.47 3.58z'
            />
            <path
                className='ClothingRetail_svg__cls-1'
                d='M47 46h-1V24a1 1 0 00-2 0v22H20V24a1 1 0 00-2 0v22H4V24a1 1 0 00-2 0v22H1a1 1 0 000 2h46a1 1 0 000-2z'
            />
            <path d='M11 35a5 5 0 115-5 5 5 0 01-5 5z' fill='#d7153a' />
        </svg>
    );
};

BrandIconClothingRetail.propTypes = {
    title: PropTypes.string,
    titleId: PropTypes.string,
    size: PropTypes.oneOf(['lg', 'xl', 'xxl']),
};
BrandIconClothingRetail.defaultProps = {
    size: 'xl',
    focusable: false,
};
export default BrandIconClothingRetail;
