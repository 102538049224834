import * as React from 'react';
import PropTypes from 'prop-types';
import tokens from '../../Tokens/tokens';
const { spacing } = tokens;

const BrandIconBirthsDeathsMarriages = ({ title, titleId, ...props }) => {
    const sizeArray = Object.entries(spacing.icons);
    const sizeOptions = new Map(sizeArray);
    const displaySize = sizeOptions.get(props.size);
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 48 48'
            fill={props.color && displayColor}
            aria-hidden={!title}
            role={title ? 'img' : null}
            width={props.size && displaySize}
            height={props.size && displaySize}
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <defs>
                <style>{'.BirthsDeathsMarriages_svg__cls-1{fill:#002664}'}</style>
            </defs>
            <path
                className='BirthsDeathsMarriages_svg__cls-1'
                d='M27 20h-6a1 1 0 010-2h6a1 1 0 010 2zm3 4H18a1 1 0 010-2h12a1 1 0 010 2zm-2 4h-8a1 1 0 010-2h8a1 1 0 010 2z'
            />
            <path d='M28 40a5 5 0 115-5 5 5 0 01-5 5z' fill='#d7153a' />
            <path
                className='BirthsDeathsMarriages_svg__cls-1'
                d='M36.84 48H11.16A3.17 3.17 0 018 44.84V11.17A3.16 3.16 0 0111.16 8h25.68A3.16 3.16 0 0140 11.17v33.67A3.17 3.17 0 0136.84 48zM11.16 10A1.16 1.16 0 0010 11.17v33.67A1.16 1.16 0 0011.16 46h25.68A1.16 1.16 0 0038 44.84V11.17A1.16 1.16 0 0036.84 10z'
            />
            <path
                className='BirthsDeathsMarriages_svg__cls-1'
                d='M16.45 36a.94.94 0 01-.55-.17 1 1 0 01-.28-1.39l1.09-1.63a1 1 0 011.76.17 1 1 0 01.61-.11 1 1 0 01.68.42l.35.51.56-.19a1 1 0 011.27.61 1 1 0 01-.61 1.28l-1.28.44a1 1 0 01-1.15-.37l-.17-.24-.55.45a1 1 0 01-1.07.12l-.11-.06a1 1 0 01-.55.16z'
            />
            <path
                className='BirthsDeathsMarriages_svg__cls-1'
                d='M35.61 34.35a52.72 52.72 0 01-.36-6.28 53.62 53.62 0 01.36-6.32 57.91 57.91 0 00.39-6.53 1 1 0 00-1-1c-1.15 0-1.19 0-1.19-1.19a1 1 0 00-.38-.78 1 1 0 00-.85-.2l-.26.06A31.73 31.73 0 0124 13.2a31.35 31.35 0 01-8.26-1.08l-.29-.07a1 1 0 00-.85.2 1 1 0 00-.38.78c0 1.15 0 1.19-1.19 1.19a1 1 0 00-1 1 57.91 57.91 0 00.37 6.53 53.62 53.62 0 01.36 6.32 52.72 52.72 0 01-.36 6.28 57 57 0 00-.4 6.49 1 1 0 001 1c.36 0 1.19 0 1.19 1.2a1 1 0 00.35.75 1 1 0 00.66.25h.41a51.39 51.39 0 018.39-.68 52 52 0 018.4.64h.25a1 1 0 00.79-.24 1 1 0 00.35-.75c0-1.2.83-1.2 1.19-1.2a1 1 0 001-1 57 57 0 00-.37-6.46zm-3.67 7.59a51.83 51.83 0 00-8-.58 51.65 51.65 0 00-7.93.58A2.81 2.81 0 0014 40c0-2.3.19-3.83.35-5.44a54.37 54.37 0 00.38-6.49 55.27 55.27 0 00-.38-6.53c-.16-1.6-.32-3.13-.35-5.4a2.29 2.29 0 002.07-1.89 30.85 30.85 0 007.93.95 31 31 0 007.91-.95A2.29 2.29 0 0034 16.14c0 2.27-.19 3.8-.35 5.4a55.27 55.27 0 00-.38 6.53 54.37 54.37 0 00.38 6.49c.16 1.61.32 3.14.35 5.44a2.81 2.81 0 00-2.06 1.94zM32 6l-5-3a3 3 0 00-6 0l-5 3h3.89l1.79-1.07a3 3 0 004.56 0L28.07 6zm-8-2a1 1 0 111-1 1 1 0 01-1 1z'
            />
        </svg>
    );
};

BrandIconBirthsDeathsMarriages.propTypes = {
    title: PropTypes.string,
    titleId: PropTypes.string,
    size: PropTypes.oneOf(['lg', 'xl', 'xxl']),
};
BrandIconBirthsDeathsMarriages.defaultProps = {
    size: 'xl',
    focusable: false,
};
export default BrandIconBirthsDeathsMarriages;
