import React, { Component } from 'react';

import AddressAutoSuggest from '../PrimitiveAddress/AddressV2AutoSuggest';
import PropTypes from 'prop-types';
import nswPointV2Api from '../PrimitiveAddress/nswPointV2Api';
import { trackMe } from '../../Components/ComponentAnalytics/componentAnalytics';

const defaultManualFields = {
    suburb: '',
    state: '',
    postcode: '',
    addressLine: '',
};

const defaultAddressFields = {
    ...defaultManualFields,
    nswPointId: '',
    formattedAddress: '',
    unitNumber: '',
    buildingNumber: '',
    streetNumber: '',
    streetName: '',
    streetType: '',
    propertyName: '',
    latitude: '',
    longitude: '',
};

const errorMessage = address => {
    const manualAddressMessages = Object.keys(defaultManualFields)
        // eslint-disable-next-line security/detect-object-injection
        .filter(key => !address[key])
        .reduce((acc, cur) => ({ ...acc, [cur]: 'This field is required.' }), {});

    const autoSuggestMessage = address.formattedAddress ? {} : { autoSuggest: 'This field is required.' };
    return { ...manualAddressMessages, ...autoSuggestMessage };
};

const isComplete = address => {
    if (address.validated) {
        return true;
    }

    // eslint-disable-next-line security/detect-object-injection
    return Object.keys(defaultManualFields).every(key => address[key]);
};

const isAddressEqual = (address, otherAddress) => {
    const keys = Object.keys(defaultAddressFields);
    // eslint-disable-next-line security/detect-object-injection
    return keys.every(key => address[key] === otherAddress[key]);
};

export class Address extends Component {

    constructor() {
        super();
        trackMe('Address');
    };

    static defaultProps = {
        address: {},
        addressLabel: 'Business address',
        addressType: 'business',
        apiKey: '',
        required: false,
        showErrors: false,
        errorMessage,
        hasError: address => !isComplete(address),
        backendAPI: nswPointV2Api,
        onBlur: () => { }
    };

    state = {
        hasError: false,
        errorMessage: {}
    };

    componentDidMount() {
        this.handleChange(this.props.address);
    }

    componentDidUpdate(prevProps) {
        if (!isAddressEqual(prevProps.address, this.props.address)) {
            this.handleChange(this.props.address);
        }
        if (prevProps.hasError !== this.props.hasError && typeof (this.props.hasError) === 'boolean') {
            this.setState({ hasError: this.props.hasError });
        }
        if (prevProps.errorMessage !== this.props.errorMessage && typeof (this.props.errorMessage) === 'object') {
            this.setState({ errorMessage: this.props.errorMessage });
        }
    }

    setAddressFields = ({ showManual, ...addressFields }) => {
        this.handleChange(addressFields);
    };

    setManualAddress = () => {
        this.handleChange({ ...defaultAddressFields, validated: false });
        this.props.onSwitchToManual && this.props.onSwitchToManual();
    };

    handleChange = address => {
        const hasError = this.hasError(address);
        const errorMessage = this.errorMessage(address);

        this.setState({ hasError, errorMessage });
        this.props.onChange({
            hasError,
            isComplete: isComplete(address),
            address: { ...address, country: 'Australia' },
        });
    };

    errorMessage = address =>
        typeof (this.props.errorMessage) === 'function' ?
            this.props.errorMessage(address) :
            this.props.errorMessage;

    hasError = address =>
        typeof (this.props.hasError) === 'function' ?
            this.props.hasError(address) :
            this.props.hasError;

    render() {
        return (
            <AddressAutoSuggest
                id={ this.props.id }
                name={ this.props.name }
                disabled={ this.props.disabled }
                placeholder={ this.props.placeholder }
                addressType={ this.props.addressType }
                apiKey={ this.props.apiKey }
                label={ this.props.addressLabel }
                onSwitchToManual={ this.setManualAddress }
                onSwitchToAutoSuggest={ this.props.onSwitchToAutoSuggest }
                onAddressHasBecomeInvalid={ this.setAddressFields }
                onAddressHasBecomeValid={ this.setAddressFields }
                data-test='address'
                value={ { ...this.props.address } }
                emptyAddressValid={ !this.props.required }
                helpMessage={ this.props.helpMessage }
                manualModeMessage={ this.props.manualModeMessage }
                manualAddressHeading={ this.props.manualAddressHeading }
                hasError={ this.props.showErrors && this.state.hasError }
                errorMessage={ this.state.errorMessage }
                backendAPI={ this.props.backendAPI }
                onBlur={ this.props.onBlur }
                apiState={ this.props.apiState }
            />
        );
    }
}

Address.propTypes = {
    id: PropTypes.string,
    name: PropTypes.string,
    disabled: PropTypes.bool,
    placeholder: PropTypes.string,
    address: PropTypes.object.isRequired,
    addressLabel: PropTypes.string.isRequired,
    apiState: PropTypes.string,
    addressType: PropTypes.string.isRequired,
    apiKey: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    required: PropTypes.bool.isRequired,
    helpMessage: PropTypes.string,
    manualModeMessage: PropTypes.string,
    manualAddressHeading: PropTypes.string,
    showErrors: PropTypes.bool.isRequired,
    backendAPI: PropTypes.shape({
        getLatestSuggestions: PropTypes.func.isRequired,
        getDetailsById: PropTypes.func.isRequired
    }).isRequired,
    errorMessage: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    hasError: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
    onSwitchToManual: PropTypes.func,
    onSwitchToAutoSuggest: PropTypes.func,
};

export default Address;
