import styled from 'styled-components/macro';
import { H6 } from '../../Headers/HeaderTag.styled';
import { IconArrowRight, IconExternalLink } from '../../Icons/system';
import { getSpacing, pxToRem } from '../../utils';
import tokens from '../../Tokens/tokens';

const { colors } = tokens;

export const ArrowRight = styled(IconArrowRight).attrs({
    color: 'secondaryBlue',
})``;

export const ExternalLink = styled(IconExternalLink).attrs({
    color: 'secondaryBlue',
    title: 'Opens in a new tab'
})`
    width: ${pxToRem(12)};
    height: ${pxToRem(12)};
`;

export const LinkCardContainer = styled.div`
    cursor: pointer;
    color: ${colors.brand.snswDarkGrey};
    height: ${props => props.height};
    background-color: white;
    box-shadow: ${getSpacing([0, 2, 'xs', 0])} rgba(0, 0, 0, 0.07);
    border-top: 4px solid ${colors.brand.snswSecondaryBlue};
    border-radius: 4px;
    padding: ${getSpacing(['sm', 'md'])};
    margin-bottom: ${getSpacing('md')};
    position: relative;

    @media (max-width: 576px) {
        height: ${pxToRem(208)};
    }

    :hover {
        cursor: pointer;
        text-decoration: none;
        color: white;
        background-color: ${colors.brand.snswSecondaryBlue};

        ${ArrowRight} {
            margin-left: ${pxToRem(16)};
            fill: white;
        }
        ${ExternalLink} {
            fill: white;
        }
    }


    ${H6} {
        margin-bottom: 0.5rem;
    }

    ${ArrowRight} {
        position: absolute;
        left: ${pxToRem(24)};
        bottom: ${pxToRem(24)};
        margin-left: 0;
        transition: margin-left 500ms ease-in-out;
    }

    ${ExternalLink} {
        margin-left: ${pxToRem(2)};
    }
`;

export const LinkCardTitle = styled.div`
    display: flex;
`;
