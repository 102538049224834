import styled from 'styled-components/macro';
import { genericButtonStyles, secondaryButtonStyle } from '../Button/Button.styled';
import tokens from '../../Tokens/tokens';

export const FileInputButton = styled.label`
    ${genericButtonStyles};
    ${secondaryButtonStyle};
    max-width: 200px;

    &:focus-within {
        outline: 2px solid ${tokens.colors.text.link};
        outline-offset: 2px;
    }

    @media (-ms-high-contrast: active), (forced-colors: active) {
        &:focus-within {
            -ms-high-contrast-adjust: none;
            forced-color-adjust: none;
            outline-color: Highlight;
            background-color: Background;
            border-color: InactiveBorder;
            color: InfoText;
            transition: none;
        }
    }
`;
