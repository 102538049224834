import React from 'react';
import PropTypes from 'prop-types';
import { Separator, SeparatorContainer } from './Separator.styled';

const IconSeparator = ({ children }) =>
    <SeparatorContainer>
        {children}
        <Separator />
    </SeparatorContainer>;

IconSeparator.propTypes = {
    children: PropTypes.node
};

export default IconSeparator;
