import ExternalLink from './ExternalLink';
import Cancel from './Cancel';
import Cross from './Cross';
import Clock from './Clock';
import { ChevronDown, ChevronLeft, ChevronRight, ChevronUp } from './Chevron';
import Delete from './Delete';
import Info from './Info';
import Highlight from './Highlight';
import Spinner from './Spinner';
import Question from './Question';
import Arrow from './Arrow';
import Car from './Car';
import Boat from './Boat';
import Home from './Home';
import Legal from './Legal';
import Business from './Business';
import Health from './Health';
import Cost from './Cost';
import Education from './Education';
import Life from './Life';
import Print from './Print';
import Tick from './Tick';
import Pencil from './Pencil';
import TextBox from './TextBox';
import TickCircle from './TickCircle';

const Icons = {
    Arrow,
    Cancel,
    Clock,
    ChevronRight,
    ChevronLeft,
    ChevronDown,
    ChevronUp,
    Cross,
    Delete,
    ExternalLink,
    Highlight,
    Info,
    Question,
    Spinner,
    Car,
    Boat,
    Home,
    Legal,
    Business,
    Health,
    Cost,
    Education,
    Life,
    Print,
    Tick,
    Pencil,
    TextBox,
    TickCircle
};

export default Icons;
