import * as React from 'react';
import PropTypes from 'prop-types';
import tokens from '../../Tokens/tokens';
const { spacing } = tokens;

const BrandIconCafeRestaurantSmallBar = ({ title, titleId, ...props }) => {
    const sizeArray = Object.entries(spacing.icons);
    const sizeOptions = new Map(sizeArray);
    const displaySize = sizeOptions.get(props.size);
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 48 48'
            fill={props.color && displayColor}
            aria-hidden={!title}
            role={title ? 'img' : null}
            width={props.size && displaySize}
            height={props.size && displaySize}
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <defs>
                <style>{'.CafeRestaurantSmallBar_svg__cls-1{fill:#002664}'}</style>
            </defs>
            <path
                className='CafeRestaurantSmallBar_svg__cls-1'
                d='M23.48 31a1 1 0 00-.9 1.08l1 11a1 1 0 001 .91h.1a1 1 0 00.9-1.09l-1-11a1 1 0 00-1.1-.9zm10.19 0a1 1 0 00-1.09.9l-1 11a1 1 0 00.9 1.09h.1a1 1 0 001-.91l1-11a1 1 0 00-.91-1.08zm-5.09 0a1 1 0 00-1 1v11a1 1 0 002 0V32a1 1 0 00-1-1z'
            />
            <path d='M12.57 15a5 5 0 115-5 5 5 0 01-5 5z' fill='#d7153a' />
            <path
                className='CafeRestaurantSmallBar_svg__cls-1'
                d='M44.58 10h-11V6.59l8.48-4.71a1 1 0 10-1-1.75l-9 5a1 1 0 00-.51.87v13H16.29L16 13.61a5 5 0 01-1.95 1.14l.61 12.3a1 1 0 00.1.38l1.81 3.83 1 15.8a1 1 0 001 .94h20a1 1 0 001-.93l1.1-15.81 1.81-3.83a1 1 0 00.09-.37L43.51 12h1.07a1 1 0 000-2zm-4 16.75l-1.81 3.82a1 1 0 00-.09.36L37.64 46H19.51l-.94-15.06a1 1 0 00-.09-.37l-1.81-3.82-.28-5.75H41zm.49-7.75h-7.49v-7h7.93z'
            />
            <path className='CafeRestaurantSmallBar_svg__cls-1' d='M17.15 12h12.43v-2h-12a5 5 0 01-.43 2z' />
            <path
                className='CafeRestaurantSmallBar_svg__cls-1'
                d='M7.65 16.3a8.07 8.07 0 01-2.72-4l3-.52A5 5 0 017.58 10v-.14l-3 .52V10a8 8 0 011.3-4.36l2.33 2A5 5 0 019.5 6.09l-2.32-2a7.92 7.92 0 014.47-2.06v3a5 5 0 011-.1 4.57 4.57 0 011 .12v-3a8 8 0 014.59 2.28l-2.47 1.86A5 5 0 0117 7.75L19.46 6a8.06 8.06 0 01.85 2h2.06a10 10 0 10-10.16 12l-.11-2a8 8 0 01-2.73-.65l1.56-2.63a4.87 4.87 0 01-1.71-1z'
            />
        </svg>
    );
};

BrandIconCafeRestaurantSmallBar.propTypes = {
    title: PropTypes.string,
    titleId: PropTypes.string,
    size: PropTypes.oneOf(['lg', 'xl', 'xxl']),
};
BrandIconCafeRestaurantSmallBar.defaultProps = {
    size: 'xl',
    focusable: false,
};
export default BrandIconCafeRestaurantSmallBar;
