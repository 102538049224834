import * as React from 'react';
import PropTypes from 'prop-types';
import tokens from '../../Tokens/tokens';
const { spacing } = tokens;

const BrandIconBookStudy = ({ title, titleId, ...props }) => {
    const sizeArray = Object.entries(spacing.icons);
    const sizeOptions = new Map(sizeArray);
    const displaySize = sizeOptions.get(props.size);
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 48 48'
            fill={props.color && displayColor}
            aria-hidden={!title}
            role={title ? 'img' : null}
            width={props.size && displaySize}
            height={props.size && displaySize}
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <path
                d='M13 31h27V0H15c-4.58 0-7 2.43-7 7v28.64A5 5 0 0013 41h6v7l4-2.67L27 48v-6.94h13v-2H27v-3h13v-2H14a1 1 0 100 2h5V39h-6a3.06 3.06 0 01-3-3.35v-1c0-1.81 1.05-3.65 3-3.65zm25-2H16V2h22zM10 7c0-3.12 1.26-4.68 4-5v27h-1a4.68 4.68 0 00-3 1.1zm15 29v8.2l-2-1.34-2 1.34v-8.15z'
                fill='#002664'
            />
            <path d='M26 21a5 5 0 10-5-5 5 5 0 005 5z' fill='#d7153a' />
        </svg>
    );
};

BrandIconBookStudy.propTypes = {
    title: PropTypes.string,
    titleId: PropTypes.string,
    size: PropTypes.oneOf(['lg', 'xl', 'xxl']),
};
BrandIconBookStudy.defaultProps = {
    size: 'xl',
    focusable: false,
};
export default BrandIconBookStudy;
