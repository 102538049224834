import styled from 'styled-components/macro';
import tokens from '../../Tokens/tokens';
import { pxToRem, getSpacing } from '../../utils';
import { baseInputStyles } from '../Form/Field.styled';

const { colors, layouts } = tokens;

export const AutoSuggestContainer = styled.div`

    .react-autosuggest__input {
        ${baseInputStyles}
        position: relative;

        &--open {
            border-radius: 6px 6px 0 0;
        }

        &:focus {
            z-index: 2;
        }
    }

    .react-autosuggest__container {
      position: relative;
    }

    .react-autosuggest__suggestions-container {
        display: none;
        position: absolute;
        top: 100%;
        left: 0;
        width: ${props => props.inputWidth ? pxToRem(layouts.inputWidth[props.inputWidth]) : '100%'};
        z-index: 1;
        overflow: auto;
        max-height: ${pxToRem(358)};
        border-radius: 0 0 6px 6px;
        box-shadow: 0 2px 18px 0 rgba(0, 0, 0, 0.05);
        border: 2px solid ${colors.forms.borderColor};
        border-top: none;
        background-color: ${colors.white};

        &--open {
            display: block;
        }
    }

    .react-autosuggest__suggestions-list {
        list-style: none;
        padding: 0;
        margin: 0;
    }

    .react-autosuggest__suggestion {
        display: flex;
        align-items: center;
        min-height: ${pxToRem(24)};
        padding: ${getSpacing(['13', 'sm', '13', 'sm'])};
        margin: 0;
        border-top: 1px solid ${colors.grey.geyser};
    }

    .react-autosuggest__suggestion--first {
        border-top: none;
    }

    .react-autosuggest__suggestion--highlighted {
        background: ${colors.brand.snswSecondaryBlue};
        color: ${colors.white};
    }
`;
