import React from 'react';
import styled from 'styled-components/macro';
import { color, layout } from 'styled-system';
import tokens from '../../Tokens/tokens';

const { colors } = tokens;

const DeleteSvg = ({ className, color, width, height }) => {
    return (
        <svg
            className={ className }
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 24 24'
            fill={ colors.legacy[color] || color }
            width={ width }
            height={ height }
            focusable='false'
            aria-hidden='true'
        >
            <path d='M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z'/>
            <path d='M0 0h24v24H0z' fill='none'/>
        </svg>
    );
};

const Delete = styled(DeleteSvg)`
  ${color}
  ${layout}
`;

Delete.defaultProps = {
    color: `${colors.brand.snswSecondaryBlue}`,
    width: 24,
    height: 24,
};

export default Delete;
