import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { LinkContainer } from './External.styled';
import { IconExternalLink } from '../Icons/system';
import { trackMe } from '../Components/ComponentAnalytics/componentAnalytics';
import { getID } from '../utils';

const External = ({ className, children, newTab, href, onClick }) => {

    useEffect(() => {
        trackMe('External');
    }, []);

    const elemID = getID();

    return (
        <LinkContainer
            className={ className }
            href={ href }
            target={ newTab ? '_blank' : '_self' }
            rel='noopener noreferrer'
            onClick={ onClick }
        >
            {children}
            <IconExternalLink
                color='secondaryBlue'
                title='Opens in a new tab'
                titleId={ elemID }
            />
        </LinkContainer>
    );
};

External.propTypes = {
    className: PropTypes.string,
    href: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    newTab: PropTypes.bool,
    onClick: PropTypes.func,
};

External.defaultProps = {
    newTab: true
};

export default External;
