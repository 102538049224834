import * as React from 'react';
import PropTypes from 'prop-types';
import tokens from '../../Tokens/tokens';
const { colors, spacing } = tokens;

const IconRefresh = ({ title, titleId, ...props }) => {
    const sizeArray = Object.entries(spacing.icons);
    const colorArray = Object.entries(colors.icons);
    const sizeOptions = new Map(sizeArray);
    const displaySize = sizeOptions.get(props.size);
    const colorOptions = new Map(colorArray);
    const displayColor = colorOptions.get(props.color);
    return (
        <svg
            viewBox='0 0 16 16'
            xmlns='http://www.w3.org/2000/svg'
            fill={props.color && displayColor}
            aria-hidden={!title}
            role={title ? 'img' : null}
            width={props.size && displaySize}
            height={props.size && displaySize}
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <path d='M11.6908 0.902239C12.5722 1.36057 13.3531 1.97504 14 2.7085V1C14 0.447715 14.4477 1.51711e-08 15 1.51711e-08C15.5523 1.51711e-08 16 0.447715 16 1V5C16 5.55228 15.5523 6 15 6H11C10.4477 6 10 5.55228 10 5C10 4.44772 10.4477 4 11 4H12.4722C11.3736 2.77251 9.77701 2.00001 8.00001 2.00001C4.6863 2.00001 2.00001 4.6863 2.00001 8.00001C2.00001 11.3137 4.6863 14 8.00001 14C10.529 14 12.6925 12.4354 13.5754 10.2213C13.7799 9.70852 14.304 9.36022 14.8436 9.47666C15.3829 9.59301 15.7316 10.1276 15.549 10.6481C15.0301 12.1276 14.0847 13.4296 12.822 14.3834C11.2923 15.539 9.39838 16.1068 7.48519 15.9834C5.572 15.8601 3.76674 15.0537 2.39807 13.7112C1.0294 12.3688 0.188343 10.5794 0.0280308 8.66897C-0.132282 6.75852 0.398808 4.85403 1.52461 3.30223C2.65041 1.75043 4.29605 0.654524 6.16193 0.214035C8.0278 -0.226454 9.98981 0.0177688 11.6908 0.902239Z' />
        </svg>
    );
};

IconRefresh.propTypes = {
    title: PropTypes.string,
    titleId: PropTypes.string,
    size: PropTypes.oneOf(['sm', 'md']),
    color: PropTypes.oneOf(['primaryBlue', 'secondaryBlue', 'darkGrey', 'white']),
};
IconRefresh.defaultProps = {
    size: 'sm',
    color: 'darkGrey',
    focusable: false,
};
export default IconRefresh;
