import React from 'react';
import styled from 'styled-components/macro';
import { color, layout } from 'styled-system';
import tokens from '../../Tokens/tokens';

const { colors } = tokens;

// eslint-disable-next-line react/prop-types
const PrintSvg = ({className, color, width, height}) => (
    <svg className={ className } xmlns='http://www.w3.org/2000/svg' viewBox='0 0 44 44' fill={ colors.legacy[color] || color } width={ width } height={ height } focusable='false' aria-hidden='true'>
        <path d='M0 0h24v24H0z' fill='none' />
        <path d='M19 8H5c-1.66 0-3 1.34-3 3v6h4v4h12v-4h4v-6c0-1.66-1.34-3-3-3zm-3 11H8v-5h8v5zm3-7c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1zm-1-9H6v4h12V3z' />
    </svg>
);

export const Print = styled(PrintSvg)`
  ${color}
  ${layout}
`;

Print.defaultProps = {
    color: `${colors.brand.snswSecondaryBlue}`,
    height: 44,
    width: 44,
};

export default Print;
