import * as React from 'react';
import PropTypes from 'prop-types';
import tokens from '../../Tokens/tokens';
const { spacing } = tokens;

const BrandIconSafeWorkingEnvironment = ({ title, titleId, ...props }) => {
    const sizeArray = Object.entries(spacing.icons);
    const sizeOptions = new Map(sizeArray);
    const displaySize = sizeOptions.get(props.size);
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 48 48'
            fill={props.color && displayColor}
            aria-hidden={!title}
            role={title ? 'img' : null}
            width={props.size && displaySize}
            height={props.size && displaySize}
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <defs>
                <style>{'.SafeWorkingEnvironment_svg__cls-1{fill:#002664}'}</style>
            </defs>
            <path
                className='SafeWorkingEnvironment_svg__cls-1'
                d='M22 9H6a1 1 0 00-1 1v16a1 1 0 001 1h8.94a4.83 4.83 0 01.23-2H7V11h14v8.1c.7-.59 1.37-1.18 2-1.75V10a1 1 0 00-1-1z'
            />
            <path d='M19 18a5 5 0 11-5-5 5 5 0 015 5z' fill='#d7153a' />
            <path
                className='SafeWorkingEnvironment_svg__cls-1'
                d='M41.17 22.13l-4.86-10a5 5 0 00-6.83-1.83c-1.39.8-2.43 3-3.65 5.81a16.23 16.23 0 01-1.08 2.23c-.69.71-5 4.47-6.57 5.8a3 3 0 001.2 5.41 3.15 3.15 0 00.53 0h.17a2.57 2.57 0 00.4 0h.15a3.12 3.12 0 00.43-.15h.08a2.82 2.82 0 00.48-.27l7.91-6a1 1 0 00.31-.38l2.68-5.77a1 1 0 00-1.79-.98l-2.57 5.55-7.69 5.82a.93.93 0 01-.74.17 1 1 0 01-.65-.41 1 1 0 01.33-1.46c.24-.2 5.88-5.06 6.78-6a10.78 10.78 0 001.48-2.82c.7-1.63 1.88-4.35 2.81-4.88a3 3 0 014.06 1l4.84 10c.69 1.34.41 1.62-.75 2.77l-9.05 8a1 1 0 00-.15.2.76.76 0 00-.08.11.84.84 0 00-.05.17c0 .06 0 .13-.05.2s0 .08 0 .12a.84.84 0 000 .27l3.08 8.46a2 2 0 01-.07 1.53 2 2 0 01-1.13 1 2 2 0 01-2.56-1.2l-3.71-10.25c-.28-.77-.17-1 1.2-2.43l7.44-7.19a1 1 0 00.28-.9l-.65-3.47-1.45 3.12v.18L25 30.16l-.37.38C23.29 31.93 22.25 33 23 35l3.76 10.34A4 4 0 0030.5 48a4.08 4.08 0 001.37-.24 4 4 0 002.39-5.13l-2.85-7.81 8.47-7.45.16-.16c1.21-1.21 2.47-2.46 1.13-5.08zm-11.3.5l2.68-5.77M27 10a5 5 0 10-5-5 5 5 0 005 5zm0-8a3 3 0 11-3 3 3 3 0 013-3z'
            />
        </svg>
    );
};

BrandIconSafeWorkingEnvironment.propTypes = {
    title: PropTypes.string,
    titleId: PropTypes.string,
    size: PropTypes.oneOf(['lg', 'xl', 'xxl']),
};
BrandIconSafeWorkingEnvironment.defaultProps = {
    size: 'xl',
    focusable: false,
};
export default BrandIconSafeWorkingEnvironment;
