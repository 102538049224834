import * as React from 'react';
import PropTypes from 'prop-types';
import tokens from '../../Tokens/tokens';
const { spacing } = tokens;

const BrandIconFast = ({ title, titleId, ...props }) => {
    const sizeArray = Object.entries(spacing.icons);
    const sizeOptions = new Map(sizeArray);
    const displaySize = sizeOptions.get(props.size);
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 48 48'
            fill={props.color && displayColor}
            aria-hidden={!title}
            role={title ? 'img' : null}
            width={props.size && displaySize}
            height={props.size && displaySize}
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <defs>
                <style>{'.Fast_svg__cls-1{fill:#002664}'}</style>
            </defs>
            <path
                className='Fast_svg__cls-1'
                d='M13 26H1a1 1 0 010-2h12a1 1 0 010 2zm3-5H3a1 1 0 010-2h13a1 1 0 010 2zm0 10H4a1 1 0 010-2h12a1 1 0 010 2z'
            />
            <path
                className='Fast_svg__cls-1'
                d='M40.68 11.11L42.76 9l1.41 1.41a1 1 0 001.41 0 1 1 0 000-1.41l-2.11-2.09-2.12-2.12a1 1 0 00-1.42 1.42l1.41 1.41-2.24 2.24A20.93 20.93 0 0028 6.05V2h2.89a1 1 0 000-2H23a1 1 0 000 2h3v4a20.86 20.86 0 00-10.79 3.65l-1.89-1.88 1.41-1.41a1 1 0 000-1.42 1 1 0 00-1.41 0L9.08 9.18a1 1 0 00.71 1.7 1 1 0 00.71-.29l1.41-1.41 1.69 1.69A21.41 21.41 0 009.15 16h2.39a19 19 0 11-2.19 18H7.23a21 21 0 0019 14h1.5a21 21 0 0012.95-36.89z'
            />
            <path
                className='Fast_svg__cls-1'
                d='M18.88 14.91a12.77 12.77 0 0116.18 0 1 1 0 001.27-1.55 14.77 14.77 0 00-18.72 0 1 1 0 00.64 1.77.94.94 0 00.63-.22zM20 31a1 1 0 01-.53-1.85l19-12a1 1 0 111.06 1.7l-19 12A1 1 0 0120 31z'
            />
            <circle cx={27} cy={26} r={5} fill='#d7153a' />
        </svg>
    );
};

BrandIconFast.propTypes = {
    title: PropTypes.string,
    titleId: PropTypes.string,
    size: PropTypes.oneOf(['lg', 'xl', 'xxl']),
};
BrandIconFast.defaultProps = {
    size: 'xl',
    focusable: false,
};
export default BrandIconFast;
