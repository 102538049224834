import React from 'react';
import PropTypes from 'prop-types';
import FormStyled from './Form.styled';

const Form = ({ children, onSubmit, ...restProps }) => {
    const handleOnSubmit = e => {
        onSubmit && onSubmit(e);
        e.preventDefault();
    };
    return <FormStyled onSubmit={ handleOnSubmit } { ...restProps }>{children}</FormStyled>;
};

Form.propTypes = {
    children: PropTypes.node,
    onSubmit: PropTypes.func,
};

export default Form;