import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { DimmerContainer } from './Dimmer.styled';

const defaultAnimDuration = 300;

const Dimmer = props => {
    const { className, active, fullPage, children, animDuration = defaultAnimDuration } = props;
    const [isActive, setIsActive] = useState(active);

    useEffect(() => {
        if (active) {
            setIsActive(true);
        } else {
            const tm = setTimeout(() => {
                setIsActive(false);
            }, defaultAnimDuration);
            return () => {
                clearTimeout(tm);
            };
        }
    }, [active]);

    if (isActive || active) {
        return (
            <DimmerContainer
                className={ className }
                style={ {
                    opacity: isActive && active ? 1 : 0,
                    transitionDuration: `${animDuration}ms`
                } }
                fullPage={ fullPage }
            >
                {children}
            </DimmerContainer>
        );
    }

    return null;
};

Dimmer.propTypes = {
    className: PropTypes.string,
    active: PropTypes.bool,
    fullPage: PropTypes.bool,
};

Dimmer.defaultProps = {
    active: false,
    fullPage: false
};

export default Dimmer;
