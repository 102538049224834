import * as React from 'react';
import PropTypes from 'prop-types';
import tokens from '../../Tokens/tokens';
const { spacing } = tokens;

const BrandIconReporting = ({ title, titleId, ...props }) => {
    const sizeArray = Object.entries(spacing.icons);
    const sizeOptions = new Map(sizeArray);
    const displaySize = sizeOptions.get(props.size);
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 48 48'
            fill={props.color && displayColor}
            aria-hidden={!title}
            role={title ? 'img' : null}
            width={props.size && displaySize}
            height={props.size && displaySize}
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <defs>
                <style>{'.Reporting_svg__cls-2{fill:#002664}'}</style>
            </defs>
            <path d='M24 35a5 5 0 115-5 5 5 0 01-5 5z' fill='#d7153a' />
            <path
                className='Reporting_svg__cls-2'
                d='M31.21 13.74l2.47 2.38A2.94 2.94 0 0033 18a3 3 0 103-3 3.29 3.29 0 00-.54.05l-2.8-2.69A3 3 0 0033 11a3 3 0 00-6 0 2.92 2.92 0 00.32 1.32l-6 6a2.88 2.88 0 00-2.64 0l-4-4a3 3 0 10-1.41 1.41l4 4A2.92 2.92 0 0017 21a3 3 0 006 0 2.92 2.92 0 00-.32-1.32l6-6A2.92 2.92 0 0030 14a3.05 3.05 0 001.21-.26zM11 13a1 1 0 111 1 1 1 0 01-1-1zm9 9a1 1 0 111-1 1 1 0 01-1 1zm17-4a1 1 0 11-1-1 1 1 0 011 1zm-8-7a1 1 0 111 1 1 1 0 01-1-1z'
            />
            <path
                className='Reporting_svg__cls-2'
                d='M43 4H5a1 1 0 00-1 1v25a1 1 0 001 1h12v-2H6V6h36v23H31v2h12a1 1 0 001-1V5a1 1 0 00-1-1z'
            />
            <path
                className='Reporting_svg__cls-2'
                d='M43 0H4.87A5 5 0 000 5v29a5 5 0 005 5h15v7H10a1 1 0 000 2h28a1 1 0 000-2H28v-7h15a5 5 0 005-5V5a5 5 0 00-5-5zM26 46h-4v-7h4zm20-12a3 3 0 01-3 3H5a3 3 0 01-3-3V5a3 3 0 013-3h38a3 3 0 013 3z'
            />
        </svg>
    );
};

BrandIconReporting.propTypes = {
    title: PropTypes.string,
    titleId: PropTypes.string,
    size: PropTypes.oneOf(['lg', 'xl', 'xxl']),
};
BrandIconReporting.defaultProps = {
    size: 'xl',
    focusable: false,
};
export default BrandIconReporting;
