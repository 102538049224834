import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { BreadcrumbContainer, BreadcrumbList, BreadcrumbListItem } from './Breadcrumb.styled';
import { trackMe } from '../ComponentAnalytics/componentAnalytics';
import { IconChevronRight, IconChevronLeft } from '../../Icons/system';
import { Link } from 'react-router-dom';

export const Breadcrumb = ({
    className,
    linksList
}) => {

    useEffect(() => {
        trackMe('Breadcrumb');
    }, []);

    const breadcrumbListItems = linksList.map(({ content, path }, index) => {
        const isLastItem = index === linksList.length - 1;
        return (
            <BreadcrumbItem
                content={ content }
                path={ path }
                key={ index }
                isLastItem={ isLastItem }
            />
        );
    });

    return (
        <BreadcrumbContainer className={ className } aria-label='Breadcrumb'>
            <IconChevronLeft />
            <BreadcrumbList>
                {breadcrumbListItems}
            </BreadcrumbList>
        </BreadcrumbContainer>
    );
};

Breadcrumb.propTypes = {
    className: PropTypes.string,
    linksList: PropTypes.arrayOf(PropTypes.shape({
        content: PropTypes.string.isRequired,
        path: PropTypes.string.isRequired,
    })).isRequired,
};

export default Breadcrumb;

export const BreadcrumbItem = ({ content, path, isLastItem }) => (
    <BreadcrumbListItem>
        <Link to={ path } { ...isLastItem && { 'aria-current': 'page' } }> { content } </Link>
        {!isLastItem &&
            <IconChevronRight />
        }
    </BreadcrumbListItem>
);

BreadcrumbItem.propTypes = {
    path: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired
};
