import React from 'react';
import PropTypes from 'prop-types';
import { StyledTableHead } from './Table.styled';

const TableHead = ({ children, ...rest }) => (
    <StyledTableHead { ...rest }>
        {children}
    </StyledTableHead>
);

TableHead.propTypes = {
    children: PropTypes.node.isRequired
};

export default TableHead;
