import styled from 'styled-components/macro';
import PropTypes from 'prop-types';

export const HorizontalRule = styled.hr`
    display: block;
    margin-top: ${props => props.marginTop ? props.marginTop : '3.5rem'};
    margin-bottom: ${props => props.marginBottom ? props.marginBottom : '3.5rem'};
    margin-left: 0;
    margin-right: 0;
    height: 0;
    box-sizing: content-box;
    overflow: visible;
    background: none;
    border-top: 2px solid #dee3e5;
    border-bottom: none;
    border-left: none;
    border-right: none;
`;

HorizontalRule.propTypes = {
    className: PropTypes.string,
};

export default HorizontalRule;
