import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { FormLegend, FormLegendLabel, FormFieldset } from '../../Components/Form/Form.styled';
import DropZone from '../../Components/DropZone/DropZone';
import FileInput from '../../Components/FileInput/FileInput';
import UploadedItem from '../../Components/UploadedItem/UploadedItem';
import ComponentLoader from '../../Loader/ComponentLoader';
import Field from '../../Components/Form/Field.styled';
import { byteToMegabyte, useBoolean, detectDragDrop } from '../../utils';
import { SROnly } from '../../GlobalStyle/utilities/utilities.styled';
import { trackMe } from '../../Components/ComponentAnalytics/componentAnalytics';

const FileUpload = props => {
    const {
        id,
        name,
        initialFiles,
        legendText,
        maxMBSize,
        maxFiles,
        acceptedFormats,
        endpoint,
        onUploaded,
        onRemoved,
        errorMessage,
        api,
        ...rest
    } = props;
    const [errorMsg, setErrorMsg] = useState();
    const [hasError, setError, removeError] = useBoolean(false);
    const [loading, setLoading, removeLoading] = useBoolean(false);
    const [hasMaxFiles, setHasMaxFiles, removeHasMaxFiles] = useBoolean(false);
    const [uploadedFiles, setUploadedFiles] = useState([]);

    useEffect(() => {
        trackMe('FileUpload [GEL]');
        // set the initial files state once
        initialFiles && setUploadedFiles(initialFiles);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (errorMessage) {
            setErrorMsg(errorMessage);
            setError();
        } else {
            setErrorMsg('');
            removeError();
        }
    }, [errorMessage]); // eslint-disable-line

    useEffect(() => {
        const checkMaxFiles = uploadedFiles.length === maxFiles;
        checkMaxFiles ? setHasMaxFiles() : removeHasMaxFiles();
    },[uploadedFiles, maxFiles, setHasMaxFiles, removeHasMaxFiles]);

    const checkForErrors = error => {
        if (error.response) {
            if (error.response.status < 200 || error.response.status > 299) {
                if (error.response.status === 403) {
                    setErrorMsg(error.response.data.message);
                    setError();
                }
                else {
                    setErrorMsg('Request is unsuccessful');
                    setError();
                }
            }
        } else if (error.request) {
            setErrorMsg('Request is unsuccessful');
            setError();
        }
    };

    const uploadFile = async file => {
        setLoading();

        const content = new FormData();
        content.append('file', file);

        return await axios({ method: 'POST',
            url: `${endpoint}/${file.name}`,
            data: content })
            .then(response => {
                if (response.status >= 200 && response.status < 300) {
                    // set the newly uploaded file so we can return it (as a promise)
                    return { id: response.data, name: file.name, size: file.size, type: file.type };
                }
            })
            .catch(error => {
                checkForErrors(error);
            })
            .finally(() => {
                removeLoading();
            });
    };

    const removeFile = async fileId => {
        setLoading();
        if (api) {
            await api.removeFile(fileId);
            const newFiles = uploadedFiles.filter(file => file.id !== fileId);
            setUploadedFiles(newFiles);
            onRemoved && onRemoved(newFiles);
            removeLoading();
        } else {
            await axios({
                method: 'delete',
                url: `${endpoint}/${fileId}`,
            })
                .then(response => {
                    if (response.status >= 200 && response.status < 300) {
                        const newFiles = uploadedFiles.filter(file => file.id !== fileId);
                        setUploadedFiles(newFiles);
                        onRemoved && onRemoved(newFiles);
                    }
                })
                .catch(error => {
                    checkForErrors(error);
                })
                .finally(() => {
                    removeLoading();
                });
        }
    };

    const isFileSmallerThanMax = file => byteToMegabyte(file.size) <= maxMBSize;

    const hasValidExtension = file => {
        if (acceptedFormats) {
            const fname = file.name;
            const extension = fname.slice((fname.lastIndexOf('.') - 1 >>> 0) + 1).toUpperCase();
            const acceptedFilesArray = acceptedFormats.toUpperCase().split(',');

            return acceptedFilesArray.includes(extension);
        }

        return true;
    };

    const hasExceededMaxFiles = files => (files.length + uploadedFiles.length) > maxFiles;

    const checkFilesBeforeUpload = file => {
        const validSize = isFileSmallerThanMax(file);
        const validExtension = hasValidExtension(file);

        if (!validSize) {
            setErrorMsg(`The file you have attempted to upload is too big.
            The maximum file size permitted is ${maxMBSize}MB.
            Please try again with a smaller file.`);
            setError();
            return false;
        }

        if (!validExtension) {
            setErrorMsg('Unsupported file extension.');
            setError();
            return false;
        }

        return file;

    };

    function RenderFormatText(formats) {
        const formatString = formats.replace(/,/g, ', ').replace(/\./g, '')
            .toUpperCase();
        return (
            <>
                Formats accepted: {formatString}.
                <br />
            </>
        );
    }

    const RenderFilesizeText = size => `File size must not exceed ${size} MB.`;

    const handleFiles = async files => {
        removeError();

        if (!hasExceededMaxFiles(files)) {
            const newFiles = [...uploadedFiles];

            // loop files from the input (cannot use forEach, must loop without a callback)
            for (const file of Array.from(files)) {
                // validate the file
                const validatedFile = checkFilesBeforeUpload(file);
                if (validatedFile) {
                    // if valid, attempt to upload the file
                    // NB: we must await this promise before pushing to array, as we're in a for..of loop
                    if (api) {
                        setLoading();
                        const fileId = await api.uploadFile(validatedFile);
                        if (fileId) {
                            const newFile = { id: fileId, name: file.name, size: file.size, type: file.type };
                            newFile && newFiles.push(newFile);
                        }
                        removeLoading();
                    } else {
                        const newFile = await uploadFile(validatedFile);
                        // if uploaded, add to array
                        newFile && newFiles.push(newFile);
                    }
                };
            }

            // set the state with newly uploaded file(s)
            setUploadedFiles(newFiles);
            onUploaded && onUploaded(newFiles);
        }
        else {
            setErrorMsg('Too many files have been selected, please remove one or more of the files and try again.');
            setError();
        }
    };

    const onChangeFunction = e => {
        handleFiles(e.target.files);
        e.target.value = null;
    };

    const onDropFunction = e => {
        handleFiles(e.dataTransfer.files);
    };

    const allowMultiple = maxFiles > 1;
    const legendHelpText = <>
        <SROnly>
            {acceptedFormats && RenderFormatText(acceptedFormats)}
            {RenderFilesizeText(maxMBSize)}
        </SROnly>
    </>;
    const helpMessageText = <>
        <p>
            Drag and drop files into this box or use the button to select and upload your files.<br />
            {acceptedFormats && RenderFormatText(acceptedFormats)}
            {RenderFilesizeText(maxMBSize)}
        </p>
    </>;

    const FileUploadWithDrop = () => (
        <DropZone
            showUploadIcon
            helpMessage={ helpMessageText }
            id={ `${id}-dropzone` }
            testId={ `${id}-dropzone` }
            onDrop={ onDropFunction }
            hasError={ hasError }
            fileInput={
                <FileInput
                    id={ `${id}-fileinput` }
                    onChange={ onChangeFunction }
                    name={ name }
                    multiple={ allowMultiple }
                    accept={ acceptedFormats }
                    label={ allowMultiple ? 'Select files' : 'Select file' }
                />
            }
        />
    );

    const FileUploadButton = () => (
        <>
            {helpMessageText}
            <FileInput
                id={ `${id}-fileinput` }
                onChange={ onChangeFunction }
                name={ name }
                multiple={ allowMultiple }
                accept={ acceptedFormats }
                label={ allowMultiple ? 'Select files' : 'Select file' }
            />
        </>
    );

    const FileUploadContents = () => (
        <>
            {detectDragDrop ?
                <FileUploadWithDrop /> :
                <FileUploadButton />}
        </>
    );

    const listItems = uploadedFiles.map(({ id, name, size }) => (
        <UploadedItem
            key={ id }
            id={ id }
            name={ name }
            size={ size }
            onRemove={ () => removeFile(id) }
        />
    ));

    const legendStyle = {
        marginBottom: '1rem',
    };

    return (
        <div { ...rest }>
            <FormFieldset
                aria-invalid={ hasError }
            >
                <FormLegend style={ legendStyle }>
                    <FormLegendLabel>{legendText}</FormLegendLabel>
                    {legendHelpText}
                    {hasError && <SROnly>{errorMsg}</SROnly>}
                </FormLegend>
                <ComponentLoader active={ loading } label='Please wait...' />
                {hasMaxFiles ?
                    <p>To upload a different file, please remove one or more of the files below.</p> :
                    <FileUploadContents />}
                {hasError && <Field.Error aria-hidden={ true }>{errorMsg}</Field.Error>}
            </FormFieldset>
            {uploadedFiles && listItems}
        </div>
    );
};

FileUpload.propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    initialFiles: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        size: PropTypes.number.isRequired,
        type: PropTypes.string.isRequired
    })),
    legendText: PropTypes.string,
    maxMBSize: PropTypes.number,
    maxFiles: PropTypes.number,
    endpoint: PropTypes.string,
    acceptedFormats: PropTypes.string,
    /** Will trigger when uploading files */
    onUploaded: PropTypes.func,
    /** Will trigger when removing files */
    onRemoved: PropTypes.func,
    /** Custom error message */
    errorMessage: PropTypes.string,
    /** Custom api can have upload and remove file functions */
    api: PropTypes.shape({
        uploadFile: PropTypes.func.isRequired,
        removeFile: PropTypes.func
    })
};

FileUpload.defaultProps = {
    legendText: 'Upload file',
    maxMBSize: 4,
    maxFiles: 1,
    endpoint: '',
    acceptedFormats: '',
    initialFiles: []
};

export default FileUpload;
