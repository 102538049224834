export const init = (recaptchaTarget, callback) => global.grecaptcha.render(recaptchaTarget, {
    sitekey: process.env.REACT_APP_RECAPTCHA_SITE_KEY,
    size: 'invisible',
    callback: callback
});

export const execute = recaptchaId => {
    global.grecaptcha.execute(recaptchaId);
};

export const getResponse = recaptchaId => {
    const grecaptchaResponse = global.grecaptcha.getResponse(recaptchaId);
    global.grecaptcha.reset(recaptchaId);

    return grecaptchaResponse;
};
