import * as React from 'react';
import PropTypes from 'prop-types';
import tokens from '../../Tokens/tokens';
const { colors, spacing } = tokens;

const IconGlobe = ({ title, titleId, ...props }) => {
    const sizeArray = Object.entries(spacing.icons);
    const colorArray = Object.entries(colors.icons);
    const sizeOptions = new Map(sizeArray);
    const displaySize = sizeOptions.get(props.size);
    const colorOptions = new Map(colorArray);
    const displayColor = colorOptions.get(props.color);
    return (
        <svg
            viewBox='0 0 16 16'
            xmlns='http://www.w3.org/2000/svg'
            fill={props.color && displayColor}
            aria-hidden={!title}
            role={title ? 'img' : null}
            width={props.size && displaySize}
            height={props.size && displaySize}
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <path d='M8,0 C12.418278,0 16,3.581722 16,8 C16,10.1217319 15.1571453,12.1565632 13.6568542,13.6568542 C12.1565632,15.1571453 10.1217319,16 8,16 C3.581722,16 0,12.418278 0,8 C0,3.581722 3.581722,0 8,0 Z M9.46940079,9.00143682 L6.53059921,9.00143682 C6.62280244,10.4984046 6.91887689,11.8527845 7.35744094,12.8552166 C7.55139103,13.2985311 7.75834585,13.6360875 7.94775511,13.8495761 L8,13.904 L8.05224489,13.8495761 C8.21459569,13.6665859 8.38983709,13.3924438 8.55873046,13.0386801 L8.64255906,12.8552166 C9.08112311,11.8527845 9.37719756,10.4984046 9.46940079,9.00143682 Z M4.52713121,9.00096541 L2.08309386,9.00080686 C2.41187517,10.9589009 3.68908292,12.5953045 5.42615037,13.4214511 C4.94430443,12.2265117 4.61970226,10.6961121 4.52713121,9.00096541 Z M13.9169061,9.00080686 L11.4728688,9.00096541 C11.3802977,10.6961121 11.0556956,12.2265117 10.5740071,13.4208617 C12.3109171,12.5953045 13.5881248,10.9589009 13.9169061,9.00080686 Z M5.42599288,2.57913833 L5.28025825,2.65038556 C3.61749032,3.49742093 2.40217567,5.09689358 2.08292631,7.00019152 L4.52707665,7.00003432 C4.61957464,5.30448963 4.94420974,3.77372312 5.42599288,2.57913833 Z M8,2.095 L7.94775511,2.15042388 C7.75834585,2.36391252 7.55139103,2.70146892 7.35744094,3.14478342 C6.91877952,4.14743808 6.62267098,5.50219679 6.53053782,6.9995603 L9.46946218,6.9995603 C9.37732902,5.50219679 9.08122048,4.14743808 8.64255906,3.14478342 C8.44860897,2.70146892 8.24165415,2.36391252 8.05224489,2.15042388 L8,2.095 Z M10.5738496,2.57854893 L10.5982827,2.63995092 C11.0666895,3.82547611 11.3819875,5.33312555 11.4729234,7.00003432 L13.9170737,7.00019152 C13.588559,5.04165536 12.3112123,3.40483586 10.5738496,2.57854893 Z' />
        </svg>
    );
};

IconGlobe.propTypes = {
    title: PropTypes.string,
    titleId: PropTypes.string,
    size: PropTypes.oneOf(['sm', 'md']),
    color: PropTypes.oneOf(['primaryBlue', 'secondaryBlue', 'darkGrey', 'white']),
};
IconGlobe.defaultProps = {
    size: 'sm',
    color: 'darkGrey',
    focusable: false,
};
export default IconGlobe;
