import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import StyledSectionHeader, { PageNav, PageLabel, PageTitle } from './SectionHeader.styled';
import { Container } from '../ContentContainer/ContentContainer.styled';
import { trackMe } from '../ComponentAnalytics/componentAnalytics';
import Breadcrumb from '../Breadcrumb/Breadcrumb';

const SectionHeader = ({ className, breadcrumb, label, title, intro, children, breadcrumbList }) => {
    useEffect(() => {
        trackMe('SectionHeader');
    }, []);

    const introText = (typeof intro === 'object') ? intro : <p>{intro}</p>;

    return (
        <StyledSectionHeader className={ className }>
            <Container>
                {breadcrumbList ?
                    <PageNav><Breadcrumb linksList={ breadcrumbList } /></PageNav> :
                    breadcrumb && <PageNav>{breadcrumb}</PageNav>
                }
                {label && <PageLabel>{label}</PageLabel>}
                {title && <PageTitle>{title}</PageTitle>}
                {intro && introText}
                {children}
            </Container>
        </StyledSectionHeader>
    );
};

SectionHeader.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node,
    label: PropTypes.string,
    title: PropTypes.string.isRequired,
    breadcrumbList: PropTypes.arrayOf(PropTypes.shape({
        content: PropTypes.string.isRequired,
        path: PropTypes.string.isRequired,
    })),
    intro: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
    ]),
};

export default SectionHeader;
