import * as React from 'react';
import PropTypes from 'prop-types';
import tokens from '../../Tokens/tokens';
const { spacing } = tokens;

const BrandIconSurvey = ({ title, titleId, ...props }) => {
    const sizeArray = Object.entries(spacing.icons);
    const sizeOptions = new Map(sizeArray);
    const displaySize = sizeOptions.get(props.size);
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 48 48'
            fill={props.color && displayColor}
            aria-hidden={!title}
            role={title ? 'img' : null}
            width={props.size && displaySize}
            height={props.size && displaySize}
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <defs>
                <style>{'.Survey_svg__cls-2{fill:#002664}'}</style>
            </defs>
            <path d='M29 5a5 5 0 11-5-5 5 5 0 015 5z' fill='#d7153a' />
            <path className='Survey_svg__cls-2' d='M21 22v1h-8v-8h6l2-2h-9a1 1 0 00-1 1v10a1 1 0 001 1h10a1 1 0 001-1v-4l-2 2z' />
            <path
                className='Survey_svg__cls-2'
                d='M23.24 13.35l-5.3 6.18-1.23-1.24a1 1 0 00-1.42 1.42l2 2A1 1 0 0018 22a1 1 0 00.72-.35l6-7a1 1 0 10-1.52-1.3zM26 17h11v2H26zm0 4h8v2h-8z'
            />
            <path
                className='Survey_svg__cls-2'
                d='M39 48H9a3 3 0 01-3-3V7a3 3 0 013-3h8v2H9a1 1 0 00-1 1v38a1 1 0 001 1h30a1 1 0 001-1V7a1 1 0 00-1-1h-8V4h8a3 3 0 013 3v38a3 3 0 01-3 3z'
            />
            <path className='Survey_svg__cls-2' d='M21 39h-8v-8h6l2-2h-9a1 1 0 00-1 1v10a1 1 0 001 1h10a1 1 0 001-1v-4l-2 2z' />
            <path
                className='Survey_svg__cls-2'
                d='M23.24 29.35l-5.3 6.18-1.23-1.24a1 1 0 00-1.42 1.42l2 2A1.05 1.05 0 0018 38a1 1 0 00.72-.35l6-7a1 1 0 10-1.52-1.3zM26 33h11v2H26zm0 4h8v2h-8z'
            />
        </svg>
    );
};

BrandIconSurvey.propTypes = {
    title: PropTypes.string,
    titleId: PropTypes.string,
    size: PropTypes.oneOf(['lg', 'xl', 'xxl']),
};
BrandIconSurvey.defaultProps = {
    size: 'xl',
    focusable: false,
};
export default BrandIconSurvey;
