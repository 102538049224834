import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { getFormattedDate } from '../../utils';
import { trackMe } from '../ComponentAnalytics/componentAnalytics';
import { validDateFormat } from '../../Validation/Validators';
import Field from '../Form/Field.styled';

const DateInput = ({ id, name, value, onChange, disabled, hasError, inputWidth, inputRef, dateFormat, ...rest }) => {

    const sizeArray = [
        [ 'MM/YY', 'xs' ],
        [ 'MM/YYYY', 'sm' ],
        [ 'DD/MM/YYYY', 'md' ],
    ];
    const sizes = new Map(sizeArray);
    const internalWidth = sizes.get(dateFormat);

    useEffect(() => {
        trackMe('DateInput [GEL]');
    }, []);

    const handleOnChange = e => {
        const value = e.target.value;
        const isValidFormat = validDateFormat(value, dateFormat);
        if (isValidFormat && dateFormat === 'DD/MM/YYYY') {
            const dateArray = value.split('/');
            const isoFormat = `${dateArray[2]}-${dateArray[1]}-${dateArray[0]}`;
            const dateFormats = {
                isValid: true,
                value: value,
                isoDate: isoFormat, // YYYY-MM-DD
                shortDate: getFormattedDate(isoFormat,'DD/MM/YYYY'),
                longDate: getFormattedDate(isoFormat,'DD MMM YYYY')
            };
            return onChange(e, dateFormats);
        } else if (isValidFormat) {
            const dateFormats = {
                isValid: true,
                value: value,
            };
            return onChange(e, dateFormats);
        } else {
            return onChange(e, { isValid: false, value: value });
        }
    };

    return (
        <Field.Input
            id={ id }
            name={ name }
            type='text'
            value={ value }
            onChange={ handleOnChange }
            disabled={ disabled }
            hasError={ hasError }
            inputWidth={ inputWidth || internalWidth }
            ref={ inputRef }
            { ...rest }
        />
    );
};

DateInput.propTypes = {
    id: PropTypes.string,
    name: PropTypes.string,
    value: PropTypes.string,
    disabled: PropTypes.bool,
    hasError: PropTypes.bool,
    inputWidth: PropTypes.oneOf(['xxs', 'xs', 'sm', 'md', 'lg', 'xl']),
    inputRef: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.object,
    ]),
    dateFormat: PropTypes.oneOf(['DD/MM/YYYY', 'MM/YY', 'MM/YYYY']),
    onChange: PropTypes.func,
};

DateInput.defaultProps = {
    dateFormat: 'DD/MM/YYYY',
    hasError: false,
    disabled: false,
};

export default DateInput;
