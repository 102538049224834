import * as React from 'react';
import PropTypes from 'prop-types';
import tokens from '../../Tokens/tokens';
const { spacing } = tokens;

const BrandIconEducation = ({ title, titleId, ...props }) => {
    const sizeArray = Object.entries(spacing.icons);
    const sizeOptions = new Map(sizeArray);
    const displaySize = sizeOptions.get(props.size);
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 48 48'
            fill={props.color && displayColor}
            aria-hidden={!title}
            role={title ? 'img' : null}
            width={props.size && displaySize}
            height={props.size && displaySize}
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <path d='M41 46l-1.4-4.44h-.85L37.35 46z' fill='none' />
            <path
                d='M41 46h-3.65l1.41-4.44a4.12 4.12 0 01-1.89-.66L34.62 48h9.13l-2.25-7.1a4.12 4.12 0 01-1.89.66zm6.34-34.88l-23-8.18a1.1 1.1 0 00-.67 0l-23 8.18a1 1 0 00-.63.71v3.49a1 1 0 00.67.74l23 7a1.07 1.07 0 00.58 0l9.11-2.73v9.77c-2.51 2.21-6.22 3.38-10.76 3.38-4.77 0-10.27-1.64-12.82-3.77v-7.26a1 1 0 00-2 0v7.7a1 1 0 00.32.73c3.14 3 9.82 4.6 14.51 4.6 5.25 0 9.56-1.46 12.46-4.21a1 1 0 00.31-.73V19.76l2.79-.84v14.42a4.16 4.16 0 011-.14 4.09 4.09 0 011 .14V16.11l-15.66-4.76a1 1 0 10-.55 1.91l13 4-13 3.84-22-6.73v-1.61l22-7.83 22 7.83v1.56l-3.55.85v2.46l4.83-1.45a1 1 0 00.71-1v-3.12a1 1 0 00-.65-.94z'
                fill='#002664'
            />
            <path d='M39 42a5 5 0 115-5 5 5 0 01-5 5z' fill='#d7153a' opacity={0.97} />
        </svg>
    );
};

BrandIconEducation.propTypes = {
    title: PropTypes.string,
    titleId: PropTypes.string,
    size: PropTypes.oneOf(['lg', 'xl', 'xxl']),
};
BrandIconEducation.defaultProps = {
    size: 'xl',
    focusable: false,
};
export default BrandIconEducation;
