import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { InternalContainer } from './Internal.styled';
import { trackMe } from '../Components/ComponentAnalytics/componentAnalytics';

const Internal = ({ className, children, href, newTab, onClick }) => {

    useEffect(() => {
        trackMe('Internal');
    }, []);

    return (
        <InternalContainer
            className={ className }
            to={ href }
            target={ newTab ? '_blank' : '_self' }
            rel='noopener noreferrer'
            onClick={ onClick }
        >
            {children}
        </InternalContainer>
    );
};

Internal.propTypes = {
    className: PropTypes.string,
    href: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    newTab: PropTypes.bool,
    onClick: PropTypes.func,
};

Internal.defaultProps = {
    newTab: false
};

export default Internal;
