import * as React from 'react';
import PropTypes from 'prop-types';
import tokens from '../../Tokens/tokens';
const { colors, spacing } = tokens;

const IconChat = ({ title, titleId, ...props }) => {
    const sizeArray = Object.entries(spacing.icons);
    const colorArray = Object.entries(colors.icons);
    const sizeOptions = new Map(sizeArray);
    const displaySize = sizeOptions.get(props.size);
    const colorOptions = new Map(colorArray);
    const displayColor = colorOptions.get(props.color);
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 16 16'
            fill={props.color && displayColor}
            aria-hidden={!title}
            role={title ? 'img' : null}
            width={props.size && displaySize}
            height={props.size && displaySize}
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <path d='M15 16a1 1 0 01-.71-.29L11.59 13H8a3 3 0 01-3-3V9h2v1a1 1 0 001 1h4a1 1 0 01.71.29l1.29 1.3V7a1 1 0 00-1-1h-2V4h2a3 3 0 013 3v8a1 1 0 01-.62.92.84.84 0 01-.38.08z' />
            <path d='M1 13a.84.84 0 01-.38-.08A1 1 0 010 12V3a3 3 0 013-3h6a3 3 0 013 3v4a3 3 0 01-3 3H4.41l-2.7 2.71A1 1 0 011 13zM3 2a1 1 0 00-1 1v6.59l1.29-1.3A1 1 0 014 8h5a1 1 0 001-1V3a1 1 0 00-1-1z' />
        </svg>
    );
};

IconChat.propTypes = {
    title: PropTypes.string,
    titleId: PropTypes.string,
    size: PropTypes.oneOf(['sm', 'md']),
    color: PropTypes.oneOf(['primaryBlue', 'secondaryBlue', 'darkGrey', 'white']),
};
IconChat.defaultProps = {
    size: 'sm',
    color: 'darkGrey',
    focusable: false,
};
export default IconChat;
