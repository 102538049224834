import * as React from 'react';
import PropTypes from 'prop-types';
import tokens from '../../Tokens/tokens';
const { colors, spacing } = tokens;

const IconDelete = ({ title, titleId, ...props }) => {
    const sizeArray = Object.entries(spacing.icons);
    const colorArray = Object.entries(colors.icons);
    const sizeOptions = new Map(sizeArray);
    const displaySize = sizeOptions.get(props.size);
    const colorOptions = new Map(colorArray);
    const displayColor = colorOptions.get(props.color);
    return (
        <svg
            viewBox='0 0 16 16'
            xmlns='http://www.w3.org/2000/svg'
            fill={props.color && displayColor}
            aria-hidden={!title}
            role={title ? 'img' : null}
            width={props.size && displaySize}
            height={props.size && displaySize}
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <g>
                <path
                    fillRule='evenodd'
                    d='M4.82036 1.29775C5.11309 0.517143 5.85933 0 6.69301 0H9.30701C10.1407 0 10.8869 0.517146 11.1797 1.29775L12.193 4H12.9866C12.9947 3.9999 13.0029 3.9999 13.0111 4H14C14.5523 4 15 4.44772 15 5C15 5.55228 14.5523 6 14 6H13.905L12.9961 15.0885C12.9871 15.1915 12.9625 15.29 12.9246 15.3816C12.8673 15.5207 12.7798 15.6431 12.671 15.7415C12.4893 15.9058 12.2483 16.0032 11.989 16H4.01105C3.9077 16.0013 3.80724 15.9865 3.7123 15.958C3.42636 15.872 3.19036 15.6605 3.07539 15.3816C3.03752 15.29 3.01289 15.1915 3.00386 15.0885L2.09501 6H2C1.44772 6 1 5.55228 1 5C1 4.44772 1.44772 4 2 4H2.98895C2.99713 3.9999 3.0053 3.9999 3.01344 4H3.807L4.82036 1.29775ZM4.47665 6C4.49215 6.00036 4.50762 6.00036 4.52305 6H11.477C11.4924 6.00036 11.5079 6.00036 11.5234 6H11.895L11.095 14H4.90499L4.10499 6H4.47665ZM10.057 4H5.94301L6.69301 2H9.30701L10.057 4Z'
                />
            </g>
        </svg>
    );
};

IconDelete.propTypes = {
    title: PropTypes.string,
    titleId: PropTypes.string,
    size: PropTypes.oneOf(['sm', 'md']),
    color: PropTypes.oneOf(['primaryBlue', 'secondaryBlue', 'darkGrey', 'white']),
};
IconDelete.defaultProps = {
    size: 'sm',
    color: 'darkGrey',
    focusable: false,
};
export default IconDelete;
