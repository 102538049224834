import * as React from 'react';
import PropTypes from 'prop-types';
import tokens from '../../Tokens/tokens';
const { spacing } = tokens;

const BrandIconServiceCentre = ({ title, titleId, ...props }) => {
    const sizeArray = Object.entries(spacing.icons);
    const sizeOptions = new Map(sizeArray);
    const displaySize = sizeOptions.get(props.size);
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 48 48'
            fill={props.color && displayColor}
            aria-hidden={!title}
            role={title ? 'img' : null}
            width={props.size && displaySize}
            height={props.size && displaySize}
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <path
                d='M5.76 21h36.45A5.76 5.76 0 0048 15.3v-3.55L41.59 0H6.41L0 11.74v3.56A5.74 5.74 0 005.76 21zM2 12.26L7.59 2h4.8l-1 4.51a1 1 0 102 .44L14.45 2h5.21l-.28 4.67a1 1 0 102 .12L21.67 2H27l.29 4.79a1 1 0 002-.12L29 2h5.21l1.13 5a1 1 0 001.19.76 1 1 0 00.76-1.2L36.26 2h4.15l4.89 9H6.09a1 1 0 000 2H46v2.3a3.8 3.8 0 01-3.79 3.7H5.76A3.78 3.78 0 012 15.3zM47 46h-1V23h-2v23H20.79a60 60 0 00.73-9.25A61.08 61.08 0 0019.94 23h-2.06a58.88 58.88 0 011.64 13.75 58.8 58.8 0 01-.75 9.25h-6a58.13 58.13 0 01-.77-9H4v-4h8a59.83 59.83 0 011.5-10h-2.05a62 62 0 00-1.29 8H4v-8H2v23H1a1 1 0 000 2h46a1 1 0 000-2zM4 46v-7h6.05a62.13 62.13 0 00.67 7z'
                fill='#002664'
            />
            <path d='M38 34a5 5 0 11-5-5 5 5 0 015 5z' fill='#d7153a' />
        </svg>
    );
};

BrandIconServiceCentre.propTypes = {
    title: PropTypes.string,
    titleId: PropTypes.string,
    size: PropTypes.oneOf(['lg', 'xl', 'xxl']),
};
BrandIconServiceCentre.defaultProps = {
    size: 'xl',
    focusable: false,
};
export default BrandIconServiceCentre;
