/* eslint-disable security/detect-object-injection */
import React, { useEffect } from 'react';
import { Button, FormRadioGroup, Fieldset, utils, tokens } from '../..';
import PropTypes from 'prop-types';
import OtherName from './OtherName';
import { trackMe } from '../../Components/ComponentAnalytics/componentAnalytics';
import { getHasError, getErrorMessage, getId, getRadioValue } from '../utils';

const OtherNames = ({
    id,
    label,
    labelFirstName,
    labelMiddleName,
    labelLastName,
    helpMessage,
    onChange,
    value,
    smallLegend
}) => {
    useEffect(() => {
        trackMe('PatternOtherNames');
    }, []);

    const prevValue = utils.usePrevious(value);
    const multipleNames = value && value.names && value.names.length > 1;
    const nameBoilerplate = {
        firstName: { value: '' },
        middleName: { value: '' },
        lastName: { value: '' },
    };

    // Watch for changes on otherName. If it changes focus the last child. Used for adding & removing names.
    useEffect(() => {
        // we only need to focus when adding/removing
        if (
            value?.names &&
            prevValue?.names &&
            value.names.length !== prevValue.names.length
        ) {
            // The length is the total number of names, but we need the index (as used in the input ID attr) hence '-1'.
            const numberOfNames = value.names.length - 1;
            const newFirstName = document.getElementById(`${id}-${numberOfNames}-formgroup-firstname`);
            newFirstName && newFirstName.focus();
        }
    }, [value, prevValue, id]);

    /**
     * handles the updating of data, and calls callback function accordingly
     * @param {string} updatedValue
     */
    const handleOnChangeRadio = updatedValue => {
        // copy existing values
        const newValue = { ...value };

        // edge case for hasName, where the input onChange event returns a string
        // though we need to return a boolean for the pattern onChange event.
        updatedValue = 'true' === updatedValue || true === updatedValue;

        // if key exists in value object, update it whilst keeping existing data
        // else create new key/value pair
        newValue.hasName = { value: updatedValue };

        // if we already have names, leave them as-is
        // else add a name boilerplate to the value object
        newValue.names = newValue?.names ?
            [...newValue.names] :
            [nameBoilerplate];

        // call onChange callback
        onChange && onChange(newValue, 'hasName', null);
    };

    /**
     * handles the updating of data, and calls callback function accordingly
     * @param {string} key
     * @param {string} updatedValue
     * @param {number} index
     */
    const handleOnChange = (key, updatedValue, index) => {
        // create copy of all current names
        const newArr = value?.names ?
            [...value.names] :
            [];
        // get the current name being updated
        const currentName = value.names[parseInt(index)];
        // create new instance of current name, with updated value
        const changedName = {
            ...currentName,
            [key]: {
                ...currentName[key],
                value: updatedValue
            }
        };
        // overwrite current name in array, with updated value
        newArr[parseInt(index)] = changedName;

        // call onChange callback
        onChange && onChange({ ...value, names: newArr }, key, index);
    };

    const addOtherName = () => {
        const newArr = value?.names ?
            [...value.names] :
            [];
        newArr.push(nameBoilerplate);

        // call onChange callback
        onChange && onChange({ ...value, names: newArr });
    };

    const removeOtherName = index => e => {
        let newArr = [...value.names];
        newArr = newArr.filter((value, i) => i !== index);

        // call onChange callback
        onChange && onChange({ ...value, names: newArr });
    };

    // create the names array if it hasn't been create or contains no objects
    const initNamesArray = () => {
        // NB: not checking firstName, middleName or lastName, as these are enforced by propTypes below.
        if (!value?.names || 0 === value.names.length) {
            value.names = [nameBoilerplate];
        }
    };
    // check and initialise the name array, if required
    initNamesArray();

    return (
        <>
            <FormRadioGroup
                id={ getId('hasName', value, `${id}-radiogroup-hasname`) }
                errorMessage={ getErrorMessage('hasName', value) }
                hasError={ getHasError('hasName', value) }
                helpMessage={ helpMessage }
                legend={ label }
                onChange={ value => handleOnChangeRadio(value) }
                options={ [
                    {
                        label: 'Yes',
                        value: true
                    },
                    {
                        label: 'No',
                        value: false
                    }
                ] }
                value={ getRadioValue('hasName', value) }
            />

            {value?.hasName?.value === true &&
                <>
                    {value.names && value.names.map((name, index) => (
                        <React.Fragment key={ index }>
                            <Fieldset
                                legend={ multipleNames ? `Name ${(index + 1).toString()}` : '' }
                                margin={ { top: multipleNames ? 'md' : 'sm' } }
                                smallLegend={ smallLegend }
                            >
                                <OtherName
                                    id={ `${id}-${index}` }
                                    index={ index }
                                    labelFirstName={ labelFirstName }
                                    labelMiddleName={ labelMiddleName }
                                    labelLastName={ labelLastName }
                                    value={ name }
                                    onChange={ handleOnChange }
                                />
                                {multipleNames &&
                                    <Button
                                        style={ { display: 'block', marginTop: `${utils.pxToRem(tokens.spacing.md)}` } }
                                        variant='link'
                                        onClick={ removeOtherName(index) }
                                    >
                                        Remove
                                    </Button>
                                }
                            </Fieldset>
                        </React.Fragment>
                    ))}

                    <Button
                        style={ { marginTop: `${utils.pxToRem(tokens.spacing.md)}` } }
                        variant='secondary'
                        onClick={ () => addOtherName() }
                    >
                        + Add another name
                    </Button>
                </>
            }
        </>
    );
};

OtherNames.propTypes = {
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    labelFirstName: PropTypes.string,
    labelMiddleName: PropTypes.string,
    labelLastName: PropTypes.string,
    value: PropTypes.shape({
        hasName: PropTypes.shape({
            id: PropTypes.string,
            value: PropTypes.bool,
            errorMessage: PropTypes.string
        }),
        names: PropTypes.arrayOf(
            PropTypes.shape({
                firstName: PropTypes.shape({
                    id: PropTypes.string,
                    value: PropTypes.string,
                    errorMessage: PropTypes.string
                }).isRequired,
                middleName: PropTypes.shape({
                    id: PropTypes.string,
                    value: PropTypes.string,
                    errorMessage: PropTypes.string
                }).isRequired,
                lastName: PropTypes.shape({
                    id: PropTypes.string,
                    value: PropTypes.string,
                    errorMessage: PropTypes.string
                }).isRequired,
            })
        )
    }).isRequired,
    onChange: PropTypes.func.isRequired,
    helpMessage: PropTypes.string,
    /** Optionally style the legend as a label. See 'Small Legend' section above. */
    smallLegend: PropTypes.bool,
};

OtherNames.defaultProps = {
    helpMessage: `Legal names are names that have been registered and used
    on government-issued documentation such as a passport or driver licence.`,
    labelFirstName: 'Given name',
    labelMiddleName: 'Middle name (optional)',
    labelLastName: 'Family name',
    smallLegend: false
};

export default OtherNames;
