import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { CardTitleContainer } from './Card.styled';
import { trackMe } from '../ComponentAnalytics/componentAnalytics';
import Heading from '../Headings/Heading';

const CardTitle = ({ level, children, icon, text }) => {

    useEffect(() => {
        trackMe('CardTitle [GEL]');
    }, []);

    return (
        <CardTitleContainer level={ level }>
            {icon}
            <Heading level={ level }>{text ? text : children}</Heading>
        </CardTitleContainer>
    );
};

CardTitle.propTypes = {
    level: PropTypes.oneOf([3, 4, 5]),
    /**
    * @ignore
    */
    text: PropTypes.node,
    children: PropTypes.node.isRequired,
    icon: PropTypes.node,
};

CardTitle.defaultProps = {
    level: 3,
};

export default CardTitle;
