import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Field from './Field.styled';
import Radio, { RadioItemEditorContainer, RadioGroupFieldset, RadioItemClarifyContainer } from './FormRadioGroup.styled';
import { FormLegend, RadioCheckboxLegendLabel } from './Form.styled';
import { SROnly } from '../../GlobalStyle/utilities/utilities.styled';
import { getID } from '../../utils';
import { trackMe } from '../ComponentAnalytics/componentAnalytics';

export const FormRadioGroup = ({
    id,
    name,
    className,
    legend,
    helpMessage,
    fieldType,
    options,
    value,
    hasError,
    errorMessage,
    vertical,
    onChange,
    inputRef,
    margin,
    ...rest
}) => {

    useEffect(() => {
        trackMe('FormRadioGroup');
    }, []);

    const fieldClass = className === undefined ? '' : ` ${className}`;

    const genID = () => id || getID();

    const elemID = genID();

    return (
        <RadioGroupFieldset
            id={ `${elemID}` }
            inline={ !vertical }
            aria-invalid={ hasError }
            className={ fieldClass }
            margin={ margin }
        >
            {legend && <FormLegend>
                <RadioCheckboxLegendLabel>{legend}</RadioCheckboxLegendLabel>
                {helpMessage && (
                    <Field.GroupHelp>{helpMessage}</Field.GroupHelp>
                )}
                {hasError && errorMessage && <SROnly>{errorMessage}</SROnly>}
            </FormLegend>}

            <Radio>
                {options.map((option, index) =>
                    <RadioItem
                        key={ `${elemID}-${index}` }
                        id={ `${elemID}-${index}` }
                        option={ option }
                        value={ value }
                        fieldType={ fieldType }
                        onSelection={ onChange }
                        hasError={ hasError }
                        name={ name }
                        inputRef={ inputRef }
                        vertical={ vertical }
                    />
                )}
            </Radio>
            {hasError && <Field.Error aria-hidden='true'>{errorMessage}</Field.Error>}
        </RadioGroupFieldset>
    );
};

FormRadioGroup.propTypes = {
    id: PropTypes.string,
    name: PropTypes.string,
    className: PropTypes.string,
    legend: PropTypes.string.isRequired,
    helpMessage: PropTypes.string,
    fieldType: PropTypes.string,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.any.isRequired,
            label: PropTypes.node.isRequired,
            clarify: PropTypes.string,
        })
    ).isRequired,
    value: PropTypes.any,
    hasError: PropTypes.bool,
    errorMessage: PropTypes.string,
    vertical: PropTypes.bool,
    onChange: PropTypes.func,
    inputRef: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.object,
    ]),
    margin: PropTypes.shape({
        top: PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.oneOf(['none', 'xs', 'sm', 'md', 'lg', 'xl', 'xxl', 'xxxl']),
        ]),
    })
};

export const RadioItem = ({ id, name, option, value, fieldType, onSelection, hasError, inputRef, vertical }) => {
    const handleClick = e => {
        if (e.target.tagName === 'INPUT') {
            e.target.checked = true;

            let selectedValue = e.target.value;
            if (fieldType === 'boolean') {
                selectedValue = stringToBoolean(selectedValue);
            }
            onSelection(selectedValue);
        }
    };

    const stringToBoolean = b => b.toLowerCase() === 'true';

    const checked = value === undefined ? value : option.value === value;
    const editor = checked && option.editor ? option.editor : undefined;
    const clarify = option.clarify && vertical ? option.clarify : undefined;

    return (
        <>
            <Radio.Item onClick={ handleClick }>
                <Radio.Input
                    className={ hasError ? 'error' : '' }
                    name={ name }
                    type='radio'
                    id={ id }
                    value={ option.value }
                    ref={ inputRef }
                    aria-describedby={ clarify && `${id}-clarifyText` }
                    checked={ checked }
                    onChange={ Function.prototype }
                />
                <Radio.Label htmlFor={ id } hasError={ hasError }>{option.label}</Radio.Label>
            </Radio.Item>
            {editor && (
                <RadioItemEditorContainer>
                    {editor}
                </RadioItemEditorContainer>
            )}
            {clarify && (
                <RadioItemClarifyContainer id={ `${id}-clarifyText` }>
                    {clarify}
                </RadioItemClarifyContainer>
            )}
        </>);
};

RadioItem.propTypes = {
    id: PropTypes.string,
    name: PropTypes.string,
    hasError: PropTypes.bool,
    option: PropTypes.shape({
        editor: PropTypes.node,
        clarify: PropTypes.string,
    }),
    value: PropTypes.any,
    fieldType: PropTypes.string,
    onSelection: PropTypes.func,
    inputRef: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.object,
    ]),
};

export default FormRadioGroup;
