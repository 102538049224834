import { useMemo } from 'react';
import utils from '../../utils';

const useManualAddress = statesOverride => {

    const australianStates = useMemo(() => {
        var statesToMap = (statesOverride && statesOverride.length > 0) ? statesOverride : utils.getAustralianStates();
        return statesToMap.map(australianState => ({ text: australianState, value: australianState }));
    }, [statesOverride]);

    return {
        australianStates,
    };
};

export default useManualAddress;
