import * as React from 'react';
import PropTypes from 'prop-types';
import tokens from '../../Tokens/tokens';
const { colors, spacing } = tokens;

const IconNotificationWarning = ({ title, titleId, ...props }) => {
    const sizeArray = Object.entries(spacing.icons);
    const colorArray = Object.entries(colors.icons);
    const sizeOptions = new Map(sizeArray);
    const displaySize = sizeOptions.get(props.size);
    const colorOptions = new Map(colorArray);
    const displayColor = colorOptions.get(props.color);
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 16 16'
            fill={props.color && displayColor}
            aria-hidden={!title}
            role={title ? 'img' : null}
            width={props.size && displaySize}
            height={props.size && displaySize}
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <g fill='none' fillRule='evenodd'>
                <path
                    d='M9 8V5a1 1 0 10-2 0v3a1 1 0 102 0zm-.62 3.92a1.15 1.15 0 00.33-.21 1.58 1.58 0 00.12-.15.76.76 0 00.09-.18.64.64 0 00.08-.18 1.5 1.5 0 000-.2 1 1 0 00-.08-.38 1 1 0 00-.21-.33 1 1 0 00-1.09-.21 1.15 1.15 0 00-.33.21 1 1 0 00-.21.33A1 1 0 007 11a1.5 1.5 0 000 .2.64.64 0 00.06.18.76.76 0 00.09.18l.12.15c.097.088.209.16.33.21a.94.94 0 00.76 0h.02z'
                    fill='#FFF'
                />
                <path
                    d='M8 16A8 8 0 118 0a8 8 0 010 16zm1-8V5a1 1 0 10-2 0v3a1 1 0 102 0zm-.62 3.92a1.15 1.15 0 00.33-.21 1.58 1.58 0 00.12-.15.76.76 0 00.09-.18.64.64 0 00.08-.18 1.5 1.5 0 000-.2 1 1 0 00-.08-.38 1 1 0 00-.21-.33 1 1 0 00-1.09-.21 1.15 1.15 0 00-.33.21 1 1 0 00-.21.33A1 1 0 007 11a1.5 1.5 0 000 .2.64.64 0 00.06.18.76.76 0 00.09.18l.12.15c.097.088.209.16.33.21a.94.94 0 00.76 0h.02z'
                    fill='#DC5800'
                />
            </g>
        </svg>
    );
};

IconNotificationWarning.propTypes = {
    title: PropTypes.string,
    titleId: PropTypes.string,
    size: PropTypes.oneOf(['sm', 'md']),
};
IconNotificationWarning.defaultProps = {
    size: 'sm',
    focusable: false,
};
export default IconNotificationWarning;
