import React from 'react';
import styled from 'styled-components/macro';
import { color, layout } from 'styled-system';
import tokens from '../../Tokens/tokens';

const { colors } = tokens;

// eslint-disable-next-line react/prop-types
const ClockSvg = ({ className, color, width, height }) => (
    <svg className={ className } xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill={ colors.legacy[color] || color } width={ width } height={ height } focusable='false' aria-hidden='true'>
        <path d='M0 0h24v24H0z' fill='none' />
        <path d='M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z' />
        <path d='M12.5 7H11v6l5.25 3.15.75-1.23-4.5-2.67z' />
    </svg>
);

export const Clock = styled(ClockSvg)`
  ${color}
  ${layout}
`;

Clock.defaultProps = {
    color: `${colors.brand.snswSecondaryBlue}`,
    height: 44,
    width: 44,
};

export default Clock;
