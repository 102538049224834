import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { trackMe } from '../ComponentAnalytics/componentAnalytics';
import StyledInPageAlert from '../InPageAlert/InPageAlert.styled';

const Callout = ({ className, title, headingLevel, children, role, innerRef, ...rest }) => {

    useEffect(() => {
        trackMe('Callout');
    }, []);

    return (
        <StyledInPageAlert
            variant='callout'
            className={ className }
            role={ role }
            ref={ innerRef }
            { ...rest }
        >
            <StyledInPageAlert.Title
                className={ classNames({ 'title-only': !children }) }
                headingLevel={ headingLevel }
            >
                {title}
            </StyledInPageAlert.Title>
            { children &&
                <StyledInPageAlert.Children variant='callout'>{ children }</StyledInPageAlert.Children>
            }
        </StyledInPageAlert>
    );
};

Callout.propTypes = {
    className: PropTypes.string,
    title: PropTypes.string.isRequired,
    role: PropTypes.oneOf(['alert','status']),
    children: PropTypes.node,
    headingLevel: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.oneOf([ 'h2', 'h3', 'h4', 'h5', 'h6', 'p']),
    ]),
    innerRef: PropTypes.object,
};

Callout.defaultProps = {
    headingLevel: 'h6',
};

export default Callout;
