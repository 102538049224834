import * as React from 'react';
import PropTypes from 'prop-types';
import tokens from '../../Tokens/tokens';
const { spacing } = tokens;

const BrandIconLocked = ({ title, titleId, ...props }) => {
    const sizeArray = Object.entries(spacing.icons);
    const sizeOptions = new Map(sizeArray);
    const displaySize = sizeOptions.get(props.size);
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 48 48'
            fill={props.color && displayColor}
            aria-hidden={!title}
            role={title ? 'img' : null}
            width={props.size && displaySize}
            height={props.size && displaySize}
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <defs>
                <style>{'.Locked_svg__cls-1{fill:#002664}'}</style>
            </defs>
            <path
                className='Locked_svg__cls-1'
                d='M23 34.9V41a1 1 0 002 0v-6.1a5.05 5.05 0 01-2 0zM32 17h-2v-5a6 6 0 00-12 0v5h-2v-5a8 8 0 0116 0z'
            />
            <path
                className='Locked_svg__cls-1'
                d='M36 17h-2v-5a10 10 0 00-20 0v5h-2v-5a12 12 0 0124 0zm-3 31H15a9 9 0 01-9-9V24a5 5 0 015-5h26a5 5 0 015 5v15a9 9 0 01-9 9zM11 21a3 3 0 00-3 3v15a7 7 0 007 7h18a7 7 0 007-7V24a3 3 0 00-3-3z'
            />
            <path d='M29 30a5 5 0 11-5-5 5 5 0 015 5z' fill='#d7153a' />
        </svg>
    );
};

BrandIconLocked.propTypes = {
    title: PropTypes.string,
    titleId: PropTypes.string,
    size: PropTypes.oneOf(['lg', 'xl', 'xxl']),
};
BrandIconLocked.defaultProps = {
    size: 'xl',
    focusable: false,
};
export default BrandIconLocked;
