import * as React from 'react';
import PropTypes from 'prop-types';
import tokens from '../../Tokens/tokens';
const { spacing } = tokens;

const BrandIconRefreshPhone = ({ title, titleId, ...props }) => {
    const sizeArray = Object.entries(spacing.icons);
    const sizeOptions = new Map(sizeArray);
    const displaySize = sizeOptions.get(props.size);
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 48 48'
            fill={props.color && displayColor}
            aria-hidden={!title}
            role={title ? 'img' : null}
            width={props.size && displaySize}
            height={props.size && displaySize}
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <defs>
                <style>{'.RefreshPhone_svg__cls-2{fill:#002664}'}</style>
            </defs>
            <path d='M24 44a5 5 0 115-5 5 5 0 01-5 5z' fill='#d7153a' />
            <path
                className='RefreshPhone_svg__cls-2'
                d='M35 4H13a1 1 0 00-1 1v34a1 1 0 001 1h4v-2h-3V6h20v32h-3v2h4a1 1 0 001-1V5a1 1 0 00-1-1z'
            />
            <path
                className='RefreshPhone_svg__cls-2'
                d='M40 4.87A5 5 0 0035 0H13a5 5 0 00-5 5v38a5 5 0 005 5h22a5 5 0 005-5V4.87zM38 43a3 3 0 01-3 3H13a3 3 0 01-3-3V5a3 3 0 013-3h22a3 3 0 013 3z'
            />
            <path
                className='RefreshPhone_svg__cls-2'
                d='M27.29 22.29L25 24.59V12a1 1 0 00-2 0v12.59l-2.29-2.3a1 1 0 00-1.42 1.42l4 4a1 1 0 00.33.21.94.94 0 00.76 0 1 1 0 00.33-.21l4-4a1 1 0 00-1.42-1.42z'
            />
        </svg>
    );
};

BrandIconRefreshPhone.propTypes = {
    title: PropTypes.string,
    titleId: PropTypes.string,
    size: PropTypes.oneOf(['lg', 'xl', 'xxl']),
};
BrandIconRefreshPhone.defaultProps = {
    size: 'xl',
    focusable: false,
};
export default BrandIconRefreshPhone;
