import * as React from 'react';
import PropTypes from 'prop-types';
import tokens from '../../Tokens/tokens';
const { spacing } = tokens;

const BrandIconHealth = ({ title, titleId, ...props }) => {
    const sizeArray = Object.entries(spacing.icons);
    const sizeOptions = new Map(sizeArray);
    const displaySize = sizeOptions.get(props.size);
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 48 48'
            fill={props.color && displayColor}
            aria-hidden={!title}
            role={title ? 'img' : null}
            width={props.size && displaySize}
            height={props.size && displaySize}
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <defs>
                <style>{'.Health_svg__cls-1{fill:#002664}'}</style>
            </defs>
            <path
                className='Health_svg__cls-1'
                d='M31.13 18.6h-4.07l-2.14-5a1 1 0 00-1-.6 1 1 0 00-.89.76L19.64 28l-4.36-8.09a1 1 0 00-.86-.53 1 1 0 00-.89.51L10.62 25H0v2h11.2a1 1 0 00.87-.5l2.31-4 4.74 8.81a1 1 0 00.88.53h.13A1 1 0 0021 31l3.3-13.85L25.48 20a1 1 0 00.92.6h4.73a4.09 4.09 0 01-.13-1 4.15 4.15 0 01.13-1z'
            />
            <path
                className='Health_svg__cls-1'
                d='M44.37 4.15A11.87 11.87 0 0035.45 0h-.07a12.29 12.29 0 00-9.24 4.36L24 6.93l-2.15-2.31A12.59 12.59 0 0012.59 0a12.13 12.13 0 00-8.95 4.43A15.12 15.12 0 000 14.54v.05a15.93 15.93 0 002.6 8.61h2.5a13.66 13.66 0 010-17.39 10.2 10.2 0 017.49-3.76A10.65 10.65 0 0120.4 6l2.88 3.13a.91.91 0 00.13.08.75.75 0 00.17.1h.56a.61.61 0 00.18 0 .62.62 0 00.17-.11s.09 0 .12-.08l2.87-3.39a10.35 10.35 0 017.79-3.68 9.91 9.91 0 017.63 3.49 13.66 13.66 0 01-.18 17.61L24 45.42 10.54 29.61H7.9l15.36 18v.06a1 1 0 00.17.1l.11.07A.94.94 0 0024 48a1 1 0 00.37-.08l.1-.07a.87.87 0 00.18-.11v-.05h.05l19.5-23.18A15.62 15.62 0 0048 14.32v-.06a15.14 15.14 0 00-3.63-10.11z'
            />
            <path d='M35 25a5 5 0 115-5 5 5 0 01-5 5z' fill='#d7153a' />
        </svg>
    );
};

BrandIconHealth.propTypes = {
    title: PropTypes.string,
    titleId: PropTypes.string,
    size: PropTypes.oneOf(['lg', 'xl', 'xxl']),
};
BrandIconHealth.defaultProps = {
    size: 'xl',
    focusable: false,
};
export default BrandIconHealth;
