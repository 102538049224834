import * as React from 'react';
import PropTypes from 'prop-types';
import tokens from '../../Tokens/tokens';
const { colors, spacing } = tokens;

const IconPrint = ({ title, titleId, ...props }) => {
    const sizeArray = Object.entries(spacing.icons);
    const colorArray = Object.entries(colors.icons);
    const sizeOptions = new Map(sizeArray);
    const displaySize = sizeOptions.get(props.size);
    const colorOptions = new Map(colorArray);
    const displayColor = colorOptions.get(props.color);
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 16 16'
            fill={props.color && displayColor}
            aria-hidden={!title}
            role={title ? 'img' : null}
            width={props.size && displaySize}
            height={props.size && displaySize}
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <path d='M11 16H5a1 1 0 01-1-1V9a1 1 0 011-1h6a1 1 0 011 1v6a1 1 0 01-1 1zm-5-2h4v-4H6zm7-9h-2V2H5v3H3V1a1 1 0 011-1h8a1 1 0 011 1z' />
            <path d='M13 5h-2V2H5v3H3V1a1 1 0 011-1h8a1 1 0 011 1z' />
            <path d='M16 11h-2V6H2v5H0V5a1 1 0 011-1h14a1 1 0 011 1z' />
        </svg>
    );
};

IconPrint.propTypes = {
    title: PropTypes.string,
    titleId: PropTypes.string,
    size: PropTypes.oneOf(['sm', 'md']),
    color: PropTypes.oneOf(['primaryBlue', 'secondaryBlue', 'darkGrey', 'white']),
};
IconPrint.defaultProps = {
    size: 'sm',
    color: 'darkGrey',
    focusable: false,
};
export default IconPrint;
