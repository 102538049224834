import styled from 'styled-components/macro';

export const FullScreen = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2000;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const FullScreenOpac = styled(FullScreen)`
    background-color: #848484;
    opacity: .8;
`;
