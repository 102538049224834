import React from 'react';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import { layout } from 'styled-system';
import tokens from '../../Tokens/tokens';

const { colors } = tokens;

const TickCircleSvg = ({ className, width, height }) => (
    <svg className={ className } width={ width } height={ height }
        viewBox='0 0 18 18'
        focusable='false'
        aria-hidden='true'>
        <defs>
            <path
                d='M16 7.273A8.727 8.727 0 007.273 16 8.727 8.727 0 0016 24.727 8.727 8.727 0 0024.727 16 8.727 8.727 0 0016 7.273zM14.97 20.23l-3.221-3.219 1.23-1.234 1.957 1.95 5.022-5.308 1.268 1.2-6.256 6.61z'
                id='a'
            />
        </defs>
        <g transform='translate(-7 -7)' fill='none' fillRule='evenodd'>
            <mask id='b' fill='#fff'>
                <use xlinkHref='#a' />
            </mask>
            <use fill='#000' fillRule='nonzero' xlinkHref='#a' />
            <g mask='url(#b)'>
                <path fill={colors.aquamentus} d='M1.455 1.455h29.09v29.09H1.456z' />
            </g>
        </g>
    </svg>
);

const TickCircle = styled(TickCircleSvg)`
    ${layout}
`;

TickCircle.defaultProps = {
    height: 24,
    width: 24,
};

TickCircle.propTypes = {
    className: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
};

export default TickCircle;
