import styled from 'styled-components/macro';
import { pxToRem } from '../../utils';
import tokens from '../../Tokens/tokens';

const { colors } = tokens;

export const LinkContainer = styled.a`
  text-decoration: underline;

  &:focus {
    outline: 2px solid ${colors.borders.focus};
    outline-offset: 2px;
  }

  svg {
    width: ${pxToRem(12)};
    height: ${pxToRem(12)};
    margin-left: ${pxToRem(4)};
  }
`;

