import * as React from 'react';
import PropTypes from 'prop-types';
import tokens from '../../Tokens/tokens';
const { spacing } = tokens;

const BrandIconInformation = ({ title, titleId, ...props }) => {
    const sizeArray = Object.entries(spacing.icons);
    const sizeOptions = new Map(sizeArray);
    const displaySize = sizeOptions.get(props.size);
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 48 48'
            fill={props.color && displayColor}
            aria-hidden={!title}
            role={title ? 'img' : null}
            width={props.size && displaySize}
            height={props.size && displaySize}
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <defs>
                <style>{'.Information_svg__cls-1{fill:#002664}'}</style>
            </defs>
            <path
                className='Information_svg__cls-1'
                d='M24 48a24 24 0 1124-24 24 24 0 01-24 24zm0-46a22 22 0 1022 22A22 22 0 0024 2z'
            />
            <path
                className='Information_svg__cls-1'
                d='M28 41h-8a1 1 0 01-1-1V19a1 1 0 011-1h8a1 1 0 011 1v21a1 1 0 01-1 1zm-7-2h6V20h-6z'
            />
            <path d='M29 11a5 5 0 11-5-5 5 5 0 015 5z' fill='#d7153a' />
        </svg>
    );
};

BrandIconInformation.propTypes = {
    title: PropTypes.string,
    titleId: PropTypes.string,
    size: PropTypes.oneOf(['lg', 'xl', 'xxl']),
};
BrandIconInformation.defaultProps = {
    size: 'xl',
    focusable: false,
};
export default BrandIconInformation;
