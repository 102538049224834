import * as React from 'react';
import PropTypes from 'prop-types';
import tokens from '../../Tokens/tokens';
const { colors, spacing } = tokens;

const IconSNSWAvatar = ({ title, titleId, ...props }) => {
    const sizeArray = Object.entries(spacing.icons);
    const colorArray = Object.entries(colors.icons);
    const sizeOptions = new Map(sizeArray);
    const displaySize = sizeOptions.get(props.size);
    const colorOptions = new Map(colorArray);
    const displayColor = colorOptions.get(props.color);
    return (
        <svg
            viewBox='0 0 16 16'
            fill={props.color && displayColor}
            xmlns='http://www.w3.org/2000/svg'
            aria-hidden={!title}
            role={title ? 'img' : null}
            width={props.size && displaySize}
            height={props.size && displaySize}
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <path
                d='M8.00125 8.02088e-08C4.76549 -0.000456496 1.8481 1.94836 0.609513 4.93768C-0.629073 7.92699 0.0550845 11.3681 2.34295 13.6563C4.63081 15.9444 8.07179 16.6291 11.0613 15.3909C14.0508 14.1528 16 11.2356 16 7.99988C16 3.58211 12.419 0.000623443 8.00125 8.02088e-08Z'
                fill='#D7153A'
            />
            <path
                d='M12.9623 9.77667C12.8787 9.64676 12.7393 9.56327 12.5853 9.5509C12.5853 9.5509 12.3596 9.53962 11.9848 9.53962H11.7816C11.443 9.53962 10.9023 9.56219 10.2148 9.62654C10.1972 10.3959 10.1289 11.1633 10.0105 11.9237C10.5647 12.1348 11.1393 12.3865 11.7139 12.6721C12.8427 11.2498 13.0131 10.3016 13.0391 10.0487C13.0386 9.95275 13.0121 9.85872 12.9623 9.77667Z'
                fill='white'
            />
            <path
                d='M6.10481 8.54174C6.08111 8.45595 6.03821 8.34871 5.96145 8.34871C5.9197 8.35193 5.8801 8.36856 5.84857 8.39612C5.84857 8.39612 5.80681 8.42773 5.73569 8.49207C5.66458 8.55642 5.55169 8.64672 5.39705 8.77879C5.01945 9.11818 4.65927 9.47647 4.3179 9.85229C3.69819 10.542 2.91479 11.5579 2.39893 12.7477C3.36662 13.8914 4.66091 14.7119 6.1082 15.099C6.36328 14.127 6.48593 13.1249 6.4728 12.1201C6.46774 11.0135 6.36347 9.90963 6.16125 8.82169C6.12513 8.63995 6.10481 8.54174 6.10481 8.54174Z'
                fill='white'
            />
            <path
                d='M3.16204 6.3507C3.16262 6.34809 3.16262 6.3454 3.16204 6.34279C3.12366 6.26265 3.08528 6.18589 3.04916 6.11026L3.02433 6.05946C2.98143 5.9748 2.93967 5.8924 2.8979 5.81338L2.88774 5.79419C2.61908 5.27833 2.38768 4.87986 2.24093 4.6349C2.1574 4.50622 2.11563 4.43736 2.11563 4.43736C2.03775 4.32448 1.97227 4.30981 1.95308 4.30981C1.89777 4.30981 1.8402 4.35496 1.78376 4.43962C1.78376 4.43962 1.73635 4.50961 1.66072 4.6349C1.54784 4.82567 1.43496 5.0108 1.32772 5.19931L1.26564 5.31219C1.25548 5.33025 1.24419 5.34944 1.23403 5.36976L1.22274 5.39008C1.14711 5.52779 1.06358 5.68357 0.974406 5.85628C0.307317 8.04676 0.698763 10.423 2.03323 12.2837C2.54232 11.2136 3.30201 10.148 4.29763 9.1129C3.98124 8.16783 3.60186 7.24502 3.16204 6.3507Z'
                fill='white'
            />
            <path
                d='M9.61773 7.29662C9.60644 7.18374 9.59628 7.07086 9.58725 6.98394C9.58725 6.95346 9.58047 6.92298 9.57709 6.89589C9.5737 6.8688 9.57709 6.86316 9.57709 6.84848C9.57709 6.83381 9.57709 6.82026 9.57709 6.80672C9.57709 6.79317 9.57709 6.77511 9.57709 6.76156C9.57659 6.75292 9.57659 6.74425 9.57709 6.7356C9.57709 6.70512 9.56919 6.67803 9.56693 6.65771V6.65094C9.56693 6.63965 9.56693 6.63062 9.56693 6.62272C9.56693 6.61482 9.56693 6.61708 9.56693 6.61482C9.56693 6.61256 9.56693 6.59111 9.56693 6.59111C9.56487 6.51315 9.53026 6.43961 9.47149 6.38835C9.41271 6.33708 9.33515 6.31278 9.25763 6.32133C9.20983 6.32254 9.1627 6.33288 9.11879 6.35181C9.11879 6.35181 9.04655 6.37777 8.9156 6.43872C8.78466 6.49968 8.61986 6.56967 8.40651 6.67013L8.33201 6.704C8.20445 6.76608 8.0769 6.83042 7.95047 6.89476C7.42896 7.16681 6.91987 7.45691 6.43561 7.76395C6.36675 7.80233 6.29789 7.84635 6.23016 7.89038C6.43155 8.00359 6.57218 8.20047 6.61396 8.42769C6.61524 8.43609 6.61712 8.44439 6.6196 8.45252C6.86428 9.65983 6.99018 10.8882 6.9955 12.12C7.00885 13.1635 6.88281 14.204 6.62073 15.2141C7.07478 15.3034 7.53627 15.3496 7.99901 15.3518C8.11189 15.3518 8.21236 15.3518 8.31846 15.3439L8.39635 15.2141C9.47324 13.32 9.70013 10.8591 9.70013 9.12642C9.70013 8.51235 9.67304 7.90166 9.61773 7.29662Z'
                fill='white'
            />
            <path
                d='M9.91566 12.4531C9.7332 13.439 9.40607 14.3926 8.94489 15.283C10.2742 15.1124 11.5309 14.5793 12.5774 13.7422C12.2602 13.5559 11.8866 13.3493 11.4666 13.1382C10.9621 12.8828 10.4443 12.654 9.91566 12.4531Z'
                fill='white'
            />
            <path
                d='M3.83929 6.54035C4.12487 7.15555 4.38563 7.78204 4.61252 8.40175C5.20801 7.93611 5.83153 7.50749 6.47957 7.1183L6.45587 7.00542V6.99977C6.44458 6.94446 6.43329 6.88689 6.42088 6.83496V6.82932C6.40959 6.77401 6.39717 6.71644 6.38588 6.66451C6.23801 6.00303 6.09691 5.49507 6.00434 5.21738V5.20609L5.98403 5.15304C5.98403 5.13272 5.97161 5.11466 5.96709 5.09772L5.96032 5.07966C5.93122 4.95566 5.82237 4.86672 5.69505 4.86293C5.67816 4.86126 5.66114 4.86126 5.64425 4.86293C5.59972 4.86833 5.55753 4.8859 5.52234 4.91373C5.52234 4.91373 5.23675 5.08869 4.80103 5.40137C4.55947 5.57408 4.1768 5.8608 3.70721 6.24911C3.7535 6.34506 3.79752 6.44214 3.84154 6.53809L3.83929 6.54035Z'
                fill='white'
            />
        </svg>
    );
};

IconSNSWAvatar.propTypes = {
    title: PropTypes.string,
    titleId: PropTypes.string,
    size: PropTypes.oneOf(['sm', 'md']),
    color: PropTypes.oneOf(['primaryBlue', 'secondaryBlue', 'darkGrey', 'white']),
};
IconSNSWAvatar.defaultProps = {
    size: 'sm',
    color: 'darkGrey',
    focusable: false,
};
export default IconSNSWAvatar;
