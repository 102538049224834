export const australianPhoneNumber = [[0], /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];
export const bankAccountNumber = [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];
export const bsb = [/\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/];

const Masks = {
    australianPhoneNumber,
    bankAccountNumber,
    bsb,
};
export default Masks;
