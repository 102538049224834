import * as React from 'react';
import PropTypes from 'prop-types';
import tokens from '../../Tokens/tokens';
const { spacing } = tokens;

const BrandIconTraining = ({ title, titleId, ...props }) => {
    const sizeArray = Object.entries(spacing.icons);
    const sizeOptions = new Map(sizeArray);
    const displaySize = sizeOptions.get(props.size);
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 48 48'
            fill={props.color && displayColor}
            aria-hidden={!title}
            role={title ? 'img' : null}
            width={props.size && displaySize}
            height={props.size && displaySize}
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <defs>
                <style>{'.Training_svg__cls-2{fill:#002664}'}</style>
            </defs>
            <path
                d='M23.55 32.01l.81.84 1.43-1-1.19-1.24-1.05 1.4zM41 14.1a44.4 44.4 0 00-15.53 14.53L27.71 31a44.37 44.37 0 0015.12-15A1.58 1.58 0 0041 14.1z'
                fill='none'
            />
            <path
                className='Training_svg__cls-2'
                d='M40.19 12.27l-.11.05c-7 4-11.84 8.58-16.72 15.86a1 1 0 00-.1.9l-1.82 2.4a1 1 0 00.07 1.3l.3.31-1.23 1.19a1 1 0 00.7 1.72 1 1 0 00.69-.28l1.23-1.19.32.33a1 1 0 00.72.31.93.93 0 00.57-.19l2.54-1.78a1.38 1.38 0 00.2 0 1 1 0 00.52-.14c7.47-4.6 12.25-9.26 16.5-16.1l.06-.1a3.55 3.55 0 00-4.44-4.62zM24.36 32.85l-.81-.85 1.05-1.4 1.19 1.24zM42.83 16a44.37 44.37 0 01-15.12 15l-2.24-2.33A44.4 44.4 0 0141 14.1a1.58 1.58 0 011.83 1.9zM7 15v7H4a1 1 0 000 2h8a1 1 0 000-2H9v-7zm6 19a1 1 0 00-1-1H8.9l.1-7H7l-.1 7H4a1 1 0 000 2h8a1 1 0 001-1zM40 0H10a3 3 0 00-3 3v8H4a1 1 0 000 2h8a1 1 0 000-2H9V3a1 1 0 011-1h30a1 1 0 011 1v7.49a5.14 5.14 0 01.55-.06 11.29 11.29 0 011.45.2V3a3 3 0 00-3-3z'
            />
            <path
                className='Training_svg__cls-2'
                d='M41 45a1 1 0 01-1 1H10a.94.94 0 01-1-1v-8H7v8a2.93 2.93 0 002.56 3h30.83A3 3 0 0043 45V23.44c-.64.81-1.31 1.59-2 2.35z'
            />
            <path
                d='M29.72 23.17a4.82 4.82 0 001 2.15 5 5 0 002.56 1.62 42.3 42.3 0 006.09-6.1 4.84 4.84 0 00-1-1.94A4.94 4.94 0 0036 17.34a41.32 41.32 0 00-6.28 5.83z'
                fill='#d7153a'
            />
        </svg>
    );
};

BrandIconTraining.propTypes = {
    title: PropTypes.string,
    titleId: PropTypes.string,
    size: PropTypes.oneOf(['lg', 'xl', 'xxl']),
};
BrandIconTraining.defaultProps = {
    size: 'xl',
    focusable: false,
};
export default BrandIconTraining;
