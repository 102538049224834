import styled from 'styled-components/macro';

export const MoreInfoModalIconButton = styled.button`
    cursor: pointer;
    display: inline-flex;
    background: none;
    border: none;
    padding: 0;

    svg {
        margin: 0;
    }
`;