import * as React from 'react';
import PropTypes from 'prop-types';
import tokens from '../../Tokens/tokens';
const { colors, spacing } = tokens;

const IconExternalLink = ({ title, titleId, ...props }) => {
    const sizeArray = Object.entries(spacing.icons);
    const colorArray = Object.entries(colors.icons);
    const sizeOptions = new Map(sizeArray);
    const displaySize = sizeOptions.get(props.size);
    const colorOptions = new Map(colorArray);
    const displayColor = colorOptions.get(props.color);
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 16 16'
            fill={props.color && displayColor}
            aria-hidden={!title}
            role={title ? 'img' : null}
            width={props.size && displaySize}
            height={props.size && displaySize}
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <path d='M10 14H2V6h4l2-2H1a1 1 0 00-1 1v10a1 1 0 001 1h10a1 1 0 001-1V8l-2 2z' />
            <path d='M15.92.62a1 1 0 00-.54-.54A1 1 0 0015 0h-5a1 1 0 000 2h2.59l-6.3 6.29a1 1 0 000 1.42 1 1 0 001.42 0L14 3.41V6a1 1 0 002 0V1a1 1 0 00-.08-.38z' />
        </svg>
    );
};

IconExternalLink.propTypes = {
    title: PropTypes.string,
    titleId: PropTypes.string,
    size: PropTypes.oneOf(['sm', 'md']),
    color: PropTypes.oneOf(['primaryBlue', 'secondaryBlue', 'darkGrey', 'white']),
};
IconExternalLink.defaultProps = {
    size: 'sm',
    color: 'darkGrey',
    focusable: false,
};
export default IconExternalLink;
