import * as React from 'react';
import PropTypes from 'prop-types';
import tokens from '../../Tokens/tokens';
const { spacing } = tokens;

const BrandIconMaritime = ({ title, titleId, ...props }) => {
    const sizeArray = Object.entries(spacing.icons);
    const sizeOptions = new Map(sizeArray);
    const displaySize = sizeOptions.get(props.size);
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 48 48'
            fill={props.color && displayColor}
            aria-hidden={!title}
            role={title ? 'img' : null}
            width={props.size && displaySize}
            height={props.size && displaySize}
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <defs>
                <style>{'.Maritime_svg__cls-1{fill:#002664}'}</style>
            </defs>
            <path
                className='Maritime_svg__cls-1'
                d='M47.73 43.93a1 1 0 00-1.41-.06 10.87 10.87 0 01-7 2.13 10.9 10.9 0 01-7-2.13 1 1 0 00-.17-.11l-.12-.07a1.14 1.14 0 00-.25-.06h-.12a1.18 1.18 0 00-.25 0 .61.61 0 00-.13 0 .76.76 0 00-.19.12l-.13.07A10.83 10.83 0 0124 46a10.9 10.9 0 01-7-2.13 1 1 0 00-.17-.11l-.12-.07a1.14 1.14 0 00-.25-.06h-.12a1 1 0 00-.25 0 .61.61 0 00-.13 0 .76.76 0 00-.19.12l-.13.07a10.83 10.83 0 01-7 2.13 10.87 10.87 0 01-7-2.13 1 1 0 00-1.41.05 1 1 0 000 1.41c.2.16 2.99 2.72 8.44 2.72a13.44 13.44 0 007.66-2.14A13.48 13.48 0 0024 48a13.44 13.44 0 007.66-2.14A13.51 13.51 0 0039.33 48c5.44 0 8.23-2.56 8.35-2.67a1 1 0 00.05-1.4zm-12.64-15h-3.17a2.59 2.59 0 110-5.17h3.17a2.58 2.58 0 012.58 2.56 2.59 2.59 0 01-2.58 2.61zm-3.76-2.6a.59.59 0 00.59.6h3.17a.59.59 0 000-1.17h-3.17a.59.59 0 00-.59.57z'
            />
            <path
                className='Maritime_svg__cls-1'
                d='M47.89 32.22a1 1 0 00-1-.54l-2.91.2c0-3.07-.14-6-2.67-8.5-1.44-1.43-6.46-6-10.75-3.81-1.8.9-4.12 2-6.17 2.93-1.67.77-3.25 1.5-4.24 2l-.57.28c-2.24 1.11-4.56 2.25-4.56 4.38v4.72l-8.31.58v-1.57h1.42a2.59 2.59 0 002.58-2.59 2.58 2.58 0 00-2.58-2.56H3.37a2.59 2.59 0 100 5.17h1.38v1.7l-3.82.27a1 1 0 00-.77.46 1 1 0 00-.08.91L2.37 42h2.16l-2.09-5.24 42.29-2.93-6.8 8.17h2.6l7.24-8.69a1 1 0 00.12-1.09zM2.78 30.29a.59.59 0 01.59-.57h4.76a.59.59 0 010 1.17H3.37a.59.59 0 01-.59-.6zm14.28-1.13c0-.88 1.91-1.83 3.45-2.59l.57-.28c1-.49 2.54-1.21 4.19-2 2.06-1 4.4-2 6.22-2.95 2.46-1.23 6 1 8.45 3.44 2 2 2.07 4.32 2.08 7.22l-25 1.72zM5.6 18.82a1 1 0 01-.54-1.31l.76-1.8a1 1 0 111.84.77l-.75 1.8a1 1 0 01-.91.62m6.16-15.1a.91.91 0 01-.39-.08 1 1 0 01-.54-1.3L12 .61a1 1 0 111.84.78l-.75 1.8a1 1 0 01-.93.61zM1 14a1 1 0 01-.38-1.92l1.8-.74a1 1 0 11.76 1.85l-1.78.71a1 1 0 01-.4.1zm15.06-6.34a1 1 0 01-.38-1.92L17.49 5a1 1 0 11.76 1.85l-1.81.74a1 1 0 01-.38.07zM2.8 7.74a1.09 1.09 0 01-.39-.08l-1.8-.75A1 1 0 01.08 5.6a1 1 0 011.31-.54l1.8.76a1 1 0 01-.39 1.92zm15.1 6.17a.91.91 0 01-.39-.08l-1.8-.75a1 1 0 01.77-1.85l1.8.76a1 1 0 01-.38 1.92zM13 18.87a1 1 0 01-.92-.62l-.74-1.81a1 1 0 111.85-.75l.74 1.8a1 1 0 01-.55 1.31M6.66 3.83a1 1 0 01-.92-.62L5 1.41A1 1 0 116.85.65l.74 1.81A1 1 0 017 3.76a1 1 0 01-.34.07z'
            />
            <path d='M14.91 9.45a5 5 0 11-5-5 5 5 0 015 5z' fill='#d7153a' />
        </svg>
    );
};

BrandIconMaritime.propTypes = {
    title: PropTypes.string,
    titleId: PropTypes.string,
    size: PropTypes.oneOf(['lg', 'xl', 'xxl']),
};
BrandIconMaritime.defaultProps = {
    size: 'xl',
    focusable: false,
};
export default BrandIconMaritime;
