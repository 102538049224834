import * as React from 'react';
import PropTypes from 'prop-types';
import tokens from '../../Tokens/tokens';
const { spacing } = tokens;

const BrandIconPrintingServices = ({ title, titleId, ...props }) => {
    const sizeArray = Object.entries(spacing.icons);
    const sizeOptions = new Map(sizeArray);
    const displaySize = sizeOptions.get(props.size);
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 48 48'
            fill={props.color && displayColor}
            aria-hidden={!title}
            role={title ? 'img' : null}
            width={props.size && displaySize}
            height={props.size && displaySize}
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <defs>
                <style>{'.PrintingServices_svg__cls-2{fill:#002664}'}</style>
            </defs>
            <path d='M15 31a5 5 0 11-5-5 5 5 0 015 5z' fill='#d7153a' />
            <path className='PrintingServices_svg__cls-2' d='M15 13a5 5 0 10-5 5 5 5 0 005-5zm-8 0a3 3 0 113 3 3 3 0 01-3-3z' />
            <path
                className='PrintingServices_svg__cls-2'
                d='M39.31 21.92C42.17 20.3 44 17.05 44 13c0-5.8-3.79-10-9-10H11a8.77 8.77 0 00-1 0 10 10 0 00-4.28 19A10 10 0 009 41h14v-2h-7a10 10 0 002.39-13.4C22.18 29.3 25 36.52 25 44a1 1 0 001 1h21a1 1 0 001-1c0-11.82-3.11-19.57-8.69-22.08zM35 5c4.12 0 7 3.29 7 8s-2.88 8-7 8H15.9A10 10 0 0016 5zM2 13a8 8 0 118 8 8 8 0 01-8-8zm8 26a8 8 0 118-8 8 8 0 01-8 8zm17 4c-.26-8.69-3.77-16.48-8.49-20H35c9.6 0 10.87 14.2 11 20z'
            />
        </svg>
    );
};

BrandIconPrintingServices.propTypes = {
    title: PropTypes.string,
    titleId: PropTypes.string,
    size: PropTypes.oneOf(['lg', 'xl', 'xxl']),
};
BrandIconPrintingServices.defaultProps = {
    size: 'xl',
    focusable: false,
};
export default BrandIconPrintingServices;
