import * as React from 'react';
import PropTypes from 'prop-types';
import tokens from '../../Tokens/tokens';
const { spacing } = tokens;

const BrandIconElectricalProvider = ({ title, titleId, ...props }) => {
    const sizeArray = Object.entries(spacing.icons);
    const sizeOptions = new Map(sizeArray);
    const displaySize = sizeOptions.get(props.size);
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 48 48'
            fill={props.color && displayColor}
            aria-hidden={!title}
            role={title ? 'img' : null}
            width={props.size && displaySize}
            height={props.size && displaySize}
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <defs>
                <style>{'.ElectricalProvider_svg__cls-1{fill:#002664}.ElectricalProvider_svg__cls-2{fill:#d7153a}'}</style>
            </defs>
            <path
                className='ElectricalProvider_svg__cls-1'
                d='M34 6h-2V3.47A1.76 1.76 0 0030.53 2H17.47A1.76 1.76 0 0016 3.47V6h-2V3.47A3.71 3.71 0 0117.47 0h13.06A3.71 3.71 0 0134 3.47zm13 14H1a1 1 0 01-1-1v-7.83A3.17 3.17 0 013.17 8h41.66A3.17 3.17 0 0148 11.17V19a1 1 0 01-1 1zM2 18h44v-6.83A1.18 1.18 0 0044.83 10H3.17A1.18 1.18 0 002 11.17zm2 30a1 1 0 01-.71-.29A1 1 0 013 47V22h2v24l38-.1V22h2v24.9a1 1 0 01-1 1L4 48z'
            />
            <path
                className='ElectricalProvider_svg__cls-1'
                d='M24 41a1.25 1.25 0 01-.32 0 1 1 0 01-.63-1.27l2.46-7.36-3.31.63a1 1 0 01-1-.37 1 1 0 01-.1-1.06l4-8a1 1 0 011.78.9l-3.08 6.17 3-.6a1 1 0 011.2 1.28l-3 9a1 1 0 01-1 .68z'
            />
            <path className='ElectricalProvider_svg__cls-2' d='M12 24a5 5 0 004.9-4H7.1a5 5 0 004.9 4z' />
            <path
                className='ElectricalProvider_svg__cls-2'
                d='M12 24a5 5 0 004.9-4H7.1a5 5 0 004.9 4zm24 0a5 5 0 004.9-4h-9.8a5 5 0 004.9 4z'
            />
        </svg>
    );
};

BrandIconElectricalProvider.propTypes = {
    title: PropTypes.string,
    titleId: PropTypes.string,
    size: PropTypes.oneOf(['lg', 'xl', 'xxl']),
};
BrandIconElectricalProvider.defaultProps = {
    size: 'xl',
    focusable: false,
};
export default BrandIconElectricalProvider;
