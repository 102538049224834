import * as React from 'react';
import PropTypes from 'prop-types';
import tokens from '../../Tokens/tokens';
const { colors, spacing } = tokens;

const IconCopy = ({ title, titleId, ...props }) => {
    const sizeArray = Object.entries(spacing.icons);
    const colorArray = Object.entries(colors.icons);
    const sizeOptions = new Map(sizeArray);
    const displaySize = sizeOptions.get(props.size);
    const colorOptions = new Map(colorArray);
    const displayColor = colorOptions.get(props.color);
    return (
        <svg
            viewBox='0 0 16 16'
            xmlns='http://www.w3.org/2000/svg'
            fill={props.color && displayColor}
            aria-hidden={!title}
            role={title ? 'img' : null}
            width={props.size && displaySize}
            height={props.size && displaySize}
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <path d='M1,5 C1.55228475,5 2,5.44771525 2,6 L2,14 L10,14 C10.5522847,14 11,14.4477153 11,15 C11,15.5522847 10.5522847,16 10,16 L1,16 C0.44771525,16 0,15.5522847 0,15 L0,6 C0,5.48716416 0.38604019,5.06449284 0.883378875,5.00672773 L1,5 Z M14,0 C15.1045695,0 16,0.8954305 16,2 L16,10 C16,11.1045695 15.1045695,12 14,12 L6,12 C4.8954305,12 4,11.1045695 4,10 L4,2 C4,0.8954305 4.8954305,0 6,0 L14,0 Z M14,2 L6,2 L6,10 L14,10 L14,2 Z' />
        </svg>
    );
};

IconCopy.propTypes = {
    title: PropTypes.string,
    titleId: PropTypes.string,
    size: PropTypes.oneOf(['sm', 'md']),
    color: PropTypes.oneOf(['primaryBlue', 'secondaryBlue', 'darkGrey', 'white']),
};
IconCopy.defaultProps = {
    size: 'sm',
    color: 'darkGrey',
    focusable: false,
};
export default IconCopy;
