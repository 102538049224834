/* eslint-disable no-nested-ternary */
import React, { Component } from 'react';
import { AutoSuggestContainer } from './AutoSuggest.styled';
import Autosuggest from 'react-autosuggest';
import Field from '../Components/Form/Field.styled';
import PropTypes from 'prop-types';
import { getID, getAriaDescribedby } from '../utils';
import { trackMe } from '../Components/ComponentAnalytics/componentAnalytics';

class AutoSuggest extends Component {

    constructor(props) {
        super(props);
        this.state = {
            value: this.props.initialValue,
            filteredValues: [],
            // Auto detect whether we're in Simple Mode or not.
            simpleMode: !props.onGetSuggestions && !props.getSuggestionValue && !props.renderSuggestion
        };
        trackMe('AutoSuggest');
    }

    componentDidUpdate(prevProps) {
        const { initialValue, onValueChange } = this.props;
        if (initialValue && !prevProps.initialValue) {
            this.setState({
                value: initialValue,
                suggestion: null
            });
            onValueChange && onValueChange(initialValue);
        }
    };

    onChange = (event, { newValue }) => {
        this.setState({
            value: newValue
        });
        this.props.onValueChange && this.props.onValueChange(newValue);
    };

    onBlur = (event, { highlightedSuggestion }) => {
        // Use highlightedSuggestion where possible, otherwise default to suggestion.
        const blurSuggestion = highlightedSuggestion ?? this.state.suggestion;
        // Update suggestion always
        this.setState({ suggestion: blurSuggestion });
        this.props.onBlur && this.props.onBlur(blurSuggestion, this.state.value);
    };

    simpleFilter = value => {
        // Return the list of data which partial match the full list of values.
        value = value.toLowerCase();
        const newValues = this.props.suggestions.filter(data => data.toLowerCase().includes(value));
        this.setState({ filteredValues: newValues });
    };

    getSuggestions = value => {
        this.props.onGetSuggestions ? this.props.onGetSuggestions(value) : this.simpleFilter(value);
    };

    onSuggestionsFetchRequested = ({ value }) => {
        // If we've been passed in a minLength, use that.
        // If not, default to 3 characters UNLESS we've been passed in 'values' in which case use 0 as
        // we want the list to appear immediately.
        const minLength = this.props.minLength ? this.props.minLength : (this.state.simpleMode ? 0 : 3);
        const inputValue = value.trim();
        if (inputValue.length >= minLength) {
            this.getSuggestions(value);
        } else {
            this.onSuggestionsClearRequested();
        }
    };

    onSuggestionsClearRequested = () => {
        this.props.onClearSuggestions && this.props.onClearSuggestions();
    };

    onSuggestionSelected = (_, { suggestion }) => {
        this.setState({ suggestion });
        this.props.onSelect(suggestion);
    };

    render() {
        const {
            className,
            id,
            name,
            label,
            helpMessage,
            hasError,
            errorMessage,
            disabled,
            placeholder,
            getSuggestionValue,
            renderSuggestion,
            shouldRenderSuggestions,
            showManualAddress,
            inputRef,
            isRequired,
            isOptional,
        } = this.props;

        // If we've been passed suggestions, use those otherwise use the values filtered by user input
        const suggestions = this.state.simpleMode ? this.state.filteredValues : this.props.suggestions;

        // If shouldRenderSuggestions is passed in, use that.
        // If it hasn't AND we're in Simple Mode, default to returning true
        // which means the list will display as soon as the user clicks in the
        // AutoSuggest component without having to type anything.
        let callShouldRenderSuggestions = shouldRenderSuggestions;
        if (!callShouldRenderSuggestions && this.state.simpleMode) {
            callShouldRenderSuggestions = () => true;
        }

        const genID = () => id || getID();

        const elemID = genID();
        const idError = `${elemID}-error`;
        const idHelper = `${elemID}-helper`;

        const showError = hasError && !!errorMessage;
        const showAriaDescribedby = getAriaDescribedby( !!showError && idError, !!helpMessage && idHelper );

        const optional = isOptional && !isRequired;
        const required = !isOptional && isRequired;

        const showRequired =
        ((!optional || !required) && null) ||
            (required ? true : null) ||
            (optional ? false : null);

        const value = this.state.value || '';
        const inputProps = {
            id: elemID, name, disabled, placeholder, value,
            onChange: this.onChange,
            onBlur: this.onBlur,
            ref: inputRef,
            'aria-invalid': hasError,
            'aria-describedby': showAriaDescribedby,
            'aria-required': showRequired,
        };

        let finalSuggestions = suggestions;
        if (showManualAddress) {
            finalSuggestions = suggestions.concat({
                address: <>Can’t find your address? &nbsp;<span className='enter-address-manually'>Click here</span></>
            });
        }
        return (
            <Field className={ className }>
                { label &&
                    <Field.Label
                        htmlFor={ elemID }
                        style={ { maxWidth: '100%' } }
                    >
                        {optional ? `${label} (optional)` : label}
                    </Field.Label>
                }
                { helpMessage && <Field.Help id={ idHelper }>{ helpMessage }</Field.Help> }
                <AutoSuggestContainer
                    showManualAddress={ showManualAddress }
                    disabled={ disabled }
                    hasError={ hasError }
                >
                    <Autosuggest
                        suggestions={ finalSuggestions }
                        getSuggestionValue={ getSuggestionValue ? getSuggestionValue : value => value }
                        inputProps={ inputProps }
                        onSuggestionsFetchRequested={ this.onSuggestionsFetchRequested }
                        onSuggestionsClearRequested={ this.onSuggestionsClearRequested }
                        onSuggestionSelected={ this.onSuggestionSelected }
                        shouldRenderSuggestions={ callShouldRenderSuggestions }
                        renderSuggestion={ renderSuggestion ? renderSuggestion : value => value }
                    />
                </AutoSuggestContainer>
                { showError && <Field.Error id={ idError }>{ errorMessage }</Field.Error> }
            </Field>
        );
    }
}

AutoSuggest.propTypes = {
    className: PropTypes.string,
    id: PropTypes.string,
    name: PropTypes.string,
    label: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node,
    ]),
    /** `aria-required: true` on form element */
    isRequired: PropTypes.bool,
    /** `aria-required: false` on form element */
    isOptional: PropTypes.bool,
    helpMessage: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node,
    ]),
    hasError: PropTypes.bool,
    errorMessage: PropTypes.string,
    disabled: PropTypes.bool,
    suggestions: PropTypes.array.isRequired,
    onGetSuggestions: PropTypes.func,
    onClearSuggestions: PropTypes.func,
    onSelect: PropTypes.func.isRequired,
    getSuggestionValue: PropTypes.func,
    onValueChange: PropTypes.func,
    onBlur: PropTypes.func,
    renderSuggestion: PropTypes.func,
    shouldRenderSuggestions: PropTypes.func,
    showManualAddress: PropTypes.bool,
    placeholder: PropTypes.string,
    inputRef: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.object,
    ]),
    initialValue: PropTypes.string,
    minLength: PropTypes.number
};

export default AutoSuggest;
