// import api from '../api';
import axios from 'axios';

// No need to handle try/catch here, as it's already handled in calling method
const customExecutor = async method => method instanceof Promise ? await method : method;

export const useNSWPointV2API = ({
    key: apiKey,
    stateTerritory: apiState,
    addressType: apiAddressType,
    dataSet: apiDataSet,
    outFields: apiOutFields,
    maxResults: apiMaxResults = 10,
    customBackendAPI = {
        getSuggestions: Function.prototype,
        getDetailsById: Function.prototype
    }
}) => {
    let currentRequestNo = 0;
    const pointNSWDomain = 'https://point.digital.nsw.gov.au';

    const getLatestSuggestions = async value => {
        const defaultResult = {
            suggestions: [],
            isLatest: false
        };

        if (!checkAndShowWarning()) {
            return defaultResult;
        }

        try {
            const { requestNo, suggestions } = await getSuggestions(value, currentRequestNo);
            const result = {
                suggestions,
                isLatest: requestNo === currentRequestNo,
            };

            currentRequestNo++;

            return result;
        } catch (err) {
            return defaultResult;
        }
    };

    const getSuggestions = async (value, requestNo) => {
        const defaultResult = {
            suggestions: [],
            requestNo
        };
        const params = {
            address: value,
            maxNumberOfResults: apiMaxResults
        };

        if (apiState) {
            params.stateTerritory = apiState;
        }

        if (apiAddressType) {
            params.addressType = apiAddressType;
        }

        if (apiDataSet) {
            params.dataset = apiDataSet;
        }

        const paramStr = Object
            .entries(params)
            .map(([key, val]) => `${key}=${encodeURIComponent(val)}`)
            .join('&')
        ;
        const url = `${pointNSWDomain}/v2/api/predictive1?${paramStr}`;

        try {
            if (!apiKey) {
                return await customExecutor(customBackendAPI.getSuggestions(value, requestNo, url, params));
            }

            const { data } = await axios({
                url,
                headers: { 'x-api-key': apiKey },
            });

            return {
                ...defaultResult,
                suggestions: Array.isArray(data) ? data : []
            };
        } catch (err) {
            return defaultResult;
        }
    };

    const getDetailsById = async id => {
        const defaultResult = {};
        const url = `${pointNSWDomain}/v2/api/predictive2`;
        const body = { id };

        if (!checkAndShowWarning()) {
            return defaultResult;
        }

        if (apiOutFields) {
            body.outFields = apiOutFields;
        }

        try {
            if (!apiKey) {
                return convertAddress(await customExecutor(customBackendAPI.getDetailsById(id, url, body)));
            }

            const { data, status } = await axios({
                url,
                method: 'POST',
                headers: { 'x-api-key': apiKey },
                data: JSON.stringify(body)
            });

            return status === 200 ? convertAddress(data) : defaultResult;
        } catch (err) {
            return defaultResult;
        }
    };

    const convertAddress = address => {
        const {
            data: { geo, addressDetails, addressId, localGovernmentArea, cadastralParcels },
        } = address;

        const {
            streetName,
            streetType,
            siteName,
            localityName,
            stateTerritory,
            postcode,
            formattedAddress,
            postalDeliveryType,
            postalDeliveryNumber,
            cadastralIdentifier
        } = addressDetails;

        // check if we have localGovernmentArea first, then set LGA vars
        const lgaName = localGovernmentArea ?
            localGovernmentArea.lgaName :
            undefined;
        const lgaShortName = localGovernmentArea ?
            localGovernmentArea.lgaShortName :
            undefined;
        const lgaPid = localGovernmentArea ?
            localGovernmentArea.lgaPid :
            undefined;

        // check if we have a lat/long first, and then set the lat/long accordingly
        const latLong = geo &&
            geo.geometry &&
            geo.geometry.coordinates &&
            2 === geo.geometry.coordinates.length;
        const longitude = latLong ?
            geo.geometry.coordinates[0] :
            undefined;
        const latitude = latLong ?
            geo.geometry.coordinates[1] :
            undefined;

        // build the street address in correct order, from the following variables
        const propertyName = siteName;
        const buildingNumber = getIdentifier(addressDetails, true);
        const unitNumber = getIdentifier(addressDetails, false);
        const streetNumber = getStreetNumber(addressDetails);
        let streetAddress = '';

        streetAddress = propertyName || streetAddress;
        streetAddress = buildingNumber ? `${streetAddress} ${buildingNumber}` : streetAddress;
        streetAddress = unitNumber ? `${streetAddress} ${unitNumber}` : streetAddress;
        if (streetNumber && streetName) {
            streetAddress = streetAddress ?
                `${streetAddress} ${streetNumber} ${streetName}` :
                `${streetNumber} ${streetName}`;
            streetAddress = streetType ? `${streetAddress} ${streetType}` : streetAddress;
        } else if (postalDeliveryType && postalDeliveryNumber) {
            streetAddress = streetAddress ?
                `${streetAddress} ${postalDeliveryType} ${postalDeliveryNumber}` :
                `${postalDeliveryType} ${postalDeliveryNumber}`;
        }

        return {
            unitNumber,
            buildingNumber,
            streetNumber: streetNumber,
            streetName,
            streetType,
            propertyName,
            postalDeliveryType,
            postalDeliveryNumber,
            streetAddress: streetAddress.trim(),
            suburb: localityName,
            state: stateTerritory,
            country: 'Australia',
            postcode,
            latitude,
            longitude,
            nswPointId: addressId,
            formattedAddress: formattedAddress,
            lgaName,
            lgaShortName,
            lgaPid,
            cadastralIdentifier,
            cadastralParcels,
            validated: true,
        };
    };

    const getIdentifier = (address, isBuilding) => {
        const {
            complexUnitType,
            complexLevelType,
            complexUnitIdentifier,
            complexLevelNumber,
        } = address;
        let identifier = '';

        if (complexUnitType == null && complexLevelType == null) {
            return undefined;
        }

        if (isBuilding && complexUnitType !== 'BUILDING') {
            return undefined;
        }

        if (!isBuilding && complexUnitType === 'BUILDING') {
            return undefined;
        }

        if (complexUnitType != null) {
            identifier += `${complexUnitType} ${complexUnitIdentifier}`;
        }

        if (complexLevelType != null) {
            identifier += ` ${complexLevelType} ${complexLevelNumber}`;
        }

        return identifier.trim();
    };

    const getStreetNumber = address => {
        const { streetNumber1, streetNumber2 } = address;
        if (streetNumber1 == null && streetNumber2 == null) {
            return undefined;
        }

        if (streetNumber1 != null && streetNumber2 != null) {
            return `${streetNumber1}-${streetNumber2}`;
        }
        return streetNumber1 || streetNumber2;
    };

    const checkAndShowWarning = () => {
        if (!apiKey && !(customBackendAPI.getSuggestions || customBackendAPI.getDetailsById)) {
            // eslint-disable-next-line max-len
            console.warn('No api key, getSuggestions or getDetailsById methods provided for the NSW Point API. Please request API key or supply custom methods as per documentation.');
            return false;
        }

        return true;
    };

    return {
        getLatestSuggestions,
        getDetailsById
    };
};
