import React from 'react';
import { Input, FormGroup } from '../..';
import PropTypes from 'prop-types';
import { getHasError, getErrorMessage, getId, getValue } from '../utils';

const OtherName = ({
    id,
    index,
    labelFirstName,
    labelMiddleName,
    labelLastName,
    value,
    onChange
}) => (
    <>
        <FormGroup
            id={ `${id}-formgroup-firstname` }
            label={ labelFirstName }
            errorMessage={ getErrorMessage('firstName', value) }
            hasError={ getHasError('firstName', value) }
        >
            <Input
                id={ getId('firstName', value, `${id}-input-firstname`) }
                name='firstName'
                value={ getValue('firstName', value) }
                onChange={ e => onChange(e.target.name, e.target.value, index) }
            />
        </FormGroup>

        <FormGroup
            id={ `${id}-formgroup-middlename` }
            label={ labelMiddleName }
            errorMessage={ getErrorMessage('middleName', value) }
            hasError={ getHasError('middleName', value) }
        >
            <Input
                id={ getId('middleName', value, `${id}-input-middlename`) }
                name='middleName'
                value={ getValue('middleName', value) }
                onChange={ e => onChange(e.target.name, e.target.value, index) }
            />
        </FormGroup>

        <FormGroup
            id={ `${id}-formgroup-lastname` }
            label={ labelLastName }
            errorMessage={ getErrorMessage('lastName', value) }
            hasError={ getHasError('lastName', value) }
        >
            <Input
                id={ getId('lastName', value, `${id}-input-lastname`) }
                name='lastName'
                value={ getValue('lastName', value) }
                onChange={ e => onChange(e.target.name, e.target.value, index) }
            />
        </FormGroup>
    </>
);

OtherName.propTypes = {
    id: PropTypes.string.isRequired,
    labelFirstName: PropTypes.string.isRequired,
    labelMiddleName: PropTypes.string.isRequired,
    labelLastName: PropTypes.string.isRequired,
    value: PropTypes.shape({
        firstName: PropTypes.shape({
            id: PropTypes.string,
            value: PropTypes.string,
            errorMessage: PropTypes.string
        }),
        middleName: PropTypes.shape({
            id: PropTypes.string,
            value: PropTypes.string,
            errorMessage: PropTypes.string
        }),
        lastName: PropTypes.shape({
            id: PropTypes.string,
            value: PropTypes.string,
            errorMessage: PropTypes.string
        })
    }).isRequired,
    onChange: PropTypes.func.isRequired
};

export default OtherName;
