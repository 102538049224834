import styled, { css } from 'styled-components/macro';
import PropTypes from 'prop-types';
import space from '../GlobalStyle/space/space';
import externalSvg from '../Image/external-link.svg';

const EtdbButton = styled.button`
    font-size: 1rem;
    font-weight: 500;
    text-align: center;
    white-space: nowrap;
    height: 2.75rem;
    padding-top: ${props => !!props.as ? '0.7rem' : '0'};
    padding-bottom: ${props => !!props.as ? '0.7rem' : '0'};
    padding-left: ${props => props.width ? 'unset' : '2.5rem'};
    padding-right: ${props => props.width ? 'unset' : '2.5rem'};
    width: ${props => props.width || 'unset'};
    min-width: 4.75rem;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    cursor: ${props => props.disabled ? 'default' : 'pointer'};
    opacity: ${props => props.disabled ? '0.45' : '1.0'};
    pointer-events: ${props => props.disabled ? 'none' : 'unset'};
    display: inline-block;
    ${props => props.external && css`
        text-decoration: none;
        :after {
            content: '';
            background-image: url(${externalSvg});
            display: inline-block;
            width: 12px;
            height: 12px;
            margin-left: 4px;
        }
    `}

    @media (max-width: 480px) {
        height: 2.375rem;
        line-height: 0.7;
        ${props => props.fullwidthmobile === 'true' && css`
            width: 100%;
            padding-left: 0;
            padding-right: 0;
        `}
    }

    ${space};
`;

EtdbButton.propTypes = {
    width: PropTypes.string,
    disabled: PropTypes.bool,
    external: PropTypes.bool,
    fullwidthmobile: PropTypes.string, // Please leave this as "String". It's wrong but it's what works.
    to: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
        PropTypes.func,
    ]),
};

export default EtdbButton;
