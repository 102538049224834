import React from 'react';
import styled from 'styled-components/macro';
import { color, layout } from 'styled-system';
import tokens from '../../Tokens/tokens';
const { colors } = tokens;

// eslint-disable-next-line react/prop-types
const ChevronRightSvg = ({ className, color, width, height }) => (
    <svg className={ className } viewBox='0 0 320 512' width={ width } height={ height } xmlns='http://www.w3.org/2000/svg' focusable='false' aria-hidden='true'>
        <path fill={ colors.legacy[color] || color }
            d='M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z'>
        </path>
    </svg>
);

export const ChevronRight = styled(ChevronRightSvg)`
  ${color}
  ${layout}
`;

ChevronRight.defaultProps = {
    color: `${colors.brand.snswSecondaryBlue}`,
    height: 15,
    width: 15,
};

export const ChevronLeft = styled(ChevronRight)`
  transform: rotate(180deg);
`;

export const ChevronDown = styled(ChevronRight)`
  transform: rotate(90deg);
`;

export const ChevronUp = styled(ChevronRight)`
  transform: rotate(-90deg);
`;
