import React, { useEffect, useRef, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import Button from '../Button/Button';
import { getID, Portal } from '../../utils';
import FocusLock from 'react-focus-lock';
import StyledModal, { Overlay, BgClicker } from './Modal.styled';
import { SROnly } from '../../GlobalStyle/utilities/utilities.styled';
import { IconClose } from '../../Icons/system';
import { trackMe } from '../ComponentAnalytics/componentAnalytics';
import { showDeprecatedMsg } from '../../utils';

const deprecatedMsg = '\'isScrollable\' is deprecated and has been removed in favour of an automatic solution.';
const deprecatedLink = 'https://kiama.testservicensw.net/storybook/?path=/docs/documentation-migration-notes-removed-isscrollable-prop-from-modal--page';

const Modal = props => {
    const {
        title,
        buttons,
        children,
        description,
        onClose,
        id,
        className,
    } = props;

    useEffect(() => {
        trackMe('Modal');

        if (props.isScrollable) {
            showDeprecatedMsg(deprecatedMsg, deprecatedLink);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const genID = () => id || getID();

    const elemID = genID();
    const modalStart = `${elemID}-start`;
    const modalTitle = `${elemID}-title`;
    const modalDesc = `${elemID}-desc`;
    const buttonGroup = buttons.slice(0, 2)
        .reverse()
        .map(({ text, onClick }, index) => {
            const hasMultipleButtons = buttons.length > 1;
            const isSecondary = hasMultipleButtons ? index === 0 : false;
            return (
                <ModalButtons
                    key={ `${text}_modal` }
                    text={ text }
                    onClick={ onClick }
                    isSecondary={ isSecondary }
                />
            );
        });

    const scrollDivRef = useRef(null);
    const titleRef = useRef();
    const modalRef = useRef();
    const returnFocusRef = useRef();

    const [isContentFocusable, setContentFocusable] = useState(false);

    const setContentFocus = () => {
        const target = scrollDivRef.current;
        target && setContentFocusable(target.scrollHeight > target.clientHeight);
    };

    useEffect(() => {
        const isFocusWithinModal = Boolean(document.activeElement) && modalRef.current.contains(document.activeElement);

        if (!isFocusWithinModal) {
            returnFocusRef.current = document.activeElement;
            titleRef.current.focus();
            setContentFocus();
        }

        return () => {
            returnFocusRef.current?.focus();
        };
    }, []);

    const escFunction = useCallback(event => {
        if (event.keyCode === 27 && onClose) {
            onClose();
        }
    }, [onClose]);

    useEffect(() => {

        if (onClose) {
            document.addEventListener('keydown', escFunction);

            return () => {
                document.removeEventListener('keydown', escFunction);
            };
        }

    }, [escFunction, onClose]);

    return (
        <Portal id='modal-portal'>
            <FocusLock returnFocus>
                <Overlay>
                    {onClose && <BgClicker data-testid='modal-dimmer' onClick={ onClose } /> }
                    <StyledModal
                        ref={ modalRef }
                        id={ elemID }
                        className={ className }
                        role='dialog'
                        aria-modal='true'
                        aria-labelledby={ `${modalStart} ${modalTitle}` }
                        { ...description && { 'aria-describedby': modalDesc } }
                    >
                        <StyledModal.Container>
                            <StyledModal.Body
                                data-testid='modal-body'
                            >
                                <StyledModal.Overflow
                                    ref={ scrollDivRef }
                                    { ...isContentFocusable && { 'tabIndex': '0' } }
                                >
                                    <StyledModal.Header>
                                        <SROnly
                                            id={ modalStart }
                                            tabIndex='-1'
                                        >
                                            Start of dialog
                                        </SROnly>
                                        <StyledModal.Title
                                            ref={ titleRef }
                                            id={ modalTitle }
                                            tabIndex='-1'
                                        >
                                            {title}
                                        </StyledModal.Title>
                                    </StyledModal.Header>
                                    {description && <p className='description1' id={ modalDesc }>{description}</p>}
                                    {children}
                                    <StyledModal.Footer
                                        data-testid='modal-footer'
                                    >
                                        <StyledModal.ButtonGroup>
                                            {buttonGroup}
                                        </StyledModal.ButtonGroup>
                                    </StyledModal.Footer>
                                </StyledModal.Overflow>
                            </StyledModal.Body>
                            {onClose && (
                                <StyledModal.CloseButton type='button' onClick={ onClose }>
                                    <IconClose />
                                    <SROnly>Close Modal</SROnly>
                                </StyledModal.CloseButton>
                            )}
                        </StyledModal.Container>
                    </StyledModal>
                </Overlay>
            </FocusLock>
        </Portal>
    );
};

Modal.propTypes = {
    title: PropTypes.string.isRequired,
    /** Accepts up to two items in `Array` each item is an object
     *
     * The following structure is an example of creating one button */
    buttons: PropTypes.arrayOf(PropTypes.shape({
        text: PropTypes.string.isRequired,
        onClick: PropTypes.func.isRequired,
    })).isRequired,
    children: PropTypes.node,
    description: PropTypes.string,
    /** Accepts a function which is used to close the modal */
    onClose: PropTypes.func,
    id: PropTypes.string,
    className: PropTypes.string,
};

const ModalButtons = ({ text, onClick, isSecondary }) => (
    <Button onClick={ onClick } theme={ isSecondary ? 'secondary' : 'primary' }>{text}</Button>
);

ModalButtons.propTypes = {
    text: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    isSecondary: PropTypes.bool.isRequired,
};

export default Modal;
