import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Field from './Field.styled';
import Checkbox from './FormCheckbox.styled';
import { getID, getAriaDescribedby } from '../../utils';
import { trackMe } from '../ComponentAnalytics/componentAnalytics';

export const FormCheckbox = ({ className, id, name, label, checked, value, onChange, hasError,
    errorMessage, disabled, testId, inputRef, margin, isRequired, isOptional, 'aria-describedby': ariaDescribedby }) => {

    useEffect(() => {
        trackMe('FormCheckbox');
    }, []);

    const handleOnChange = ({ target: { checked } }) => onChange && onChange(checked ? value : '');

    const genID = () => id || getID();

    const elemID = genID();
    const idError = `${elemID}-error`;

    const showError = hasError && !!errorMessage;
    const showAriaDescribedby = getAriaDescribedby( !!showError && idError, ariaDescribedby );

    const optional = isOptional && !isRequired;
    const required = !isOptional && isRequired;

    const showRequired =
    ((!optional || !required) && null) ||
        (required ? true : null) ||
        (optional ? false : null);

    return (
        <Checkbox.Item className={ className } margin={ margin }>
            <Checkbox.Input
                type='checkbox'
                id={ elemID }
                name={ name }
                onChange={ handleOnChange }
                checked={ checked }
                disabled={ disabled }
                data-testid={ testId }
                aria-invalid={ hasError }
                aria-describedby={ showAriaDescribedby }
                aria-required={ showRequired }
                ref={ inputRef }
            />
            <Checkbox.Label htmlFor={ elemID } hasError={ hasError }>{optional ? `${label} (optional)` : label}</Checkbox.Label>
            {showError && <Field.Error id={ idError }>{errorMessage}</Field.Error>}
        </Checkbox.Item>);
};

FormCheckbox.propTypes = {
    className: PropTypes.string,
    id: PropTypes.string,
    name: PropTypes.string.isRequired,
    label: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node,
    ]).isRequired,
    /** `aria-required: true` on form element */
    isRequired: PropTypes.bool,
    /** `aria-required: false` on form element */
    isOptional: PropTypes.bool,
    value: PropTypes.string.isRequired,
    testId: PropTypes.string,
    errorMessage: PropTypes.string,
    checked: PropTypes.bool,
    hasError: PropTypes.bool,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
    inputRef: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.object,
    ]),
    margin: PropTypes.shape({
        top: PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.oneOf(['none', 'xs', 'sm', 'md', 'lg', 'xl', 'xxl', 'xxxl']),
        ]),
    })
};

FormCheckbox.defaultProps = {
    label: '',
    testId: '',
    errorMessage: '',
    hasError: false,
    disabled: false,
};

export default FormCheckbox;
