import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { StyledPagination } from './Pagination.styled';
import Button from '../Button/Button';
import { IconChevronRight, IconChevronLeft } from '../../Icons/system';
import { SROnly } from '../../GlobalStyle/utilities/utilities.styled';
import { trackMe } from '../ComponentAnalytics/componentAnalytics';

const Pagination = ({ className, pageSize, totalItems, onPageChange, currentPage }) => {
    // add displayRange as a prop above if it needs to be exposed again
    const displayPageRange = 7;
    const totalPages = Math.ceil(totalItems / pageSize);

    const onPrev = () => changeHandler(currentPage - 1);
    const onNext = () => changeHandler(currentPage + 1);

    let showFirstLink = false;
    let showLastLink = false;

    const getDisplayPagesMap = () => {
        const totalPagesArray = Array.from(Array(totalPages), (item, index) => index + 1 );
        showFirstLink = false;
        showLastLink = false;
        const eachSide = Math.max(displayPageRange / 2);
        if (totalPages <= displayPageRange) {
            return totalPagesArray;
        }
        if (currentPage < eachSide + 1) {
            showLastLink = true;
            return totalPagesArray.slice(0, displayPageRange - 2);
        }
        if (currentPage + displayPageRange > totalPages && totalPages - currentPage < displayPageRange / 2) {
            showFirstLink = true;
            return totalPagesArray.slice(totalPages - displayPageRange + 2
                , totalPages);
        }
        showFirstLink = true;
        showLastLink = true;
        return totalPagesArray.slice(currentPage - eachSide + 2, currentPage + eachSide - 2);
    };
    const changeHandler = pageNo => {
        const startIndex = ( pageNo - 1 ) * pageSize;
        const endIndex = startIndex + pageSize;
        onPageChange && onPageChange(pageNo, { startIndex,endIndex });
    };
    const displayPagesMap = getDisplayPagesMap();
    useEffect(() => {
        trackMe('Pagination [GEL]');
        // eslint-disable-next-line max-len
        // only on page one, just so it happens on first page load because there is no callback on first render and we still need to load the data
        (currentPage === 1) && changeHandler(1);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (totalPages > 1 &&
        <>
            <StyledPagination
                aria-label='pagination'
                role='navigation'
                className={ className }
            >
                <StyledPagination.Prev>
                    {currentPage !== 1 &&
                        <Button
                            variant='link'
                            data-testid='page-number-prev'
                            onClick={ () => onPrev() }
                        >
                            <IconChevronLeft /><SROnly>Previous</SROnly>
                        </Button>
                    }
                </StyledPagination.Prev>
                {showFirstLink &&
                    <>
                        <StyledPagination.Number
                            data-testid='page-number-first'
                            current={ currentPage === 1 }
                            onClick={ () => changeHandler(1) }
                        >
                            1
                        </StyledPagination.Number>
                        <StyledPagination.Separator data-testid='page-number-first-separator'>
                            &hellip;
                        </StyledPagination.Separator>
                    </>
                }
                {
                    displayPagesMap.map((pageNumber, index) => <StyledPagination.Number
                        { ...currentPage === pageNumber && { 'aria-current': 'page' } }
                        key={ index }
                        data-testid={ `page-number-${pageNumber}` }
                        current={ currentPage === pageNumber }
                        onClick={ () => (currentPage !== pageNumber) && changeHandler(pageNumber) }
                    >
                        {pageNumber}
                    </StyledPagination.Number>)}
                {showLastLink &&
                    <>
                        <StyledPagination.Separator data-testid='page-number-last-separator'>&hellip;</StyledPagination.Separator>
                        <StyledPagination.Number
                            data-testid='page-number-last'
                            current={ currentPage === totalPages }
                            onClick={ () => changeHandler(totalPages) }
                        >
                            {totalPages}
                        </StyledPagination.Number>
                    </>
                }
                <StyledPagination.Next>
                    {currentPage !== totalPages &&
                        <Button
                            variant='link'
                            data-testid='page-number-next'
                            onClick={ onNext }
                        >
                            <SROnly>Next</SROnly><IconChevronRight />
                        </Button>
                    }
                </StyledPagination.Next>
            </StyledPagination>
        </>
    );
};

Pagination.propTypes = {
    className: PropTypes.string,
    pageSize: PropTypes.number,
    /** totalItems needs to be passed to generate the pagination accordingly */
    totalItems: PropTypes.number.isRequired,
    /** Callback function that returns the page number and an indexes object including startIndex and endIndex */
    onPageChange: PropTypes.func,
    /** Needs to be set to define the current page */
    currentPage: PropTypes.number.isRequired,
};

Pagination.defaultProps = {
    pageSize: 10
};

export default Pagination;
