import * as React from 'react';
import PropTypes from 'prop-types';
import tokens from '../../Tokens/tokens';
const { spacing } = tokens;

const BrandIconCrackedScreen = ({ title, titleId, ...props }) => {
    const sizeArray = Object.entries(spacing.icons);
    const sizeOptions = new Map(sizeArray);
    const displaySize = sizeOptions.get(props.size);
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 48 48'
            fill={props.color && displayColor}
            aria-hidden={!title}
            role={title ? 'img' : null}
            width={props.size && displaySize}
            height={props.size && displaySize}
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <defs>
                <style>{'.CrackedScreen_svg__cls-2{fill:#002664}'}</style>
            </defs>
            <path d='M24 44a5 5 0 115-5 5 5 0 01-5 5z' fill='#d7153a' />
            <path
                className='CrackedScreen_svg__cls-2'
                d='M35 4H13a1 1 0 00-1 1v34a1 1 0 001 1h4v-2h-3V6h20v14.53l-4.67 3.89-6.44-12.87a1 1 0 00-1.78.9l1.65 3.3-6.31 5.5a1 1 0 00-.1 1.41 1 1 0 00.75.34 1 1 0 00.66-.25l1.91-1.66 2 2.62-4.05 2.43a1 1 0 00-.34 1.37 1 1 0 00.86.49 1.06 1.06 0 00.51-.14l5-3a1 1 0 00.47-.67 1 1 0 00-.19-.79l-2.72-3.63 2.5-2.17 4.43 8.85a1 1 0 00.68.53L29 27a1 1 0 00.64-.23L34 23.14V38h-3v2h4a1 1 0 001-1V5a1 1 0 00-1-1z'
            />
            <path
                className='CrackedScreen_svg__cls-2'
                d='M40 4.87A5 5 0 0035 0H13a5 5 0 00-5 5v38a5 5 0 005 5h22a5 5 0 005-5V4.87zM38 43a3 3 0 01-3 3H13a3 3 0 01-3-3V5a3 3 0 013-3h22a3 3 0 013 3z'
            />
        </svg>
    );
};

BrandIconCrackedScreen.propTypes = {
    title: PropTypes.string,
    titleId: PropTypes.string,
    size: PropTypes.oneOf(['lg', 'xl', 'xxl']),
};
BrandIconCrackedScreen.defaultProps = {
    size: 'xl',
    focusable: false,
};
export default BrandIconCrackedScreen;
