import React from 'react';
import PropTypes from 'prop-types';
import { StyledTableHeader } from './Table.styled';

const TableHeader = ({ children, textAlign, ...rest }) => (
    <StyledTableHeader textAlign={ textAlign } { ...rest }>
        {children}
    </StyledTableHeader>
);

TableHeader.propTypes = {
    children: PropTypes.node.isRequired,
    textAlign: PropTypes.oneOf(['left', 'center', 'right']),
};

TableHeader.defaultProps = {
    textAlign: 'left'
};

export default TableHeader;
