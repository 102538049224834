import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import StyledStatusLabel from './StatusLabel.styled';
import { trackMe } from '../ComponentAnalytics/componentAnalytics';

const StatusLabel = props => {
    const { text, variant, ...rest } = props;

    useEffect(() => {
        trackMe('StatusLabel [GEL]');
    }, []);

    return (
        <StyledStatusLabel variant={ variant } { ...rest }>{ text }</StyledStatusLabel>
    );
};

StatusLabel.propTypes = {
    text: PropTypes.string.isRequired,
    variant: PropTypes.oneOf(['neutral','error','success','warning', 'info']),
};

StatusLabel.defaultProps = {
    variant: 'neutral'
};

export default StatusLabel;
