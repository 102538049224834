import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { LinkCardContainer, LinkCardTitle, ArrowRight, ExternalLink } from './LinkCard.styled';
import { H6 } from '../../Headers/HeaderTag.styled';
import ComponentLoader from '../../Loader/ComponentLoader';
import { trackMe } from '../../Components/ComponentAnalytics/componentAnalytics';

const LinkCard = ({ title, description, loading, height, titleAsExternalLink, onClick, ...rest }) => {
    useEffect(() => {
        trackMe('LinkCard');
    }, []);

    return (
        <LinkCardContainer
            height={ height }
            onClick={ () => onClick() }
            { ...rest }
        >
            <LinkCardTitle>
                <H6>{title}</H6>
                {titleAsExternalLink && <ExternalLink />}
            </LinkCardTitle>
            {description}
            <ComponentLoader active={ loading } />
            <ArrowRight />
        </LinkCardContainer>
    );
};
LinkCard.defaultProps = {
    description: '',
    loading: false,
    height: '14rem',
    titleAsExternalLink: false,
};

LinkCard.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    loading: PropTypes.bool,
    height: PropTypes.string,
    titleAsExternalLink: PropTypes.bool,
};

export default LinkCard;
