import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(isBetween);
dayjs.extend(customParseFormat);

export const minLength = (value, minLength) => value && (value.length < minLength);

export const dateBetween = (value, minDate, maxDate) =>
    value.isBetween(minDate, maxDate, 'days', '[]') // all inclusive
;

export const validDateFormat = (value, dateFormat) => dayjs(value, dateFormat, true).isValid();

export const isMaskInputIncomplete = value => value && value.indexOf('_') !== -1;

export const emailFormat = value =>
    // eslint-disable-next-line max-len, security/detect-unsafe-regex
    value && !/^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i.test(value)
;

export const bankAccountNameDoesNotMatchRegex = value => value && !/^[a-z0-9][a-z0-9\s]{1,28}[a-z0-9]$/i.test(value);

export const bankAccountNumberDoesNotMatchRegex = value => value && !/^[0-9]{3,9}$/i.test(value);

export const alphaNameRegex =
    /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆŠŽ∂ð ,.'-]+$/i;

export const notAnAlphaName = value => !!value && !alphaNameRegex.test(value);

export const phoneNumberRegex = /^(\+?61[ ]?|0)[2-478]([ ]\d{8}|[ ]\d{4}[ ]\d{4}|\d{8}|\d{2}[ ]\d{3}[ ]\d{3})$/;

export const notAustralianPhoneNumber = value => !!value && !phoneNumberRegex.test(value);

export const isAllWhitespace = value => !!value && value.trim() === '';

// https://regex101.com/r/jwHtQ5/1 regex test for more valid postcodes
export const notAustralianPostcode = value => !!value && !/^(0[289][0-9]{2})|^([1-9][0-9]{3})$/i.test(value);

const Validators = {
    dateBetween,
    validDateFormat,
    emailFormat,
    isMaskInputIncomplete,
    bankAccountNameDoesNotMatchRegex,
    bankAccountNumberDoesNotMatchRegex,
    minLength,
    notAnAlphaName,
    notAustralianPhoneNumber,
    isAllWhitespace,
    notAustralianPostcode
};

export default Validators;
