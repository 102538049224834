import React from 'react';
import styled from 'styled-components/macro';
import { color, layout, space } from 'styled-system';
import tokens from '../../Tokens/tokens';

const { colors } = tokens;


// eslint-disable-next-line react/prop-types
const ExternalLinkSvg = ({ className, color, width, height }) => (
    <svg className={ className } fill={ colors.legacy[color] || color } width={ width } height={ height } xmlns='http://www.w3.org/2000/svg' focusable='false' aria-hidden='true'>
        <path d='M0 0v12h12V0H0zm10.9 10.9H1.1V1.1h9.8v9.8z'/>
        <path d='M4.4 2.7v1.1h3L2.3 8.9l.8.8 5.1-5.1v3h1.1V2.7H4.4z'/>
    </svg>
);

const ExternalLink = styled(ExternalLinkSvg)`
  ${color};
  ${layout};
  ${space};
`;

ExternalLink.defaultProps = {
    color: `${colors.brand.snswSecondaryBlue}`,
    height: 12,
    width: 12,
};

export default ExternalLink;
