import styled from 'styled-components/macro';

export const ListItem = styled.li`
    display: inline;

    &:not(:first-child):before {
        content: "";
        display: inline-block;
        opacity: .7;
        border-left: 1px solid #242934;
        width: 0;
        height: 15px;
        transform: rotate(28deg);
        top: -1px;
        position: relative;
        margin: 0 11px;
        vertical-align: middle;
    }
`;
