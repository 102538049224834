import * as React from 'react';
import PropTypes from 'prop-types';
import tokens from '../../Tokens/tokens';
const { colors, spacing } = tokens;

const IconArrowRightLong = ({ title, titleId, ...props }) => {
    const sizeArray = Object.entries(spacing.icons);
    const colorArray = Object.entries(colors.icons);
    const sizeOptions = new Map(sizeArray);
    const displaySize = sizeOptions.get(props.size);
    const colorOptions = new Map(colorArray);
    const displayColor = colorOptions.get(props.color);
    return (
        <svg
            viewBox='0 0 16 16'
            xmlns='http://www.w3.org/2000/svg'
            fill={props.color && displayColor}
            aria-hidden={!title}
            role={title ? 'img' : null}
            width={props.size && displaySize}
            height={props.size && displaySize}
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <g>
                <path d='M12.8 3.40006C12.4686 2.95823 11.8418 2.86869 11.4 3.20006C10.9582 3.53143 10.8686 4.15823 11.2 4.60006L13 7.00006H1C0.447715 7.00006 0 7.44777 0 8.00006C0 8.55234 0.447715 9.00006 1 9.00006H13L11.2 11.4001C10.8686 11.8419 10.9582 12.4687 11.4 12.8001C11.8418 13.1314 12.4686 13.0419 12.8 12.6001L15.7866 8.6179C15.8288 8.56426 15.8649 8.50697 15.8948 8.44707C15.9158 8.4051 15.9339 8.36145 15.9489 8.31637C15.9833 8.21332 16.0001 8.10643 16.0001 8.00002C16.0001 7.89265 15.983 7.78481 15.948 7.6809C15.9331 7.63676 15.9153 7.59401 15.8947 7.55287C15.8648 7.49304 15.8288 7.43582 15.7866 7.38224L12.8 3.40006Z' />
            </g>
        </svg>
    );
};

IconArrowRightLong.propTypes = {
    title: PropTypes.string,
    titleId: PropTypes.string,
    size: PropTypes.oneOf(['sm', 'md']),
    color: PropTypes.oneOf(['primaryBlue', 'secondaryBlue', 'darkGrey', 'white']),
};
IconArrowRightLong.defaultProps = {
    size: 'sm',
    color: 'darkGrey',
    focusable: false,
};
export default IconArrowRightLong;
