import * as React from 'react';
import PropTypes from 'prop-types';
import tokens from '../../Tokens/tokens';
const { spacing } = tokens;

const BrandIconSport = ({ title, titleId, ...props }) => {
    const sizeArray = Object.entries(spacing.icons);
    const sizeOptions = new Map(sizeArray);
    const displaySize = sizeOptions.get(props.size);
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 48 48'
            fill={props.color && displayColor}
            aria-hidden={!title}
            role={title ? 'img' : null}
            width={props.size && displaySize}
            height={props.size && displaySize}
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <path
                d='M37.51 29.51a36.79 36.79 0 012.9 9.12 22 22 0 005.31-11.11 9.72 9.72 0 00-8.21 1.99zm-1.84 1.81c-.17.21-3.91 4.86-.89 11.85a22.29 22.29 0 003.91-2.81A35.63 35.63 0 0036 31c-.12.1-.23.2-.33.32zM23.18 14.73A15.52 15.52 0 0024.34 2H24A21.86 21.86 0 009.75 7.26a51.15 51.15 0 0111.57 5L20.3 14A48.55 48.55 0 008.05 8.87a21.9 21.9 0 00-5.19 9.05c12.98 7.39 20.03-2.75 20.32-3.19z'
                fill='none'
            />
            <path
                d='M24 0a24 24 0 1024 24A24 24 0 0024 0zM8.05 8.87A48.55 48.55 0 0120.3 14l1-1.72a51.15 51.15 0 00-11.57-5A21.86 21.86 0 0124 2h.34a15.52 15.52 0 01-1.16 12.72c-.29.44-7.34 10.58-20.32 3.19a21.9 21.9 0 015.19-9.04zm26.73 34.3c-3-7 .72-11.64.89-11.85s.21-.22.31-.34a35.63 35.63 0 012.71 9.38 22.29 22.29 0 01-3.91 2.81zm5.63-4.54a36.79 36.79 0 00-2.9-9.12 9.72 9.72 0 018.21-2 22 22 0 01-5.31 11.12zM36.6 27.7a36.19 36.19 0 00-2-3.24 5.07 5.07 0 01-1.66 1.14A32.85 32.85 0 0135 29.06a12.31 12.31 0 00-.9 1c-.21.24-4.76 5.82-1.14 14a21.94 21.94 0 01-24.78-4.8c9.4-4.65 15.89-9.44 20.38-13.91A5 5 0 0127 24c-4.33 4.28-10.72 9.06-20.21 13.7A21.87 21.87 0 012 24a22.73 22.73 0 01.38-4.07 20 20 0 009.52 2.65 15.94 15.94 0 0012.34-5.93 41.9 41.9 0 012.39 1.93 5 5 0 011.31-1.52c-.84-.72-1.71-1.42-2.62-2.1a17.41 17.41 0 001.16-12.82A22 22 0 0136.56 6c0 .17.19 4.33-3.3 10.24l-.16.25a5 5 0 011.64 1.22A24.54 24.54 0 0038.5 7.48 21.94 21.94 0 0146 24c0 .51 0 1-.06 1.52a11.72 11.72 0 00-9.34 2.18z'
                fill='#002664'
            />
            <path d='M35.82 19.63a5 5 0 11-3.46-3.45 4.92 4.92 0 013.46 3.45z' fill='#d7153a' />
        </svg>
    );
};

BrandIconSport.propTypes = {
    title: PropTypes.string,
    titleId: PropTypes.string,
    size: PropTypes.oneOf(['lg', 'xl', 'xxl']),
};
BrandIconSport.defaultProps = {
    size: 'xl',
    focusable: false,
};
export default BrandIconSport;
