import styled, { css } from 'styled-components/macro';
import Spinner from '../Icons/obsolete/Spinner';
import tokens from '../Tokens/tokens';
import { pxToRem, getSpacing } from '../utils';

const { font } = tokens;

export const LoaderContainer = styled.div`
    text-align: center;
    position: relative;
    max-width: ${pxToRem(500)};
`;

export const SpinnerImageContainer = styled.div`
    text-indent: 100%;
    overflow: hidden;
    width: 100%;
    height: ${pxToRem(70)};

    ${Spinner} {
        position: absolute;
        top: 0;
        transform: translateX(-50%);
        left: 50%;
        width: ${pxToRem(70)};
        height: ${pxToRem(70)};
    }
`;

export const LoaderMessage = styled.p`
    margin: ${getSpacing([10, 0, 0])};

    ${props => (props.fullPage) && css`
        font-size: ${pxToRem(font.size.xxl)};
        font-weight: ${font.weight.bold};
    `};
`;
