import colors from './compiled/colors';
import spacing from './compiled/spacing';
import font from './compiled/font';
import breakpoints from './compiled/breakpoints';
import layouts from './compiled/layouts';

const tokens = {
    font,
    colors,
    spacing,
    breakpoints,
    layouts
};

export default tokens;
