import * as React from 'react';
import PropTypes from 'prop-types';
import tokens from '../../Tokens/tokens';
const { spacing } = tokens;

const BrandIconElectricity = ({ title, titleId, ...props }) => {
    const sizeArray = Object.entries(spacing.icons);
    const sizeOptions = new Map(sizeArray);
    const displaySize = sizeOptions.get(props.size);
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 48 48'
            fill={props.color && displayColor}
            aria-hidden={!title}
            role={title ? 'img' : null}
            width={props.size && displaySize}
            height={props.size && displaySize}
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <defs>
                <style>{'.Electricity_svg__cls-1{fill:#002664}'}</style>
            </defs>
            <path
                className='Electricity_svg__cls-1'
                d='M4 48H2a21.34 21.34 0 014.61-13.49c-4-6.22-3.41-14.72 1.52-19.68l7.51-7.54c2.24-2.25 4.48-.87 5.75.23l7-7A1.53 1.53 0 0130.54.4l2.38 2.39A1.56 1.56 0 0132.8 5l-6.95 7 8.08 8.11 7-7a1.71 1.71 0 011.2-.52 1.34 1.34 0 011 .4l2.56 2.57a1.38 1.38 0 01.4 1 1.77 1.77 0 01-.52 1.21l-7 7a5.28 5.28 0 011.14 2.08 3.29 3.29 0 01-1 3.24L31 37.76c-6.15 6.18-15 4.62-19.64 1.48A13.61 13.61 0 008 48H6a15.68 15.68 0 014.55-10.8 1 1 0 011.31-.06c4 3.15 12.33 4.62 17.72-.79l7.68-7.71a1.29 1.29 0 00.49-1.33 4.63 4.63 0 00-1.29-1.89 1 1 0 010-1.42l7.39-7.42-1.85-1.8-7.4 7.42a1 1 0 01-1.41 0l-9.49-9.53a1 1 0 010-1.42l7.39-7.42-1.59-1.62-7.39 7.42a1 1 0 01-1.41 0c-2.11-2.11-2.9-1.63-3.65-.92l-7.51 7.54C5.11 20.7 4.73 28.5 8.67 34a1 1 0 01-.06 1.26A19.17 19.17 0 004 48zm40.2-31zm-2.56-2.56zM29.12 1.82z'
            />
            <path
                className='Electricity_svg__cls-1'
                d='M33.42 16.65L32 15.23l5.92-5.94a1 1 0 00.26-.68 1.27 1.27 0 00-.34-.86 1.28 1.28 0 00-1.61 0l-5.92 5.94-1.41-1.42 5.92-5.94a3.32 3.32 0 014.43 0 3.24 3.24 0 01.93 2.28 3 3 0 01-.84 2.1z'
            />
            <path d='M25 25a5 5 0 11-5-5 5 5 0 015 5z' fill='#d7153a' />
        </svg>
    );
};

BrandIconElectricity.propTypes = {
    title: PropTypes.string,
    titleId: PropTypes.string,
    size: PropTypes.oneOf(['lg', 'xl', 'xxl']),
};
BrandIconElectricity.defaultProps = {
    size: 'xl',
    focusable: false,
};
export default BrandIconElectricity;
