import * as React from 'react';
import PropTypes from 'prop-types';
import tokens from '../../Tokens/tokens';
const { spacing } = tokens;

const BrandIconCovidTest = ({ title, titleId, ...props }) => {
    const sizeArray = Object.entries(spacing.icons);
    const sizeOptions = new Map(sizeArray);
    const displaySize = sizeOptions.get(props.size);
    return (
        <svg
            viewBox='0 0 48 48'
            xmlns='http://www.w3.org/2000/svg'
            fill={props.color && displayColor}
            aria-hidden={!title}
            role={title ? 'img' : null}
            width={props.size && displaySize}
            height={props.size && displaySize}
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <g stroke='none' strokeWidth={1} fill='none' fillRule='evenodd'>
                <path
                    d='M14,45.0391187 C11.2385763,45.0391187 9,42.7830284 9,40 C9,37.2169716 11.2385763,34.9608813 14,34.9608813 C16.7614237,34.9608813 19,37.2169716 19,40 C19,42.7830284 16.7614237,45.0391187 14,45.0391187 L14,45.0391187 Z'
                    fill='#D7153A'
                    fillRule='nonzero'
                />
                <polygon fill='#002664' fillRule='nonzero' points='20 11 20 13 15 13 15 31 20 31 20 33 13 33 13 11' />
                <path
                    d='M22,1 L22,40 C22,44.418278 18.418278,48 14,48 C9.66508574,48 6.13545713,44.5521622 6.00380682,40.2491793 L6,40 L6,4 L15,4 L15,6 L8,6 L8,40 C8,43.2383969 10.5655749,45.8775718 13.7750617,45.9958615 L14,46 C17.2383969,46 19.8775718,43.4344251 19.9958615,40.2249383 L20,40 L20,1 L22,1 Z'
                    fill='#002664'
                    fillRule='nonzero'
                />
                <line x1={6} y1={1} x2={22} y2={1} stroke='#002664' strokeWidth={2} />
                <path
                    d='M43,39 L27,39 L27,48 L43,48 L43,39 Z M41,41 L41,46 L29,46 L29,41 L41,41 Z'
                    fill='#002664'
                    fillRule='nonzero'
                />
                <polygon fill='#002664' fillRule='nonzero' points='39 40 39 47 37 47 37 40' />
                <polygon fill='#002664' fillRule='nonzero' points='36 13.63 36 39.78 34 39.78 34 13.63' />
                <path
                    d='M35,4.51 C32.7140396,4.51 31,6.82966686 31,9.57 C31,12.3103331 32.7140396,14.63 35,14.63 C37.2859604,14.63 39,12.3103331 39,9.57 C39,6.82966686 37.2859604,4.51 35,4.51 Z M35,6.51 C36.0277481,6.51 37,7.82578097 37,9.57 C37,11.314219 36.0277481,12.63 35,12.63 C33.9722519,12.63 33,11.314219 33,9.57 C33,7.82578097 33.9722519,6.51 35,6.51 Z'
                    fill='#002664'
                    fillRule='nonzero'
                />
            </g>
        </svg>
    );
};

BrandIconCovidTest.propTypes = {
    title: PropTypes.string,
    titleId: PropTypes.string,
    size: PropTypes.oneOf(['lg', 'xl', 'xxl']),
};
BrandIconCovidTest.defaultProps = {
    size: 'xl',
    focusable: false,
};
export default BrandIconCovidTest;
