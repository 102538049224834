import styled, { css } from 'styled-components/macro';

export const CardsContainer = styled.div`

  display: grid;
  grid-template-columns: ${props => props.vertical ? '100%' : '1fr 1fr 1fr'};
  column-gap: 1rem;

  ${props => !props.vertical && css`
    @media (max-width: 992px) {
      grid-template-columns: 49% 49%;
    }
  `};

  @media (max-width: 576px) {
    grid-template-columns: 100%;
  }

`;
