import React from 'react';
import PropTypes from 'prop-types';
import { SkeletonParagraph, SkeletonParagraphContainer, StyledSkeletonText } from './SkeletonText.styled';

const SkeletonText = ({ rows, width, widthLast, noMargin }) => {
    const rowList = [...Array(rows)].map((_, index) => (
        <SkeletonParagraph key={ index } widthLast={ widthLast }>
            <div />
        </SkeletonParagraph>
    ));

    return (rows === 1 ?
        <StyledSkeletonText
            width={ width }
            noMargin={ noMargin }
            data-testid='skeleton-text'
        >
            <div />
        </StyledSkeletonText> :
        <SkeletonParagraphContainer
            noMargin={ noMargin }
            width={ width }
            data-testid='skeleton-paragraph'
        >
            {rowList}
        </SkeletonParagraphContainer>
    );
};

SkeletonText.propTypes = {
    rows: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.oneOf([1, 2, 3, 4, 5, 6]),
    ]),
    width: PropTypes.number,
    /** Adjust the width of the last row */
    widthLast: PropTypes.number,
    /** Removes default margin values */
    noMargin: PropTypes.bool,
};

SkeletonText.defaultProps = {
    rows: 1,
    widthLast: 414,
    noMargin: false,
};

export default SkeletonText;
