import React from 'react';
import PropTypes from 'prop-types';
import { LoaderContainer, LoaderMessage, SpinnerImageContainer } from './Loader.styled';
import Spinner from '../Icons/obsolete/Spinner';

const Loader = ({ content, fullPage }) => (
    <LoaderContainer aria-hidden={ true }>
        <SpinnerImageContainer>
            <Spinner color='#929ea4' />
        </SpinnerImageContainer>
        <LoaderMessage fullPage={ fullPage }>{content}<br /></LoaderMessage>
    </LoaderContainer>
);

Loader.propTypes = {
    content: PropTypes.string,
    fullPage: PropTypes.bool
};

Loader.defaultProps = {
    content: 'Loading...',
    fullPage: false
};

export default Loader;
