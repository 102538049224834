/* eslint-disable */
export const australiaPhone = (phone) => /^(?:\+?61|0)[2-478](?:[ -]?[0-9]){8}$/.test(phone) || !phone;

export const email = (email) => /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i.test(email) || !email;

export const abn = (abn) => /^\d{11}$/.test(abn) || !abn;

export const nswPostcode = (postcode) => /^[2]\d{3}$/.test(postcode) || !postcode;

export const postcode = (postcode) => /^\d{4}$/.test(postcode) || !postcode;

export const liquorLicenseNumber = (liquorLicenceNum) => /^\d{9}$/.test(liquorLicenceNum) || !liquorLicenceNum;

export const required = (value) => value !== '' && value !== undefined;

export const name = (value) => /^[A-Za-z]+$/.test(value) || !value;

export const date = (value, dateFormatValidator) => (dateFormatValidator && dateFormatValidator(value).isValid()) || !value;

export const australianMobilePhone = (mobilePhone) => /^(?:\+?61|0)4[0-9]{8}$/.test(mobilePhone) || !mobilePhone;

export default {
    australiaPhone,
    email,
    abn,
    nswPostcode,
    postcode,
    required,
    liquorLicenseNumber,
    name,
    date,
    australianMobilePhone
};
