import * as React from 'react';
import PropTypes from 'prop-types';
import tokens from '../../Tokens/tokens';
const { colors, spacing } = tokens;

const IconSettings = ({ title, titleId, ...props }) => {
    const sizeArray = Object.entries(spacing.icons);
    const colorArray = Object.entries(colors.icons);
    const sizeOptions = new Map(sizeArray);
    const displaySize = sizeOptions.get(props.size);
    const colorOptions = new Map(colorArray);
    const displayColor = colorOptions.get(props.color);
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 16 16'
            fill={props.color && displayColor}
            aria-hidden={!title}
            role={title ? 'img' : null}
            width={props.size && displaySize}
            height={props.size && displaySize}
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <path d='M10 16H6a1 1 0 01-1-1v-1.77l-1.5.87a1 1 0 01-1.36-.37l-2-3.46A1 1 0 01.5 8.9L2 8 .47 7.15a1 1 0 01-.47-.6 1.05 1.05 0 01.1-.76l2-3.47A1 1 0 013.47 2L5 2.84V1a1 1 0 011-1h4a1 1 0 011 1v1.77l1.5-.87a1 1 0 011.36.37l2 3.46a1 1 0 01-.36 1.37L14 8l1.53.89a1 1 0 01.37 1.36l-2 3.47a1 1 0 01-1.37.36L11 13.16V15a1 1 0 01-1 1zm-3-2h2v-1.51a1 1 0 01.61-.91 4.84 4.84 0 00.74-.43 1 1 0 011.08-.05l1.23.71 1-1.73-1.21-.7a1 1 0 01-.49-1v-.2A1.49 1.49 0 0012 8a1.43 1.43 0 000-.29v-.17a1 1 0 01.49-1l1.19-.68-1-1.74-1.25.73a1 1 0 01-1.08 0 3.67 3.67 0 00-.69-.39A1 1 0 019 3.51V2H7v1.51a1 1 0 01-.6.92 3.48 3.48 0 00-.74.42 1 1 0 01-1.08.06l-1.24-.72-1 1.73 1.22.7a1 1 0 01.49 1v.19C4 7.86 4 7.93 4 8a2.58 2.58 0 000 .29v.17a1 1 0 01-.5 1l-1.19.69 1 1.74 1.26-.73a1 1 0 011.07 0 4.49 4.49 0 00.7.4 1 1 0 01.6.91z' />
            <path d='M8 11a3 3 0 113-3 3 3 0 01-3 3zm0-4a1 1 0 101 1 1 1 0 00-1-1z' />
        </svg>
    );
};

IconSettings.propTypes = {
    title: PropTypes.string,
    titleId: PropTypes.string,
    size: PropTypes.oneOf(['sm', 'md']),
    color: PropTypes.oneOf(['primaryBlue', 'secondaryBlue', 'darkGrey', 'white']),
};
IconSettings.defaultProps = {
    size: 'sm',
    color: 'darkGrey',
    focusable: false,
};
export default IconSettings;
