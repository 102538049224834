import * as React from 'react';
import PropTypes from 'prop-types';
import tokens from '../../Tokens/tokens';
const { spacing } = tokens;

const BrandIconDontHandlePhone = ({ title, titleId, ...props }) => {
    const sizeArray = Object.entries(spacing.icons);
    const sizeOptions = new Map(sizeArray);
    const displaySize = sizeOptions.get(props.size);
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 48 48'
            fill={props.color && displayColor}
            aria-hidden={!title}
            role={title ? 'img' : null}
            width={props.size && displaySize}
            height={props.size && displaySize}
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <defs>
                <style>{'.DontHandlePhone_svg__cls-1{fill:#002664}'}</style>
            </defs>
            <path
                className='DontHandlePhone_svg__cls-1'
                d='M18.42 29.75a1.37 1.37 0 111.94-1.94l4.74 4.75a1 1 0 00.71.29 1 1 0 00.71-.29 1 1 0 000-1.42L24 28.59l-2.2-2.19-3.28-3.28a1.38 1.38 0 111.94-1.95L30 30.72a1 1 0 001.42 0 1 1 0 000-1.41l-9.55-9.55L20 17.93a1.46 1.46 0 01-.41-1A1.3 1.3 0 0120 16a1.34 1.34 0 011-.39 1.39 1.39 0 011 .39l1.81 1.81 9.68 9.67a1 1 0 001.41-1.41l-9.67-9.67a1.37 1.37 0 111.94-1.94l15 15a1 1 0 001.41 0 1 1 0 000-1.41l-1.29-1.3a.71.71 0 000-.21l-.6-6.74a3.43 3.43 0 01.47-2.84l.64-.8a.65.65 0 01.61-.21c.15 0 .19.11.23.24L48 28.48v-5.91l-2.54-7.07a2.26 2.26 0 00-1.71-1.58 2.7 2.7 0 00-2.56.89l-.7.87-.07.09a5.31 5.31 0 00-.79 4.23l.4 4.53L28.53 13a3.39 3.39 0 00-4.79 0 3.47 3.47 0 00-.74 1.24 3.38 3.38 0 00-2-.67 3.33 3.33 0 00-3.34 3.35 3.37 3.37 0 00.69 2 3.33 3.33 0 00-1.24.78 3.39 3.39 0 000 4.79l1.08 1.08a3.32 3.32 0 00-1.18.76 3.4 3.4 0 000 4.8l14.45 14.49A18.45 18.45 0 0034.38 48h5.13a12.59 12.59 0 01-6.63-3.81z'
            />
            <path
                className='DontHandlePhone_svg__cls-1'
                d='M30 4H8a1 1 0 00-1 1v34a1 1 0 001 1h4v-2H9V6h20v4.75a5.94 5.94 0 01.95.74L31 12.57V5a1 1 0 00-1-1z'
            />
            <path d='M14 39a5 5 0 009.7 1.64l-6.3-6.35A5 5 0 0014 39z' fill='#d7153a' />
            <path
                className='DontHandlePhone_svg__cls-1'
                d='M35 5v-.1A5 5 0 0030 0H8a5 5 0 00-5 5v38a5 5 0 005 5h23l-2-2H8a3 3 0 01-3-3V5a3 3 0 013-3h22a3 3 0 013 3v9.54l2 2V5.05z'
            />
        </svg>
    );
};

BrandIconDontHandlePhone.propTypes = {
    title: PropTypes.string,
    titleId: PropTypes.string,
    size: PropTypes.oneOf(['lg', 'xl', 'xxl']),
};
BrandIconDontHandlePhone.defaultProps = {
    size: 'xl',
    focusable: false,
};
export default BrandIconDontHandlePhone;
