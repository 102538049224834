import * as React from 'react';
import PropTypes from 'prop-types';
import tokens from '../../Tokens/tokens';
const { colors, spacing } = tokens;

const IconAttach = ({ title, titleId, ...props }) => {
    const sizeArray = Object.entries(spacing.icons);
    const colorArray = Object.entries(colors.icons);
    const sizeOptions = new Map(sizeArray);
    const displaySize = sizeOptions.get(props.size);
    const colorOptions = new Map(colorArray);
    const displayColor = colorOptions.get(props.color);
    return (
        <svg
            viewBox='0 0 16 16'
            xmlns='http://www.w3.org/2000/svg'
            fill={props.color && displayColor}
            aria-hidden={!title}
            role={title ? 'img' : null}
            width={props.size && displaySize}
            height={props.size && displaySize}
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <g>
                <path d='M-8 -9C-7.44772 -9 -7 -9.44771 -7 -10C-7 -10.5523 -7.44772 -11 -8 -11C-8.55229 -11 -9 -10.5523 -9 -10C-9 -9.44771 -8.55229 -9 -8 -9Z' />
                <path d='M2 4.5C2 2.01472 4.01472 0 6.5 0C8.98528 0 11 2.01472 11 4.5V10C11 11.6569 9.65685 13 8 13C6.34315 13 5 11.6569 5 10V5C5 4.44772 5.44772 4 6 4C6.55228 4 7 4.44772 7 5V10C7 10.5523 7.44772 11 8 11C8.55228 11 9 10.5523 9 10V4.5C9 3.11929 7.88071 2 6.5 2C5.11929 2 4 3.11929 4 4.5V10C4 12.2091 5.79086 14 8 14C10.2091 14 12 12.2091 12 10V4C12 3.44772 12.4477 3 13 3C13.5523 3 14 3.44772 14 4V10C14 13.3137 11.3137 16 8 16C4.68629 16 2 13.3137 2 10V4.5Z' />
                <path d='M21 20C21 22.2091 19.2091 24 17 24C14.7909 24 13 22.2091 13 20V17H15V20C15 21.1046 15.8954 22 17 22C18.1046 22 19 21.1046 19 20V17H21V20Z' />
            </g>
        </svg>
    );
};

IconAttach.propTypes = {
    title: PropTypes.string,
    titleId: PropTypes.string,
    size: PropTypes.oneOf(['sm', 'md']),
    color: PropTypes.oneOf(['primaryBlue', 'secondaryBlue', 'darkGrey', 'white']),
};
IconAttach.defaultProps = {
    size: 'sm',
    color: 'darkGrey',
    focusable: false,
};
export default IconAttach;
