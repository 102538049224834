import React, { useEffect } from 'react';
import Address from '../../Form/Address/Address';
import FormInput from '../../Components/Form/FormInput';
import Heading from '../../Components/Headings/Heading';
import PropTypes from 'prop-types';
import { australianPhoneNumber } from '../../Validation/Masks';
import { isAllWhitespace, emailFormat, notAnAlphaName, notAustralianPhoneNumber } from '../../Validation/Validators';
import { getErrorMessage } from '../../Validation/ErrorMessages';
import Field from '../../Components/Form/Field.styled';
import { trackMe } from '../../Components/ComponentAnalytics/componentAnalytics';

const onNameValidate = name => {
    if (notAnAlphaName(name)) {
        return getErrorMessage('notAlphaName');
    } else if (isAllWhitespace(name)) {
        return getErrorMessage('allWhitespace');
    }
};

const onPhoneValidate = name => {
    if (notAustralianPhoneNumber(name)) {
        return getErrorMessage('notAustralianPhoneNumber');
    }
};

const emailOnValidate = value => {
    if (emailFormat(value)) {
        return getErrorMessage('emailFormat');
    }
};

const PersonalDetailsPage = ({ onChange, customer, apiKey, options }) => {
    useEffect(() => {
        trackMe('TemplatePersonalDetailsPage');
    }, []);

    return (
        <>
            <Heading>{ options?.personalDetailsHeading || 'Personal details'}</Heading>
            {!(options?.lockName) ?
                <>
                    <FormInput
                        label='Given name'
                        name='givenName'
                        onChange={ (e, value) => onChange(e.target.name, value) }
                        onValidate={ onNameValidate }
                        value={ customer.firstName.value }
                    />
                    <FormInput
                        label='Middle name'
                        name='middleName'
                        isOptional
                        onChange={ (e, value) => onChange(e.target.name, value) }
                        onValidate={ onNameValidate }
                        value={ customer.middleName.value }
                    />
                    <FormInput
                        label='Family name'
                        name='familyName'
                        onChange={ (e, value) => onChange(e.target.name, value) }
                        onValidate={ onNameValidate }
                        value={ customer.lastName.value }
                    /></> :
                <div className='locked-name'>
                    <Field>
                        <Field.Label>Full name</Field.Label>
                        {!!(options?.lockedNameHelp) && <Field.Help>{options?.lockedNameHelp}</Field.Help>}
                        <Field.Label.Note>
                            {customer.firstName.value} {customer.middleName.value} {customer.lastName.value}
                        </Field.Label.Note>
                    </Field>
                </div>
            }
            <Heading>{ options?.contactDetailsHeading || 'Contact details'}</Heading>
            { (options?.captureAddress !== false) &&
                <Address
                    addressLabel='Residential address'
                    name='address'
                    addressType='personal'
                    onChange={ value => onChange('address', value) }
                    helpMessage='Start typing and select your address from the results that appear.
                             If you are unable to locate your address please enter it manually.'
                    address={ customer.address.address }
                    apiKey={ apiKey }
                />}
            {(options?.lockEmail) ?
                <div className='locked-email'>
                    <Field>
                        <Field.Label>Email</Field.Label>
                        {!!(options?.lockedEmailHelp) && <Field.Help>{options?.lockedEmailHelp}</Field.Help>}
                        <Field.Label.Note>{customer.email.value}</Field.Label.Note>
                    </Field>
                </div> :
                <FormInput
                    label='Email address'
                    name='email'
                    onChange={ (e, value) => onChange(e.target.name, value) }
                    helpMessage='Enter your email address using the format example@email.com'
                    onValidate={ emailOnValidate }
                    value={ customer.email.value }
                />}
            <FormInput
                label='Phone number'
                name='phoneNumber'
                onChange={ (e, value) => onChange(e.target.name, value) }
                helpMessage='Enter your phone number using 10 digits.
                             Include the area code if you are entering a landline.'
                mask={ australianPhoneNumber }
                onValidate={ onPhoneValidate }
                value={ customer.phoneNumber.value }
            />
        </>
    );
};

PersonalDetailsPage.propTypes = {
    customer: PropTypes.shape({
        firstName: PropTypes.shape({
            value: PropTypes.string,
            hasError: PropTypes.bool
        }),
        middleName: PropTypes.shape({
            value: PropTypes.string,
            hasError: PropTypes.bool
        }),
        lastName: PropTypes.shape({
            value: PropTypes.string,
            hasError: PropTypes.bool
        }),
        address: PropTypes.shape({
            address: PropTypes.shape({
                addressLine: PropTypes.string,
                buildingNumber: PropTypes.string,
                country: PropTypes.string,
                formattedAddress: PropTypes.string,
                latitude: PropTypes.string,
                longitude: PropTypes.string,
                nswPointId: PropTypes.string,
                postcode: PropTypes.string,
                propertyName: PropTypes.string,
                state: PropTypes.string,
                streetName: PropTypes.string,
                streetNumber: PropTypes.string,
                streetType: PropTypes.string,
                suburb: PropTypes.string,
                unitNumber: PropTypes.string,
                validated: PropTypes.bool
            }),
        }),
        email: PropTypes.shape({
            value: PropTypes.string,
            hasError: PropTypes.bool
        }),
        phoneNumber: PropTypes.shape({
            value: PropTypes.string,
            hasError: PropTypes.bool
        }),
    }),
    apiKey: PropTypes.string,
    onChange: PropTypes.func,
    options: PropTypes.shape({
        captureAddress: PropTypes.bool,
        personalDetailsHeading: PropTypes.string,
        contactDetailsHeading: PropTypes.string,
        lockName: PropTypes.bool,
        lockedNameHelp: PropTypes.string,
        lockEmail: PropTypes.bool,
        lockedEmailHelp: PropTypes.string,
    })
};

export default PersonalDetailsPage;
