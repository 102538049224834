import styled from 'styled-components/macro';

//NOTE: this is wrong: delete this as soon as we introduce templates
export const StyledBreadcrumb = styled.div`
    padding-top: 2.5rem;
`;

const maximumWidth = props => `max-width:
    ${props.theme.componentWidths.screener.progressBar ?
        props.theme.componentWidths.screener.progressBar : '54rem'}`;

//NOTE: media.xs StyledBreadcrumb styles should be changed once theme templates and breadcrumb is refactored
export const BreadCrumbWithProgressBarContainer = styled.div`
  ${maximumWidth};
  width: 100%;
  align-self: center;
  padding-top: 2.5rem;

  display: flex;
  flex-direction: column;

  > nav {
     padding-top: 2.5rem;
  }

  @media(max-width: 480px) {
    padding-top: 1rem;

    flex-direction: row;
    margin-bottom: 2rem;

    ${StyledBreadcrumb} {
      padding-top: 0.55rem !important;
      padding-right: 0.75rem !important;
    }

    > nav {
      padding-top: 0.55rem;
      padding-right: 0.75rem;
    }
  }
`;
