import styled from 'styled-components/macro';
import { pxToRem } from '../../utils';
import tokens from '../../Tokens/tokens';
import { skeletonAnimation } from './SkeletonContainer.styled';

const { spacing } = tokens;

export const StyledSkeletonShape = styled.div`
    ${skeletonAnimation};
    width: 100%;
    max-width: ${props => props.size ? pxToRem(spacing.icons[props.size]) : pxToRem(props.width)};
    height: ${props => props.size ? pxToRem(spacing.icons[props.size]) : pxToRem(props.height)};
    border-radius: ${props => props.circle ? '50%' : 0};
    clip-path: ${props => props.circle ? 'circle(50%)' : 'none'};
`;

export default StyledSkeletonShape;
