import React from 'react';
import styled from 'styled-components/macro';
import { color, layout } from 'styled-system';
import tokens from '../../Tokens/tokens';

const { colors } = tokens;

// eslint-disable-next-line react/prop-types
const BusinessSvg = ({ className, color, width, height }) => (
    <svg className={ className } viewBox='0 0 44 44' fill={ colors.legacy[color] || color } width={ width } height={ height } xmlns='http://www.w3.org/2000/svg' focusable='false' aria-hidden='true'>
        <path d='m 30,11 c -0.6,0 -1,-0.4 -1,-1 V 8 C 29,7.6 28.4,7 28,7 H 16 c -0.4,0 -1,0.6 -1,1 v 2 c 0,0.6 -0.4,1 -1,1 -0.6,0 -1,-0.4 -1,-1 V 8 c 0,-1.5 1.5,-3 3,-3 h 12 c 1.5,0 3,1.5 3,3 v 2 c 0,0.6 -0.4,1 -1,1 z' />
        <path d='M 7,37 C 5.5,37 4,35.5 4,34 V 24 h 2 v 10 c 0,0.4 0.6,1 1,1 h 30 c 0.4,0 1,-0.6 1,-1 V 24 h 2 v 10 c 0,1.5 -1.5,3 -3,3 z' />
        <path d='m 23.398193,22.910399 c -0.248211,-0.102096 -0.389044,-0.236518 -0.500251,-0.47748 -0.05186,-0.112363 -0.06156,-0.181214 -0.06156,-0.437015 0,-0.276689 0.0069,-0.317609 0.0773,-0.46105 0.108244,-0.220426 0.307705,-0.393007 0.535554,-0.463382 0.167533,-0.05175 0.255716,-0.05549 1.306211,-0.05549 H 25.882 l 0.03141,0.144918 c 0.01728,0.07971 0.04524,0.284632 0.06215,0.455392 0.03377,0.34113 0.002,0.818867 -0.07793,1.170101 l -0.04309,0.189438 -1.150234,-6.55e-4 c -1.139653,-6.49e-4 -1.151668,-0.0012 -1.306119,-0.06477 z m -5.327564,-0.133178 c -0.05072,-0.274502 -0.04998,-1.26252 0.0012,-1.547314 l 0.03841,-0.213927 h 0.73638 c 0.813588,0 0.909213,0.01408 1.141851,0.168177 0.258712,0.171364 0.384235,0.435082 0.38637,0.811747 0.0024,0.420844 -0.163064,0.715793 -0.496078,0.884361 l -0.161527,0.08176 -0.804652,0.0084 -0.804653,0.0084 z M 22.1,18 C 19.9,17.9 18,19.7 18,21.9 17.9,24.1 19.7,26 21.9,26 H 22 c 2.1,0 3.9,-1.7 4,-3.9 0.1,-2.2 -1.7,-4 -3.9,-4.1 z M 39,23 H 23.8 c -0.6,0 -1,-0.4 -1,-1 0,-0.6 0.4,-1 1,-1 H 38 V 14.8 C 38,14.4 37.7,14 37.2,14 H 6.8 C 6.3,14 6,14.3 6,14.8 V 21 h 13.4 c 0.6,0 1,0.4 1,1 0,0.6 -0.4,1 -1,1 H 5 C 4.4,23 4,22.6 4,22 V 14.8 C 4,13.2 5.2,12 6.8,12 h 30.5 c 1.5,0 2.8,1.2 2.8,2.8 V 22 C 40,22.6 39.6,23 39,23 Z' />
    </svg>
);

export const Business = styled(BusinessSvg)`
  ${color}
  ${layout}
`;

Business.defaultProps = {
    color: `${colors.brand.snswSecondaryBlue}`,
    height: 44,
    width: 44,
};

export default Business;
