import styled, { css } from 'styled-components/macro';
import { getSpacing, pxToRem, getBreakpoint } from '../../utils';
import tokens from '../../Tokens/tokens';
import { skeletonAnimation } from './SkeletonContainer.styled';

const { font } = tokens;

const StyledSkeletonHeading = styled.div`
    ${skeletonAnimation};
    width: 100%;
    max-width: ${props => props.width && pxToRem(props.width)};
    ${props => props.level && headingSizes[props.level]};
    ${props => props.noMargin && 'margin: 0 !important'};
`;

const h1Style = css`
    height: ${pxToRem(font.size.xxxl * font.lineHeight.heading)};
    margin-top: ${getSpacing('md')};
    margin-bottom: ${getSpacing('lg')};

    ${getBreakpoint.md`
        height: ${pxToRem(font.size.xxxxl * font.lineHeight.heading)};
        margin-top: ${getSpacing('xl')};
        margin-bottom: ${getSpacing('xxl')};
    `}
`;

const h2Style = css`
    height: ${pxToRem(font.size.xl * font.lineHeight.heading)};
    margin-top: ${getSpacing('lg')};
    margin-bottom: ${getSpacing('sm')};

    ${getBreakpoint.md`
        height: ${pxToRem(font.size.xxl * font.lineHeight.heading)};
        margin-top: ${getSpacing('xxxl')};
        margin-bottom: ${getSpacing('md')};
    `}
`;

const h3Style = css`
    height: ${pxToRem(font.size.md * font.lineHeight.heading)};
    margin-top: ${getSpacing('md')};
    margin-bottom: ${getSpacing('sm')};

    ${getBreakpoint.md`
    height: ${pxToRem(font.size.lg * font.lineHeight.heading)};
        margin-top: ${getSpacing('lg')};
        margin-bottom: ${getSpacing('md')};
    `}
`;

const h4Style = css`
    height: ${pxToRem(font.size.sm * font.lineHeight.heading)};
    margin-top: ${getSpacing('sm')};
    margin-bottom: ${getSpacing('sm')};

    ${getBreakpoint.md`
        margin-top: ${getSpacing('md')};
        margin-bottom: ${getSpacing('md')};
    `}
`;

const h5Style = css`
    height: ${pxToRem(font.size.base * font.lineHeight.heading)};
    margin-top: ${getSpacing('sm')};
    margin-bottom: ${getSpacing('sm')};

    ${getBreakpoint.md`
        margin-top: ${getSpacing('md')};
        margin-bottom: ${getSpacing('md')};
    `}
`;

const headingSizes = {
    h1: h1Style,
    h2: h2Style,
    h3: h3Style,
    h4: h4Style,
    h5: h5Style,
};

export default StyledSkeletonHeading;
