import React from 'react';
import styled from 'styled-components/macro';
import { color, layout, space } from 'styled-system';
import tokens from '../../Tokens/tokens';

const { colors } = tokens;

// eslint-disable-next-line react/prop-types
const QuestionSvg = ({ className, color, width, height }) => (
    <svg className={ className } xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' width={ width } height={ height } focusable='false' aria-hidden='true'>
        <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
            <g transform='translate(-861.000000, -784.000000)'>
                <g transform='translate(861.000000, 784.000000)'>
                    <circle fill={ colors.legacy[color] || color } cx='8' cy='8' r='8' />
                    <path d='M5.45032051,5.37820513 C5.53632522,4.61912014 5.81490149,4.03298818 6.28605769,3.61979167 C6.75721389,3.20659516 7.36671635,3 8.11458333,3 C8.88488633,3 9.51775927,3.21220407 10.0132212,3.63661859 C10.508683,4.0610331 10.7564103,4.59481516 10.7564103,5.23798077 C10.7564103,5.55956357 10.6844291,5.87179336 10.5404647,6.17467949 C10.3965003,6.47756562 10.0552912,6.86645062 9.51682692,7.34134615 C9.10550008,7.70780098 8.84655502,7.99666027 8.73998397,8.20793269 C8.63341293,8.41920512 8.57451929,8.7978071 8.56330128,9.34375 L7.59855769,9.34375 C7.59855769,8.90998715 7.61444963,8.59308327 7.64623397,8.39302885 C7.67801832,8.19297443 7.7434557,7.99759711 7.84254808,7.80689103 C7.94164045,7.61618494 8.07064557,7.43669956 8.22956731,7.26842949 C8.38848904,7.10015942 8.61191095,6.8907598 8.89983974,6.64022436 C9.26629457,6.31490222 9.4953254,6.05969644 9.5869391,5.87459936 C9.67855281,5.68950228 9.72435897,5.50160352 9.72435897,5.31089744 C9.72435897,4.91826727 9.5691789,4.57331879 9.2588141,4.27604167 C8.9484493,3.97876454 8.57078214,3.83012821 8.12580128,3.83012821 C7.22088223,3.83012821 6.67494752,4.38354147 6.48798077,5.49038462 L5.45032051,5.37820513 Z M8.7791748,12.7948718 L7.32861328,12.7948718 L7.32861328,11.6450321 L8.7791748,11.6450321 L8.7791748,12.7948718 Z' stroke='#FFFFFF' strokeWidth='0.5' fill='#FFFFFF' fillRule='nonzero'></path>
                </g>
            </g>
        </g>
    </svg>
);

const Question = styled(QuestionSvg)`
  ${color};
  ${layout};
  ${space};
`;

Question.defaultProps = {
    color: `${colors.brand.snswSecondaryBlue}`,
    height: 16,
    width: 16,
    marginRight: '0.5rem'
};

export default Question;
