import * as React from 'react';
import PropTypes from 'prop-types';
import tokens from '../../Tokens/tokens';
const { spacing } = tokens;

const BrandIconChildren = ({ title, titleId, ...props }) => {
    const sizeArray = Object.entries(spacing.icons);
    const sizeOptions = new Map(sizeArray);
    const displaySize = sizeOptions.get(props.size);
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 48 48'
            fill={props.color && displayColor}
            aria-hidden={!title}
            role={title ? 'img' : null}
            width={props.size && displaySize}
            height={props.size && displaySize}
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <defs>
                <style>{'.Children_svg__cls-1{fill:#002664}'}</style>
            </defs>
            <path className='Children_svg__cls-1' d='M22 23a5 5 0 10-5-5 5 5 0 005 5zm0-8a3 3 0 11-3 3 3 3 0 013-3z' />
            <path
                className='Children_svg__cls-1'
                d='M34.93 19.58a2.76 2.76 0 00-3.93.57C28.27 24 25.71 24 24 24h-4c-1.2 0-4.38 0-7.11-3.85A2.74 2.74 0 0011.05 19a2.71 2.71 0 00-2.05.58 2.76 2.76 0 00-.51 3.84 38.75 38.75 0 002.78 3.07C12.6 27.92 14.89 30.3 15 31v14a3 3 0 003 3 3 3 0 002.08-.88A3 3 0 0021 45v-7h-2v7a1 1 0 01-.32.7 1 1 0 01-.68.3 1 1 0 01-1-1V31c0-1.32-1.64-3.1-4.33-5.89C11.61 24 10.61 23 10 22.21a.74.74 0 01.15-1.05.72.72 0 01.57-.15.77.77 0 01.5.3C14.59 26 18.66 26 20 26h4c1.85 0 5.29 0 8.62-4.69a.75.75 0 01.5-.3.73.73 0 01.57.15.74.74 0 01.15 1.05 36.46 36.46 0 01-2.48 2.71C28.65 27.72 27 29.53 27 31v13.99a1 1 0 01-1 1 1 1 0 01-1-1v-7h-2v7a3 3 0 003 3 3 3 0 003-3V31c0-.78 2.48-3.32 3.8-4.69a34.22 34.22 0 002.64-2.89 2.76 2.76 0 00-.51-3.84zM39 17.35A3.49 3.49 0 0037.75 15a1.08 1.08 0 01-.36-.85 1.24 1.24 0 010-.51l.59-.07a1 1 0 00.81-.6 1 1 0 00-.13-1l-1.33-1.69a1 1 0 00-.89-.38 1 1 0 00-.79.55l-1 2a1 1 0 00.08 1 1 1 0 00.59.4 4.34 4.34 0 000 .51 3.17 3.17 0 00.92 2.07l.13.12a1.47 1.47 0 01.55 1 1.21 1.21 0 01-.25 1 1 1 0 00.32 1.37 1 1 0 00.52.15 1 1 0 00.86-.47 3.18 3.18 0 00.63-2.25z'
            />
            <path d='M41 5a5 5 0 11-5-5 5 5 0 015 5z' fill='#d7153a' />
        </svg>
    );
};

BrandIconChildren.propTypes = {
    title: PropTypes.string,
    titleId: PropTypes.string,
    size: PropTypes.oneOf(['lg', 'xl', 'xxl']),
};
BrandIconChildren.defaultProps = {
    size: 'xl',
    focusable: false,
};
export default BrandIconChildren;
