import styled from 'styled-components/macro';
import { pxToRem } from '../../utils';
import Button from '../../Components/Button/Button';
import Field from '../../Components/Form/Field.styled';

export const StyledKeepARecord = styled.div`
    width: 100%;

    input {
        display: block;
        margin-top: ${pxToRem(8)};
    }

    h2, h3, h4, h5, h6 {
        margin-bottom: ${pxToRem(16)};
    }
`;

StyledKeepARecord.IconButton = styled(Button)`
    text-decoration: none;
    transition: fill 0.5s ease;
    margin-top: ${pxToRem(16)};

    svg {
        width: ${pxToRem(16)};
        height: ${pxToRem(16)};
        position: relative;
        top:  ${pxToRem(2)};
        right: ${pxToRem(8)};
    }
`;

StyledKeepARecord.Group = styled.div`
    margin-bottom: ${pxToRem(16)};

    &:last-child {
        margin-bottom: 0;
    }
`;

StyledKeepARecord.IconButtonLinkWrapper = styled.div`
    display: flex;

    svg {
        width: ${pxToRem(24)};
        height: ${pxToRem(24)};
        margin-right: ${pxToRem(8)};
    }
`;

StyledKeepARecord.Error = styled(Field.Error)`
    margin-bottom: ${pxToRem(16)};
`;

StyledKeepARecord.Loading = styled.span`
    display: block;
    margin-top: ${pxToRem(8)};
    margin-bottom: ${pxToRem(16)};
`;

StyledKeepARecord.EmailSuccess = styled.span`
    display: block;
    margin-top: ${pxToRem(8)};
    padding-left: ${pxToRem(28)};
    background: url("data:image/svg+xml;charset=utf-8,%3Csvg width='16' height='16' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%2300A908' d='M8 16A8 8 0 118 0a8 8 0 010 16zm-.94-4a1 1 0 00.72-.38l4-5a1 1 0 10-1.56-1.24L6.92 9.5 5.71 8.29a1.004 1.004 0 00-1.42 1.42l2 2A1 1 0 007 12h.06z'/%3E%3C/svg%3E") no-repeat 0 4px;
`;

StyledKeepARecord.EmailLoading = styled.span`
    display: block;
    margin-top: ${pxToRem(8)};
`;

export default StyledKeepARecord;
