import * as React from 'react';
import PropTypes from 'prop-types';
import tokens from '../../Tokens/tokens';
const { spacing } = tokens;

const BrandIconSaveTime = ({ title, titleId, ...props }) => {
    const sizeArray = Object.entries(spacing.icons);
    const sizeOptions = new Map(sizeArray);
    const displaySize = sizeOptions.get(props.size);
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 48 48'
            fill={props.color && displayColor}
            aria-hidden={!title}
            role={title ? 'img' : null}
            width={props.size && displaySize}
            height={props.size && displaySize}
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <defs>
                <style>{'.SaveTime_svg__cls-1{fill:#002664}'}</style>
            </defs>
            <path className='SaveTime_svg__cls-1' d='M31.7 20.06l-1.41-1.41-6.05 6.05-3.56-3.35-1.37 1.46 4.97 4.67 7.42-7.42z' />
            <path
                className='SaveTime_svg__cls-1'
                d='M24 37a13 13 0 100-26 12.87 12.87 0 00-4.49.8 1 1 0 10.69 1.87A11 11 0 1113 24a11 11 0 014-8.49A1 1 0 1015.73 14 13 13 0 0024 37z'
            />
            <path
                className='SaveTime_svg__cls-1'
                d='M42 24a18 18 0 00-4.75-12.2L35 .8a1 1 0 00-1-.8H15a1 1 0 00-1 .76L11.48 11a18.09 18.09 0 000 25.94L14 47.24a1 1 0 001 .76h19a1 1 0 001-.8l2.31-11A18.09 18.09 0 0042 24zM15.78 2h17.41l1.56 7.53a17.82 17.82 0 00-20.69-.6zM8 24a16 16 0 1116 16.09A16.07 16.07 0 018 24zm25.19 22H15.78l-1.72-6.93a17.83 17.83 0 0020.7-.6z'
            />
            <path
                d='M45.83 21.79a4.94 4.94 0 00-3.25-1.74A19 19 0 0142 30a4.93 4.93 0 003.17-1.15 5 5 0 00.66-7.06z'
                fill='#d7153a'
            />
        </svg>
    );
};

BrandIconSaveTime.propTypes = {
    title: PropTypes.string,
    titleId: PropTypes.string,
    size: PropTypes.oneOf(['lg', 'xl', 'xxl']),
};
BrandIconSaveTime.defaultProps = {
    size: 'xl',
    focusable: false,
};
export default BrandIconSaveTime;
