import styled from 'styled-components/macro';
import tokens from '../../Tokens/tokens';
import { pxToRem } from '../../utils';

const { colors } = tokens;

export const StyledAutoSuggestAddress = styled.div`
    width: 100%;

    .react-autosuggest__suggestions-list {
        li {
            &:last-child {
                .switch-to-manual-mode-cta {
                    color: ${colors.brand.snswSecondaryBlue};
                    text-decoration: underline;
                }

                &:hover,
                &:active,
                &:focus,
                &.react-autosuggest__suggestion--highlighted {
                    cursor: inherit;

                    .switch-to-manual-mode-cta {
                        cursor: inherit;
                        color: ${colors.white};
                    }
                }
            }
        }
    }

    .switch-to-auto-mode-cta {
        margin-top: ${pxToRem(tokens.spacing.sm)};
    }
    .switch-to-manual-mode-cta {
        margin-top: ${pxToRem(tokens.spacing.xs)};
    }
`;
