import { useMemo } from 'react';
import utils from '../../utils';

const useInternationalAddress = countriesOverride => {

    const countries = useMemo(() => {
        // Remove "Australia" from countries & use memoization so we don't have to calculate it again
        var countriesToFilter = (countriesOverride && countriesOverride.length > 0) ? countriesOverride : utils.getCountries();
        return countriesToFilter.filter(value => value !== 'Australia');
    }, [countriesOverride]);

    return { countries };
};

export default useInternationalAddress;
