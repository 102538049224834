import HeadingWithIcon from '../../Components/Headings/HeadingWithIcon';
import React, { useEffect } from 'react';
import { trackMe } from '../../Components/ComponentAnalytics/componentAnalytics';

const ErrorPage = () => {
    useEffect(() => {
        trackMe('TemplateErrorPage');
    }, []);

    return <HeadingWithIcon level={ 2 } type='error'>Sorry, we can&apos;t progress your application</HeadingWithIcon>;
};

export default ErrorPage;
