import * as React from 'react';
import PropTypes from 'prop-types';
import tokens from '../../Tokens/tokens';
const { spacing } = tokens;

const BrandIconSupportCare = ({ title, titleId, ...props }) => {
    const sizeArray = Object.entries(spacing.icons);
    const sizeOptions = new Map(sizeArray);
    const displaySize = sizeOptions.get(props.size);
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 48 48'
            fill={props.color && displayColor}
            aria-hidden={!title}
            role={title ? 'img' : null}
            width={props.size && displaySize}
            height={props.size && displaySize}
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <defs>
                <style>{'.SupportCare_svg__cls-1{fill:#002664}'}</style>
            </defs>
            <path
                className='SupportCare_svg__cls-1'
                d='M34.05 11.37a6.7 6.7 0 002-4.73 6.51 6.51 0 00-1.91-4.72 6.7 6.7 0 00-9.47.08l-.67.67-.67-.67a6.69 6.69 0 00-9.46-.08A6.51 6.51 0 0012 6.64a6.7 6.7 0 002 4.73l9.34 9.33a1 1 0 00.66.3 1 1 0 00.7-.29l9.35-9.33zM32.64 10L24 18.58 15.36 10A4.78 4.78 0 0114 6.62 4.53 4.53 0 0118.52 2a4.79 4.79 0 013.39 1.41l1.38 1.37a1 1 0 001.41 0l1.39-1.38a4.7 4.7 0 016.63-.08A4.55 4.55 0 0134 6.62 4.78 4.78 0 0132.64 10zm10.3 10.85c-1.6-1.48-4.48-1-6.4 1.1C34.65 23.86 25 33.56 25 37.56V48h2V37.56c0-2.46 6.81-10 11-14.22 1.27-1.38 2.92-1.65 3.6-1a1.41 1.41 0 01.41 1A3.9 3.9 0 0140.87 26l-3.35 3.61A1 1 0 1039 30.93l3.34-3.6A6 6 0 0044 23.25a2.87 2.87 0 00-1.06-2.4z'
            />
            <path
                className='SupportCare_svg__cls-1'
                d='M44 5a4 4 0 00-4 4v9h2V9a2 2 0 014 0v17c0 2.53-5.22 7.91-7.73 10.84a20.41 20.41 0 00-2.16 2.71C35 41.76 35 42.92 35 46v2h2v-2c0-3 0-3.76.89-5.55.15-.26 1.08-1.35 1.9-2.31C43.94 33.29 48 29 48 26V9a4 4 0 00-4-4z'
            />
            <path
                d='M34 6.62a4.55 4.55 0 00-1.32-3.29 4.7 4.7 0 00-6.63.08L24.7 4.79a1 1 0 01-1.41 0L30.55 12l2.09-2A4.78 4.78 0 0034 6.62z'
                fill='#d7153a'
            />
            <path
                className='SupportCare_svg__cls-1'
                d='M5.06 20.85c1.6-1.48 4.48-1 6.4 1.1C13.35 23.86 23 33.56 23 37.56V48h-2V37.56c0-2.46-6.81-10-11-14.22-1.27-1.38-2.92-1.65-3.6-1a1.41 1.41 0 00-.41 1A3.9 3.9 0 007.13 26l3.35 3.61A1 1 0 019 30.93l-3.34-3.6A6 6 0 014 23.25a2.87 2.87 0 011.06-2.4z'
            />
            <path
                className='SupportCare_svg__cls-1'
                d='M4 5a4 4 0 014 4v9H6V9a1.94 1.94 0 00-2-2 1.94 1.94 0 00-2 2v17c0 2.53 5.22 7.91 7.73 10.84a20.41 20.41 0 012.16 2.71C13 41.76 13 42.92 13 46v2h-2v-2c0-3 0-3.76-.89-5.55-.11-.26-1.11-1.35-1.9-2.31C4.06 33.29 0 29 0 26V9a4 4 0 014-4z'
            />
        </svg>
    );
};

BrandIconSupportCare.propTypes = {
    title: PropTypes.string,
    titleId: PropTypes.string,
    size: PropTypes.oneOf(['lg', 'xl', 'xxl']),
};
BrandIconSupportCare.defaultProps = {
    size: 'xl',
    focusable: false,
};
export default BrandIconSupportCare;
