import * as React from 'react';
import PropTypes from 'prop-types';
import tokens from '../../Tokens/tokens';
const { spacing } = tokens;

const BrandIconMscBuses = ({ title, titleId, ...props }) => {
    const sizeArray = Object.entries(spacing.icons);
    const sizeOptions = new Map(sizeArray);
    const displaySize = sizeOptions.get(props.size);
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 48 48'
            fill={props.color && displayColor}
            aria-hidden={!title}
            role={title ? 'img' : null}
            width={props.size && displaySize}
            height={props.size && displaySize}
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <defs>
                <style>{'.MSCBuses_svg__cls-1{fill:#002664}'}</style>
            </defs>
            <path
                className='MSCBuses_svg__cls-1'
                d='M9 7h7a1 1 0 000-2H9a1 1 0 000 2zm21 28V18a1 1 0 00-1-1h-8a1 1 0 00-1 1v17h2V19h6v16z'
            />
            <path d='M38 35a3 3 0 103 3 3 3 0 00-3-3z' fill='none' />
            <path
                className='MSCBuses_svg__cls-1'
                d='M47.8 25.4l-6-8a1 1 0 00-.8-.4h-7a1 1 0 00-1 1v8a1 1 0 001 1h8v-2h-7v-6h5.5l5.5 7.33V37h-3.1a5 5 0 00-9.8 0H15.9a5.05 5.05 0 010 2h17.2a5 5 0 009.8 0H47a1 1 0 001-1V26a1 1 0 00-.2-.6zM38 41a3 3 0 113-3 3 3 0 01-3 3z'
            />
            <path
                className='MSCBuses_svg__cls-1'
                d='M6.1 37H2V13a3 3 0 013-3h28a3 3 0 013 3v3h2v-3a5 5 0 00-5-5H5a5 5 0 00-5 5v25a1 1 0 001 1h5.1a5 5 0 010-2z'
            />
            <path d='M16 38a5 5 0 11-5-5 5 5 0 015 5z' fill='#d7153a' />
        </svg>
    );
};

BrandIconMscBuses.propTypes = {
    title: PropTypes.string,
    titleId: PropTypes.string,
    size: PropTypes.oneOf(['lg', 'xl', 'xxl']),
};
BrandIconMscBuses.defaultProps = {
    size: 'xl',
    focusable: false,
};
export default BrandIconMscBuses;
