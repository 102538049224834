import React from 'react';
import styled from 'styled-components/macro';
import { color, layout } from 'styled-system';
import tokens from '../../Tokens/tokens';

const { colors } = tokens;

// eslint-disable-next-line react/prop-types
const HighlightSvg = ({ className, color, width, height }) => (
    <svg className={ className } width={ width } height={ height } viewBox='0 0 32 32' version='1.1' xmlns='http://www.w3.org/2000/svg' focusable='false' aria-hidden='true'>
        <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
            <g transform='translate(-148.000000, -1206.000000)'>
                <g transform='translate(124.000000, 1182.000000)'>
                    <g transform='translate(16.000000, 16.000000)'>
                        <rect stroke={ colors.legacy[color] || color } strokeWidth='2.1875' fillRule='nonzero' x='9.09375' y='9.09375' width='29.8125' height='29.8125' rx='14.90625'></rect>
                        <g strokeWidth='1' fillRule='evenodd' transform='translate(18.000000, 16.000000)'>
                            <path d='M6,0 C9.3137085,0 12,2.6862915 12,6 C12,8.22044953 10.793836,10.1591844 9.00096303,11.1967496 L9,13 C9,14.6568542 7.65685425,16 6,16 C4.34314575,16 3,14.6568542 3,13 L3.00003939,11.1973296 C1.20661355,10.159907 0,8.22086333 0,6 C0,2.6862915 2.6862915,0 6,0 Z' stroke={ colors.legacy[color] || color } strokeLinejoin='round'></path>
                            <rect fill={ colors.legacy[color] || color } x='3' y='11' width='6' height='1' rx='0.5'></rect>
                            <rect fill={ colors.legacy[color] || color } x='3' y='13' width='6' height='1' rx='0.5'></rect>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

const Highlight = styled(HighlightSvg)`
  ${color}
  ${layout}
`;

Highlight.defaultProps = {
    color: `${colors.brand.snswDarkGrey}`,
    height: 24,
    width: 24,
};

export default Highlight;
