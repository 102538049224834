import * as React from 'react';
import PropTypes from 'prop-types';
import tokens from '../../Tokens/tokens';
const { colors, spacing } = tokens;

const IconEmail = ({ title, titleId, ...props }) => {
    const sizeArray = Object.entries(spacing.icons);
    const colorArray = Object.entries(colors.icons);
    const sizeOptions = new Map(sizeArray);
    const displaySize = sizeOptions.get(props.size);
    const colorOptions = new Map(colorArray);
    const displayColor = colorOptions.get(props.color);
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 16 16'
            fill={props.color && displayColor}
            aria-hidden={!title}
            role={title ? 'img' : null}
            width={props.size && displaySize}
            height={props.size && displaySize}
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <path d='M8 9.72a1 1 0 01-.63-.22l-7-5.73a1 1 0 01-.32-1.11A1 1 0 011 2h14a1 1 0 01.94.66 1 1 0 01-.31 1.11l-7 5.73a1 1 0 01-.63.22zM3.79 4L8 7.43 12.2 4zM2 8H0V6.37L2 8z' />
            <path d='M15 14H1a1 1 0 01-1-1V8h2v4h12V8h2v5a1 1 0 01-1 1zm-.97-6H16V6.37L14.03 8z' />
        </svg>
    );
};

IconEmail.propTypes = {
    title: PropTypes.string,
    titleId: PropTypes.string,
    size: PropTypes.oneOf(['sm', 'md']),
    color: PropTypes.oneOf(['primaryBlue', 'secondaryBlue', 'darkGrey', 'white']),
};
IconEmail.defaultProps = {
    size: 'sm',
    color: 'darkGrey',
    focusable: false,
};
export default IconEmail;
