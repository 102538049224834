import * as React from 'react';
import PropTypes from 'prop-types';
import tokens from '../../Tokens/tokens';
const { spacing } = tokens;

const BrandIconVerifyDriversLicence = ({ title, titleId, ...props }) => {
    const sizeArray = Object.entries(spacing.icons);
    const sizeOptions = new Map(sizeArray);
    const displaySize = sizeOptions.get(props.size);
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 48 48'
            fill={props.color && displayColor}
            aria-hidden={!title}
            role={title ? 'img' : null}
            width={props.size && displaySize}
            height={props.size && displaySize}
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <defs>
                <style>{'.VerifyDriversLicence_svg__cls-1{fill:#002664}'}</style>
            </defs>
            <path
                className='VerifyDriversLicence_svg__cls-1'
                d='M26 30a10 10 0 1110-10 10 10 0 01-10 10zm0-18a8 8 0 108 8 8 8 0 00-8-8z'
            />
            <path
                className='VerifyDriversLicence_svg__cls-1'
                d='M23.88 24.72a1 1 0 01-.71-.29L20 21.28a1 1 0 011.41-1.41l2.44 2.43 5.5-5.66A1 1 0 1130.8 18l-6.21 6.38a1 1 0 01-.71.3z'
            />
            <path d='M26 44a5 5 0 115-5 5 5 0 01-5 5z' fill='#d7153a' />
            <path
                className='VerifyDriversLicence_svg__cls-1'
                d='M46.18 40.72a5 5 0 00-.5-3.79L43 32.29v4l.94 1.64a3 3 0 01-.94 4v2.33l.85-.49a5 5 0 002.33-3.05zM1.94 18.7L9 30.92v-4L3.68 17.7a3 3 0 011.09-4.09L9 11.17V8.86l-5.23 3a5 5 0 00-1.83 6.84z'
            />
            <path
                className='VerifyDriversLicence_svg__cls-1'
                d='M6 16.29l3 5.19v-4l-.77-1.33.77-.44V13.4l-2.63 1.52A1 1 0 006 16.29zM38 48H14a3 3 0 01-3-3V3a3 3 0 013-3h24a3 3 0 013 3v42a3 3 0 01-3 3zM14 2a1 1 0 00-1 1v42a1 1 0 001 1h24a1 1 0 001-1V3a1 1 0 00-1-1z'
            />
        </svg>
    );
};

BrandIconVerifyDriversLicence.propTypes = {
    title: PropTypes.string,
    titleId: PropTypes.string,
    size: PropTypes.oneOf(['lg', 'xl', 'xxl']),
};
BrandIconVerifyDriversLicence.defaultProps = {
    size: 'xl',
    focusable: false,
};
export default BrandIconVerifyDriversLicence;
