import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { LinkContainer } from './TextLink.styled';
import { IconExternalLink } from '../../Icons/system';
import { trackMe } from '../ComponentAnalytics/componentAnalytics';
import { getID } from '../../utils';

const TextLink = ({
    className,
    children,
    href,
    onClick,
    isExternal,
    as,
    ...otherOptions }) => {

    useEffect(() => {
        trackMe('TextLink');
    }, []);

    const elemID = getID();

    if (typeof as !== 'object') {
        otherOptions.href = href;
        otherOptions.as = 'a';

        if (isExternal) {
            otherOptions.target = '_blank';
            otherOptions.rel = 'noopener noreferrer';
        }
    }
    else {
        otherOptions.to = href;
        otherOptions.as = as;
    }

    return (
        <LinkContainer
            className={ className }
            onClick={ onClick }
            { ...otherOptions }
        >
            {children}
            {isExternal &&
                <IconExternalLink
                    color='secondaryBlue'
                    title='Opens in a new tab'
                    titleId={ elemID }
                />
            }
        </LinkContainer>
    );

};

TextLink.propTypes = {
    className: PropTypes.string,
    href: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    onClick: PropTypes.func,
    isExternal: PropTypes.bool,
    as: PropTypes.object
};

TextLink.defaultProps = {
    isExternal: false
};

export default TextLink;
