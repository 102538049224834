import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { trackMe } from '../ComponentAnalytics/componentAnalytics';
import { SROnly } from '../../GlobalStyle/utilities/utilities.styled';
import AnimatedIconSuccess from './AnimatedIconSuccess';
import { LoaderContainer, LoaderMessage, SpinnerImageContainer } from '../../Loader/Loader.styled';
import Dimmer from '../../Loader/Dimmer';

const SuccessIndicator = props => {
    const { className, active, label, ...rest } = props;

    useEffect(() => {
        trackMe('SuccessIndicator [GEL]');
    }, []);

    return (
        <Dimmer
            className={ className }
            active={ active }
            fullPage
            { ...rest }
        >
            <SROnly role='status'>{label}</SROnly>
            <LoaderContainer data-testid='hide-items' aria-hidden={ true }>
                <SpinnerImageContainer>
                    <AnimatedIconSuccess />
                </SpinnerImageContainer>
                <LoaderMessage fullPage>{label}<br /></LoaderMessage>
            </LoaderContainer>
        </Dimmer>
    );
};

SuccessIndicator.propTypes = {
    className: PropTypes.string,
    active: PropTypes.bool,
    label: PropTypes.string.isRequired,
};

SuccessIndicator.defaultProps = {
    className: '',
    active: false,
};

export default SuccessIndicator;
