import * as React from 'react';
import PropTypes from 'prop-types';
import tokens from '../../Tokens/tokens';
const { spacing } = tokens;

const BrandIconGas = ({ title, titleId, ...props }) => {
    const sizeArray = Object.entries(spacing.icons);
    const sizeOptions = new Map(sizeArray);
    const displaySize = sizeOptions.get(props.size);
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 48 48'
            fill={props.color && displayColor}
            aria-hidden={!title}
            role={title ? 'img' : null}
            width={props.size && displaySize}
            height={props.size && displaySize}
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <defs>
                <style>{'.Gas_svg__cls-1{fill:#002664}'}</style>
            </defs>
            <path
                className='Gas_svg__cls-1'
                d='M35 48H7a1 1 0 010-2h28a1 1 0 010 2zm9.91-16.27v-3.38c-1-8.11-9-12.91-9.38-13.13a1 1 0 00-1.5 1c.32 1.8 1.27 7.25-1.3 8.58a4.12 4.12 0 01-3.54-.4 1.75 1.75 0 01-.55-2c.81-3.61 1.61-10.8-.54-14.83C26 3.75 17.73.3 17.38.16a1 1 0 00-1.15.28 1 1 0 00-.07 1.18c3.26 5.14-.56 9.94-3.72 13.09a26.53 26.53 0 01-2.24 1.94C7 19.23 3 22.45 3 30.08a16.68 16.68 0 007 13.84v-2.58a14.76 14.76 0 01-5-11.26c0-6.67 3.28-9.32 6.45-11.87a31.69 31.69 0 002.41-2.08c1.84-1.84 7-7 5.18-13 2.68 1.35 6.23 3.46 7.28 5.42 1.63 3.05 1.32 9.11.35 13.44A3.75 3.75 0 0028 26a6.1 6.1 0 005.68.6c2.78-1.44 3-5.06 2.69-8.35 2.5 2 6.68 6.19 6.68 11.82C43 34.11 39.68 42 32 42v2a13.71 13.71 0 0012.91-12.27z'
            />
            <path
                className='Gas_svg__cls-1'
                d='M30 44h-2v-4.75A1.13 1.13 0 0027 38H15.06A1.18 1.18 0 0014 39.25V44h-2v-4.75A3.16 3.16 0 0115.06 36H27a3.1 3.1 0 013 3.25z'
            />
            <path d='M21 39.08A5 5 0 0016 44h10a5 5 0 00-5-4.92z' fill='#d7153a' />
        </svg>
    );
};

BrandIconGas.propTypes = {
    title: PropTypes.string,
    titleId: PropTypes.string,
    size: PropTypes.oneOf(['lg', 'xl', 'xxl']),
};
BrandIconGas.defaultProps = {
    size: 'xl',
    focusable: false,
};
export default BrandIconGas;
