import styled, { css } from 'styled-components/macro';
import { getBreakpoint, getSpacing, pxToRem } from '../../utils';
import Tokens from '../../Tokens/tokens';

const { colors, font } = Tokens;

export const ProgressStepperList = styled.ol`
    display: flex;
    flex-flow: row nowrap;
    padding-inline-start: 0;
    align-content: flex-start;
    margin-block-end: 0;
    margin-block-start: 0;
`;

export const ProgressStepperStep = styled.li `
    display: flex;
    flex-flow: column wrap;
    position: relative;
    width: auto;
    flex: 16%;
    text-align: center;
    padding: 0;
    margin-bottom: 0;

    ${getBreakpoint.md`
        align-content: center;
        padding: 0 12px;
        align-items: center;
    `}

    &:after {
        content: '';
        width: 100%;
        height: 3px;
        position: absolute;
        right: 0;
        top: 14px;
        ${getBreakpoint.md`
            right: -50%;
        `}
    }

    /* previous positions */
    ${props => props.status === 'completed' && css`
        font-weight: ${font.weight.book};
        color: ${colors.brand.snswPrimaryBlue};
        &:after {
            background-color: ${colors.brand.snswPrimaryBlue};
        }
        div {
            background-color: ${colors.brand.snswPrimaryBlue};
            border: 2px solid ${colors.brand.snswPrimaryBlue};
        }
    `}

    /* next positions */
    ${props => props.status === 'todo' && css`
        font-weight: ${font.weight.book};
        color: ${colors.grey.shuttle};
        &:after {
            background-color: ${colors.grey.shuttle};
        }
        div {
            background-color: ${colors.white};
        }
    `}

    /* active position */
    ${props => props.status === 'current' && css`
        background-color: transparent;
        font-weight: ${font.weight.bold};
        color: ${colors.brand.snswPrimaryBlue};

        div {
            font-size: ${pxToRem(18)};
            padding-top: 0;
            background: ${colors.brand.snswPrimaryBlue};
            border: 2px solid ${colors.brand.snswPrimaryBlue};
            color: ${colors.white};
        }
        &:after {
            background-color: ${colors.grey.shuttle};
        }
    `}

    &:last-child {
        &:after {
            display: none;
        }
    }
`;

export const StepPosition = styled.div`
    font-size: ${pxToRem(14)};
    border-radius: 50%;
    width: ${getSpacing('lg')};
    height: ${getSpacing('lg')};
    padding: 3px;
    text-align: center;
    background: ${colors.white};
    border: 2px solid ${colors.grey.shuttle};
    color: ${colors.grey.shuttle};
    z-index: 1;
    svg {
        margin-top: ${pxToRem(3)};
        background-color: ${colors.brand.snswPrimaryBlue};
    }
`;

export const MobileStepLabel = styled.span`
    font-size: ${font.size.base};
    font-weight: ${font.weight.bold};
    color: ${colors.brand.snswPrimaryBlue};
    margin-top: ${getSpacing('sm')};
    display: block;

    ${getBreakpoint.md`
        display: none;
    `}
`;

export const StepLabel = styled.span`
    margin-top: ${getSpacing('md')};
    display: none;

    ${getBreakpoint.md`
        display: inherit;
        font-size: ${font.size.base};
    `}
`;

