import React from 'react';
import styled from 'styled-components/macro';
import { color, layout } from 'styled-system';
import tokens from '../../Tokens/tokens';

const { colors } = tokens;

// eslint-disable-next-line react/prop-types
const EducationSvg = ({ className, color, width, height }) => (
    <svg className={ className } viewBox='0 0 44 44' fill={ colors.legacy[color] || color } width={ width } height={ height } xmlns='http://www.w3.org/2000/svg' focusable='false' aria-hidden='true'>
        <path d='M21.8 30.1c-3.8 0-8.9-1.3-11.5-3.8-.2-.2-.3-.5-.3-.7V20c0-.6.4-1 1-1s1 .4 1 1v5.1c2.1 1.7 6.2 2.9 9.8 2.9 3.3 0 6.5-1.1 8.7-2.9v-8.2c0-.6.4-1 1-1s1 .4 1 1v8.7c0 .3-.1.5-.3.7-2.5 2.3-6.4 3.8-10.4 3.8z'/>
        <g>
            <path d='M34.9 29c-.6 0-1-.4-1-1V15.6l-11.3-3c-.5-.1-.9-.7-.7-1.2.1-.5.7-.9 1.2-.7l12.1 3.2c.4.1.7.5.7 1V28c0 .6-.4 1-1 1z'/>
            <circle cx='34.9' cy='31' r='4'/>
            <path d='M36.9 40h-4c-.3 0-.6-.1-.8-.4-.2-.2-.3-.5-.2-.8l1-5 2 .4-.7 3.8h1.6l-.8-3.8 2-.4 1 5c.1.3 0 .6-.2.8-.3.3-.6.4-.9.4z'/>
        </g>
        <path d='M37.9 13.6l2-.5v-.9L22 6.1 3.9 12.2v1L22 18.9l12.3-4.3c.5-.2 1.1.1 1.3.6s-.1 1.1-.6 1.3l-12.7 4.4c-.1.1-.2.1-.3.1h-.3L2.6 14.8c-.4-.1-.7-.5-.7-1v-2.4c0-.4.3-.8.7-.9L21.7 4c.2-.1.4-.1.6 0l18.9 6.5c.4.1.7.5.7.9v2.4c0 .5-.3.9-.8 1l-3.2.8v-2z'/>
    </svg>
);

export const Education = styled(EducationSvg)`
  ${color}
  ${layout}
`;

Education.defaultProps = {
    color: `${colors.brand.snswSecondaryBlue}`,
    height: 44,
    width: 44,
};

export default Education;
