import * as React from 'react';
import PropTypes from 'prop-types';
import tokens from '../../Tokens/tokens';
const { spacing } = tokens;

const BrandIconRebatesSavings = ({ title, titleId, ...props }) => {
    const sizeArray = Object.entries(spacing.icons);
    const sizeOptions = new Map(sizeArray);
    const displaySize = sizeOptions.get(props.size);
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 48 48'
            fill={props.color && displayColor}
            aria-hidden={!title}
            role={title ? 'img' : null}
            width={props.size && displaySize}
            height={props.size && displaySize}
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <defs>
                <style>{'.RebatesSavings_svg__cls-1{fill:#002664}'}</style>
            </defs>
            <path
                className='RebatesSavings_svg__cls-1'
                d='M19.32 38.65a19.33 19.33 0 1119.32-19.33 19.35 19.35 0 01-19.32 19.33zm0-36.65a17.33 17.33 0 1017.32 17.32A17.34 17.34 0 0019.32 2z'
            />
            <path
                className='RebatesSavings_svg__cls-1'
                d='M19.32 38.65a19.33 19.33 0 1119.32-19.33 19.35 19.35 0 01-19.32 19.33zm0-36.65a17.33 17.33 0 1017.32 17.32A17.34 17.34 0 0019.32 2z'
            />
            <path
                className='RebatesSavings_svg__cls-1'
                d='M19.32 38.65a19.33 19.33 0 1119.32-19.33 19.35 19.35 0 01-19.32 19.33zm0-36.65a17.33 17.33 0 1017.32 17.32A17.34 17.34 0 0019.32 2z'
            />
            <path
                className='RebatesSavings_svg__cls-1'
                d='M18.4 26.89a11.66 11.66 0 01-5.47-2.2l1.8-2.64a9.27 9.27 0 003.78 1.88v-3.68c-3.34-.9-5-2.15-5-4.91 0-2.55 2-4.24 4.87-4.54V9.42h2.41v1.41a9.6 9.6 0 014.31 1.69l-1.56 2.71a9.78 9.78 0 00-2.87-1.37v3.52c3.52.95 5 2.36 5 4.95v.05c0 2.59-2 4.25-4.91 4.56v2.29H18.4zm.11-10.09v-3.15c-1.13.14-1.6.7-1.6 1.44v.05c0 .74.33 1.2 1.6 1.66zm2.18 4V24c1.09-.16 1.64-.64 1.64-1.48 0-.77-.39-1.25-1.64-1.72zM41.89 48a1 1 0 00.7-.29l5.12-5.12a1 1 0 00.29-.71 1 1 0 00-.3-.71L37.16 31c-.37.56-.76 1.11-1.18 1.64l9.6 9.31-3.7 3.69-9.51-9.41c-.53.42-1.08.81-1.65 1.18l10.46 10.3a1 1 0 00.71.29z'
            />
            <path d='M45.58 41.9l-2.14-2.08a4.18 4.18 0 00-3.76 3.6l2.2 2.17z' fill='#d7153a' />
        </svg>
    );
};

BrandIconRebatesSavings.propTypes = {
    title: PropTypes.string,
    titleId: PropTypes.string,
    size: PropTypes.oneOf(['lg', 'xl', 'xxl']),
};
BrandIconRebatesSavings.defaultProps = {
    size: 'xl',
    focusable: false,
};
export default BrandIconRebatesSavings;
