import React from 'react';
import PropTypes from 'prop-types';
import { StyledTableBody } from './Table.styled';

const TableBody = ({ children, ...rest }) => (
    <StyledTableBody { ...rest }>
        {children}
    </StyledTableBody>
);

TableBody.propTypes = {
    children: PropTypes.node.isRequired
};

export default TableBody;
