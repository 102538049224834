import * as React from 'react';
import PropTypes from 'prop-types';
import tokens from '../../Tokens/tokens';
const { spacing } = tokens;

const BrandIconDesktop = ({ title, titleId, ...props }) => {
    const sizeArray = Object.entries(spacing.icons);
    const sizeOptions = new Map(sizeArray);
    const displaySize = sizeOptions.get(props.size);
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 48 48'
            fill={props.color && displayColor}
            aria-hidden={!title}
            role={title ? 'img' : null}
            width={props.size && displaySize}
            height={props.size && displaySize}
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <defs>
                <style>{'.Desktop_svg__cls-2{fill:#002664}'}</style>
            </defs>
            <path d='M24 35a5 5 0 115-5 5 5 0 01-5 5z' fill='#d7153a' />
            <path
                className='Desktop_svg__cls-2'
                d='M43 4H5a1 1 0 00-1 1v25a1 1 0 001 1h12v-2H6V6h36v23H31v2h12a1 1 0 001-1V5a1 1 0 00-1-1z'
            />
            <path
                className='Desktop_svg__cls-2'
                d='M43 0H4.87A5 5 0 000 5v29a5 5 0 005 5h15v7H10a1 1 0 000 2h28a1 1 0 000-2H28v-7h15a5 5 0 005-5V5a5 5 0 00-5-5zM26 46h-4v-7h4zm20-12a3 3 0 01-3 3H5a3 3 0 01-3-3V5a3 3 0 013-3h38a3 3 0 013 3z'
            />
        </svg>
    );
};

BrandIconDesktop.propTypes = {
    title: PropTypes.string,
    titleId: PropTypes.string,
    size: PropTypes.oneOf(['lg', 'xl', 'xxl']),
};
BrandIconDesktop.defaultProps = {
    size: 'xl',
    focusable: false,
};
export default BrandIconDesktop;
