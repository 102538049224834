import * as React from 'react';
import PropTypes from 'prop-types';
import tokens from '../../Tokens/tokens';
const { spacing } = tokens;

const BrandIconSettings = ({ title, titleId, ...props }) => {
    const sizeArray = Object.entries(spacing.icons);
    const sizeOptions = new Map(sizeArray);
    const displaySize = sizeOptions.get(props.size);
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 48 48'
            fill={props.color && displayColor}
            aria-hidden={!title}
            role={title ? 'img' : null}
            width={props.size && displaySize}
            height={props.size && displaySize}
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <defs>
                <style>{'.Settings_svg__cls-1{fill:#002664}'}</style>
            </defs>
            <path
                className='Settings_svg__cls-1'
                d='M27.28 48h-6.56a1 1 0 01-1-.83l-.94-5.55a18.84 18.84 0 01-3.6-1.49l-4.54 3.27a1 1 0 01-1.29-.11l-4.64-4.64a1 1 0 01-.11-1.29l3.27-4.55a18.58 18.58 0 01-1.49-3.6l-5.55-.94a1 1 0 01-.83-1v-6.55a1 1 0 01.83-1l5.55-.94a18.25 18.25 0 011.47-3.57L4.6 10.64a1 1 0 01.11-1.29l4.64-4.64a1 1 0 011.29-.11l4.58 3.26a18 18 0 013.6-1.49l.91-5.53a1 1 0 011-.84h6.56a1 1 0 011 .84l.91 5.53a18.55 18.55 0 013.6 1.48l4.56-3.25a1 1 0 011.29.11l4.64 4.64a1 1 0 01.11 1.29l-3.25 4.58a18.19 18.19 0 011.48 3.6l5.53.91a1 1 0 01.84 1v6.56a1 1 0 01-.84 1l-5.53.91a18.27 18.27 0 01-1.5 3.63l3.27 4.55a1 1 0 01-.11 1.29l-4.64 4.64a1 1 0 01-1.29.11l-4.55-3.27a18.58 18.58 0 01-3.6 1.49l-.94 5.55a1 1 0 01-.99.81zm-5.72-2h4.88l.91-5.34a1 1 0 01.73-.8 16.42 16.42 0 004.28-1.78 1 1 0 011.09.05l4.39 3.15 3.44-3.44-3.15-4.39a1 1 0 01-.05-1.09 16.3 16.3 0 001.79-4.31 1 1 0 01.81-.74l5.32-.88v-4.86l-5.32-.88a1 1 0 01-.81-.74 16.33 16.33 0 00-1.77-4.28 1 1 0 010-1.09l3.13-4.41-3.4-3.45-4.41 3.13a1 1 0 01-1.09.05 16.39 16.39 0 00-4.28-1.77 1 1 0 01-.74-.8L26.43 2h-4.86l-.88 5.33a1 1 0 01-.74.8 16.39 16.39 0 00-4.28 1.77 1 1 0 01-1.09-.05l-4.41-3.13-3.45 3.45 3.13 4.41a1 1 0 01.05 1.09 16.17 16.17 0 00-1.76 4.25 1 1 0 01-.8.73L2 21.56v4.88l5.34.91a1 1 0 01.8.73 16.42 16.42 0 001.78 4.28 1 1 0 01-.05 1.09l-3.15 4.39 3.44 3.44 4.39-3.15a1 1 0 011.09-.05 16.56 16.56 0 004.28 1.78 1 1 0 01.73.8z'
            />
            <path
                className='Settings_svg__cls-1'
                d='M24 35a11 11 0 01-10.58-14l1.92.55a9 9 0 105.27-5.87l-.76-1.85A10.88 10.88 0 0124 13a11 11 0 010 22z'
            />
            <path d='M29 23.8a5 5 0 11-5-5 5 5 0 015 5z' fill='#d7153a' />
        </svg>
    );
};

BrandIconSettings.propTypes = {
    title: PropTypes.string,
    titleId: PropTypes.string,
    size: PropTypes.oneOf(['lg', 'xl', 'xxl']),
};
BrandIconSettings.defaultProps = {
    size: 'xl',
    focusable: false,
};
export default BrandIconSettings;
