import BrandIconActiveKids from './ActiveKids';
import BrandIconAddLicence from './AddLicence';
import BrandIconBirths from './Births';
import BrandIconBirthsDeathsMarriages from './BirthsDeathsMarriages';
import BrandIconBookStudy from './BookStudy';
import BrandIconBusiness from './Business';
import BrandIconBusinessGroup from './BusinessGroup';
import BrandIconCafeRestaurantSmallBar from './CafeRestaurantSmallBar';
import BrandIconCalendar from './Calendar';
import BrandIconCharity from './Charity';
import BrandIconCheckRego from './CheckRego';
import BrandIconChildren from './Children';
import BrandIconClothingRetail from './ClothingRetail';
import BrandIconCodeCheckIn from './CodeCheckIn';
import BrandIconCommunity from './Community';
import BrandIconCookingFood from './CookingFood';
import BrandIconCovidTest from './CovidTest';
import BrandIconCrackedScreen from './CrackedScreen';
import BrandIconCreativeKids from './CreativeKids';
import BrandIconDesktop from './Desktop';
import BrandIconDontHandlePhone from './DontHandlePhone';
import BrandIconDrivingTransport from './DrivingTransport';
import BrandIconEducation from './Education';
import BrandIconElectricalProvider from './ElectricalProvider';
import BrandIconElectricity from './Electricity';
import BrandIconEnvironmentalCare from './EnvironmentalCare';
import BrandIconFast from './Fast';
import BrandIconFeaturedServices from './FeaturedServices';
import BrandIconFines from './Fines';
import BrandIconGas from './Gas';
import BrandIconHealth from './Health';
import BrandIconIdentity from './Identity';
import BrandIconInformation from './Information';
import BrandIconLegalPolice from './LegalPolice';
import BrandIconLocation from './Location';
import BrandIconLocked from './Locked';
import BrandIconMscBuses from './MscBuses';
import BrandIconManagingRisks from './ManagingRisks';
import BrandIconMaritime from './Maritime';
import BrandIconMarriages from './Marriages';
import BrandIconMobile from './Mobile';
import BrandIconMotorbike from './Motorbike';
import BrandIconNoBattery from './NoBattery';
import BrandIconPartnerships from './Partnerships';
import BrandIconPayUseCard from './PayUseCard';
import BrandIconPersonalDataSecurity from './PersonalDataSecurity';
import BrandIconPetrol from './Petrol';
import BrandIconPhotos from './Photos';
import BrandIconPollVote from './PollVote';
import BrandIconPrintingServices from './PrintingServices';
import BrandIconProperty from './Property';
import BrandIconQrCard from './QrCard';
import BrandIconQuestion from './Question';
import BrandIconRebatesSavings from './RebatesSavings';
import BrandIconRefreshPhone from './RefreshPhone';
import BrandIconReporting from './Reporting';
import BrandIconReportsDocs from './ReportsDocs';
import BrandIconRoadFreight from './RoadFreight';
import BrandIconRoadsTollReliefCtp from './RoadsTollReliefCtp';
import BrandIconSafeWorkingEnvironment from './SafeWorkingEnvironment';
import BrandIconSaveTime from './SaveTime';
import BrandIconSchool from './School';
import BrandIconServiceCentre from './ServiceCentre';
import BrandIconSettings from './Settings';
import BrandIconSmallBusiness from './SmallBusiness';
import BrandIconSport from './Sport';
import BrandIconSupportCare from './SupportCare';
import BrandIconSupportTechnical from './SupportTechnical';
import BrandIconSurvey from './Survey';
import BrandIconTermsConditions from './TermsConditions';
import BrandIconThumbsUp from './ThumbsUp';
import BrandIconTradies from './Tradies';
import BrandIconTraining from './Training';
import BrandIconTruck from './Truck';
import BrandIconUseYourDdl from './UseYourDdl';
import BrandIconVerifyDriversLicence from './VerifyDriversLicence';
import BrandIconVirus from './Virus';
import BrandIconWwcc from './Wwcc';
import BrandIconWorkerCapability from './WorkerCapability';
import BrandIconWorkersCompensation from './WorkersCompensation';
export {
    BrandIconActiveKids,
    BrandIconAddLicence,
    BrandIconBirths,
    BrandIconBirthsDeathsMarriages,
    BrandIconBookStudy,
    BrandIconBusiness,
    BrandIconBusinessGroup,
    BrandIconCafeRestaurantSmallBar,
    BrandIconCalendar,
    BrandIconCharity,
    BrandIconCheckRego,
    BrandIconChildren,
    BrandIconClothingRetail,
    BrandIconCodeCheckIn,
    BrandIconCommunity,
    BrandIconCookingFood,
    BrandIconCovidTest,
    BrandIconCrackedScreen,
    BrandIconCreativeKids,
    BrandIconDesktop,
    BrandIconDontHandlePhone,
    BrandIconDrivingTransport,
    BrandIconEducation,
    BrandIconElectricalProvider,
    BrandIconElectricity,
    BrandIconEnvironmentalCare,
    BrandIconFast,
    BrandIconFeaturedServices,
    BrandIconFines,
    BrandIconGas,
    BrandIconHealth,
    BrandIconIdentity,
    BrandIconInformation,
    BrandIconLegalPolice,
    BrandIconLocation,
    BrandIconLocked,
    BrandIconMscBuses,
    BrandIconManagingRisks,
    BrandIconMaritime,
    BrandIconMarriages,
    BrandIconMobile,
    BrandIconMotorbike,
    BrandIconNoBattery,
    BrandIconPartnerships,
    BrandIconPayUseCard,
    BrandIconPersonalDataSecurity,
    BrandIconPetrol,
    BrandIconPhotos,
    BrandIconPollVote,
    BrandIconPrintingServices,
    BrandIconProperty,
    BrandIconQrCard,
    BrandIconQuestion,
    BrandIconRebatesSavings,
    BrandIconRefreshPhone,
    BrandIconReporting,
    BrandIconReportsDocs,
    BrandIconRoadFreight,
    BrandIconRoadsTollReliefCtp,
    BrandIconSafeWorkingEnvironment,
    BrandIconSaveTime,
    BrandIconSchool,
    BrandIconServiceCentre,
    BrandIconSettings,
    BrandIconSmallBusiness,
    BrandIconSport,
    BrandIconSupportCare,
    BrandIconSupportTechnical,
    BrandIconSurvey,
    BrandIconTermsConditions,
    BrandIconThumbsUp,
    BrandIconTradies,
    BrandIconTraining,
    BrandIconTruck,
    BrandIconUseYourDdl,
    BrandIconVerifyDriversLicence,
    BrandIconVirus,
    BrandIconWwcc,
    BrandIconWorkerCapability,
    BrandIconWorkersCompensation,
};
