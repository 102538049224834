import React from 'react';
import PropTypes from 'prop-types';
import {
    AccordionBody,
    AccordionHeader,
    AccordionSubTitle,
    AccordionTileContainer,
    AccordionStyled,
    ToggleIcon,
    AccordionSummary,
} from './Accordion.styled';
import Icons from '../Icons/obsolete/index';
import { Label } from './Label';
import { AccordionWhite } from './Accordion.white.styled';
import { AccordionBlue } from './Accordion.blue.styled';
import { trackMe } from '../Components/ComponentAnalytics/componentAnalytics';

export default class Accordion extends React.Component {

    constructor() {
        super();
        trackMe('Accordion');
    };

    getTiles = () => {
        const { children, indexStart, theme } = this.props;
        const tiles = React.Children.toArray(children);

        if (!this.isStepAccordion) {
            return tiles;
        }

        tiles.forEach(child => {
            if (child.type.displayName !== 'Accordion.Tile') {
                console.error('ERROR: Step Accordion supports only children of type Accordion.Tile!');
                return null;
            }
        });

        return tiles.map((child, index) => React.cloneElement(
            child, { label: indexStart + (index + 1), separator: false, theme }
        ));
    };

    get isStepAccordion() { return this.props.type === 'step'; }

    get theme() {
        const isWhiteTheme = this.props.theme === 'white' && this.isStepAccordion;
        return isWhiteTheme ? AccordionWhite : AccordionBlue;
    }

    render() {
        const AccordionTheme = this.theme;

        return (
            <AccordionTheme stepAccordion={ this.isStepAccordion }>
                <AccordionStyled border={ 'classic' === this.props.type }>
                    {this.getTiles()}
                </AccordionStyled>
            </AccordionTheme>
        );
    }
};

Accordion.defaultProps = {
    type: 'classic',
    indexStart: 0,
    theme: 'blue',
};

Accordion.propTypes = {
    type: PropTypes.oneOf(['classic', 'step', 'lite']),
    indexStart: PropTypes.number,
    theme: PropTypes.oneOf(['blue', 'white']),
};

Accordion.Tile = ({ label, title, summary, separator, expanded = false, children, theme }) => (
    <AccordionTileContainer
        separator={ separator }
        stepAccordion={ !!label }
        theme={ theme }
    >
        <div className='progress'><div className='top' /><div className='bottom' /></div>
        <AccordionTile
            label={ label }
            title={ title }
            summary={ summary }
            expanded={ expanded }
            theme={ theme }
        >
            {children}
        </AccordionTile>
    </AccordionTileContainer>
);

Accordion.Tile.displayName = 'Accordion.Tile';

Accordion.Tile.propTypes = {
    /** The number next to the tile for step accordions. */
    label: PropTypes.number,
    /** The title of the accordion tile. Can be simple string or node. */
    title: PropTypes.node,
    /** The summary of the accordion tile. Can be string. */
    summary: PropTypes.string,
    /** Weather a separator should be displayed. The separator is just a horizontal line. */
    separator: PropTypes.bool,
    /** If the accordion tile should be expanded by default. */
    expanded: PropTypes.bool,
    /** The actual body of the accordion tile. Can be simple string or node.  */
    children: PropTypes.node,
};

export class AccordionTile extends React.Component {
    state = {
        bodyIsVisible: this.props.expanded
    };

    toggleBodyVisibility = () => {
        this.setState({ bodyIsVisible: !this.state.bodyIsVisible });
    };

    onKeyDown = e => {
        if (e.keyCode === 32 || e.keyCode === 13) {
            this.toggleBodyVisibility();
        }
    };

    render() {
        const { label, title, summary, onClick, children } = this.props;
        const stepAccordionSeparator = !!label && label !== 1;
        return (
            <div>
                <AccordionHeader
                    role='button'
                    tabIndex='0'
                    aria-expanded={ this.state.bodyIsVisible }
                    onClick={ this.toggleBodyVisibility }
                    onKeyDown={ this.onKeyDown }
                    stepAccordionSeparator={ stepAccordionSeparator }
                >
                    {label && <Label
                        height='32px'
                        width='32px'
                    >
                        {label}
                    </Label>}
                    <AccordionSubTitle onClick={ onClick }>
                        {title}
                    </AccordionSubTitle>
                    <ToggleIcon rotateIt={ this.state.bodyIsVisible }>
                        <Icons.ChevronDown onClick={ onClick } />
                    </ToggleIcon>
                </AccordionHeader>
                {!this.state.bodyIsVisible && summary && <AccordionSummary>{summary}</AccordionSummary>}
                {this.state.bodyIsVisible && <AccordionBody>{children}</AccordionBody>}
            </div>
        );
    }
}

AccordionTile.propTypes = {
    label: PropTypes.number,
    title: PropTypes.node,
    summary: PropTypes.string,
    expanded: PropTypes.bool,
    children: PropTypes.node,
    onClick: PropTypes.func,
};
