import * as React from 'react';
import PropTypes from 'prop-types';
import tokens from '../../Tokens/tokens';
const { colors, spacing } = tokens;

const IconMobile = ({ title, titleId, ...props }) => {
    const sizeArray = Object.entries(spacing.icons);
    const colorArray = Object.entries(colors.icons);
    const sizeOptions = new Map(sizeArray);
    const displaySize = sizeOptions.get(props.size);
    const colorOptions = new Map(colorArray);
    const displayColor = colorOptions.get(props.color);
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 16 16'
            fill={props.color && displayColor}
            aria-hidden={!title}
            role={title ? 'img' : null}
            width={props.size && displaySize}
            height={props.size && displaySize}
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <path d='M12 16H4a1 1 0 01-1-1V1a1 1 0 011-1h8a1 1 0 011 1v14a1 1 0 01-1 1zm-7-2h6V2H5z' />
            <path d='M8 13a1.05 1.05 0 01-.71-.29 1.15 1.15 0 01-.21-.33A1 1 0 017 12a1 1 0 01.29-.71A1 1 0 019 12a1 1 0 01-.08.38 1.15 1.15 0 01-.21.33l-.16.12a.56.56 0 01-.17.09.6.6 0 01-.19.06z' />
        </svg>
    );
};

IconMobile.propTypes = {
    title: PropTypes.string,
    titleId: PropTypes.string,
    size: PropTypes.oneOf(['sm', 'md']),
    color: PropTypes.oneOf(['primaryBlue', 'secondaryBlue', 'darkGrey', 'white']),
};
IconMobile.defaultProps = {
    size: 'sm',
    color: 'darkGrey',
    focusable: false,
};
export default IconMobile;
