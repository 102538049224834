import * as React from 'react';
import PropTypes from 'prop-types';
import tokens from '../../Tokens/tokens';
const { colors, spacing } = tokens;

const IconBusiness = ({ title, titleId, ...props }) => {
    const sizeArray = Object.entries(spacing.icons);
    const colorArray = Object.entries(colors.icons);
    const sizeOptions = new Map(sizeArray);
    const displaySize = sizeOptions.get(props.size);
    const colorOptions = new Map(colorArray);
    const displayColor = colorOptions.get(props.color);
    return (
        <svg
            viewBox='0 0 16 16'
            xmlns='http://www.w3.org/2000/svg'
            fill={props.color && displayColor}
            aria-hidden={!title}
            role={title ? 'img' : null}
            width={props.size && displaySize}
            height={props.size && displaySize}
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <path d='M11,0.5 C12.1045695,0.5 13,1.3954305 13,2.5 L13,2.5 L13,4.5 L13,4.5 L14,4.5 C15.1045695,4.5 16,5.3954305 16,6.5 L16,6.5 L16,13.5 C16,14.6045695 15.1045695,15.5 14,15.5 L14,15.5 L2,15.5 C0.8954305,15.5 0,14.6045695 0,13.5 L0,13.5 L0,6.5 C0,5.3954305 0.8954305,4.5 2,4.5 L2,4.5 L3,4.5 L3,2.5 C3,1.3954305 3.8954305,0.5 5,0.5 L5,0.5 Z M14,6.5 L2,6.5 L2,13.5 L14,13.5 L14,6.5 Z M11,2.5 L5,2.5 L5,4.5 L11,4.5 L11,2.5 Z' />
        </svg>
    );
};

IconBusiness.propTypes = {
    title: PropTypes.string,
    titleId: PropTypes.string,
    size: PropTypes.oneOf(['sm', 'md']),
    color: PropTypes.oneOf(['primaryBlue', 'secondaryBlue', 'darkGrey', 'white']),
};
IconBusiness.defaultProps = {
    size: 'sm',
    color: 'darkGrey',
    focusable: false,
};
export default IconBusiness;
