import React from 'react';
import styled from 'styled-components/macro';
import { color, layout } from 'styled-system';
import tokens from '../../Tokens/tokens';

const { colors } = tokens;

// eslint-disable-next-line react/prop-types
const LegalSvg = ({ className, color, width, height }) => (
    <svg className={ className } viewBox='0 0 44 44' fill={ colors.legacy[color] || color } width={ width } height={ height } xmlns='http://www.w3.org/2000/svg' focusable='false' aria-hidden='true'>
        <g>
            <path d='m 34.7,25.3 c -2.1,0 -3.9,-1 -4.2,-2.3 H 39 c -0.5,1.3 -2.3,2.3 -4.3,2.3 z M 34,14.7 c 0.2,-0.4 0.4,-0.7 0.6,-1.1 1.6,2.7 3.1,5.6 3.9,7.4 h -7.8 c 0.5,-1.1 1.5,-3.1 3.3,-6.3 z m 1.5,-3.5 c -0.2,-0.3 -0.5,-0.5 -0.9,-0.5 -0.3,0 -0.7,0.2 -0.9,0.5 0,0 -0.6,1.1 -1.5,2.5 -4,6.9 -4,8.3 -4,8.8 0,2.7 2.8,4.8 6.3,4.8 3.5,0 6.3,-2.1 6.3,-4.8 0.2,-2.3 -4.4,-9.8 -5.3,-11.3 z' />
            <path d='M 9.3,25.3 C 8.1,25.3 7,25 6.1,24.4 5.6,24 5.2,23.5 5.1,23 h 8.5 c -0.4,1.3 -2.2,2.3 -4.3,2.3 z m 0,-11.7 c 1.6,2.7 3.2,5.6 3.9,7.4 H 5.4 c 0.8,-1.8 2.3,-4.6 3.9,-7.4 z m 0.9,-2.4 C 9.8,10.6 8.9,10.6 8.5,11.2 7.6,12.7 3,20.2 3,22.5 c 0,1.3 0.7,2.6 2,3.5 1.2,0.8 2.7,1.3 4.3,1.3 3.5,0 6.3,-2.1 6.3,-4.8 0.1,-2.3 -4.5,-9.8 -5.4,-11.3 z' />
        </g>
        <path d='M 35.5,12.5 C 34.7,12.5 33.8,12.4 32.8,12 29.5,10.7 26,10 22,10 17.9,10 14.4,10.6 11.2,12 6.8,13.6 4.5,11.1 4.1,10.4 3.8,9.8 4,9.2 4.5,9 5,8.8 5.5,8.9 5.8,9.4 5.9,9.5 7.3,11.3 10.4,10.1 13.9,8.7 17.7,8 22,8 c 4.3,0 8.1,0.7 11.6,2.1 3.2,1.1 4.6,-0.7 4.6,-0.8 0.3,-0.5 0.8,-0.7 1.3,-0.4 0.5,0.3 0.7,0.9 0.4,1.4 -0.3,0.6 -1.8,2.2 -4.4,2.2 z M 22,35 c -2.9,0 -6.6,0.9 -8.9,3 H 30.8 C 28.5,35.9 24.9,35 22,35 Z m -11,5 c -0.4,0 -0.7,-0.2 -0.9,-0.5 -0.2,-0.3 -0.2,-0.7 0,-1 2.4,-3.8 7.7,-5.5 11.9,-5.5 4.2,0 9.5,1.7 11.8,5.4 0.2,0.3 0.2,0.7 0,1 C 33.6,39.7 33.3,40 33,40 Z m 11,-9 c -0.6,0 -1,-0.4 -1,-1 V 9.4 c 0,-0.6 0.4,-1 1,-1 0.6,0 1,0.4 1,1 V 30 c 0,0.6 -0.5,1 -1,1 z' />
        <path d='m 22,3 c -2.2,0 -4,1.8 -4,4 0,1 0.4,1.9 1,2.7 0.2,0.2 0.5,0.3 0.7,0.3 h 4.4 C 24.4,10 24.6,9.9 24.8,9.7 25.6,8.9 26,8 26,7 26,4.8 24.2,3 22,3 Z' />
    </svg>
);

export const Legal = styled(LegalSvg)`
  ${color}
  ${layout}
`;

Legal.defaultProps = {
    color: `${colors.brand.snswSecondaryBlue}`,
    height: 44,
    width: 44,
};

export default Legal;
