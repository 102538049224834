import styled, { css } from 'styled-components/macro';
import { pxToRem, getSpacing } from '../../utils';
import tokens from '../../Tokens/tokens';

const { colors } = tokens;

const StyledTableContainer = styled.div`
    width: 100%;
    margin-bottom: ${getSpacing('lg')};

    h3 {
        margin: ${getSpacing(['none', 'none', 'xs', 'none'])};
    }
    p {
        margin: ${getSpacing(['none', 'none', 'sm', 'none'])};
    }
    & > div:first-of-type {
        overflow-x: scroll;
    }
`;

const StyledTable = styled.table`
    width: 100%;
    border-collapse: collapse;

    ${props => props.striped && css`
        tbody {
            tr {
                &:nth-child(even) {
                    background-color: ${colors.grey.iceBlue};
                }
            }
        }
    `};
`;

const StyledTableHead = styled.thead`
    background-color: ${colors.grey.geyser};
`;

const StyledTableBody = styled.tbody``;

const StyledTableRow = styled.tr`
    border-top: 1px solid ${colors.grey.geyser};
    border-bottom: 1px solid ${colors.grey.geyser};
`;

const StyledTableHeader = styled.th`
    padding: ${pxToRem(16)};

    ${props => props.textAlign && css`
        text-align: ${props.textAlign};
    `};
`;

const StyledTableCell = styled.td`
    padding: ${pxToRem(16)};

    ${props => props.textAlign && css`
        text-align: ${props.textAlign};
    `};
`;

export {
    StyledTableContainer,
    StyledTable,
    StyledTableHead,
    StyledTableBody,
    StyledTableRow,
    StyledTableHeader,
    StyledTableCell
};
