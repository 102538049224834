import 'whatwg-fetch';
import packageJson from '../../../package.json';

const runningInBrowser = (typeof window !== 'undefined');
const libraryVersion = packageJson.version;
var powerBIUniqueCounter = 0;
var trackMeBuffer = [];
var unique;
var isLocalhost = false;

if (runningInBrowser) {
    isLocalhost = (window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1');

    if (isLocalhost) {
        setInterval(() => {
            if (isLocalhost) {
                flushBuffer();
            }
        }, 10000);

        window.addEventListener('unload', () => {
            flushBuffer();
        });
    }

    // Get or create a unique tracking id which should stay with the user for life
    unique = window.localStorage.getItem('unique');
    if (!unique) { unique = localStorage.unique; }; // For Android devices
    if (!unique) {
        const newUnique = Math.floor(Math.random() * 100000000).toString();
        window.localStorage.setItem('unique', newUnique);
        localStorage.unique = newUnique; // For Android devices
    }
}

export const trackMe = componentName => {
    if (runningInBrowser) {
        const date = new Date().toISOString();
        const pageTitle = document.title;
        // Don't track components hosted inside Storybook
        if (!pageTitle.includes('Storybook')) {
            trackMeBuffer.push({
                powerBI: `${powerBIUniqueCounter}`,
                componentName: componentName,
                //'2020-07-28T01:30:40.156Z', // keep this for now in case Power BI changes again:  date.toLocaleString(),
                dateTime: date,
                date: date.substring(0, 10), //date.toLocaleDateString(),
                // We need to add a unique number on to fool Power BI into counting these as unique records.
                // keep this for now in case Power BI changes again:  date.toLocaleTimeString()
                time: date.substring(11, 23) + powerBIUniqueCounter,
                libraryVersion: libraryVersion,
                pageTitle: pageTitle,
                url: window.location.href,
                unique: unique
            });
            powerBIUniqueCounter += 1;
        };
    }
};

const flushBuffer = () => {
    if (runningInBrowser) {
        if (isLocalhost) {
            if (trackMeBuffer.length > 0) {
                const url = 'https://api.powerbi.com/beta/1ef97a68-e8ab-44ed-a16d-b579fe2d7cd8/datasets/4c86167e-ab16-4819-b6cf-b53e191e945d/rows?key=KR5nDqw1igE8SOqGmQ6NZoQBLb2SqG738RGdY5F9UwObLy01w49SVgqodaXvG60%2BQ92aPBM%2Fs%2BH3ayEjB%2Fwe4Q%3D%3D';
                const options = {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json;charset=UTF-8'
                    },
                    body: JSON.stringify(trackMeBuffer)
                };
                window.fetch(url, options);
                trackMeBuffer = [];
            }
        }
    }
};
