import React from 'react';
import PropTypes from 'prop-types';
import { StyledTable } from './Table.styled';

const Table = ({ className, children, striped, ...rest }) => (
    <StyledTable
        className={ className }
        striped={ striped }
        { ...rest }
    >
        {children}
    </StyledTable>
);

Table.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node.isRequired,
    striped: PropTypes.bool
};

Table.defaultProps = {
    striped: false
};

export default Table;
