import styled from 'styled-components/macro';
import Field from '../Components/Form/Field.styled';

export const MainContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;

    background: white;
    max-width: 35rem;
    height: 9rem;
    border-radius: 6px;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.07);
    padding: 1.5rem;

    @media(max-width: 480px) {
      width: 100%;
      padding: 0.5rem;
    }

    input[type=number] {
      -webkit-appearance: textfield;
      -moz-appearance: textfield;
      appearance: textfield;
      display: inline;
    }
    input[type=number]::-webkit-inner-spin-button,
    input[type=number]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
`;

export const NumberInputContainer = styled.div`
    display: flex;
    align-items: center;

    input[type=number] {
      -webkit-appearance: textfield;
      -moz-appearance: textfield;
      appearance: textfield;
      display: inline;
    }
    input[type=number]::-webkit-inner-spin-button,
    ::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
`;

NumberInputContainer.Input = styled(Field.Input).attrs({ type: 'number' })`
  padding: 0 1rem;
  border-radius: 6px;
  border: solid 2px #646974;
  width: 37.5%;

  @media(max-width: 480px) {
    width: 50%;
  }

  :focus {
    outline: none;
    border-color: #929ea4;
  }
`;

NumberInputContainer.ButtonMinus = styled.div`
    width: 3rem;
    height: 3rem;
    padding: 0 1rem;
    border-radius: 4px;
    border: solid 1px #b1bec4;
    background-color: #eaedf4;
    margin-left: 0.75rem;
    margin-top: 0.25rem;
    text-align: center;
    display: inline-block;
    line-height: 3rem;
    font-size: 1.5rem;
    color: #9aa8af;
    :hover {
      cursor: pointer;
    }
`;

NumberInputContainer.ButtonAdd = styled.div`
    width: 3rem;
    height: 3rem;
    padding: 0 1rem;
    border-radius: 4px;
    background-color: #2e5299;
    margin: 0.25rem 11rem 0 0.75rem;
    @media(max-width: 480px) {
      margin-right: 0.75rem;
    }

    text-align: center;
    display: inline-block;
    line-height: 3rem;
    font-size: 1.5rem;
    color: white;
    :hover {
      cursor: pointer;
    }
`;
