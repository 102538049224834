import styled from 'styled-components/macro';
import tokens from '../../Tokens/tokens';
import { getBreakpoint, getTypeSize, pxToRem } from '../../utils';

const { colors, font, spacing } = tokens;
const { disabled } = colors.buttons;

export const DatePickerContainer = styled.div`
    --duet-color-primary: ${colors.borders.focus};
    --duet-color-text: ${colors.forms.inputText};
    --duet-color-text-active: ${colors.white};
    --duet-color-placeholder: ${colors.forms.helpText};
    --duet-color-button: ${colors.grey.mystic};
    --duet-color-surface: ${colors.white};
    --duet-color-overlay: rgba(0, 0, 0, 0.8);
    --duet-color-border: ${colors.forms.borderColor};

    --duet-font: 'Gotham', Arial, sans-serif;
    --duet-font-normal: ${font.weight.medium};
    --duet-font-bold: ${font.weight.bold};

    --duet-radius: 6px;
    --duet-z-index: 600;

    position: relative;

    .duet-date__input-wrapper {
        display: none;
    }

    .duet-date__day {
        &:focus, &:active {
            background: none;
            box-shadow: none;
            color: ${colors.forms.inputText};
            border: 2px solid ${colors.borders.focus};
        }
        &:hover {
            background: ${colors.grey.mystic};
            color: ${colors.forms.inputText};
        }
        &.is-today {
            box-shadow: none;
            &:before {
                background: none;
            }
        }
    }

    .duet-date__dialog-content {
        padding: 0 ${pxToRem(20)} ${pxToRem(48)} ${pxToRem(20)};

        @media (min-width: 576px) {
            padding: ${pxToRem(spacing.md)};
        }
    }

    .duet-date__select-label {
        font-size: ${getTypeSize('lg', 'heading')};
        flex-direction: row-reverse;

        svg {
            display: none;
        }

        &:before {
            content: '';
            background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='16' height='16' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16 5a1 1 0 00-1.71-.71L8 10.59l-6.29-6.3A1.004 1.004 0 00.29 5.71l7 7a1 1 0 001.42 0l7-7A1 1 0 0016 5z'/%3E%3C/svg%3E");
            width: ${pxToRem(12)};
            height: ${pxToRem(12)};
            background-size: ${pxToRem(12)} ${pxToRem(12)};
            display: block;
        }
    }

    .duet-date__select span {
        margin-right: ${pxToRem(spacing.xs)};
    }

    .duet-date__day:not(.is-month) {
        font-weight: ${font.weight.book};
        color: ${colors.text.disabled};
    }

    .duet-date__prev {
        width: ${pxToRem(spacing.lg)};
        height: ${pxToRem(spacing.lg)};

        &:hover {
            background: ${colors.grey.geyser};
        }

        svg {
            display: none;
        }

        &:before {
            content: '';
            background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='16' height='16' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9 16a1 1 0 00.71-1.71L3.41 8l6.3-6.29A1.004 1.004 0 008.29.29l-7 7a1 1 0 000 1.42l7 7A1 1 0 009 16z'/%3E%3C/svg%3E");
            width: ${pxToRem(12)};
            height: ${pxToRem(12)};
            background-size: ${pxToRem(12)} ${pxToRem(12)};
            display: block;
        }
    }

    .duet-date__next {
        width: ${pxToRem(spacing.lg)};
        height: ${pxToRem(spacing.lg)};

        &:hover {
            background: ${colors.grey.geyser};
        }

        svg {
            display: none;
        }

        &:before {
            content: '';
            background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='16' height='16' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5 16a1 1 0 01-.71-.29 1 1 0 010-1.42L10.59 8l-6.3-6.29A1 1 0 015.71.29l7 7a1 1 0 010 1.42l-7 7A1 1 0 015 16z'/%3E%3C/svg%3E");
            width: ${pxToRem(12)};
            height: ${pxToRem(12)};
            background-size: ${pxToRem(12)} ${pxToRem(12)};
            display: block;
        }
    }


    .duet-date__mobile-heading {
        white-space: normal;
    }

    .duet-date__mobile {
        padding: ${pxToRem(spacing.sm)} ${pxToRem(38)};
        margin-bottom: ${pxToRem(spacing.md)};

        ${getBreakpoint.sm`
            padding: ${pxToRem(spacing.sm)} ${pxToRem(44)};
        `}

        @media (min-width: 576px) {
            padding: 0;
        }
    }

    .duet-date__close {
        width: ${pxToRem(spacing.lg)};
        height: ${pxToRem(spacing.lg)};
        color: ${colors.icons.darkGrey};

        &:hover {
            background: ${colors.white};
            box-shadow: 0 0 0 2px var(--duet-color-primary);
        }

        svg {
            display: none;
        }
        &:before {
            content: '';
            background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='16' height='16' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15 16a1 1 0 01-.71-.29l-14-14A1 1 0 011.71.29l14 14a1 1 0 010 1.42A1 1 0 0115 16z'/%3E%3Cpath d='M1 16a1 1 0 01-.71-.29 1 1 0 010-1.42l14-14a1 1 0 111.42 1.42l-14 14A1 1 0 011 16z'/%3E%3C/svg%3E");
            width: ${pxToRem(12)};
            height: ${pxToRem(12)};
            background-size: ${pxToRem(12)} ${pxToRem(12)};
            display: block;
        }
    }
`;

export const DatePickerButton = styled.button`
    position: absolute;
    border: none;
    background: ${colors.grey.mystic};
    right: 2px;
    top: 6px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    height: ${pxToRem(44)};
    width: ${pxToRem(spacing.xxl)};
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;

    &:hover {
        cursor: pointer;
    }
    &:focus {
        outline: 2px solid ${colors.borders.focus};
        outline-offset: 4px;
    }
    &:disabled {
        color: ${colors.text.disabled};
        background-color: ${disabled.background};
        border: 2px solid ${disabled.background};
        cursor: not-allowed;
        -webkit-transition: none;
        transition: none;

        svg {
           fill: ${colors.text.disabled};
        }
    }
    &.is-hidden {
        display: none;
    }
`;

export const DatePickerButtonMask = styled.div`
    position: absolute;
    border: none;
    background: ${colors.grey.mystic};
    right: 2px;
    top: 6px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    height: ${pxToRem(44)};
    width: ${pxToRem(spacing.xxl)};
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
        cursor: pointer;
    }
`;
