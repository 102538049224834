const checkResponse = async response => {
    if (response.status < 200 || response.status > 299) {
        const error = Error('Request is unsuccessful');
        error.type = response.status;
        const contentType = response.headers.get('content-type');
        if (contentType && contentType.indexOf('json') !== -1) {
            error.details = await response.json();
        }
        throw error;
    }
};

export const fileUploadApiBuilder = endPoint => ({
    uploadFile: async (
        file,
        name,
        category
    ) => {
        const uploadApiUrl = `${endPoint}/${category ? `${category}/` : ''}files/${name}`;

        const content = new FormData();
        content.append('file', file);
        const options = { method: 'PUT', body: content };
        const response = await fetch(uploadApiUrl, options);
        await checkResponse(response);
        return await response.text();
    },

    removeFile: async fileId => {
        const options = { method: 'DELETE' };
        const url = `${endPoint}/files/${fileId}`;
        const response = await fetch(url, options);
        await checkResponse(response);
        return await response.text();
    }
});

export default fileUploadApiBuilder('');
