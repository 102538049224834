import * as React from 'react';
import PropTypes from 'prop-types';
import tokens from '../../Tokens/tokens';
const { spacing } = tokens;

const BrandIconWorkerCapability = ({ title, titleId, ...props }) => {
    const sizeArray = Object.entries(spacing.icons);
    const sizeOptions = new Map(sizeArray);
    const displaySize = sizeOptions.get(props.size);
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 48 48'
            fill={props.color && displayColor}
            aria-hidden={!title}
            role={title ? 'img' : null}
            width={props.size && displaySize}
            height={props.size && displaySize}
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <defs>
                <style>{'.WorkerCapability_svg__cls-1{fill:#002664}'}</style>
            </defs>
            <path
                className='WorkerCapability_svg__cls-1'
                d='M18 22a1.05 1.05 0 01-.71-.29l-2-2a1 1 0 011.42-1.42l1.23 1.24 5.3-6.18a1 1 0 111.52 1.3l-6 7A1 1 0 0118 22zm19-3H26a1 1 0 010-2h11a1 1 0 010 2zm-3 4h-8a1 1 0 010-2h8a1 1 0 010 2z'
            />
            <path d='M29 5a5 5 0 11-5-5 5 5 0 015 5z' fill='#d7153a' />
            <path
                className='WorkerCapability_svg__cls-1'
                d='M23 21v-2l-2 2v2h-8v-8h7l2-2H12a1 1 0 00-1 1v10a1 1 0 001 1h10a1 1 0 001-1v-3z'
            />
            <path
                className='WorkerCapability_svg__cls-1'
                d='M39 48H9a3 3 0 01-3-3V7a3 3 0 013-3h8v2H9a1 1 0 00-1 1v38a1 1 0 001 1h30a1 1 0 001-1V7a1 1 0 00-1-1h-8V4h8a3 3 0 013 3v38a3 3 0 01-3 3z'
            />
            <path
                className='WorkerCapability_svg__cls-1'
                d='M18 38a1.05 1.05 0 01-.71-.29l-2-2a1 1 0 011.42-1.42l1.23 1.24 5.3-6.18a1 1 0 111.52 1.3l-6 7A1 1 0 0118 38zm19-3H26a1 1 0 010-2h11a1 1 0 010 2zm-3 4h-8a1 1 0 010-2h8a1 1 0 010 2z'
            />
            <path
                className='WorkerCapability_svg__cls-1'
                d='M23 37v-2l-2 2v2h-8v-8h7l2-2H12a1 1 0 00-1 1v10a1 1 0 001 1h10a1 1 0 001-1v-3z'
            />
        </svg>
    );
};

BrandIconWorkerCapability.propTypes = {
    title: PropTypes.string,
    titleId: PropTypes.string,
    size: PropTypes.oneOf(['lg', 'xl', 'xxl']),
};
BrandIconWorkerCapability.defaultProps = {
    size: 'xl',
    focusable: false,
};
export default BrandIconWorkerCapability;
