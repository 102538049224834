import * as React from 'react';
import PropTypes from 'prop-types';
import tokens from '../../Tokens/tokens';
const { spacing } = tokens;

const BrandIconMarriages = ({ title, titleId, ...props }) => {
    const sizeArray = Object.entries(spacing.icons);
    const sizeOptions = new Map(sizeArray);
    const displaySize = sizeOptions.get(props.size);
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 48 48'
            fill={props.color && displayColor}
            aria-hidden={!title}
            role={title ? 'img' : null}
            width={props.size && displaySize}
            height={props.size && displaySize}
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <defs>
                <style>{'.Marriages_svg__cls-1{fill:#002664}'}</style>
            </defs>
            <path
                className='Marriages_svg__cls-1'
                d='M25.77 25.17l7.55-7.08L29.5 13h-11l-3.82 5.09 7.55 7.08A10 10 0 0014 35h2a8 8 0 0116 0h2a10 10 0 00-8.23-9.83zM19.5 15h9l2.18 2.91L24 24l-6.68-6.09z'
            />
            <path
                className='Marriages_svg__cls-1'
                d='M48 9.37A1 1 0 0048 9c0-5.3-3.29-9-8-9H9a9 9 0 00-9 9 .91.91 0 00.08.37L6 24 0 38.81V45a2.69 2.69 0 003 2.92L45 48a2.76 2.76 0 003-3v-6.19L42.08 24zM9 2h31c3.75 0 5.62 2.87 6 6H2.07A7 7 0 019 2zm37 43c0 .77-.23 1-1 1l-42-.08c-.85 0-1-.24-1-.92v-5h44zM2.48 38l5.24-13H14v-2H7.72L2.48 10h43.08l-5.24 13H34v2h6.32l5.2 13z'
            />
            <path d='M28.15 20.21a5.49 5.49 0 00-.62-.75 5 5 0 00-7.07 0 4.88 4.88 0 00-.61.75L24 24z' fill='#d7153a' />
        </svg>
    );
};

BrandIconMarriages.propTypes = {
    title: PropTypes.string,
    titleId: PropTypes.string,
    size: PropTypes.oneOf(['lg', 'xl', 'xxl']),
};
BrandIconMarriages.defaultProps = {
    size: 'xl',
    focusable: false,
};
export default BrandIconMarriages;
