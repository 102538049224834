import * as React from 'react';
import PropTypes from 'prop-types';
import tokens from '../../Tokens/tokens';
const { spacing } = tokens;

const BrandIconCharity = ({ title, titleId, ...props }) => {
    const sizeArray = Object.entries(spacing.icons);
    const sizeOptions = new Map(sizeArray);
    const displaySize = sizeOptions.get(props.size);
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 48 48'
            fill={props.color && displayColor}
            aria-hidden={!title}
            role={title ? 'img' : null}
            width={props.size && displaySize}
            height={props.size && displaySize}
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <defs>
                <style>{'.Charity_svg__cls-1{fill:#002664}'}</style>
            </defs>
            <path
                className='Charity_svg__cls-1'
                d='M31.51 19.76a1 1 0 01-.71-.29L25.3 14a4.37 4.37 0 01-1.3-3.1 4.26 4.26 0 011.25-3.08 4.13 4.13 0 013.07-1.25 4.38 4.38 0 013.08 1.3l.1.1.11-.11a4.37 4.37 0 013.07-1.29 4.16 4.16 0 013.08 1.25 4.36 4.36 0 01-.04 6.18l-5.5 5.5a1 1 0 01-.71.26zM28.29 8.57A2.25 2.25 0 0026 10.88a2.42 2.42 0 00.71 1.68l4.79 4.79 4.8-4.79a2.35 2.35 0 000-3.33 2.24 2.24 0 00-1.64-.66 2.42 2.42 0 00-1.66.71l-.82.81a1 1 0 01-1.41 0L30 9.28a2.4 2.4 0 00-1.68-.71zm8.71 4.7zM16.26 48c-3 0-5.78-1.22-8.64-4.36-5.2-5.73-4.17-11.66-.85-15l10.37-10.31a3.39 3.39 0 015.51 1.17l.2-.21a3.49 3.49 0 012.49-1 3.57 3.57 0 012.51 1 3.45 3.45 0 011 1.74 3.58 3.58 0 013.57.85 3.5 3.5 0 011 2.63 3.68 3.68 0 01-.61 1.87 3.53 3.53 0 011.39 5.84l-5.34 5.34a5.37 5.37 0 013.4 1.53 4.2 4.2 0 011.14 4.48c-.58 1.18-1.82 1.22-3 1.26a20.39 20.39 0 00-6.87 1.48A20.79 20.79 0 0116.26 48zm3.23-28.64a1.36 1.36 0 00-.94.38L8.18 30.07c-2.65 2.66-3.39 7.47.92 12.22 3.83 4.22 7 4.72 13.69 2.17a22.38 22.38 0 017.52-1.61 4.62 4.62 0 001.29-.14 2.33 2.33 0 00-.75-2.18c-.56-.56-1.86-1.4-4.29-.7l-.19.05a1 1 0 01-.95-1.68l7.37-7.37a1.54 1.54 0 000-2.16 1.5 1.5 0 00-1.06-.44 1.51 1.51 0 00-1.07.45l-5.7 5.7a1 1 0 01-1.41 0 1 1 0 010-1.42L31 25.48a1.57 1.57 0 00.44-1 1.49 1.49 0 00-.44-1.16 1.57 1.57 0 00-2.15 0l-.94.95-7 7a1 1 0 01-1.42-1.41l7-7a1.53 1.53 0 000-2.14 1.56 1.56 0 00-2.17 0L21.94 23h-.05l-5.23 5.24a1 1 0 11-1.41-1.41l.05-.05 5.2-5.22a1.36 1.36 0 000-1.88 1.43 1.43 0 00-1.01-.32z'
            />
            <path
                className='Charity_svg__cls-1'
                d='M40.29 4.43c-5-5-9-5.63-15.89-2.64a17.33 17.33 0 01-6.83 1.47c-1.2 0-2.45.08-3 1.26A4.17 4.17 0 0015.68 9a5.35 5.35 0 003.4 1.53l-5.34 5.35a3.58 3.58 0 00-.74 3.78l1.86-1.86a1.66 1.66 0 01.33-.52l7.34-7.38a1 1 0 00-1-1.67h-.18C19 9 17.66 8.14 17.1 7.58a2.31 2.31 0 01-.76-2.18 4 4 0 011.3-.14 19.22 19.22 0 007.55-1.63C31.33 1 34.53 1.49 38.87 5.85c4.49 4.49 3.61 9.47.88 12.19l-4.6 4.67a5.29 5.29 0 01.26 1.91 6.31 6.31 0 01-.09.76l5.85-5.93c3.51-3.51 4.33-9.8-.88-15.02z'
            />
            <path
                d='M36.34 9.23a2.28 2.28 0 00-1.65-.66 2.42 2.42 0 00-1.69.71l-.82.81a1 1 0 01-1.41 0l4 4 1.52-1.52a2.36 2.36 0 00.05-3.34z'
                fill='#d7153a'
            />
        </svg>
    );
};

BrandIconCharity.propTypes = {
    title: PropTypes.string,
    titleId: PropTypes.string,
    size: PropTypes.oneOf(['lg', 'xl', 'xxl']),
};
BrandIconCharity.defaultProps = {
    size: 'xl',
    focusable: false,
};
export default BrandIconCharity;
