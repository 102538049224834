import styled from 'styled-components/macro';
import Field from '../Field.styled';
import { pxToRem, getBreakpoint } from '../../../utils';
import tokens from '../../../Tokens/tokens';

const { layouts } = tokens;

export const DateContainer = styled.div`
    width: 100%;
    display: flex;
`;

export const CellField = styled(Field)`
    margin: 0 !important;
`;

export const CellDay = styled.div``;

export const CellMonth = styled.div`
    margin-left: ${props => props.hideDay ? 0 : pxToRem(12)};

    ${getBreakpoint.md`
        margin-left: ${props => props.hideDay ? 0 : pxToRem(16)};
        min-width: ${pxToRem(layouts.inputWidth['md'])};
    `}
`;

export const CellYear = styled.div`
    margin-left: ${pxToRem(12)};

    ${getBreakpoint.md`
        margin-left: ${pxToRem(16)};
    `}
`;

