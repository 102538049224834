import PropTypes from 'prop-types';
import styled, { css } from 'styled-components/macro';
import SecondaryButton from '../Buttons/SecondaryButton/SecondaryButton.styled';
import tokens from '../Tokens/tokens';
import { pxToRem } from '../utils';

const { font } = tokens;

export const TriggerWrapper = styled.div`
    display: contents;
`;

const StyledModal = styled.div`
    position: relative;
    width: 60%;
    min-height: 4rem;
    max-width: ${props => props.size === 'small' ? '34rem' : '50rem'};
    z-index: 2001;
    background-color: white;
    border-radius: .5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media (max-width: 768px) {
        width: 80%;
        justify-content: flex-start;
    }

    @media (max-width: 480px) {
        background-color: #f4f6fa;

        ${props => props.mobileFullScreen && css`
            width: 100%;
            height: 100%;
            border-radius: 0;
        `};
    }
`;

StyledModal.propTypes = {
    mobileFullScreen: PropTypes.bool,
    isConfirmation: PropTypes.bool,
};

StyledModal.Wrapper = styled.div `
    overflow-y: auto;
`;

StyledModal.Header = styled.div`
    padding: 2rem 2rem 1rem 2rem;
    font-weight: 500;
    ${props => props.justifyContent && css`
        justify-content: ${props.justifyContent};
    `};
    @media (max-width: 480px) {
        padding: 1.25rem 2.5rem 1.25rem 1.25rem;
    }

    ${props => props.slidingPanel && css`
        border-bottom: solid 1px rgba(200, 200, 200, 0.5);
    `}
`;

StyledModal.Header.propTypes = {
    slidingPanel: PropTypes.bool,
    justifyContent: PropTypes.string,
};

StyledModal.Title = styled.span`
    font-size: ${pxToRem(font.size.xxl)};
    font-weight: ${font.weight.bold};
`;

StyledModal.Body = styled.div`
    padding: 1rem 2rem;
    ${props => props.textAlign && css`
        text-align: ${props.textAlign};
    `};

    @media (max-width: 480px) {
        padding: 1rem 1.25rem;
    }
`;

StyledModal.Footer = styled.div`
    padding: 1rem 2rem 2rem 2rem;
    display: ${props => props.display || 'flex'};
    justify-content: ${props => props.justifyContent || 'space-around'};
    @media(max-width: 480px) {
        padding: 1.5rem;
    }
`;

StyledModal.Footer.propTypes = {
    justifyContent: PropTypes.string,
    display: PropTypes.string,
};

StyledModal.DismissButton = styled.button`
    position: absolute;
    right: 0;
    top: 0;
    width: 4rem;
    height: 4rem;
    cursor: pointer;

    display: flex;
    justify-content: center;
    align-items: center;
    background: none;
    border: none;
`;

StyledModal.ConfirmationButton = styled(SecondaryButton)`
    font-size: 1rem;
    font-weight: 500;
    border: 1px solid #C4D0D6;
    @media(max-width: 480px) {
        width: 100%;
    }
`;

export default StyledModal;
