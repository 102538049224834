import styled from 'styled-components/macro';
import PrimaryButton from '../../Buttons/PrimaryButton/PrimaryButton.styled';

export const HighlightCardContainer = styled.div`
    background-color: ${props => props.theme.colors.lightGrey2};
    line-height: 1.5rem;
    display: flex;
    align-items: flex-start;
    padding: 1rem;
`;

export const HighlightCardIcon = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 0;
`;
export const HighlightCardHeader = styled.div`
    min-height: 35px;
    display: flex;
    align-items: center;
`;

export const HighlightCardContent = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 1rem;

    ${PrimaryButton} {
      margin-top: 1rem;
      width: fit-content;
    }
`;