import * as React from 'react';
import PropTypes from 'prop-types';
import tokens from '../../Tokens/tokens';
const { colors, spacing } = tokens;

const IconDocument = ({ title, titleId, ...props }) => {
    const sizeArray = Object.entries(spacing.icons);
    const colorArray = Object.entries(colors.icons);
    const sizeOptions = new Map(sizeArray);
    const displaySize = sizeOptions.get(props.size);
    const colorOptions = new Map(colorArray);
    const displayColor = colorOptions.get(props.color);
    return (
        <svg
            viewBox='0 0 16 16'
            xmlns='http://www.w3.org/2000/svg'
            fill={props.color && displayColor}
            aria-hidden={!title}
            role={title ? 'img' : null}
            width={props.size && displaySize}
            height={props.size && displaySize}
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <path
                fillRule='evenodd'
                d='M2 1.1C2 0.492489 2.49249 9.53674e-07 3.1 9.53674e-07H9.41421L14 4.58579V14.9C14 15.5075 13.5075 16 12.9 16H3.1C2.49248 16 2 15.5075 2 14.9V1.1ZM4 14H12V6H9.1C8.49249 6 8 5.50752 8 4.9V2H4V14ZM10 3.41421L10.5858 4L10.2929 3.70711L10 3.41421Z'
            />
        </svg>
    );
};

IconDocument.propTypes = {
    title: PropTypes.string,
    titleId: PropTypes.string,
    size: PropTypes.oneOf(['sm', 'md']),
    color: PropTypes.oneOf(['primaryBlue', 'secondaryBlue', 'darkGrey', 'white']),
};
IconDocument.defaultProps = {
    size: 'sm',
    color: 'darkGrey',
    focusable: false,
};
export default IconDocument;
