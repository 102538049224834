import React from 'react';
import PropTypes from 'prop-types';
import { css } from 'styled-components';
import { layout } from 'styled-system';
import styled from 'styled-components/macro';
import FormInput from '../../Components/Form/FormInput';
import Field from '../../Components/Form/Field.styled';
import FormSelect from '../../Components/Form/FormSelect';
import { FormLegendLabel } from '../../Components/Form/Form.styled';
import tokens from '../../Tokens/tokens';
import { pxToRem, getBreakpoint } from '../../utils';

const { colors, font } = tokens;

export const Flex = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: ${props => props.direction || 'column'};
    justify-content: ${props => props.justifyContent || 'space-between'};

    ${props => props.responsive && css`
        margin-left: -${pxToRem(8)};
        margin-right: -${pxToRem(8)};
        > div {
            margin-left: ${pxToRem(8)};
            margin-right: ${pxToRem(8)};
        }

        ${getBreakpoint.sm`
            flex-direction: row;
        `};
    `};

    ${layout};
`;

export const Container = styled.div`
    width: 100%;
    margin: 0 auto auto;
    font-weight: ${font.weight.book};
    letter-spacing: normal;
    font-size: ${font.size.base};
    color: ${colors.text.body};
`;

export const StyledFormInput = styled(FormInput)`
    ${Field.Label} {
        max-width: 100%;
    }
`;

export const StyledAddressLegend = styled(FormLegendLabel)`
    margin-bottom: ${pxToRem(24)};
`;

export const Margin = styled.div`
    margin-top: ${props => `${props.top}rem` || '0'};
    margin-right: ${props => `${props.right}rem` || '0'};
    margin-bottom: ${props => `${props.bottom}rem` || '0'};
    margin-left: ${props => `${props.left}rem` || '0'};
`;

Margin.propTypes = {
    top: PropTypes.number,
    bottom: PropTypes.number,
    left: PropTypes.number,
    right: PropTypes.number,
};

export const Dropdown = props => <FormSelect { ...props } />;

Dropdown.defaultProps = {
    errorMessage: 'This field is required.',
};

Dropdown.propTypes = {
    ...FormSelect.propTypes,
    className: PropTypes.string,
    placeholder: PropTypes.string,
    options: PropTypes.array.isRequired,
    value: PropTypes.string,
    hasError: PropTypes.bool,
    onChange: PropTypes.func,
    label: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node,
    ]),
};
