import * as React from 'react';
import PropTypes from 'prop-types';
import tokens from '../../Tokens/tokens';
const { colors, spacing } = tokens;

const IconLocation = ({ title, titleId, ...props }) => {
    const sizeArray = Object.entries(spacing.icons);
    const colorArray = Object.entries(colors.icons);
    const sizeOptions = new Map(sizeArray);
    const displaySize = sizeOptions.get(props.size);
    const colorOptions = new Map(colorArray);
    const displayColor = colorOptions.get(props.color);
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 16 16'
            fill={props.color && displayColor}
            aria-hidden={!title}
            role={title ? 'img' : null}
            width={props.size && displaySize}
            height={props.size && displaySize}
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <path d='M8 7.5A1.5 1.5 0 016.5 6a1.46 1.46 0 01.42-1A1.5 1.5 0 018 4.5a1.57 1.57 0 011 .42A1.5 1.5 0 019.5 6a1.48 1.48 0 01-.42 1A1.5 1.5 0 018 7.5z' />
            <path d='M8 16a.84.84 0 01-.38-.08C7.18 15.74 2 10.36 2 6a6.07 6.07 0 011.56-4.32A6 6 0 018 0c3.7 0 6 2.3 6 6 0 4.37-5.08 9.49-5.29 9.71A1 1 0 018 16zM8 2a4 4 0 00-3 1.07A4.14 4.14 0 004 6c0 2.5 2.46 5.8 4 7.54 1.54-1.74 4-5 4-7.54s-1.42-4-4-4z' />
        </svg>
    );
};

IconLocation.propTypes = {
    title: PropTypes.string,
    titleId: PropTypes.string,
    size: PropTypes.oneOf(['sm', 'md']),
    color: PropTypes.oneOf(['primaryBlue', 'secondaryBlue', 'darkGrey', 'white']),
};
IconLocation.defaultProps = {
    size: 'sm',
    color: 'darkGrey',
    focusable: false,
};
export default IconLocation;
