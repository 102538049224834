import * as React from 'react';
import PropTypes from 'prop-types';
import tokens from '../../Tokens/tokens';
const { colors, spacing } = tokens;

const IconThumbsUp = ({ title, titleId, ...props }) => {
    const sizeArray = Object.entries(spacing.icons);
    const colorArray = Object.entries(colors.icons);
    const sizeOptions = new Map(sizeArray);
    const displaySize = sizeOptions.get(props.size);
    const colorOptions = new Map(colorArray);
    const displayColor = colorOptions.get(props.color);
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 16 16'
            fill={props.color && displayColor}
            aria-hidden={!title}
            role={title ? 'img' : null}
            width={props.size && displaySize}
            height={props.size && displaySize}
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <path d='M1 16a1 1 0 01-1-1V7a1 1 0 012 0v8a1 1 0 01-1 1zm11 0H5a1 1 0 01-1-1V7a1 1 0 01.11-.45l3-6A1 1 0 018 0a3 3 0 013 3v2h2a3 3 0 013 3 1.36 1.36 0 010 .2l-1 4.91A3 3 0 0112 16zm-6-2h6.05a1 1 0 00.95-1 1.36 1.36 0 010-.2l1-4.88A1 1 0 0013 7h-3a1 1 0 01-1-1V3a1 1 0 00-.46-.84L6 7.24z' />
        </svg>
    );
};

IconThumbsUp.propTypes = {
    title: PropTypes.string,
    titleId: PropTypes.string,
    size: PropTypes.oneOf(['sm', 'md']),
    color: PropTypes.oneOf(['primaryBlue', 'secondaryBlue', 'darkGrey', 'white']),
};
IconThumbsUp.defaultProps = {
    size: 'sm',
    color: 'darkGrey',
    focusable: false,
};
export default IconThumbsUp;
