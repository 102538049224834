import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { formatBytes } from '../../utils';
import { UploadedItemContainer, FileInfo, ButtonContainer } from './UploadedItem.styled';
import Button from '../Button/Button';
import { trackMe } from '../ComponentAnalytics/componentAnalytics';

const UploadedItem = ({ id, name, size, onRemove }) => {

    const convertSize = size && formatBytes(size, 1);

    useEffect(() => {
        trackMe('UploadedItem');
    }, []);

    return (
        <UploadedItemContainer data-testid={ `${id}-file` }>
            <FileInfo data-testid={ `${id}-file-info` }>{`${name} (${convertSize})`}</FileInfo>
            <ButtonContainer>
                <Button
                    data-testid={ `${id}-file-remove` }
                    theme='link'
                    onClick={ onRemove }
                >
                    Remove file
                </Button>
            </ButtonContainer>
        </UploadedItemContainer>
    );
};

UploadedItem.propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    size: PropTypes.number.isRequired,
    onRemove: PropTypes.func.isRequired,
};

export default UploadedItem;
