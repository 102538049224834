import * as React from 'react';
import PropTypes from 'prop-types';
import tokens from '../../Tokens/tokens';
const { colors, spacing } = tokens;

const IconSecure = ({ title, titleId, ...props }) => {
    const sizeArray = Object.entries(spacing.icons);
    const colorArray = Object.entries(colors.icons);
    const sizeOptions = new Map(sizeArray);
    const displaySize = sizeOptions.get(props.size);
    const colorOptions = new Map(colorArray);
    const displayColor = colorOptions.get(props.color);
    return (
        <svg
            viewBox='0 0 16 16'
            xmlns='http://www.w3.org/2000/svg'
            fill={props.color && displayColor}
            aria-hidden={!title}
            role={title ? 'img' : null}
            width={props.size && displaySize}
            height={props.size && displaySize}
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <path
                fillRule='evenodd'
                d='M4.01195 6C4.06646 3.84494 4.3105 2.409 4.80566 1.483C5.11544 0.903665 5.53641 0.495636 6.06806 0.257698C6.56708 0.0343617 7.08077 0 7.5 0H8.5C8.91923 0 9.43292 0.0343617 9.93194 0.257698C10.4636 0.495637 10.8846 0.903665 11.1943 1.483C11.6895 2.409 11.9335 3.84494 11.9881 6H13C13.5523 6 14 6.44772 14 7V15C14 15.5523 13.5523 16 13 16H3C2.44772 16 2 15.5523 2 15V7C2 6.44772 2.44772 6 3 6H4.01195ZM6.01301 6C6.06948 3.94662 6.30263 2.92488 6.56934 2.42609C6.69706 2.18724 6.80734 2.118 6.88507 2.08321C6.99542 2.03382 7.16923 2 7.5 2H8.5C8.83078 2 9.00458 2.03382 9.11493 2.08321C9.19266 2.118 9.30294 2.18724 9.43066 2.42609C9.69737 2.92488 9.93052 3.94662 9.98699 6H6.01301ZM4 14V8H12V14H4Z'
            />
        </svg>
    );
};

IconSecure.propTypes = {
    title: PropTypes.string,
    titleId: PropTypes.string,
    size: PropTypes.oneOf(['sm', 'md']),
    color: PropTypes.oneOf(['primaryBlue', 'secondaryBlue', 'darkGrey', 'white']),
};
IconSecure.defaultProps = {
    size: 'sm',
    color: 'darkGrey',
    focusable: false,
};
export default IconSecure;
