import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { StyledFormHeader, PageTitle } from '../SectionHeader/SectionHeader.styled';
import { Container } from '../ContentContainer/ContentContainer.styled';
import ProgressStepper from '../ProgressStepper/ProgressStepper';
import { trackMe } from '../ComponentAnalytics/componentAnalytics';

const FormHeader = ({ className, label, title, stepsList }) => {
    useEffect(() => {
        trackMe('FormHeader');
    }, []);

    return (
        <StyledFormHeader className={ className }>
            <Container>
                {label && <p>{label}</p>}
                <PageTitle>{title}</PageTitle>
                {stepsList && <ProgressStepper stepsList={ stepsList } />}
            </Container>
        </StyledFormHeader>
    );
};

FormHeader.propTypes = {
    className: PropTypes.string,
    label: PropTypes.string,
    title: PropTypes.string.isRequired,
    stepsList: PropTypes.arrayOf(PropTypes.shape({
        content: PropTypes.string,
        status: PropTypes.oneOf(['completed', 'current', 'todo']),
    })),
};

export default FormHeader;
