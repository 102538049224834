import React, { useReducer, useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from '../Button/Button';
import StyledAccordion from './Accordion.styled';
import { trackMe } from '../ComponentAnalytics/componentAnalytics';
import { SROnly } from '../../GlobalStyle/utilities/utilities.styled';

const reducer = (state, { type, index }) => {
    // eslint-disable-next-line default-case
    switch (type) {
        case 'expand-all':
            const expandState = state.openItems.fill(true);
            return {
                ...state,
                openItems: [...expandState],
                itemsView: itemState(expandState)
            };
        case 'collapse-all':
            const collapseState = state.openItems.fill(false);
            return {
                ...state,
                openItems: [...collapseState],
                itemsView: itemState(collapseState)
            };
        case 'toggle':
            const openItems = [...state.openItems];
            openItems[parseInt(index)] = !openItems[parseInt(index)];
            return {
                ...state,
                openItems: openItems,
                itemsView: itemState(openItems)
            };
    }
};

const itemState = state => {
    if (state.every( e => e === true)) {
        return 'EXPANDED';
    }
    if (state.every( e => e === false)) {
        return 'COLLAPSED';
    }
    return 'MIXED';
};

const Accordion = ({ id, children, className, name = 'sections' }) => {
    const expandedArray = React.Children.map(children, child => !!child.props.expanded);
    const isAllExpanded = itemState(expandedArray);
    const inititalState = { openItems: expandedArray, itemsView: isAllExpanded };
    const [expandedState, dispatch] = useReducer(reducer, inititalState);
    const {
        itemsView, openItems
    } = expandedState;
    const showExpandCollapse = React.Children.count(children) >= 2;

    useEffect(() => {
        trackMe('Accordion [GEL]');
    }, []);

    return (
        <StyledAccordion id={ id } className={ className }>
            {showExpandCollapse &&
                <StyledAccordion.Toggles>
                    <Button
                        variant='link'
                        disabled={ itemsView === 'EXPANDED' }
                        onClick={ () => dispatch({ type: 'expand-all' }) }
                    >
                        Expand all
                        <SROnly>{ name }</SROnly>
                    </Button>
                    <Button
                        variant='link'
                        disabled={ itemsView === 'COLLAPSED' }
                        onClick={ () => dispatch({ type: 'collapse-all' }) }
                    >
                        Collapse all
                        <SROnly>{ name }</SROnly>
                    </Button>
                </StyledAccordion.Toggles>
            }
            { children && React.Children.map(children, (child, idx) => React.cloneElement(child, {
                onClick: () => dispatch({ type: 'toggle', index: idx }),
                id: `${id}-${idx}`,
                expanded: openItems[parseInt(idx)]
            }))}
        </StyledAccordion>
    );
};

Accordion.propTypes = {
    /** Unique ID for the accordion container, this ID is also used as a prefix for the buttons and content. */
    id: PropTypes.string.isRequired,
    /** Accepts the AccordionItem element */
    children: PropTypes.node.isRequired,
    // eslint-disable-next-line max-len
    /** Provide an understandable accessible `name` for the "Expand all" and "Collapse all" button, so that a screen reader user will know the purpose of the button. For example "Expand all FAQs" */
    name: PropTypes.string,
};

Accordion.defaultProps = {
    name: 'sections',
};

export default Accordion;
