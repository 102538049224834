import React from 'react';
import PropTypes from 'prop-types';
import { StyledTableCell } from './Table.styled';

const TableCell = ({ children, textAlign, ...rest }) => (
    <StyledTableCell textAlign={ textAlign } { ...rest }>
        {children}
    </StyledTableCell>
);

TableCell.propTypes = {
    children: PropTypes.node,
    textAlign: PropTypes.oneOf(['left', 'center', 'right']),
};

TableCell.defaultProps = {
    textAlign: 'left'
};

export default TableCell;
