import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Field from './Field.styled';
import { getID, getAriaDescribedby } from '../../utils';
import { trackMe } from '../ComponentAnalytics/componentAnalytics';

export const FormSelect = ({
    id,
    label,
    placeholder,
    value,
    options,
    onChange,
    disabled,
    hasError,
    errorMessage,
    testId,
    small,
    helpMessage,
    name,
    inputRef,
    inputWidth,
    margin,
    isRequired,
    isOptional,
    ...rest
}) => {

    useEffect(() => {
        trackMe('FormSelect');
    }, []);

    const handleOnChange = e => {
        onChange(e, options.find(option => option.value === e.target.value) || {});
    };

    const genID = () => id || getID();

    const elemID = genID();
    const idError = `${elemID}-error`;
    const idHelper = `${elemID}-helper`;

    const showError = hasError && !!errorMessage;
    const showAriaDescribedby = getAriaDescribedby( !!showError && idError, !!helpMessage && idHelper );

    const optional = isOptional && !isRequired;
    const required = !isOptional && isRequired;

    const showRequired =
        ((!optional || !required) && null) ||
            (required ? true : null) ||
            (optional ? false : null);

    return (
        <Field { ...rest } margin={ margin }>
            {label && <Field.Label htmlFor={ elemID }>{optional ? `${label} (optional)` : label}</Field.Label>}
            {helpMessage && <Field.Help id={ idHelper }>{helpMessage}</Field.Help>}
            <Field.Select
                id={ elemID }
                disabled={ disabled }
                onChange={ handleOnChange }
                value={ value }
                hasError={ hasError }
                data-testid={ testId }
                small={ small }
                aria-invalid={ hasError }
                aria-describedby={ showAriaDescribedby }
                name={ name }
                ref={ inputRef }
                inputWidth={ inputWidth }
                aria-required={ showRequired }
            >
                {placeholder && <option value=''>{placeholder}</option>}
                {options.map((option, idx) =>
                    <option key={ idx } value={ option.value }>
                        {option.text}
                    </option>)}
            </Field.Select>
            {showError && <Field.Error id={ idError }>{errorMessage}</Field.Error>}
        </Field>
    );
};

FormSelect.propTypes = {
    id: PropTypes.string,
    label: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node,
    ]),
    /** `aria-required: true` on form element */
    isRequired: PropTypes.bool,
    /** `aria-required: false` on form element */
    isOptional: PropTypes.bool,
    placeholder: PropTypes.string,
    value: PropTypes.string,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.string.isRequired,
            text: PropTypes.string.isRequired,
        })
    ).isRequired,
    onChange: PropTypes.func,
    errorMessage: PropTypes.string,
    testId: PropTypes.string,
    hasError: PropTypes.bool,
    disabled: PropTypes.bool,
    small: PropTypes.bool,
    helpMessage: PropTypes.string,
    name: PropTypes.string,
    inputRef: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.object,
    ]),
    inputWidth: PropTypes.oneOf(['xxs', 'xs', 'sm', 'md', 'lg', 'xl']),
    margin: PropTypes.shape({
        top: PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.oneOf(['none', 'xs', 'sm', 'md', 'lg', 'xl', 'xxl', 'xxxl']),
        ]),
    }),
};

FormSelect.defaultProps = {
    id: '',
    label: '',
    placeholder: '',
    errorMessage: '',
    testId: '',
    hasError: false,
    disabled: false,
    small: false,
    helpMessage: '',
};

export default FormSelect;
