import * as React from 'react';
import PropTypes from 'prop-types';
import tokens from '../../Tokens/tokens';
const { spacing } = tokens;

const BrandIconCodeCheckIn = ({ title, titleId, ...props }) => {
    const sizeArray = Object.entries(spacing.icons);
    const sizeOptions = new Map(sizeArray);
    const displaySize = sizeOptions.get(props.size);
    return (
        <svg
            viewBox='0 0 48 48'
            xmlns='http://www.w3.org/2000/svg'
            fill={props.color && displayColor}
            aria-hidden={!title}
            role={title ? 'img' : null}
            width={props.size && displaySize}
            height={props.size && displaySize}
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <g stroke='none' strokeWidth={1} fill='none' fillRule='evenodd'>
                <g transform='translate(4.000000, 0.000000)' fillRule='nonzero'>
                    <path
                        d='M15,44 C12.2385763,44 10,41.7614237 10,39 C10,36.2385763 12.2385763,34 15,34 C17.7614237,34 20,36.2385763 20,39 C20,40.3260824 19.4732158,41.597852 18.5355339,42.5355339 C17.597852,43.4732158 16.3260824,44 15,44 Z'
                        fill='#D7153A'
                    />
                    <path
                        d='M29.18,36.46 C28.9162307,36.456243 28.6646305,36.3484143 28.48,36.16 L25.29,33 C24.9022764,32.6099625 24.9022764,31.9800375 25.29,31.59 C25.4777666,31.4006873 25.7333625,31.2942017 26,31.2942017 C26.2666375,31.2942017 26.5222334,31.4006873 26.71,31.59 L29.17,34 L34.75,28.27 C35.0000859,28.0145551 35.3674531,27.9119335 35.7137178,28.0007918 C36.0599825,28.0896501 36.332539,28.3564886 36.4287178,28.7007918 C36.5248966,29.045095 36.4300859,29.4145551 36.18,29.67 L29.9,36.15 C29.7146007,36.3445357 29.4587063,36.4562642 29.19,36.46 L29.18,36.46 Z'
                        fill='#002664'
                    />
                    <g transform='translate(3.983564, 3.598802)' fill='#002664'>
                        <g>
                            <path d='M1.00003161,8.00050922 L7.00001638,8.00050922 L7.00001638,8.00050922 C7.5523065,8.00050922 8.00001638,7.55281131 8.00001638,7.00054796 L8.00001638,1.00078038 C8.00001638,0.448517029 7.5523065,0.0008191222 7.00001638,0.0008191222 L1.00003161,0.0008191222 C0.732435059,-0.0104716604 0.473203082,0.0954078808 0.290032676,0.290807888 C0.102846443,0.479519408 -0.00150364404,0.734987696 1.63761951e-05,1.00078038 L1.63761951e-05,7.00054796 C1.63761951e-05,7.55281131 0.447747689,8.00050922 1.00003161,8.00050922 Z M2.0000301,2.00074165 L6.0000301,2.00074165 L6.0000301,6.00058669 L2.0000301,6.00058669 L2.0000301,2.00074165 Z' />
                            <path d='M15.000012,8.00050922 L21.0000015,8.00050922 C21.5522854,8.00050922 22,7.55281131 22,7.00054796 L22,1.00078038 C22,0.448517029 21.5522854,0.000819122199 21.0000015,0.000819122199 L15.000012,0.000819122199 C14.4477266,0.000819122199 14.000012,0.448517029 14.000012,1.00078038 L14.000012,7.00054796 C14.000012,7.55281131 14.4477266,8.00050922 15.000012,8.00050922 Z M16.000009,2.00074165 L20.000009,2.00074165 L20.000009,6.00058669 L16.000009,6.00058669 L16.000009,2.00074165 Z' />
                            <path d='M7.00001638,14.0002601 L1.00003161,14.0002601 L1.00003161,14.0002601 C0.734229023,13.9987401 0.478751223,14.1030863 0.290032676,14.2902656 C0.102846443,14.4789771 -0.00150364404,14.7344454 1.63761951e-05,15.0002381 L1.63761951e-05,21.0000056 C1.63761951e-05,21.552269 0.447747689,21.9999836 1.00003161,21.9999836 L7.00001638,21.9999836 C7.26582516,22.0015036 7.52130296,21.8971574 7.71002151,21.7099781 C7.89720774,21.5212666 8.00155783,21.2657983 8.00003781,21.0000056 L8.00003781,15.0002381 C8.00003781,14.4479747 7.5523065,14.0002601 7.00001638,14.0002601 Z M2.0000301,20.0000444 L2.0000301,16.0001993 L6.0000301,16.0001993 L6.0000301,20.0000444 L2.0000301,20.0000444 Z' />
                            <rect x={10.0000181} y={0.000819122199} width={1.99999699} height={7.9996901} />
                            <rect x={0.0000331124477} y={10.0004317} width={4.99999247} height={1.99992252} />
                            <path d='M11.0000166,10 C11.5128524,10 11.9355237,10.3860402 11.9932888,10.8833789 L12.0000166,11 L12.0000166,14.0002437 L10.0000166,14.0002437 L10,11.999 L6.97300566,12 L6.97300566,10 L11.0000166,10 Z' />
                            <path
                                d='M13.0068366,16.006559 C13.5196725,16.006559 13.9423438,16.3925992 14.0001089,16.8899379 L14.0068366,17.006559 L14.0068366,20.0068027 L12.0068366,20.0068027 L12.006,18.006 L9.99294379,18.006559 L9.99294379,16.006559 L13.0068366,16.006559 Z'
                                transform='translate(11.999890, 18.006681) rotate(-90.000000) translate(-11.999890, -18.006681) '
                            />
                            <rect x={10.0000181} y={20.0000444} width={2} height={1.99992252} />
                            <rect x={14.0580822} y={11.717914} width={2} height={1.99992252} />
                            <polygon points='20.0329285 10.0730826 22.0328143 10.0944583 22.0007508 13.0942869 20.000865 13.0729112' />
                            <path d='M17.1153441,22.0830018 L14.0328714,22.0837704 L14.0328714,20.0837704 L18.5713836,20.0831794 C18.0024056,20.6763623 17.5113656,21.3488256 17.1153441,22.0830018 Z M18.0328714,11.717914 L18.032,15.826 L21.0328714,15.826563 C21.5457072,15.826563 21.9683785,16.2126032 22.0261437,16.7099419 L22.0328714,16.826563 L22.0328242,17.8428693 C21.3210211,18.0935437 20.6497961,18.4341576 20.0319682,18.8515264 L20.032,17.826 L17.0328714,17.826563 C16.5200355,17.826563 16.0973642,17.4405228 16.0395991,16.9431841 L16.0328714,16.826563 L16.0328714,11.717914 L18.0328714,11.717914 Z' />
                        </g>
                        <polygon points='18.0328714 10.0835991 18.0328714 13.0835991 16.0328714 13.0835991 16.0328714 10.0835991' />
                    </g>
                    <path
                        d='M31,42 C25.4771525,42 21,37.5228475 21,32 C21,26.4771525 25.4771525,22 31,22 C36.5228475,22 41,26.4771525 41,32 C41,34.6521649 39.9464316,37.195704 38.0710678,39.0710678 C36.195704,40.9464316 33.6521649,42 31,42 Z M31,24 C26.581722,24 23,27.581722 23,32 C23,36.418278 26.581722,40 31,40 C35.418278,40 39,36.418278 39,32 C39,27.581722 35.418278,24 31,24 Z'
                        fill='#002664'
                    />
                    <path
                        d='M27,48 L3,48 C1.34314575,48 0,46.6568542 0,45 L0,3 C0,1.34314575 1.34314575,0 3,0 L27,0 C28.6568542,0 30,1.34314575 30,3 L30,21 L28,21 L28,3 C28,2.44771525 27.5522847,2 27,2 L3,2 C2.44771525,2 2,2.44771525 2,3 L2,45 C2,45.5522847 2.44771525,46 3,46 L27,46 C27.5522847,46 28,45.5522847 28,45 L28,43 L30,43 L30,45 C30,46.6568542 28.6568542,48 27,48 Z'
                        fill='#002664'
                    />
                </g>
            </g>
        </svg>
    );
};

BrandIconCodeCheckIn.propTypes = {
    title: PropTypes.string,
    titleId: PropTypes.string,
    size: PropTypes.oneOf(['lg', 'xl', 'xxl']),
};
BrandIconCodeCheckIn.defaultProps = {
    size: 'xl',
    focusable: false,
};
export default BrandIconCodeCheckIn;
