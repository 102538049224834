import * as React from 'react';
import PropTypes from 'prop-types';
import tokens from '../../Tokens/tokens';
const { spacing } = tokens;

const BrandIconTermsConditions = ({ title, titleId, ...props }) => {
    const sizeArray = Object.entries(spacing.icons);
    const sizeOptions = new Map(sizeArray);
    const displaySize = sizeOptions.get(props.size);
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 48 48'
            fill={props.color && displayColor}
            aria-hidden={!title}
            role={title ? 'img' : null}
            width={props.size && displaySize}
            height={props.size && displaySize}
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <defs>
                <style>{'.TermsConditions_svg__cls-2{fill:#002664}'}</style>
            </defs>
            <path d='M34 14a5 5 0 10-5 5 5 5 0 005-5z' fill='#d7153a' />
            <path
                className='TermsConditions_svg__cls-2'
                d='M32 24H12a1 1 0 000 2h20a1 1 0 000-2zm0 5H12a1 1 0 000 2h20a1 1 0 000-2zm0 5H12a1 1 0 000 2h20a1 1 0 000-2zm0 5H12a1 1 0 000 2h20a1 1 0 000-2z'
            />
            <path
                className='TermsConditions_svg__cls-2'
                d='M34 3.87H16a1 1 0 00-.67.26l-10 9.13A1 1 0 005 14v29a5 5 0 005 5h24a5 5 0 005-5V8.87a5 5 0 00-5-5zM37 43a3 3 0 01-3 3H10a3 3 0 01-3-3V14.44l8-7.31v7.74h-5v2h7v-11h17a3 3 0 013 3z'
            />
            <path className='TermsConditions_svg__cls-2' d='M38 0H20v2h18a3 3 0 013 3v38h2V5a5 5 0 00-5-5z' />
        </svg>
    );
};

BrandIconTermsConditions.propTypes = {
    title: PropTypes.string,
    titleId: PropTypes.string,
    size: PropTypes.oneOf(['lg', 'xl', 'xxl']),
};
BrandIconTermsConditions.defaultProps = {
    size: 'xl',
    focusable: false,
};
export default BrandIconTermsConditions;
