import styled from 'styled-components/macro';

const TertiaryButton = styled.button.attrs({ type: 'button' })`
    font-size: ${props => props.fontSize ? props.fontSize : '0.875rem'};
    font-weight: 400;
    cursor: pointer;
    color: #2e5299;
    background-color: transparent;
    border: none;
    padding-left: 0;
    padding-right: 0;
    text-align: left;
    text-decoration: ${props => props.textDecoration ? props.textDecoration : 'none'};
    
    && { 
      line-height: normal;
    }

    &:hover {
      text-decoration: underline;
    }
    
    *:first-child {
      margin-right: .4rem;
    }
`;

export default TertiaryButton;
