import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import { H6 } from '../../Headers/HeaderTag.styled';

export const InfoCardContainer = styled.div`
  background-color: white;
  border-radius: 0.375rem;
  height: auto;
  box-shadow: 0 0.13rem 0.5rem 0 rgba(0, 0, 0, 0.07);
`;

export const InfoCardHeader = styled(H6)`
  padding: 1rem 1.5rem;
  border-bottom: 1px solid #dee3e5;
  max-width: 100%;
`;

export const InfoCardContent = styled.div`
  padding: ${props => props.verticalPadding ? '1rem' : '0'} 1.5rem;
  
  a {
    font-weight: 500;
  }
`;

InfoCardContent.propTypes = {
    verticalPadding: PropTypes.bool,
};

InfoCardContent.defaultProps = {
    verticalPadding: true,
};