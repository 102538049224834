import styled from 'styled-components/macro';
import { pxToRem, getTypeSize } from '../../utils';
import tokens from '../../Tokens/tokens';

const { colors } = tokens;

const StyledAccordion = styled.div`
    width: 100%;
`;

StyledAccordion.Header = styled.div`
    margin-top: 8px;

    h2 {
        margin: 0;
    }

`;

StyledAccordion.Button = styled.button`
    background-color: ${colors.grey.iceBlue};
    color: ${colors.text.body};
    margin: 0;
    display: flex;
    padding: ${pxToRem(16)} ${pxToRem(24)};
    font-weight: bold;
    width: 100%;
    align-items: flex-start;
    justify-content: flex-start;
    align-content: center;
    ${getTypeSize('sm')};
    border: none;
    cursor: pointer;

    &:hover, &[aria-expanded="true"] {
        background-color: ${colors.brand.snswSecondaryBlue};
        color: ${colors.white};

        svg {
            fill: ${colors.white};
        }
    }

    &:focus {
        outline: 2px solid ${colors.borders.focus};
        outline-offset: 2px;
    }

    span {
        width: 100%;
        margin: 0 ${pxToRem(16)} 0 0;
        text-align: left;
    }

    svg {
        width: ${pxToRem(16)};
        height: ${pxToRem(16)};
        flex: 0 0 ${pxToRem(16)};
        align-self: center;
        fill: ${colors.brand.snswPrimaryBlue};
    }
`;

StyledAccordion.Content = styled.section`
    padding: 24px 20px;
    border-left: solid 4px ${colors.brand.snswSecondaryBlue};
    &.hide-content {
        display: none;
    }
`;

StyledAccordion.Toggles = styled.div`
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    align-content: center;

    button:first-child {
        margin-right: ${pxToRem(16)};
    }
`;

export default StyledAccordion;
