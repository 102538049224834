import * as React from 'react';
import PropTypes from 'prop-types';
import tokens from '../../Tokens/tokens';
const { spacing } = tokens;

const BrandIconUseYourDdl = ({ title, titleId, ...props }) => {
    const sizeArray = Object.entries(spacing.icons);
    const sizeOptions = new Map(sizeArray);
    const displaySize = sizeOptions.get(props.size);
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 48 48'
            fill={props.color && displayColor}
            aria-hidden={!title}
            role={title ? 'img' : null}
            width={props.size && displaySize}
            height={props.size && displaySize}
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <defs>
                <style>{'.UseYourDDL_svg__cls-2{fill:#002664}'}</style>
            </defs>
            <path d='M19 44a5 5 0 115-5 5 5 0 01-5 5z' fill='#d7153a' />
            <path className='UseYourDDL_svg__cls-2' d='M36 44a10 10 0 1110-10 10 10 0 01-10 10zm0-18a8 8 0 108 8 8 8 0 00-8-8z' />
            <path
                className='UseYourDDL_svg__cls-2'
                d='M33.88 38.72a1 1 0 01-.71-.29L30 35.28a1 1 0 011.41-1.41l2.44 2.43 5.5-5.66A1 1 0 1140.8 32l-6.21 6.38a1 1 0 01-.71.3z'
            />
            <circle cx={19} cy={14} r={3} fill='none' />
            <path
                className='UseYourDDL_svg__cls-2'
                d='M35 4.87A5 5 0 0030 0H8a5 5 0 00-5 5v38a5 5 0 005 5h22a5 5 0 004.11-2.16 12.23 12.23 0 01-2.18-.56A3 3 0 0130 46H8a3 3 0 01-3-3V5a3 3 0 013-3h22a3 3 0 013 3v17.39a13 13 0 012-.34V4.87z'
            />
            <path
                className='UseYourDDL_svg__cls-2'
                d='M29 5H9a1 1 0 00-1 1v20a1 1 0 001 1h17.27A12.19 12.19 0 0130 23.62V6a1 1 0 00-1-1zM13 25a3 3 0 013-3h6a3 3 0 013 3zm15 0h-1a5 5 0 00-5-5h-6a5 5 0 00-5 5h-1V7h18z'
            />
            <path className='UseYourDDL_svg__cls-2' d='M19 19a5 5 0 10-5-5 5 5 0 005 5zm0-8a3 3 0 11-3 3 3 3 0 013-3z' />
        </svg>
    );
};

BrandIconUseYourDdl.propTypes = {
    title: PropTypes.string,
    titleId: PropTypes.string,
    size: PropTypes.oneOf(['lg', 'xl', 'xxl']),
};
BrandIconUseYourDdl.defaultProps = {
    size: 'xl',
    focusable: false,
};
export default BrandIconUseYourDdl;
