import styled from 'styled-components/macro';
import { AccordionHeader, AccordionTileContainer } from './Accordion.styled';
import { colors } from '../GlobalStyle/colors/colors';
import { LabelContainer } from './Label.styled';

export const AccordionWhite = styled.div`
  ${AccordionTileContainer} {
    :last-child::after {
        content: '';
        display: block;
        width: calc(100% + 1.5rem);
        height: 100%;
        border-bottom: ${colors.lightGrey2} solid 1px;
        position: relative;
        bottom: -25px;
    }
    .top {
       margin-top: 2px;
       border-left: ${colors.blackBlue} dashed 2px;
    }

    &:first-of-type {
      .top {
        border-left: none;
      }
    }

    .bottom {
      margin-bottom: 2px;
      border-left: ${colors.blackBlue} dashed 2px;
    }

    :last-child .bottom {
      &:before {
        content: '';
        position: absolute;
        width: 14px;
        height: 2px;
        background: ${colors.darkGrey};
        bottom: 0;
        left: 10px;
      }
    }
  }

  ${AccordionHeader} {
    ${LabelContainer} {
      border: 1px solid ${colors.darkGrey};
      color: ${colors.blackBlue};
      background-color: ${colors.white};
    }
  }
`;
