import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { trackMe } from '../../Components/ComponentAnalytics/componentAnalytics';
import { AutoSuggest } from '../..';
import useInternationalAddress from './useInternationalAddress';
import StyledInternationalAddress from './InternationalAddress.styled';
import InternationalAddressForm from './InternationalAddressForm';
import { getId, getValue, getErrorMessage, getHasError } from '../utils';

const InternationalAddress = ({
    id,
    value,
    onChange,
    countriesOverride
}) => {
    // custom hook handling state
    const { countries } = useInternationalAddress(countriesOverride);

    // check to see if the value object contains key/value pairs where the value is an object
    // Object.keys() returns an array of any of the keys which return true for the expression
    const keysWithObjectsAsValues = !value ? [] : Object.keys(value).filter(key =>
        // true if;
        // 1) key is in the value object
        // 2) the value of the key is an object
        // 3) the object value of the key contains a key called 'value'
        // eslint-disable-next-line security/detect-object-injection
        value[key] && 'object' === typeof value[key] && ('value' in value[key])
    );

    // check whether the user has either not included a value, or entered a value with obsolete structure
    const isObsoleteValue = !value || keysWithObjectsAsValues.length <= 0;

    useEffect(() => {
        trackMe('PatternInternationalAddress');
    }, []);

    /**
     * handles the updating of data, and calls callback function accordingly
     * @param {string} key
     * @param {string} updatedValue
     */
    const handleOnChange = (key, updatedValue) => {
        // copy existing values
        const newValue = value ? { ...value } : {};

        if (isObsoleteValue) {
            // structure shape, as obsolete value returned 'null' for inputs not defined
            newValue.country = newValue.country || null;
            newValue.address1 = newValue.address1 || null;
            newValue.address2 = newValue.address2 || null;
            newValue.city = newValue.city || null;
            newValue.state = newValue.state || null;
            newValue.postcode = newValue.postcode || null;
            // set the newly updated value
            // eslint-disable-next-line security/detect-object-injection
            newValue[key] = updatedValue;
        } else {
            // if key exists in value object, update it whilst keeping existing data
            // else create new key/value pair
            // eslint-disable-next-line security/detect-object-injection
            newValue[key] = (key in newValue) ?
                // eslint-disable-next-line security/detect-object-injection
                { ...newValue[key], value: updatedValue } :
                { value: updatedValue };
        }

        // call onChange callback
        onChange && onChange(newValue, key);
    };

    /**
     * helper function to get obsolete value from a pattern's value object
     * @param {string} key
     * @param {object} value
     * @returns a value extracted from the value object by key, or an empty string
     */
    const getObsoleteValue = (key, value) =>
        // eslint-disable-next-line security/detect-object-injection
        (key && value && value[key]) ? value[key] : ''
    ;

    return (
        <StyledInternationalAddress id={ `${id}-international-address` }>
            <AutoSuggest
                id={ getId('country', value, `${id}-country`) }
                label='Country/region'
                suggestions={ countries }
                initialValue={ isObsoleteValue ? getObsoleteValue('country', value) : getValue('country', value) }
                onSelect={ value => handleOnChange('country', value) }
                errorMessage={ getErrorMessage('country', value) }
                hasError={ getHasError('country', value) }
            />
            <InternationalAddressForm
                id={ `${id}-address-form` }
                value={ value || {} }
                onChange={ handleOnChange }
                isObsoleteValue={ isObsoleteValue }
                getObsoleteValue={ getObsoleteValue }
            />
        </StyledInternationalAddress>
    );
};

InternationalAddress.propTypes = {
    /** Unique ID for the container, also used as a prefix for the form input fields. */
    id: PropTypes.string.isRequired,
    /** Default values, preloaded into input fields. */
    value: PropTypes.shape({
        country: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.shape({
                id: PropTypes.string,
                value: PropTypes.string,
                errorMessage: PropTypes.string,
            }),
        ]),
        address1: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.shape({
                id: PropTypes.string,
                value: PropTypes.string,
                errorMessage: PropTypes.string,
            }),
        ]),
        address2: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.shape({
                id: PropTypes.string,
                value: PropTypes.string,
            }),
        ]),
        city: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.shape({
                id: PropTypes.string,
                value: PropTypes.string,
            }),
        ]),
        state: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.shape({
                id: PropTypes.string,
                value: PropTypes.string,
            }),
        ]),
        postcode: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.shape({
                id: PropTypes.string,
                value: PropTypes.string,
            }),
        ]),
    }),
    /** Hooked into onChange event for all input fields. */
    onChange: PropTypes.func,
    /** An array of countries to prefill the country suggestions. */
    countriesOverride: PropTypes.array,
};

export default InternationalAddress;
