import React, { forwardRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { trackMe } from '../../Components/ComponentAnalytics/componentAnalytics';
import { StyledManualAddress, ReadonlyInput } from './ManualAddress.styled';
import { getRef, getId, getValue, getErrorMessage, getHasError, stripKeyFromValue } from '../utils';
import { FormGroup, Input, Select } from '../..';
import useManualAddress from './useManualAddress';

const ManualAddress = forwardRef(({
    id,
    value,
    onChange,
    onBlur,
    statesOverride
}, ref) => {
    // custom hook handling state
    const { australianStates } = useManualAddress(statesOverride);

    useEffect(() => {
        trackMe('PatternManualAddress [GEL]');
    }, []);

    // check country is in value object, and ensure it always returns a value of 'Australia'
    value.country = ('country' in value) ? { ...value.country, value: 'Australia' } : { value: 'Australia' };

    /**
     * handles the updating of data, and calls callback function accordingly
     * @param {string} key
     * @param {string} updatedValue
     */
    const handleOnChange = (key, updatedValue) => {
        // remove all refs from value before returning it
        const newValue = stripKeyFromValue('inputRef', value);

        // if key exists in value object, update it whilst keeping existing data, else create new key/value pair
        newValue[`${key}`] = (key in newValue) ? { ...newValue[`${key}`], value: updatedValue } : { value: updatedValue };

        // call onChange callback
        onChange && onChange(newValue, key);
    };

    /**
     * handles the updating of data, and calls callback function accordingly
     * @param {string} key
     * @param {string} updatedValue
     */
    const handleOnBlur = (key, updatedValue) => {
        // remove all refs from value before returning it
        const newValue = stripKeyFromValue('inputRef', value);

        // if key exists in value object, update it whilst keeping existing data, else create new key/value pair
        newValue[`${key}`] = (key in newValue) ? { ...newValue[`${key}`], value: updatedValue } : { value: updatedValue };

        // call onChange callback
        onBlur && onBlur(newValue, key);
    };

    return (
        <StyledManualAddress id={ `${id}-australian-address` } ref={ ref }>
            <FormGroup
                id={ `${id}-form-group-street-address` }
                label='Street address'
                errorMessage={ getErrorMessage('streetAddress', value) }
                hasError={ getHasError('streetAddress', value) }
                isRequired={ true }
            >
                <Input
                    id={ getId('streetAddress', value, `${id}-street-address`) }
                    name='streetAddress'
                    autoComplete='street-address'
                    value={ getValue('streetAddress', value) }
                    inputRef={ getRef('streetAddress', value) }
                    onChange={ e => handleOnChange(e.target.name, e.target.value) }
                    onBlur={ e => handleOnBlur(e.target.name, e.target.value) }
                />
            </FormGroup>
            <FormGroup
                id={ `${id}-form-group-suburb` }
                label='Suburb'
                errorMessage={ getErrorMessage('suburb', value) }
                hasError={ getHasError('suburb', value) }
                isRequired={ true }
            >
                <Input
                    id={ getId('suburb', value, `${id}-suburb`) }
                    name='suburb'
                    autoComplete='address-level2'
                    value={ getValue('suburb', value) }
                    inputRef={ getRef('suburb', value) }
                    onChange={ e => handleOnChange(e.target.name, e.target.value) }
                    onBlur={ e => handleOnBlur(e.target.name, e.target.value) }
                />
            </FormGroup>
            <FormGroup
                id={ `${id}-form-group-state` }
                label='State'
                errorMessage={ getErrorMessage('state', value) }
                hasError={ getHasError('state', value) }
                isRequired={ true }
            >
                <Select
                    id={ getId('state', value, `${id}-state`) }
                    name='state'
                    autoComplete='address-level1'
                    options={ australianStates }
                    onChange={ e => handleOnChange(e.target.name, e.target.value) }
                    onBlur={ e => handleOnBlur(e.target.name, e.target.value) }
                    value={ getValue('state', value) }
                    inputRef={ getRef('state', value) }
                    inputWidth='sm'
                />
            </FormGroup>
            <FormGroup
                id={ `${id}-form-group-postcode` }
                label='Postcode'
                hasError={ getHasError('postcode', value) }
                errorMessage={ getErrorMessage('postcode', value) }
                isRequired={ true }
            >
                <Input
                    id={ getId('postcode', value, `${id}-postcode`) }
                    name='postcode'
                    autoComplete='postal-code'
                    value={ getValue('postcode', value) }
                    onChange={ e => handleOnChange(e.target.name, e.target.value) }
                    onBlur={ e => handleOnBlur(e.target.name, e.target.value) }
                    inputRef={ getRef('postcode', value) }
                    maxLength={ 4 }
                    inputWidth='xs'
                />
            </FormGroup>
            <FormGroup
                id={ `${id}-form-group-country` }
                label='Country'
                hasError={ getHasError('country', value) }
                errorMessage={ getErrorMessage('country', value) }
                isRequired={ true }
            >
                <ReadonlyInput
                    id={ getId('country', value, `${id}-country`) }
                    name='country'
                    value={ getValue('country', value) }
                    inputRef={ getRef('country', value) }
                    readOnly
                />
            </FormGroup>
        </StyledManualAddress>
    );
});

ManualAddress.propTypes = {
    /** Unique ID for the container, also used as a prefix for the form input fields. */
    id: PropTypes.string.isRequired,
    /** Default values, preloaded into input fields. */
    value: PropTypes.shape({
        country: PropTypes.shape({
            id: PropTypes.string,
            value: PropTypes.oneOf(['Australia', 'AUSTRALIA', '']),
            errorMessage: PropTypes.string,
            inputRef: PropTypes.oneOfType([
                PropTypes.func,
                PropTypes.object,
            ]),
        }),
        streetAddress: PropTypes.shape({
            id: PropTypes.string,
            value: PropTypes.string,
            errorMessage: PropTypes.string,
            inputRef: PropTypes.oneOfType([
                PropTypes.func,
                PropTypes.object,
            ]),
        }),
        postcode: PropTypes.shape({
            id: PropTypes.string,
            value: PropTypes.string,
            errorMessage: PropTypes.string,
            inputRef: PropTypes.oneOfType([
                PropTypes.func,
                PropTypes.object,
            ]),
        }),
        suburb: PropTypes.shape({
            id: PropTypes.string,
            value: PropTypes.string,
            errorMessage: PropTypes.string,
            inputRef: PropTypes.oneOfType([
                PropTypes.func,
                PropTypes.object,
            ]),
        }),
        state: PropTypes.shape({
            id: PropTypes.string,
            value: PropTypes.string,
            errorMessage: PropTypes.string,
            inputRef: PropTypes.oneOfType([
                PropTypes.func,
                PropTypes.object,
            ]),
        }),
    }).isRequired,
    /** Hooked into onChange event for all input fields. */
    onChange: PropTypes.func.isRequired,
    /** Hooked into onBlur event for all input fields. */
    onBlur: PropTypes.func,
    /** An array of states to prefill the state suggestions. */
    statesOverride: PropTypes.arrayOf(PropTypes.string)
};

export default ManualAddress;
