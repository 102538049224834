import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { FullScreenOpac } from '../layout/Screen/Screen.styled';
import { StyledSlidingModal } from './SlidingModal.styled';
import SlidingTransition from './SlidingTransition.styled';
import { getFocusableElement, trapTabKey } from '../utils';
import { SROnly } from '../GlobalStyle/utilities/utilities.styled';
import { trackMe } from '../Components/ComponentAnalytics/componentAnalytics';

const SlidingModal = ({ children, open, onClose }) => {

    useEffect(() => {
        trackMe('SlidingModal');
    }, []);

    const [currentAnimation, setCurrentAnimation] = useState('slidein');
    const [nextAnimation, setNextAnimation] = useState('slidein');
    const closeRef = useRef(null);
    const modalRef = useRef(null);

    const handleAnimationEnd = animationName => {
        if (animationName === 'slidein') {
            setNextAnimation('slideout');
        } else {
            setNextAnimation('slidein');
            setCurrentAnimation('slidein');
        }
    };

    const handleTabKey = e => {
        const focusableElems = getFocusableElement(modalRef.current);
        trapTabKey(e, focusableElems);
    };

    const onEscClose = e => {
        onClose();
    };

    const keyListenersMap = new Map([[27, onEscClose], [9, handleTabKey]]);

    useEffect(() => {
        const keyListener = e => {
            const listener = keyListenersMap.get(e.keyCode);
            return listener && listener(e);
        };

        if (open) {
            closeRef.current.focus();
            document.addEventListener('keydown', keyListener);
        } else {
            setCurrentAnimation(nextAnimation);
            document.removeEventListener('keydown', keyListener);
        }

        // check this is removed even without the return
        return () => document.removeEventListener('keydown', keyListener);
    },[open]); // eslint-disable-line

    const show = open || currentAnimation === 'slideout';

    if (!show) {
        return null;
    }

    return (
        <>
            <FullScreenOpac onClick={ onClose } />
            <SlidingTransition
                role='dialog'
                aria-modal='true'
                animation={ currentAnimation }
                ref={ modalRef }
                onAnimationEnd={ event =>
                    handleAnimationEnd(event.animationName) }
            >
                <StyledSlidingModal>
                    <StyledSlidingModal.DismissButton
                        onClick={ onClose }
                        data-test='modal-close-button'
                        ref={ closeRef }
                    >
                        <StyledSlidingModal.CloseIcon />
                        <SROnly>Close</SROnly>
                    </StyledSlidingModal.DismissButton>
                    {children}
                </StyledSlidingModal>
            </SlidingTransition>
        </>
    );
};

SlidingModal.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    children: PropTypes.node,
};

export default SlidingModal;
