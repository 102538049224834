import * as React from 'react';
import PropTypes from 'prop-types';
import tokens from '../../Tokens/tokens';
const { spacing } = tokens;

const BrandIconFeaturedServices = ({ title, titleId, ...props }) => {
    const sizeArray = Object.entries(spacing.icons);
    const sizeOptions = new Map(sizeArray);
    const displaySize = sizeOptions.get(props.size);
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 48 48'
            fill={props.color && displayColor}
            aria-hidden={!title}
            role={title ? 'img' : null}
            width={props.size && displaySize}
            height={props.size && displaySize}
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <defs>
                <style>{'.FeaturedServices_svg__cls-2{fill:#002664}'}</style>
            </defs>
            <path d='M40.54 3.46a5 5 0 11-7.08 0 5 5 0 017.08 0z' fill='#d7153a' />
            <path
                className='FeaturedServices_svg__cls-2'
                d='M10.71 6.29a1 1 0 00-1.42 0L8 7.59l-1.29-1.3a1 1 0 00-1.42 1.42L6.59 9l-1.3 1.29a1 1 0 000 1.42 1 1 0 001.42 0L8 10.41l1.29 1.3a1 1 0 001.42 0 1 1 0 000-1.42L9.41 9l1.3-1.29a1 1 0 000-1.42zm-5 31a1 1 0 00-1.42 0L3 38.59l-1.29-1.3a1 1 0 00-1.42 1.42L1.59 40l-1.3 1.29a1 1 0 000 1.42 1 1 0 001.42 0L3 41.41l1.29 1.3a1 1 0 001.42 0 1 1 0 000-1.42L4.41 40l1.3-1.29a1 1 0 000-1.42zM46.41 34l1.3-1.29a1 1 0 00-1.42-1.42L45 32.59l-1.29-1.3a1 1 0 00-1.42 1.42l1.3 1.29-1.3 1.29a1 1 0 000 1.42 1 1 0 001.42 0l1.29-1.3 1.29 1.3a1 1 0 001.42 0 1 1 0 000-1.42zM46 14a1 1 0 111-1 1 1 0 01-1 1zM15 2a1 1 0 111-1 1 1 0 01-1 1zm6.5 46a1 1 0 111-1 1 1 0 01-1 1zM4 30.5a1 1 0 111-1 1 1 0 01-1 1z'
            />
            <path
                className='FeaturedServices_svg__cls-2'
                d='M11 48a2.9 2.9 0 01-1.66-.53 3.06 3.06 0 01-1.23-3l2.14-13.08a1.07 1.07 0 00-.28-.93L.87 21.2a3.08 3.08 0 01-.71-3.14 3 3 0 012.35-2l12.54-1.9a1 1 0 00.7-.55L21.36 1.7a2.9 2.9 0 015.28 0l5.61 11.9a1 1 0 00.7.55l12.54 1.9a3 3 0 012.35 2 3.08 3.08 0 01-.71 3.14l-9.08 9.26a1.07 1.07 0 00-.28.93l2.14 13.08a3 3 0 01-1.24 3 2.86 2.86 0 01-3 .17l-11.25-6.16a.84.84 0 00-.84 0l-11.21 6.17A2.88 2.88 0 0111 48zm13-8.64a2.92 2.92 0 011.38.35l11.22 6.18a.84.84 0 00.92-.06 1 1 0 00.42-1l-2.15-13.12a3.13 3.13 0 01.83-2.65l9.08-9.26a1.09 1.09 0 00.24-1.11 1 1 0 00-.75-.66l-12.54-1.91a2.94 2.94 0 01-2.21-1.67L24.83 2.56a.9.9 0 00-1.66 0l-5.61 11.89a2.92 2.92 0 01-2.21 1.67L2.81 18a1 1 0 00-.75.66 1.09 1.09 0 00.24 1.14l9.08 9.26a3.13 3.13 0 01.83 2.65l-2.15 13.08a1 1 0 00.42 1 .84.84 0 00.92.06l11.22-6.18a2.92 2.92 0 011.38-.31z'
            />
        </svg>
    );
};

BrandIconFeaturedServices.propTypes = {
    title: PropTypes.string,
    titleId: PropTypes.string,
    size: PropTypes.oneOf(['lg', 'xl', 'xxl']),
};
BrandIconFeaturedServices.defaultProps = {
    size: 'xl',
    focusable: false,
};
export default BrandIconFeaturedServices;
