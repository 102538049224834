import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Field, {
    AffixWrapperStyled,
    PrefixStyled,
    PrefixWrapperStyled,
    SuffixStyled,
    SuffixWrapperStyled,
} from '../Form/Field.styled';
import { trackMe } from '../ComponentAnalytics/componentAnalytics';

const Input = ({ id, name, type, value, onChange, disabled, hasError, inputWidth, inputRef, prefix, suffix, ...rest }) => {

    useEffect(() => {
        trackMe('Input');
    }, []);

    const renderInputWithAddons = () => (
        <AffixWrapperStyled>
            {prefix &&
                <PrefixWrapperStyled>
                    <PrefixStyled hasError={ hasError }>
                        {prefix}
                    </PrefixStyled>
                </PrefixWrapperStyled>
            }
            <Field.Input
                id={ id }
                name={ name }
                type={ type }
                value={ value }
                onChange={ onChange }
                disabled={ disabled }
                hasError={ hasError }
                inputWidth={ inputWidth }
                ref={ inputRef }
                prefix={ prefix }
                suffix={ suffix }
                { ...rest }
            />
            {suffix &&
                <SuffixWrapperStyled>
                    <SuffixStyled hasError={ hasError }>
                        {suffix}
                    </SuffixStyled>
                </SuffixWrapperStyled>
            }
        </AffixWrapperStyled>
    );

    return (
        (prefix || suffix) ?
            renderInputWithAddons() :
            <Field.Input
                id={ id }
                name={ name }
                type={ type }
                value={ value }
                onChange={ onChange }
                disabled={ disabled }
                hasError={ hasError }
                inputWidth={ inputWidth }
                ref={ inputRef }
                { ...rest }
            />
    );
};

Input.propTypes = {
    id: PropTypes.string,
    name: PropTypes.string,
    type: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    hasError: PropTypes.bool,
    inputWidth: PropTypes.oneOf(['xxs', 'xs', 'sm', 'md', 'lg', 'xl']),
    inputRef: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.object,
    ]),
    prefix: PropTypes.string,
    suffix: PropTypes.string,
};

Input.defaultProps = {
    type: 'text',
    hasError: false,
    disabled: false,
};

export default Input;
