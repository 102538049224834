import * as React from 'react';
import PropTypes from 'prop-types';
import tokens from '../../Tokens/tokens';
const { spacing } = tokens;

const BrandIconBirths = ({ title, titleId, ...props }) => {
    const sizeArray = Object.entries(spacing.icons);
    const sizeOptions = new Map(sizeArray);
    const displaySize = sizeOptions.get(props.size);
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 48 48'
            fill={props.color && displayColor}
            aria-hidden={!title}
            role={title ? 'img' : null}
            width={props.size && displaySize}
            height={props.size && displaySize}
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <defs>
                <style>{'.Births_svg__cls-1{fill:#002664}'}</style>
            </defs>
            <path
                className='Births_svg__cls-1'
                d='M31 0H17a9 9 0 00-9 9v30a9 9 0 009 9h14.82a1.55 1.55 0 00.18 0 1 1 0 00.47-.14A9 9 0 0040 39V9a9 9 0 00-9-9zM10 39V26.44L29.61 46H17a7 7 0 01-7-7zm3.42-12l3.11-3.12A11 11 0 0024.07 27h.06a10.8 10.8 0 007.47-3l3.4 3.4-10.9 10.28zM38 39a7 7 0 01-5.68 6.87l-6.8-6.77L38 27.34zm0-14.4L36.48 26l-3.56-3.56a11.37 11.37 0 001.88-4.06 2.9 2.9 0 001.13-.76 3.81 3.81 0 00.94-2.73 3.54 3.54 0 00-2.36-3.32 11.44 11.44 0 00-7.88-7.34 1 1 0 00-1.21.77 1 1 0 00.71 1.22 9.47 9.47 0 016.66 6.6 1 1 0 00.09.22 1 1 0 001.1.52 1.59 1.59 0 01.89 1.44 1.84 1.84 0 01-.41 1.29.86.86 0 01-.46.26 1 1 0 00-1 .68 1 1 0 000 .23A9.11 9.11 0 0124.12 25a9.35 9.35 0 01-9-7.8 1 1 0 00-1-.8 1.44 1.44 0 01-.85-1.52 1.81 1.81 0 01.5-1.25.81.81 0 01.49-.23.94.94 0 00.41 0 1 1 0 00.68-.7 9.07 9.07 0 016.52-6.51 5.4 5.4 0 002.8 3.74 1 1 0 10.84-1.82C23.79 7.32 23.63 5 23.63 5a.88.88 0 00-.06-.29A1 1 0 0022.49 4a11 11 0 00-8.93 7.49 2.92 2.92 0 00-1.2.67 3.76 3.76 0 00-1.15 2.65 3.47 3.47 0 002.06 3.44 11.77 11.77 0 001.94 4.14L12 25.61l-2-2V9a7 7 0 017-7h14a7 7 0 017 7z'
            />
            <path
                className='Births_svg__cls-1'
                d='M19.32 11.31a1.15 1.15 0 00-.21.33A1 1 0 0019 12a1 1 0 00.29.71 1 1 0 100-1.42zm8.15 1.52l.18.09.18.06h.2a1 1 0 10-.71-1.71 1.15 1.15 0 00-.21.33 1 1 0 00-.11.4 1.05 1.05 0 00.29.71z'
            />
            <path d='M24 24a5 5 0 115-5 5 5 0 01-5 5z' fill='#d7153a' />
        </svg>
    );
};

BrandIconBirths.propTypes = {
    title: PropTypes.string,
    titleId: PropTypes.string,
    size: PropTypes.oneOf(['lg', 'xl', 'xxl']),
};
BrandIconBirths.defaultProps = {
    size: 'xl',
    focusable: false,
};
export default BrandIconBirths;
