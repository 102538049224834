import { css } from 'styled-components';
import styled from 'styled-components/macro';
import { getSpacing, getTypeSize, getBreakpoint } from '../../utils';

const HeadingStyle = styled.h1`
    letter-spacing: 0;
    font-weight: 700;
    margin: 0;

    ${props => props.as && headingSizes[props.as]};
`;

const h1Style = css`
    ${getTypeSize('xxl', 'heading')};
    margin-top: ${getSpacing('md')};
    margin-bottom: ${getSpacing('lg')};

    ${getBreakpoint.md`
        margin-top: ${getSpacing('xl')};
        margin-bottom: ${getSpacing('xxl')};
    `}
`;

const h2Style = css`
    ${getTypeSize('xl', 'heading')};
    margin-top: ${getSpacing('lg')};
    margin-bottom: ${getSpacing('sm')};

    ${getBreakpoint.md`
        margin-top: ${getSpacing('xxxl')};
        margin-bottom: ${getSpacing('md')};
    `}
`;

const h3Style = css`
    ${getTypeSize('lg', 'heading')};
    margin-top: ${getSpacing('md')};
    margin-bottom: ${getSpacing('sm')};

    ${getBreakpoint.md`
        margin-top: ${getSpacing('lg')};
        margin-bottom: ${getSpacing('md')};
    `}
`;

const h4Style = css`
    ${getTypeSize('md', 'heading')};
    margin-top: ${getSpacing('sm')};
    margin-bottom: ${getSpacing('sm')};

    ${getBreakpoint.md`
        margin-top: ${getSpacing('md')};
        margin-bottom: ${getSpacing('md')};
    `}
`;

const h5Style = css`
    ${getTypeSize('sm')};
    margin-top: ${getSpacing('sm')};
    margin-bottom: ${getSpacing('sm')};

    ${getBreakpoint.md`
        margin-top: ${getSpacing('md')};
        margin-bottom: ${getSpacing('md')};
    `}
`;

const h6Style = css`
    ${getTypeSize('sm')};
    margin-top: ${getSpacing('sm')};
    margin-bottom: ${getSpacing('sm')};

    ${getBreakpoint.md`
        margin-top: ${getSpacing('md')};
        margin-bottom: ${getSpacing('md')};
    `}
`;

const headingSizes = {
    h1: h1Style,
    h2: h2Style,
    h3: h3Style,
    h4: h4Style,
    h5: h5Style,
    h6: h6Style,
};

export default HeadingStyle;
