import * as React from 'react';
import PropTypes from 'prop-types';
import tokens from '../../Tokens/tokens';
const { spacing } = tokens;

const BrandIconTruck = ({ title, titleId, ...props }) => {
    const sizeArray = Object.entries(spacing.icons);
    const sizeOptions = new Map(sizeArray);
    const displaySize = sizeOptions.get(props.size);
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 48 48'
            fill={props.color && displayColor}
            aria-hidden={!title}
            role={title ? 'img' : null}
            width={props.size && displaySize}
            height={props.size && displaySize}
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <path
                d='M9 29h11a1 1 0 000-2H9a1 1 0 000 2zm39-9.21l-4-19A1 1 0 0043 0H31a1 1 0 00-1 1v30H1a1 1 0 00-1 1v11a1 1 0 001 1h3.1a5 5 0 010-2H2v-9h29a1 1 0 001-1V2h10.19l3.58 17H36V9h-2v11a1 1 0 001 1h11v21h-2.1a5 5 0 00-9.8 0h-7.2a5 5 0 00-9.8 0h-3.2a5.05 5.05 0 010 2h3.2a5 5 0 009.8 0h7.2a5 5 0 009.8 0H47a1 1 0 001-1V20a1.49 1.49 0 000-.21zM22 46a3 3 0 113-3 3 3 0 01-3 3zm17 0a3 3 0 113-3 3 3 0 01-3 3z'
                fill='#002664'
            />
            <path d='M4 43a5 5 0 105-5 5 5 0 00-5 5z' fill='#d7153a' />
        </svg>
    );
};

BrandIconTruck.propTypes = {
    title: PropTypes.string,
    titleId: PropTypes.string,
    size: PropTypes.oneOf(['lg', 'xl', 'xxl']),
};
BrandIconTruck.defaultProps = {
    size: 'xl',
    focusable: false,
};
export default BrandIconTruck;
