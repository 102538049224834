import {
    UploadIcon,
    DropZoneWrapper,
    DropZoneMask,
    DropZoneText,
} from './DropZone.styled';
import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useBoolean } from '../../utils';
import { trackMe } from '../ComponentAnalytics/componentAnalytics';

const DropZone = ({
    className,
    id,
    testId,
    fileInput,
    helpMessage,
    hasError,
    onDrop,
    showUploadIcon
}) => {
    const [isDraggedIn, setDrag, removeDrag] = useBoolean(false);
    const overlay = useRef(null);

    const renderHelpMessage = (typeof helpMessage === 'object') ? helpMessage : <p>{helpMessage}</p>;

    useEffect(() => {
        trackMe('DropZone');
    }, []);

    const handleDrop = e => {
        e.preventDefault();
        removeDrag();
        onDrop(e);
    };

    const handleDrag = e => {
        e.preventDefault();
        e.stopPropagation();
    };

    const handleDragIn = e => {
        e.preventDefault();
        e.stopPropagation();
        if (e.target !== overlay.current) {
            setDrag();
        }
    };

    const handleDragOut = e => {
        e.preventDefault();
        e.stopPropagation();
        if (e.target === overlay.current) {
            removeDrag();
        }
    };

    return (
        <DropZoneWrapper
            className={ className }
            id={ id }
            data-testid={ testId }
            isDraggedIn={ isDraggedIn }
            hasError={ hasError }
            onDrop={ handleDrop }
            onDragOver={ handleDrag }
            onDragEnter={ handleDragIn }
            onDragLeave={ handleDragOut }
        >
            {isDraggedIn &&
                <DropZoneMask ref={ overlay }>Drop files here</DropZoneMask>
            }
            <DropZoneText>
                {showUploadIcon && <UploadIcon />}
                {renderHelpMessage}
            </DropZoneText>
            {fileInput}
        </DropZoneWrapper>
    );
};

DropZone.propTypes = {
    className: PropTypes.string,
    id: PropTypes.string.isRequired,
    /** Error state */
    hasError: PropTypes.bool,
    /** data-testid for the react testing library */
    testId: PropTypes.string,
    /** Button inside of the component */
    fileInput: PropTypes.node,
    /** helpMessage inside of the component */
    helpMessage: PropTypes.node,
    /** Function that returns an event */
    onDrop: PropTypes.func.isRequired,
    showUploadIcon: PropTypes.bool,
};

DropZone.defaultProps = {
    showUploadIcon: false,
};

export default DropZone;

