import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Field from '../Form/Field.styled';
import { trackMe } from '../ComponentAnalytics/componentAnalytics';

const Textarea = ({ id, name, value, onChange, disabled, hasError, rows, inputWidth, inputRef, ...rest }) => {

    useEffect(() => {
        trackMe('Textarea');
    }, []);

    const sizeOptionsArr = [
        ['small', 4],
        ['medium', 6],
        ['large', 8]
    ];

    const sizeOptions = new Map(sizeOptionsArr);

    const rowSize = sizeOptions.get(rows);

    return (
        <Field.TextArea
            id={ id }
            name={ name }
            value={ value }
            onChange={ onChange }
            disabled={ disabled }
            hasError={ hasError }
            rows={ rowSize }
            inputWidth={ inputWidth }
            ref={ inputRef }
            { ...rest }
        />
    );
};

Textarea.propTypes = {
    id: PropTypes.string,
    name: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    hasError: PropTypes.bool,
    rows: PropTypes.oneOf(['small', 'medium', 'large']),
    inputWidth: PropTypes.oneOf(['xxs', 'xs', 'sm', 'md', 'lg', 'xl']),
    inputRef: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.object,
    ]),
};

Textarea.defaultProps = {
    disabled: false,
    hasError: false,
    rows: 'small',
};

export default Textarea;
