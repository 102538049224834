import React from 'react';
import PropTypes from 'prop-types';
import { FormLegend, FormLegendLabel, FormFieldset } from './Form.styled';
import Field from './Field.styled';
import { SROnly } from '../../GlobalStyle/utilities/utilities.styled';

const Fieldset = ({
    children,
    className,
    legend,
    hasError,
    helpMessage,
    errorMessage,
    margin,
    smallLegend
}) => (
    <FormFieldset
        className={ className }
        aria-invalid={ hasError }
        margin={ margin }
        smallLegend={ smallLegend }
    >
        {legend && <FormLegend>
            <FormLegendLabel>{legend}</FormLegendLabel>
            {helpMessage && <Field.GroupHelp>{helpMessage}</Field.GroupHelp>}
            {hasError && errorMessage && <SROnly>{errorMessage}</SROnly>}
        </FormLegend>}
        {children}
        {hasError && errorMessage && <Field.Error aria-hidden={ true } data-testid='error'>{errorMessage}</Field.Error>}
    </FormFieldset>
);

Fieldset.defaultProps = {
    hasError: false,
    smallLegend: false
};

Fieldset.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node.isRequired,
    legend: PropTypes.string.isRequired,
    hasError: PropTypes.bool,
    helpMessage: PropTypes.string,
    errorMessage: PropTypes.string,
    margin: PropTypes.shape({
        top: PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.oneOf(['none', 'xs', 'sm', 'md', 'lg', 'xl', 'xxl', 'xxxl']),
        ]),
    }),
    /** Optionally style the legend as a label. See 'Small Legend' section above. */
    smallLegend: PropTypes.bool
};

export default Fieldset;
