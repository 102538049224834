import styled from 'styled-components/macro';
import { color, layout } from 'styled-system';

export const LabelContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    
    height: 1.5rem;
    min-height: 1.5rem;
    width: 1.5rem;
    min-width: 1.5rem;
    
    color: white;
    background-color: black;
    border-radius: 1rem;
    
    text-align: center;
    font-weight: 700;
    
    margin-right: .5rem;
    
    ${color};
    ${layout};

    border: ${props => props.border};
`;
