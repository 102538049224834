import * as React from 'react';
import PropTypes from 'prop-types';
import tokens from '../../Tokens/tokens';
const { spacing } = tokens;

const BrandIconDrivingTransport = ({ title, titleId, ...props }) => {
    const sizeArray = Object.entries(spacing.icons);
    const sizeOptions = new Map(sizeArray);
    const displaySize = sizeOptions.get(props.size);
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 48 48'
            fill={props.color && displayColor}
            aria-hidden={!title}
            role={title ? 'img' : null}
            width={props.size && displaySize}
            height={props.size && displaySize}
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <path d='M15 11a5 5 0 00-5 5h10a5 5 0 00-5-5z' fill='#d7153a' />
            <path
                d='M40.05 44H40a4 4 0 01-4-4v-2h1.92v2a2.08 2.08 0 004.16 0v-2H44v2a4 4 0 01-3.9 4zm6.79-22.26a1.28 1.28 0 00-.11-.2c-1-1.43-3.51-4.84-4-5.45L39.66 9c-1.15-2.88-2.86-3.5-3.12-3.57a56.68 56.68 0 00-12.43-1.34h-.23a56.72 56.72 0 00-12.45 1.34 1.17 1.17 0 00-.26.1A6 6 0 008.35 9l-3.12 7.09c-.45.61-3 4-4 5.45a1.28 1.28 0 00-.11.2 19.1 19.1 0 00-.71 10.47C.6 33.82 2 37 5 37h38c3 0 4.4-3.17 4.59-4.79a19.1 19.1 0 00-.75-10.47zm-1.84.85a15 15 0 01.86 3.41h-6.98v-.08c.48-2.84 4.6-3.55 5.91-3.71zM10.2 9.65C11 7.68 12 7.27 12 7.27A56.21 56.21 0 0123.87 6h.25A55.76 55.76 0 0136 7.26s1 .42 1.84 2.42L40.88 16H7.22zm-7 12.57c1.33.16 5.4.88 5.88 3.64v.13L9 26H2.14A15.25 15.25 0 013 22.58zM30 35H18v-3h12zm15.63-3.21v.15S45 35 43 35H32v-4a1 1 0 00-1-1H17a1 1 0 00-1 1v4H5c-2 0-2.59-3-2.6-3.06v-.15A16.61 16.61 0 012 28h7.23a2.11 2.11 0 001.45-.78 2.23 2.23 0 00.42-1.64c-.6-3.51-4.34-4.75-6.55-5.18.77-1.07 1.31-1.82 1.76-2.42h35.37l1.77 2.41c-2.21.43-6 1.68-6.58 5.23a2.25 2.25 0 00.43 1.6 2.11 2.11 0 001.45.78H46a16.66 16.66 0 01-.37 3.79zM8.05 44H8a4 4 0 01-4-4v-2h1.92v2a2.08 2.08 0 004.16 0v-2H12v2a4 4 0 01-3.9 4z'
                fill='#002664'
            />
        </svg>
    );
};

BrandIconDrivingTransport.propTypes = {
    title: PropTypes.string,
    titleId: PropTypes.string,
    size: PropTypes.oneOf(['lg', 'xl', 'xxl']),
};
BrandIconDrivingTransport.defaultProps = {
    size: 'xl',
    focusable: false,
};
export default BrandIconDrivingTransport;
