import * as React from 'react';
import PropTypes from 'prop-types';
import tokens from '../../Tokens/tokens';
const { spacing } = tokens;

const BrandIconCreativeKids = ({ title, titleId, ...props }) => {
    const sizeArray = Object.entries(spacing.icons);
    const sizeOptions = new Map(sizeArray);
    const displaySize = sizeOptions.get(props.size);
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 48 48'
            fill={props.color && displayColor}
            aria-hidden={!title}
            role={title ? 'img' : null}
            width={props.size && displaySize}
            height={props.size && displaySize}
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <defs>
                <style>{'.CreativeKids_svg__cls-1{fill:#002664}'}</style>
            </defs>
            <path
                className='CreativeKids_svg__cls-1'
                d='M7.71 21.29a2 2 0 01-1.84-1.22 2 2 0 012.59-2.64 2 2 0 01-.75 3.86zm1.74 9.26a3 3 0 01-2.78-4.13 3 3 0 012.78-1.87 2.9 2.9 0 011.12.22 3 3 0 01-1.12 5.78zm0-4a1 1 0 00-.93.62 1 1 0 00.55 1.31 1 1 0 10.75-1.86 1.16 1.16 0 00-.37-.07zm16.61-13.62a1.93 1.93 0 01-.75-.15 2 2 0 01-1.09-1.07 2 2 0 010-1.53 2 2 0 113.7 1.5 2 2 0 01-1.86 1.25zM48 3.09A1 1 0 0047.07 2a1 1 0 00-1.07.94 76.33 76.33 0 01-2.71 13.78 52.12 52.12 0 01-3.42 8.69l-6.25-2.25a60.25 60.25 0 012.2-8.77 52.1 52.1 0 016-12.81A1 1 0 1040.18.42a53.34 53.34 0 00-6.26 13.36 50.32 50.32 0 00-2.82 14.64c0 .43-.05.83-.08 1.22a6 6 0 00-2.58 1.46c-.1.09-2.48 2.27-1.4 7.19a11.48 11.48 0 01-1.64 8.14 1 1 0 00.82 1.57h.22c.24-.06 6.11-1.45 11.5-8a6.16 6.16 0 001.41-4.67 6.58 6.58 0 00-1.1-3 59.44 59.44 0 007-15.08A78.33 78.33 0 0048 3.09zM38.94 27.2c-.86 1.6-1.61 2.85-2.09 3.61h-.06A6.38 6.38 0 0033 29.4c0-.28 0-.57.05-.88 0-1 .11-2 .26-3.33zm-10.66 18a12.52 12.52 0 00.72-7.3c-.81-3.7.72-5.21.81-5.29a4.22 4.22 0 013-1.2 4.5 4.5 0 012.77 1 4.81 4.81 0 011.81 3.21 4.4 4.4 0 010 1.08c-.94.74-1.37.49-2.17 0a6.18 6.18 0 00-.88-.48 3.73 3.73 0 00-3.33.37 1 1 0 00-.28 1.38 1 1 0 001.38.29 1.78 1.78 0 011.43-.26 3.87 3.87 0 01.57.32 4.28 4.28 0 002 .77 23.48 23.48 0 01-7.83 6.07z'
            />
            <path d='M24 32a5 5 0 11-5-5 5 5 0 015 5z' fill='#d7153a' />
            <path
                className='CreativeKids_svg__cls-1'
                d='M12.36 38.93a16.84 16.84 0 01-9.22-21.78c.92-2.47 2.26-3.87 3.86-4.06a4.35 4.35 0 013.9 2c1.63 2.42 4 3.27 6.38 2.27 1.42-.6 3-2 2.45-5.05-.48-2.75.16-5 1.79-6.31a5.83 5.83 0 015.86-.68 29.17 29.17 0 016 4.28c.27-.67.54-1.32.8-1.94a29.26 29.26 0 00-6-4.2 7.83 7.83 0 00-7.87 1c-2.22 1.8-3.11 4.71-2.5 8.21.34 1.9-.41 2.5-1.26 2.86-1.87.79-3.17-.41-4-1.55a6.29 6.29 0 00-5.77-2.88c-1.68.2-4 1.29-5.51 5.34A19.71 19.71 0 000 23.57V24a18.59 18.59 0 0011.61 16.79 18.71 18.71 0 006.7 1.05 29.76 29.76 0 006.91-.8 8.91 8.91 0 00-.07-2c-4.15 1.06-9.86 1.08-12.79-.11z'
            />
        </svg>
    );
};

BrandIconCreativeKids.propTypes = {
    title: PropTypes.string,
    titleId: PropTypes.string,
    size: PropTypes.oneOf(['lg', 'xl', 'xxl']),
};
BrandIconCreativeKids.defaultProps = {
    size: 'xl',
    focusable: false,
};
export default BrandIconCreativeKids;
