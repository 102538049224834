import * as React from 'react';
import PropTypes from 'prop-types';
import tokens from '../../Tokens/tokens';
const { spacing } = tokens;

const BrandIconProperty = ({ title, titleId, ...props }) => {
    const sizeArray = Object.entries(spacing.icons);
    const sizeOptions = new Map(sizeArray);
    const displaySize = sizeOptions.get(props.size);
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 48 48'
            fill={props.color && displayColor}
            aria-hidden={!title}
            role={title ? 'img' : null}
            width={props.size && displaySize}
            height={props.size && displaySize}
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <defs>
                <style>{'.Property_svg__cls-1{fill:#002664}'}</style>
            </defs>
            <path
                className='Property_svg__cls-1'
                d='M41.45 48H28.76a1 1 0 01-1-1V36.89h-7.52V47a1 1 0 01-1 1H6.55a1 1 0 01-1-1V26.39a1 1 0 01.3-.71L23.29 8.21a1 1 0 011.42 0l17.44 17.46a1 1 0 01.3.7V47a1 1 0 01-1 1zm-11.69-2h10.69V26.79L24 10.34 7.55 26.8V46h10.69V35.89a1 1 0 011-1h9.52a1 1 0 011 1z'
            />
            <path d='M24 28.19A4.2 4.2 0 1128.2 24a4.2 4.2 0 01-4.2 4.19z' fill='#d7153a' />
            <path
                className='Property_svg__cls-1'
                d='M47 25a1 1 0 01-.71-.29L24 2.4 12.81 13.6a1 1 0 01-1.09.22 1 1 0 01-.62-.93v-3H8.35v7.73a1 1 0 01-.3.71L1.71 24.7a1 1 0 01-1.42 0 1 1 0 010-1.41l6.06-6.05V8.92a1 1 0 011-1h4.75a1 1 0 011 1v1.56L23.29.28a1 1 0 011.42 0l23 23a1 1 0 010 1.41A1 1 0 0147 25z'
            />
        </svg>
    );
};

BrandIconProperty.propTypes = {
    title: PropTypes.string,
    titleId: PropTypes.string,
    size: PropTypes.oneOf(['lg', 'xl', 'xxl']),
};
BrandIconProperty.defaultProps = {
    size: 'xl',
    focusable: false,
};
export default BrandIconProperty;
