const layouts = {
    grid: {
        columns: 12,
        gutters: 24,
        columnSizes: {
            quarter: 3,
            third: 4,
            half: 6,
            twoThirds: 8,
            threeQuarters: 9,
            full: 12
        }
    },
    inputWidth: {
        xxs: 56,
        xs: 92,
        sm: 132,
        md: 200,
        lg: 268,
        xl: 416
    },
    containers: {
        maxWidth: 1224,
        gutters: {
            mobile: 20,
            desktop: 30
        }
    }
};

export default layouts;