import api from '../../api/api';

export class NSWPointV2API {
    currentRequestNo = 0;
    defaultApikey = '';
    pointNSWDomain = 'https://point.digital.nsw.gov.au';

    getLatestSuggestions = async (
        value,
        apiKey,
        apiState,
        apiAddressType,
        apiDataSet
    ) => {
        const { requestNo, suggestions } = await this.getSuggestions(
            value,
            ++this.currentRequestNo,
            apiKey,
            apiState,
            apiAddressType,
            apiDataSet
        );

        return {
            suggestions,
            isLatest: requestNo === this.currentRequestNo,
        };
    };

    getSuggestions = async (
        value,
        requestNo,
        apiKey,
        apiState,
        apiAddressType,
        apiDataSet
    ) => {
        apiKey = this.validatedApiKey(apiKey);
        let url = `${this.pointNSWDomain}/v2/api/predictive1?address=${value}&maxNumberOfResults=5`;

        if (apiState) {
            url += `&stateTerritory=${apiState}`;
        }
        if (apiAddressType) {
            url += `&addressType=${apiAddressType}`;
        }
        if (apiDataSet) {
            url += `&dataset=${apiDataSet}`;
        }

        const suggestions = await api.request(url, {
            method: 'GET',
            headers: {
                'x-api-key': apiKey,
            },
        });

        return {
            suggestions: suggestions instanceof Array ? suggestions : [],
            requestNo,
        };
    };

    getDetailsById = async (id, apiKey) => {
        apiKey = this.validatedApiKey(apiKey);

        const body = `{"id":"${id}"}`;

        const addressDetails = await api.request(
            `${this.pointNSWDomain}/v2/api/predictive2`,
            {
                method: 'POST',
                headers: {
                    'x-api-key': apiKey,
                },
                body: body,
            }
        );

        return this.convertAddress(addressDetails);
    };

    convertAddress = address => {
        const {
            data: { geo, addressDetails, addressId },
        } = address;

        const {
            streetName,
            streetType,
            siteName,
            localityName,
            stateTerritory,
            postcode,
            formattedAddress,
        } = addressDetails;

        // check if we have a lat/long first, and then set the lat/long accordingly
        const latLong = geo && geo.geometry && geo.geometry.coordinates && 2 === geo.geometry.coordinates.length;
        const longitude = latLong ?
            geo.geometry.coordinates[0] :
            null;
        const latitude = latLong ?
            geo.geometry.coordinates[1] :
            null;

        return {
            unitNumber: this.getIdentifier(addressDetails, false),
            buildingNumber: this.getIdentifier(addressDetails, true),
            streetNumber: this.getStreetNumber(addressDetails),
            streetName,
            streetType,
            propertyName: siteName,
            addressLine: null,
            suburb: localityName,
            state: stateTerritory,
            country: 'AUSTRALIA',
            postcode,
            latitude,
            longitude,
            nswPointId: addressId,
            formattedAddress: formattedAddress,
            validated: true,
        };
    };

    getIdentifier = (address, isBuilding) => {
        const {
            complexUnitType,
            complexLevelType,
            complexUnitIdentifier,
            complexLevelNumber,
        } = address;
        let identifier = '';

        if (complexUnitType == null && complexLevelType == null) {
            return null;
        }

        if (isBuilding && complexUnitType !== 'BUILDING') {
            return null;
        }

        if (!isBuilding && complexUnitType === 'BUILDING') {
            return null;
        }

        if (complexUnitType != null) {
            identifier += `${complexUnitType} ${complexUnitIdentifier}`;
        }
        if (complexLevelType != null) {
            identifier += ` ${complexLevelType} ${complexLevelNumber}`;
        }

        return identifier.trim();
    };

    getStreetNumber = address => {
        const { streetNumber1, streetNumber2 } = address;
        if (streetNumber1 == null && streetNumber2 == null) {
            return null;
        }

        if (streetNumber1 != null && streetNumber2 != null) {
            return `${streetNumber1}-${streetNumber2}`;
        }
        return streetNumber1 || streetNumber2;
    };

    validatedApiKey(apiKey) {
        !apiKey &&
            console.warn(
                `No api key provided for the nsw point, using default now.
          Please request api key for your usage as the default api key will be removed soon.`
            );
        apiKey = apiKey || this.defaultApikey;
        return apiKey;
    }
}

export default new NSWPointV2API();
