import styled, { css } from 'styled-components/macro';
import tokens from '../../Tokens/tokens';
import { pxToRem, getSpacing, getBreakpoint, marginMixin } from '../../utils';

const { colors, font } = tokens;

export const FormFieldset = styled.fieldset`
    position: relative;
    border: 0;
    padding: 0;
    border-radius: 0;
    margin-inline-start: 0;
    margin-inline-end: 0;
    ${marginMixin}

    > .field-item:first-of-type,
    .date-container {
        margin-top: ${getSpacing(['xs'])};
    }

    .radio-item:first-of-type,
    .checkbox-item:first-of-type {
        margin-top: ${getSpacing(['sm'])};
    }

    ${props => props.smallLegend && css`
        legend span:first-of-type {
            font-size: ${pxToRem(font.size.base)};
            font-weight: ${font.weight.medium};
            line-height: ${font.lineHeight.default};
        }
        label {
            font-weight: ${font.weight.book};
        }
    `}
`;

const FormStyled = styled.form`
    position: relative;
    max-width: 100%;
`;

export const FormContainer = css`
    position: relative;
    margin: ${getSpacing(['sm', 'none'])};
`;

export const FormLabel = styled.label`
    display: block;
    color: ${colors.forms.labelText};
    margin-bottom: 0;
`;

export const FormLabelNote = styled.span`
    font-style: italic;
    font-size: ${pxToRem(font.size.base)};
`;

export const FormError = styled.span`
    display: block;
    color: ${colors.text.error};
    margin-top: ${getSpacing(['xs'])};
    padding-left: ${pxToRem(28)};
    background: url("data:image/svg+xml;charset=utf-8,%3Csvg width='16' height='16' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%23b81237' d='M8 16A8 8 0 118 0a8 8 0 010 16zm1-8V5a1 1 0 10-2 0v3a1 1 0 102 0zm-.29 3.71a1.004 1.004 0 10-1.42-1.42 1.004 1.004 0 001.42 1.42z'/%3E%3C/svg%3E") no-repeat 0 4px;
`;

export const HiddenRadioCheckbox = css`
    position: absolute;
    opacity: 0;
    width: ${pxToRem(32)};
    height: ${pxToRem(32)};
    top: 0;
    left: 0;
    z-index: 1;
    cursor: pointer;
    padding: 0;
    margin: 0;
`;

export const CustomRadioCheckbox = css`
    position: relative;
    padding: ${getSpacing([4, 'none', 4, 'xxl'])};
    margin: 0;

    &::before {
        position: absolute;
        content: '';
        top: 0;
        left: 0;
        border: 2px solid ${colors.forms.borderColor};
        background-color: ${colors.forms.background};
        width: ${pxToRem(32)};
        height: ${pxToRem(32)};
    }

    &::after {
        position: absolute;
        content: '';
        top: 0;
        left: 0;
        border: 2px solid transparent;
        background-color: ${colors.grey.shuttle};
        transform: scale(0.68);
        width: ${pxToRem(32)};
        height: ${pxToRem(32)};
        display: none;
    }
`;

export const FormLegend = styled.legend`
    padding: 0;
`;

export const FormLegendLabel = styled.span`
    margin: 0;
    font-size: ${pxToRem(font.size.lg)};
    font-weight: ${font.weight.bold};
    line-height: ${font.lineHeight.heading};
`;

export const RadioCheckboxLegendLabel = styled.span`
    display: block;
    color: ${colors.forms.labelText};
    font-size: ${pxToRem(font.size.base)};
    font-weight: ${font.weight.medium};
`;

export const FormHelp = styled.span`
    font-size: ${pxToRem(14)};
    line-height: ${font.lineHeight.heading};
    color: ${colors.forms.helpText};
    margin: 0;
    display: block;
`;

export const FormButtonContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: ${getSpacing(['xl'])};

    * + * {
        margin-top: ${getSpacing(['md'])};
    }

    ${getBreakpoint.md`
        flex-direction: row;
        justify-content: space-between;
        margin-top: ${getSpacing(['xxxl'])};

        * + * {
            margin-top: 0;
        }
    `}
`;

export default FormStyled;
