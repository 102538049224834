import styled from 'styled-components/macro';
import EtdbButton from '../EtdbButton.styled';

const BlueButton = styled(EtdbButton)`
    border-radius: 8px;
    background-color: #2e5299;
    color: #ffffff;
    border: 2px solid #274a8f;
    :hover {
      background-color: #5272b1;
      border-color: transparent;
    }
    :active {
      background-color: #002664;
      border-color: transparent;
    }
    :after {
      filter: brightness(100);
    }
    :focus {
      outline: none;
    }
`;

export default BlueButton;
