import * as React from 'react';
import PropTypes from 'prop-types';
import tokens from '../../Tokens/tokens';
const { spacing } = tokens;

const BrandIconPollVote = ({ title, titleId, ...props }) => {
    const sizeArray = Object.entries(spacing.icons);
    const sizeOptions = new Map(sizeArray);
    const displaySize = sizeOptions.get(props.size);
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 48 48'
            fill={props.color && displayColor}
            aria-hidden={!title}
            role={title ? 'img' : null}
            width={props.size && displaySize}
            height={props.size && displaySize}
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <defs>
                <style>{'.PollVote_svg__cls-2{fill:#002664}'}</style>
            </defs>
            <path d='M29 36a5 5 0 11-5-5 5 5 0 015 5z' fill='#d7153a' />
            <path className='PollVote_svg__cls-2' d='M29 14H19a1 1 0 010-2h10a1 1 0 010 2zm0-6H19a1 1 0 010-2h10a1 1 0 110 2z' />
            <path
                className='PollVote_svg__cls-2'
                d='M48 24.94a.65.65 0 000-.19 1.07 1.07 0 00-.07-.19.41.41 0 000-.08L39.75 11.9h-2.37L45.17 24H2.83l7.74-12H8.19l-8 12.48a.41.41 0 000 .08 1.07 1.07 0 00-.07.19.65.65 0 000 .19A.29.29 0 000 25v22a1 1 0 001 1h46a1 1 0 001-1V25a.29.29 0 000-.06zM46 46H2V26h44z'
            />
            <path
                className='PollVote_svg__cls-2'
                d='M10 19a1 1 0 001 1h26a1 1 0 000-2h-2.06v-.05L35 3a3 3 0 00-3-3H16a3 3 0 00-3 3v15h-2a1 1 0 00-1 1zm5-1V3a1 1 0 011-1h16a1 1 0 011 1v15.06H15z'
            />
        </svg>
    );
};

BrandIconPollVote.propTypes = {
    title: PropTypes.string,
    titleId: PropTypes.string,
    size: PropTypes.oneOf(['lg', 'xl', 'xxl']),
};
BrandIconPollVote.defaultProps = {
    size: 'xl',
    focusable: false,
};
export default BrandIconPollVote;
