import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { trackMe } from '../ComponentAnalytics/componentAnalytics';
import {
    CardContainer,
    CardContent,
    CardImage,
    CardCta,
    CardCtaDefaultIcon,
} from './Card.styled';

const Card = ({ className, children, imageSrc, isClickable, ctaText, ctaIcon }) => {
    useEffect(() => {
        trackMe('Card [GEL]');
    }, []);

    return (
        <CardContainer
            isClickable={ isClickable }
            imageSrc={ imageSrc }
            className={ className }
            animateIcon={ isClickable && !ctaText && !ctaIcon }
            data-testid='card-test-id'
        >
            {imageSrc && <CardImage src={ imageSrc } alt='' />}
            <CardContent isClickable={ isClickable } imageSrc={ imageSrc }>
                <div>
                    {children}
                </div>
                {isClickable &&
                    <CardCta ctaIcon={ ctaIcon }>
                        {ctaText && <span>{ctaText}</span>}
                        {(ctaIcon && ctaText) ?
                            ctaIcon :
                            <CardCtaDefaultIcon data-testid='card-test-arrow' />}
                    </CardCta>}
            </CardContent>
        </CardContainer>
    );
};

Card.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node.isRequired,
    imageSrc: PropTypes.string,
    isClickable: PropTypes.bool,
    ctaIcon: PropTypes.node,
    ctaText: PropTypes.string,
};

export default Card;
