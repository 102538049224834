import * as React from 'react';
import PropTypes from 'prop-types';
import tokens from '../../Tokens/tokens';
const { spacing } = tokens;

const BrandIconEnvironmentalCare = ({ title, titleId, ...props }) => {
    const sizeArray = Object.entries(spacing.icons);
    const sizeOptions = new Map(sizeArray);
    const displaySize = sizeOptions.get(props.size);
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 48 48'
            fill={props.color && displayColor}
            aria-hidden={!title}
            role={title ? 'img' : null}
            width={props.size && displaySize}
            height={props.size && displaySize}
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <defs>
                <style>{'.EnvironmentalCare_svg__cls-1{fill:#002664}'}</style>
            </defs>
            <path
                className='EnvironmentalCare_svg__cls-1'
                d='M47.86 5.49a1 1 0 00-.73-.49c-.48-.07-11.94-1.57-16.66.38a9.17 9.17 0 00-5.54 5.54 9.49 9.49 0 00.51 7 18.92 18.92 0 00-3 4.66 32.08 32.08 0 00-2.51-9 4.23 4.23 0 00.74-2 8.19 8.19 0 00-2.16-6.35c-2.8-2.9-7.88-5.09-8.1-5.18A1 1 0 009 1.21c0 .18 1 4.36 2.29 8.39a8.12 8.12 0 004.62 5 4.31 4.31 0 001.5.28 3.77 3.77 0 00.83-.1C19.44 17.18 21 23.25 21 30h2c0-.88 0-1.77-.08-2.65a.94.94 0 00.08-.15 18.08 18.08 0 013.56-7.47 9 9 0 005.58 3.52 7.24 7.24 0 001.19.1 9.88 9.88 0 006.58-2.83c4.06-3.59 7.89-13.74 8-14.17a1 1 0 00-.05-.86zm-29.8 7.2a1.74 1.74 0 01-1.45 0A6.21 6.21 0 0113.21 9c-.74-2.24-1.36-4.57-1.75-6.17a22.8 22.8 0 015.59 3.84 6.14 6.14 0 011.61 4.71 1.83 1.83 0 01-.6 1.33zM38.57 19c-2.06 1.82-4.12 2.58-6.14 2.26a7 7 0 01-4.27-2.72L30.71 17l3.93 2a.93.93 0 00.45.11 1 1 0 00.45-1.89l-2.85-1.44 7.94-4.94a1 1 0 10-1.06-1.7l-4.14 2.58.06-1.62a1 1 0 00-1-1 1 1 0 00-1 1l-.11 3-6.24 3.88a7.47 7.47 0 01-.32-5.27 7.31 7.31 0 014.42-4.33 19.33 19.33 0 016.65-.85 75.86 75.86 0 017.7.45c-1.13 2.63-4.1 9.46-7.02 12.02zM8 45h14a13 13 0 005.47-1.12l17.92-9.55a4.44 4.44 0 002-5.9 3.81 3.81 0 00-2.65-1.95 4.89 4.89 0 00-3.31.52l-9 4.61.91 1.78 9-4.62a3 3 0 012-.33 1.84 1.84 0 011.3.93 2.45 2.45 0 01-1.23 3.2L26.56 42.1A11.31 11.31 0 0122 43H8c-.4 0-2.58.26-6.47 5h2.64C6.73 45.21 8 45 8 45z'
            />
            <path
                className='EnvironmentalCare_svg__cls-1'
                d='M12 32a18.55 18.55 0 013.14.42A26.85 26.85 0 0020 33h7c1.44 0 2 .56 2 2s-.56 2-2 2H17v2h10a3.64 3.64 0 004-4 3.64 3.64 0 00-4-4h-7a24.72 24.72 0 01-4.5-.55A20.48 20.48 0 0012 30c-2.34 0-5.35 1.29-9.52 4.12l-2.48 2v2.57l3.66-3C7.32 33.25 10.13 32 12 32z'
            />
            <path
                d='M16.61 12.69a1.74 1.74 0 001.45 0 1.83 1.83 0 00.6-1.33A6.17 6.17 0 0018 8.05a4.87 4.87 0 00-5.63-1.76c.25.87.52 1.79.82 2.69a6.21 6.21 0 003.42 3.71z'
                fill='#d7153a'
            />
        </svg>
    );
};

BrandIconEnvironmentalCare.propTypes = {
    title: PropTypes.string,
    titleId: PropTypes.string,
    size: PropTypes.oneOf(['lg', 'xl', 'xxl']),
};
BrandIconEnvironmentalCare.defaultProps = {
    size: 'xl',
    focusable: false,
};
export default BrandIconEnvironmentalCare;
