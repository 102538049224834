import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Autosuggest from 'react-autosuggest';
import { utils } from '../..';
import { trackMe } from '../ComponentAnalytics/componentAnalytics';
import { AutoSuggestContainer } from './AutoSuggest.styled';

const AutoSuggest = ({
    id,
    className,
    name,
    value,
    suggestion,
    suggestions,
    placeholder,
    disabled,
    inputWidth,
    inputRef,
    onSelect,
    onChange,
    onBlur,
    appendedSuggestion,
    filterCustomSuggestions, // filters custom suggestion when typing
    renderCustomSuggestion, // renders a custom suggestion in dropdown
    getCustomSuggestion, // get suggestion value when suggestion is clicked
    minSearchChars,
    ...rest
}) => {
    // states
    const [inputData, setInputData] = useState({
        // the current value entered by the user
        value: value || '',
        // the current suggestion selected by the user
        suggestion: suggestion || '',
        // the current method (up, down, click, enter, etc..) used to change or select the input
        method: (value || suggestion) ? 'pre-filled' : '',
    });
    // the current filtered suggestions, based on currentValue
    const [filteredSuggestions, setFilteredSuggestions] = useState(suggestions);

    // hooks
    const hasInitialised = utils.useHasInitialised();

    // vars
    const elemId = id || utils.getID();

    useEffect(() => {
        trackMe('AutoSuggest [GEL]');
    }, []);

    // watch for changes to value prop, and when empty/null reset the internal states
    useEffect(() => {
        hasInitialised && value !== inputData.value && setInputData(prevState => ({
            value,
            suggestion: !value ? '' : prevState.suggestion,
            method: !value ? '' : 'pre-filled',
        }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    // watch for changes to suggestion prop
    useEffect(() => {
        hasInitialised && suggestion !== inputData.suggestion && setInputData(prevState => ({
            value: prevState.value,
            suggestion,
            method: !suggestion ? '' : 'pre-filled',
        }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [suggestion]);

    // ping various callbacks when inputData updates
    useEffect(() => {
        const { value, suggestion, method } = inputData;
        if (hasInitialised) {
            onChange && onChange(suggestion, value, method);
            if (
                method === 'click' ||
                method === 'enter' ||
                method === 'pre-filled' ||
                value === '' // input is cleared, therefore clear selection
            ) {
                onSelect && onSelect(suggestion, value, method);
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inputData]);

    // build filtered suggestions if suggestions are updated
    // useful when integrating AutoSuggest with an API
    useEffect(() => {
        setFilteredSuggestions(buildSuggestions(suggestions));
    }, [suggestions]);

    // helper to build suggestions, including the appendedSuggestion if defined
    const buildSuggestions = (suggestions = []) => {
        if (appendedSuggestion) {
            const newSuggestions = [...suggestions];
            // if appendedSuggestion doesn't exist, add it
            !newSuggestions[newSuggestions.length - 1]?.isCustom &&
                newSuggestions.push({ isCustom: true, ...appendedSuggestion });
            return newSuggestions;
        } else {
            return suggestions;
        }
    };

    // the default/fallback filtering helper
    const simpleFilter = value => (
        suggestions.filter(suggestion => (
            // the suggestion value being filtered through simpleFilter should be a string
            ('string' === typeof suggestion) && suggestion
                .toLowerCase()
                .trim()
                .startsWith(value.trim().toLowerCase())
        ))
    );

    // get suggestions, either via custom filter or the detault simple filter
    // the value is the input value, and the reason is relating to the user interaction
    const getSuggestions = (value, reason) => (
        filterCustomSuggestions ? filterCustomSuggestions(value, reason) : simpleFilter(value)
    );

    // calls function to filter suggestions, and updates state
    const handleGetSuggestions = ({ value, reason }) => {
        // filter suggestions
        const newSuggestions = buildSuggestions(getSuggestions(value, reason));
        // ensure suggestions is an array before updating
        (newSuggestions instanceof Array) && setFilteredSuggestions(newSuggestions);
    };

    // check for min chars before rendering suggestions
    const handleShouldRenderSuggestions = value => value.trim().length >= minSearchChars;

    // handle the rendering of each individual suggestion
    const handleRenderSuggestion = suggestion => {
        if (suggestion.isCustom) {
            return suggestion.suggestion;
        } else {
            return renderCustomSuggestion ? renderCustomSuggestion(suggestion) : suggestion;
        }
    };

    // handle the return of a suggestion value when clicked/selected
    const handleGetSuggestionValue = suggestion => {
        if (suggestion.isCustom) {
            return inputData.value;
        } else {
            return getCustomSuggestion ? getCustomSuggestion(suggestion) : suggestion;
        }
    };

    // update input value, suggestion and method states
    const handleOnSelect = (e, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }) => {
        if (suggestion.isCustom) {
            // perform the onClick for appendedSuggestion
            suggestion.onClick && suggestion.onClick();
            setInputData({
                value: '',
                suggestion: '',
                method,
            });
        } else {
            setInputData(prevState => ({ ...prevState, suggestion, method }));
        }
    };

    // update input value, suggestion and method states
    const handleOnChange = (e, { newValue, method }) => {
        // if the input has been cleared, clear the suggestion
        const newCurrentSuggestion = !newValue.length ? '' : inputData.suggestion;
        setInputData({
            value: newValue,
            suggestion: newCurrentSuggestion,
            method,
        });
    };

    // ping onBlur callback
    const handleOnBlur = (e, { highlightedSuggestion }) => {
        // note: highlighted suggestion is either the value or an empty string to keep returned values consistent
        onBlur && onBlur(inputData.suggestion, inputData.value, highlightedSuggestion || '');
    };

    // clear filtered suggestions
    const handleOnClear = () => {
        setFilteredSuggestions(buildSuggestions());
    };

    // build input props for react-autosuggest component
    const defaultInputProps = {
        ref: inputRef,
        id: elemId,
        name: name || 'auto-suggest-input',
        value: inputData.value,
        placeholder: placeholder,
        onChange: handleOnChange,
        onBlur: handleOnBlur,
        'aria-invalid': rest['hasError'],
        'aria-describedby': rest['aria-describedby'],
        'aria-required': rest['aria-required'],
        disabled,
    };

    return (
        <AutoSuggestContainer
            id={ `${elemId}-container` }
            className={ className }
            hasError={ rest['hasError'] }
            inputWidth={ inputWidth }
        >
            <Autosuggest
                inputProps={ defaultInputProps }
                suggestions={ filteredSuggestions }
                onSuggestionsFetchRequested={ handleGetSuggestions }
                onSuggestionsClearRequested={ handleOnClear }
                getSuggestionValue={ handleGetSuggestionValue }
                renderSuggestion={ handleRenderSuggestion }
                onSuggestionSelected={ handleOnSelect }
                shouldRenderSuggestions={ handleShouldRenderSuggestions }
            />
        </AutoSuggestContainer>
    );
};

AutoSuggest.propTypes = {
    /** Use if you have multiple AutoSuggest components on a single page. */
    id: PropTypes.string,
    /** A class name for the AutoSuggest wrapper. */
    className: PropTypes.string,
    /** Input name. */
    name: PropTypes.string,
    /** Initial input value, for pre-filling data. */
    value: PropTypes.string,
    /** Initial suggestion, for pre-filling data. */
    suggestion: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
    ]),
    /** List of initial suggestions to be filtered by input value. */
    suggestions: PropTypes.array.isRequired,
    /** Append a custom suggestion to the suggestions */
    appendedSuggestion: PropTypes.shape({
        suggestion: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.node,
        ]).isRequired,
        onClick: PropTypes.func.isRequired,
    }),
    /** Input placeholder. */
    placeholder: PropTypes.string,
    /** Callback on selection of suggestion, returning the suggestion and input value. */
    onSelect: PropTypes.func.isRequired,
    /** Callback on change of input, returning the suggestion and input value. */
    onChange: PropTypes.func,
    /** Callback on blur of input, returning the suggestion, input value and highlighted value. */
    onBlur: PropTypes.func,
    /** Optionally disable the input. */
    disabled: PropTypes.bool,
    /** Set the minimum number of characters before the suggestions are displayed. */
    minSearchChars: PropTypes.number,
    /** Set the input width. */
    inputWidth: PropTypes.oneOf(['xxs', 'xs', 'sm', 'md', 'lg', 'xl']),
    /** A reference to the input field. */
    inputRef: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.object,
    ]),
    /** Used to render complex suggestions. Exposes suggestion. Must return a string. */
    renderCustomSuggestion: PropTypes.func,
    /** Used to filter complex suggestions based on input value. Exposes input value and reason. Must return an array. */
    filterCustomSuggestions: PropTypes.func,
    // eslint-disable-next-line max-len
    /** Used to set the input value when user highlights complex suggestion using keyboard. Exposes input value. Must return a string. */
    getCustomSuggestion: PropTypes.func,
};

AutoSuggest.defaultProps = {
    disabled: false,
    minSearchChars: 0
};

export default AutoSuggest;
