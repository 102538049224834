import React from 'react';
import PropTypes from 'prop-types';
import { StyledTableRow } from './Table.styled';

const TableRow = ({ children, ...rest }) => (
    <StyledTableRow { ...rest }>
        {children}
    </StyledTableRow>
);

TableRow.propTypes = {
    children: PropTypes.node.isRequired
};

export default TableRow;
