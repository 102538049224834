import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { ProgressBarStyle } from './ProgressBar.styled';
import { trackMe } from '../Components/ComponentAnalytics/componentAnalytics';

const ProgressBar = ({ className, total, current, maxWidth, margin }) => {
    useEffect(() => {
        trackMe('Progress Bar');
    }, []);

    const percentage = current / total * 100;
    return (
        <ProgressBarStyle className={ className }>
            <ProgressBarStyle.FullBar maxWidth={ maxWidth } margin={ margin }>
                <ProgressBarStyle.Progress percentage={ percentage } />
            </ProgressBarStyle.FullBar>
        </ProgressBarStyle>
    );
};

ProgressBar.propTypes = {
    className: PropTypes.string,
    total: PropTypes.number.isRequired,
    current: PropTypes.number.isRequired,
    maxWidth: PropTypes.bool,
};

ProgressBar.defaultProps = {
    maxWidth: false,
};

export default ProgressBar;
