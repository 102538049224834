import { css } from 'styled-components';
import { color, typography } from 'styled-system';
import styled from 'styled-components/macro';
import space from '../GlobalStyle/space/space';

const heading = css`
  margin: 0;
  letter-spacing: 0;
  font-weight: 700;
  ${props => props.color && css`
    color: ${props.color};
  `}
`;

export const H1 = styled.h1`
  ${heading};

  font-size: 2.25rem;
  line-height: 1.388888;

  @media (max-width: 768px) {
    font-size: 2rem;
    line-height: 1.375;
  }

  ${color};
  ${space};
`;

export const H2 = styled.h2`
  ${heading};

  font-size: 1.75rem;
  line-height: 1.42857;

  @media (max-width: 768px) {
    font-size: 1.625rem;
    line-height: 1.384615;
  }

  ${space};
  ${color};
`;

export const H3 = styled.h3`
  ${heading};

  font-size: 1.375rem;
  line-height: 1.363636;

  @media (max-width: 768px) {
    font-size: 1.25rem;
    line-height: 1.4;
  }

  ${space};
  ${color};
  ${typography}
`;

export const H4 = styled.h4`
  ${heading};

  font-size: 1.125rem;
  line-height: 1.444444;

  ${space};
  ${color};
`;

export const H5 = styled.h5`
  ${heading};

  font-size: 1rem;
  line-height: 1.5;

  ${space};
  ${color};
`;

export const H6 = styled.h6`
  ${heading};

  font-size: 1rem;
  line-height: 1.5;

  ${space};
  ${color};
`;
