import React, { useState, useEffect } from 'react';
import Button from '../../Components/Button/Button';
import External from '../../Link/External';
import FormCheckBox from '../../Components/Form/FormCheckbox';
import Heading from '../../Components/Headings/Heading';
import PropTypes from 'prop-types';
import { trackMe } from '../../Components/ComponentAnalytics/componentAnalytics';

const ReviewApplicationPage = ({
    accountNameLabel,
    paymentDetails,
    paymentDetailsOnClick,
    personalDetails,
    personalDetailsOnClick,
    personalDetailsTitle,
    termsAndConditionsUrl,
    checkBoxOnChange,
}) => {
    useEffect(() => {
        trackMe('TemplateReviewApplicationPage');
    }, []);

    const [checked, setChecked] = useState(false);
    const handleOnChange = e => {
        setChecked(!!e);
        checkBoxOnChange(e);
    };

    return (
        <>
            <Heading level={ 2 }>Review your application</Heading>
            <p>Please ensure that the details listed below are correct. Any incorrect information may
                cause a delay in processing your application.</p>
            { personalDetails &&
                <>
                    <Heading level={ 3 }>{ personalDetailsTitle }</Heading>
                    <p><strong>Given name:</strong> { personalDetails.firstName }</p>
                    <p><strong>Family name:</strong> { personalDetails.lastName }</p>
                    <p><strong>Date of birth:</strong> { personalDetails.dateOfBirth }</p>
                    <p><strong>Residential address:</strong> { personalDetails.address }</p>
                    <p><strong>Email:</strong> { personalDetails.email }</p>
                    <p><strong>Phone number:</strong> { personalDetails.phoneNumber }</p>
                    <Button theme='secondary' onClick={ personalDetailsOnClick }>Edit</Button>
                </>
            }
            { paymentDetails &&
                <>
                    <Heading level={ 3 }>Payment details</Heading>
                    <p><strong>{ accountNameLabel }:</strong> { paymentDetails.accountName }</p>
                    <p><strong>BSB:</strong> { paymentDetails.bsb }</p>
                    <p><strong>Account number:</strong> { paymentDetails.accountNumber }</p>
                    <Button theme='secondary' onClick={ paymentDetailsOnClick }>Edit</Button>
                </>
            }
            <Heading level={ 3 }>Declaration</Heading>
            <FormCheckBox
                name='termsAndConditionsCheckbox'
                checked={ checked }
                onChange={ handleOnChange }
                value='termsAndConditionsChecked'
                label={
                    <>
                        <strong>I have read and accept the <External href={ termsAndConditionsUrl }>
                            Terms and Conditions.</External></strong>
                        <p>Our <External href='https://www.service.nsw.gov.au/privacy'>privacy statement</External> explains how we collect and use information.</p>
                    </>
                }
            />
        </>
    );
};

ReviewApplicationPage.propTypes = {
    accountNameLabel: PropTypes.string,
    paymentDetails: PropTypes.exact({
        accountName: PropTypes.string,
        accountNumber: PropTypes.string,
        bsb: PropTypes.string
    }),
    paymentDetailsOnClick: PropTypes.func,
    personalDetails: PropTypes.exact({
        address: PropTypes.string,
        dateOfBirth: PropTypes.string,
        email: PropTypes.string,
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        phoneNumber: PropTypes.string
    }),
    personalDetailsOnClick: PropTypes.func,
    personalDetailsTitle: PropTypes.string,
    termsAndConditionsUrl: PropTypes.string,
    checkBoxOnChange: PropTypes.func
};

ReviewApplicationPage.defaultProps = {
    accountNameLabel: 'Account name',
    personalDetailsTitle: 'Personal details',
    termsAndConditionsUrl: '',
};

export default ReviewApplicationPage;
