import * as React from 'react';
import PropTypes from 'prop-types';
import tokens from '../../Tokens/tokens';
const { spacing } = tokens;

const BrandIconPayUseCard = ({ title, titleId, ...props }) => {
    const sizeArray = Object.entries(spacing.icons);
    const sizeOptions = new Map(sizeArray);
    const displaySize = sizeOptions.get(props.size);
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 48 48'
            fill={props.color && displayColor}
            aria-hidden={!title}
            role={title ? 'img' : null}
            width={props.size && displaySize}
            height={props.size && displaySize}
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <defs>
                <style>{'.PayUseCard_svg__cls-1{fill:#002664}'}</style>
            </defs>
            <path
                className='PayUseCard_svg__cls-1'
                d='M18.79 43.42a11.14 11.14 0 001.27-.05c1.38-.24 5.84-5 9.7-9.35h-2.7c-3.49 3.88-6.58 7.06-7.34 7.36S11 41.15 5.8 40.89L0 40.61v2l5.7.28c6.3.31 10.88.53 13.09.53zM42.39 32H20.51l2.14-2h19.74A3.61 3.61 0 0046 26.39V10.61A3.61 3.61 0 0042.39 7H11.61A3.61 3.61 0 008 10.61V25a1 1 0 01-2 0V10.61A5.62 5.62 0 0111.61 5h30.78A5.62 5.62 0 0148 10.61v15.78A5.62 5.62 0 0142.39 32zm-17.08-7.24l-10.63 9.97'
            />
            <path
                className='PayUseCard_svg__cls-1'
                d='M24.63 18.74c1 1.15.66 3.25-.7 4.57l-10.61 10a1 1 0 00-.05 1.41A1 1 0 0014 35a1 1 0 00.68-.27l10.63-10c2.15-2.08 2.52-5.37.84-7.33s-5-2.16-7.45-.52L0 28.57v2.36l19.79-12.34c1.62-1.09 3.83-1.02 4.84.15z'
            />
            <path
                d='M28 13a5 5 0 00-4.55 3 4.76 4.76 0 012.7 1.48 5.09 5.09 0 01.52 5.37A4.8 4.8 0 0028 23a5 5 0 000-10z'
                fill='#d7153a'
            />
        </svg>
    );
};

BrandIconPayUseCard.propTypes = {
    title: PropTypes.string,
    titleId: PropTypes.string,
    size: PropTypes.oneOf(['lg', 'xl', 'xxl']),
};
BrandIconPayUseCard.defaultProps = {
    size: 'xl',
    focusable: false,
};
export default BrandIconPayUseCard;
