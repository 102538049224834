import styled, { css } from 'styled-components/macro';
import { getBreakpoint, pxToRem } from '../../utils';
import tokens from '../../Tokens/tokens';
import PropTypes from 'prop-types';

const { gutters } = tokens.layouts.grid;

const halfGutter = pxToRem(gutters / 2);

const flexProps = option => {
    if (option === 'start' || option === 'end') {
        return `flex-${option}`;
    }
    else {
        return option;
    }
};

const Row = styled.div`
    margin-left: 0;
    margin-right: 0;

    ${getBreakpoint.md`
        display: flex;
        margin-left: -${halfGutter};
        margin-right: -${halfGutter};

        ${props => props.alignItems && css`
            align-items: ${flexProps(props.alignItems)};
        `}

        ${props => props.justifyContent && css`
            justify-content: ${flexProps(props.justifyContent)};
        `}
    `}
`;

Row.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node,
    alignItems: PropTypes.oneOf(['start', 'center', 'end', 'stretch']),
    justifyContent: PropTypes.oneOf(['start', 'center', 'end', 'space-between', 'space-around', 'space-evenly']),
};

export default Row;
