import styled, { css } from 'styled-components/macro';
import { getSpacing, pxToRem } from '../../utils';
import Tokens from '../../Tokens/tokens';
import { IconArrowRight } from '../../Icons/system';

const { colors, spacing, font } = Tokens;

export const CardContainer = styled.article`
    display: flex;
    flex-direction: column;
    position: relative;
    background: ${colors.white};
    border-radius: 8px 8px 6px 6px;
    z-index: 2;
    transition: box-shadow 0.25s cubic-bezier(0.17, 0.84, 0.44, 1);
    box-shadow: 0px 2px 4px -1px rgba(19, 36, 69, 0.1),
        0px 4px 5px 0px rgba(19, 36, 69, 0.05),
        0px 1px 10px 0px rgba(19, 36, 69, 0.05);

    ${props => props.isClickable && css`
        [class*="CardTitleContainer-sc"] a {
            color: ${colors.text.body};
            text-decoration: none;
            &:before {
                content: '';
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                z-index: 1;
                border: solid 2px transparent;
                border-radius: 6px;
            }
            &:focus {
                outline: none;
            }
        }
        &:hover, &:focus {
            a:before {
                border: solid 2px ${colors.borders.focus};
            }
            [class*="CardTitleContainer-sc"] a {
                text-decoration: underline;
            }
            ${props => props.animateIcon && css`
                [class*="CardCtaDefaultIcon-sc"] {
                    margin-left: ${pxToRem(spacing.icons.sm)};
                }
            `}
        }
        &:hover {
            box-shadow: 0px 5px 5px -3px rgba(19, 36, 69, 0.1),
                0px 8px 10px 1px rgba(19, 36, 69, 0.05),
                0px 3px 14px 2px rgba(19, 36, 69, 0.05);
        }
        &:focus {
            box-shadow: 0px 5px 5px -3px rgba(19, 36, 69, 0.1),
                0px 8px 10px 1px rgba(19, 36, 69, 0.05),
                0px 3px 14px 2px rgba(19, 36, 69, 0.05);

            a {
                &:after {
                    content: '';
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    z-index: 1;
                    border-radius: 0;
                    outline: 2px solid ${colors.borders.focus};
                    outline-offset: 2px;
                }
            }
        }
        /* IE11 Fix: https://css-tricks.com/dont-comma-separate-focus-within-if-you-need-deep-browser-support */
        &:focus-within {
            a:before {
                border: solid 2px ${colors.borders.focus};
            }
            [class*="CardTitleContainer-sc"] a {
                text-decoration: underline;
            }
            ${props => props.animateIcon && css`
                [class*="CardCtaDefaultIcon-sc"] {
                    margin-left: ${pxToRem(spacing.icons.sm)};
                }
            `}
            box-shadow: 0px 5px 5px -3px rgba(19, 36, 69, 0.1),
                0px 8px 10px 1px rgba(19, 36, 69, 0.05),
                0px 3px 14px 2px rgba(19, 36, 69, 0.05);

            a {
                &:after {
                    content: '';
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    z-index: 1;
                    border-radius: 0;
                    outline: 2px solid ${colors.borders.focus};
                    outline-offset: 2px;
                }
            }
        }
    `};
`;

export const CardContent = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    padding: ${getSpacing('md')};
    ${props => props.isClickable && css`
        padding-bottom: ${getSpacing('lg')};
        border-top: 6px solid ${colors.borders.focus};
        border-top-right-radius: 6px;
        border-top-left-radius: 6px;
    `};
    ${props => props.imageSrc && css`
        border-top-right-radius: 0;
        border-top-left-radius: 0;
    `};
`;

export const CardImage = styled.img`
    border-radius: 6px 6px 0 0;
    width: 100%;
    height: auto;
    display: block;
`;

export const CardTitleContainer = styled.div`
    display: flex;
    flex-flow: column wrap;
    margin-bottom: ${getSpacing('xs')};
    svg {
        width: ${pxToRem(spacing.icons.xxl)};
        height: ${pxToRem(spacing.icons.xxl)};
        flex-shrink: 0;
    }
    h3, h4, h5 {
        margin: 0;
    }
    ${props => props.level === 3 && css`
        svg {
            margin-bottom: ${getSpacing('xs')};
        }
    `}
    ${props => (props.level === 4 || props.level === 5) && css`
        flex-flow: row nowrap;
        svg {
            margin-right: ${getSpacing('sm')}
        }
    `}
    ${props => props.level === 4 && css`
        svg {
            width: ${pxToRem(spacing.icons.xl)};
            height: ${pxToRem(spacing.icons.xl)};
        }
    `}
    ${props => props.level === 5 && css`
        svg {
            width: ${pxToRem(spacing.icons.lg)};
            height: ${pxToRem(spacing.icons.lg)};
        }
    `}
`;

export const CardCta = styled.div`
    color: ${colors.buttons.linkButton.text};
    display: flex;
    font-weight: ${font.weight.medium};
    margin-top: auto;
    padding-top: ${pxToRem(spacing.xs)};
    align-items: center;

    span {
        margin-right: ${pxToRem(spacing.xs)};

        ${props => props.ctaIcon && css`
            order: 1;
            margin-left: ${pxToRem(spacing.xs)};
        `}

        ${CardContainer}:hover &,
        ${CardContainer}:focus-within &,
        ${CardContainer}:focus & {
            text-decoration: underline;
        }
    }
    svg {
        fill: ${colors.icons.secondaryBlue};
        width: ${pxToRem(spacing.icons.sm)};
        height: ${pxToRem(spacing.icons.sm)};
        flex-shrink: 0;
    }
`;

export const CardCtaDefaultIcon = styled(IconArrowRight)`
    transition: 250ms;
    transition-timing-function: cubic-bezier(0.25,0.46,0.45,0.94);
`;

