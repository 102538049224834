import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
    FileInputButton
} from './FileInput.styled';
import { SROnly } from '../../GlobalStyle/utilities/utilities.styled';
import { trackMe } from '../ComponentAnalytics/componentAnalytics';

const FileInput = ({ id, accept, onChange, multiple, className, label, name, capture, ...rest }) => {

    useEffect(() => {
        trackMe('FileInput');
    }, []);

    return (
        <FileInputButton htmlFor={ id } className={ className }>
            <SROnly>
                <input
                    id={ id }
                    type='file'
                    accept={ accept }
                    onChange={ onChange }
                    encType='multipart/form-data'
                    multiple={ multiple }
                    name={ name }
                    capture={ capture }
                    { ...rest }
                />
            </SROnly>
            {label}
        </FileInputButton>
    );
};

FileInput.propTypes = {
    id: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    className: PropTypes.string,
    multiple: PropTypes.bool,
    /** Comma-separated list of one or more file types.
    *
    * Read more about [accept attribute](https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/accept)
    *
    */
    accept: PropTypes.string,
    /** Select which camera to use for capture of image or video data
     *
     * Read more about [capture attribute](https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/capture)
     *
    */
    capture: PropTypes.oneOf(['user', 'environment']),
};

FileInput.defaultProps = {
    label: 'Select files',
    className: '',
    multiple: false,
    accept: '',
};

export default FileInput;
