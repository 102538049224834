import { useMemo } from 'react';
import utils from '../../utils';

const usePlace = countriesOverride => {

    const countries = useMemo(() => {
        // Remove "Australia" from countries & use memoization so we don't have to calculate it again
        var countriesToFilter = (countriesOverride && countriesOverride.length > 0) ? countriesOverride : utils.getCountries();
        return countriesToFilter.filter(value => value !== 'Australia');
    }, [countriesOverride]);

    const australianStates = useMemo(() =>
        utils.getAustralianStates().map(australianState => ({ text: australianState, value: australianState })), []
    );

    return {
        countries,
        australianStates,
    };
};

export default usePlace;
