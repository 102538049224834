import * as React from 'react';
import PropTypes from 'prop-types';
import tokens from '../../Tokens/tokens';
const { spacing } = tokens;

const BrandIconThumbsUp = ({ title, titleId, ...props }) => {
    const sizeArray = Object.entries(spacing.icons);
    const sizeOptions = new Map(sizeArray);
    const displaySize = sizeOptions.get(props.size);
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 48 48'
            fill={props.color && displayColor}
            aria-hidden={!title}
            role={title ? 'img' : null}
            width={props.size && displaySize}
            height={props.size && displaySize}
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <path d='M13.94 36.73a5 5 0 11-5-5 5 5 0 015 5z' fill='#d7153a' />
            <path
                d='M46.7 32a4.09 4.09 0 00-.51-6.39A4.19 4.19 0 0047 23a3.68 3.68 0 00-4-4h-9c-1.57 0-2.29-.35-2.4-.59a3.6 3.6 0 01.57-2.65c.14-.28.4-.68.7-1.18 1.25-2 3.13-5 3.13-7.58 0-2.94-1.64-5-4-5-2.68 0-4.44 3.52-4.92 4.6a36.8 36.8 0 01-3.23 5.34 32.39 32.39 0 01-5.85 6V15a1 1 0 00-1-1H1a1 1 0 00-.71.29A1 1 0 000 15v29.91a1.23 1.23 0 000 .27A1 1 0 001 46h16a1 1 0 001-1v-3a7.71 7.71 0 013.36 1.21c.37.2.76.42 1.19.63C24 44.63 27.84 45 30.78 45h11.13A3.91 3.91 0 0046 41a4.18 4.18 0 00-.62-2.22A3.83 3.83 0 0048 35a3.94 3.94 0 00-1.3-3zM16 44H2V16h14zm28-7h-1a1 1 0 00-.33.07A4.18 4.18 0 0042 37h-6a1 1 0 00-1 1 1 1 0 001 1h6a1.86 1.86 0 012 2 1.94 1.94 0 01-2.08 2H31c-3 .06-6.53-.41-7.54-.91-.41-.21-.77-.41-1.11-.6A9.56 9.56 0 0018 40V20.5c1.3-1 5.41-4.31 7.51-7.45a40.2 40.2 0 003.41-5.65C29.52 6 30.84 4 32 4c1.48 0 2 1.62 2 3 0 2-1.77 4.82-2.83 6.53-.34.55-.62 1-.78 1.32-.89 1.75-1.11 3.26-.62 4.37S31.72 21 34 21h9c1.38 0 2 .62 2 2s-.61 2-2 2h-8a1 1 0 000 2h8.92A1.94 1.94 0 0146 29a1.84 1.84 0 01-1.92 2H35a1 1 0 000 2h9.09A1.92 1.92 0 0146 35a1.86 1.86 0 01-2 2z'
                fill='#002664'
            />
        </svg>
    );
};

BrandIconThumbsUp.propTypes = {
    title: PropTypes.string,
    titleId: PropTypes.string,
    size: PropTypes.oneOf(['lg', 'xl', 'xxl']),
};
BrandIconThumbsUp.defaultProps = {
    size: 'xl',
    focusable: false,
};
export default BrandIconThumbsUp;
