import styled from 'styled-components/macro';
import { pxToRem, getSpacing } from '../../utils';
import tokens from '../../Tokens/tokens';
import { IconUpload } from '../../Icons/system/index';

const { font, colors, spacing } = tokens;

export const UploadIcon = styled(IconUpload)`
    width: ${pxToRem(spacing.icons.lg)};
    height: ${pxToRem(spacing.icons.lg)};
    fill: ${colors.grey.shuttle};
    margin-top: ${getSpacing('xs')};
    margin-bottom: ${getSpacing('md')};
`;

export const Fieldset = styled.fieldset`
    border: none;
    padding: 0;
`;

export const Legend = styled.legend`
    font-weight: ${font.weight.bold};
    font-size: ${pxToRem(28)};
    margin-bottom: ${getSpacing(['md'])};
`;

export const DropZoneWrapper = styled.div`
    border-radius: 6px;
    padding: ${getSpacing(['md', 'md', 'lg', 'md'])};
    border: ${props => !props.isDraggedIn ?
        `2px dashed ${tokens.colors.forms.helpText};` :
        `2px dashed ${tokens.colors.borders.info}`};
    min-height: 228px;
    position: relative;
    overflow: hidden;
    text-align: center;
    display: flex;
    align-items: center;
    flex-flow: column;
    justify-content: center;
    ${props => props.hasError && `
        border: 2px dashed ${tokens.colors.borders.error};
    `}
`;

export const DropZoneMask = styled.div`
    display: flex;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    align-items: center;
    justify-content: center;
    background: ${tokens.colors.grey.iceBlue};
    height: 100%;
    width: 100%;
    font-size: ${pxToRem(36)};
    font-weight: ${font.weight.bold};
    color: ${tokens.colors.forms.helpText};
`;

export const DropZoneText = styled.div`
    max-width: 368px;
    margin: 0 auto;

    p {
        color: ${tokens.colors.forms.helpText};
        margin-top: 0;
    }
`;

