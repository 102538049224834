import styled, { css } from 'styled-components/macro';
import { FormContainer, FormLabel, HiddenRadioCheckbox, CustomRadioCheckbox } from './Form.styled';
import tokens from '../../Tokens/tokens';
import { getSpacing, marginMixin } from '../../utils';
import Field from './Field.styled';

const { colors } = tokens;

const Checkbox = styled.div`
    ${FormContainer}
`;

Checkbox.Item = styled.div.attrs(props => ({
    className: 'checkbox-item' // needed when referencing this in another component for spacing
}))`
    position: relative;
    ${marginMixin}

    + ${Field.Error} {
        margin-top: ${getSpacing(['md'])};
    }
`;

Checkbox.Input = styled.input`
    ${HiddenRadioCheckbox}

    &:checked + ${Checkbox.Label} {
        &::before {
            background: url("data:image/svg+xml;charset=utf-8,%3Csvg width='20' height='20' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'%3E %3Cpath d='M17.8086633,2.3722292 C18.2495376,1.84741939 19.0323792,1.77937621 19.557189,2.2202505 C20.0470115,2.63173317 20.1389395,3.3411074 19.7905725,3.86055163 L19.7091677,3.96877624 L8.23471343,17.6277858 C7.8043828,18.1400446 7.05368641,18.2136052 6.53429769,17.8183552 L6.42674157,17.7264721 L0.383338261,11.9474554 C-0.112038965,11.4737499 -0.129607388,10.6881532 0.34409809,10.1927759 C0.786223203,9.73042386 1.50004027,9.68429634 1.99601091,10.065341 L2.09877755,10.1535358 L7.1842797,15.0167896 L17.8086633,2.3722292 Z' fill='%23646974'/%3E %3C/svg%3E") no-repeat center;
            background-color: ${colors.forms.background};
        }
    }

    &:focus ~ ${Checkbox.Label} {
        &::before {
            outline: 2px solid ${colors.borders.focus};
            outline-offset: 2px;
        }
    }

    &:disabled + ${Checkbox.Label} {
        &::before {
            opacity: 1;
            background-color: ${colors.backgrounds.disabled};
        }
    }

    @media (-ms-high-contrast: active), (forced-colors: active) {
        &:checked + ${Checkbox.Label} {
            &::before {
                background: url("data:image/svg+xml;charset=utf-8,%3Csvg width='20' height='20' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'%3E %3Cpath d='M17.8086633,2.3722292 C18.2495376,1.84741939 19.0323792,1.77937621 19.557189,2.2202505 C20.0470115,2.63173317 20.1389395,3.3411074 19.7905725,3.86055163 L19.7091677,3.96877624 L8.23471343,17.6277858 C7.8043828,18.1400446 7.05368641,18.2136052 6.53429769,17.8183552 L6.42674157,17.7264721 L0.383338261,11.9474554 C-0.112038965,11.4737499 -0.129607388,10.6881532 0.34409809,10.1927759 C0.786223203,9.73042386 1.50004027,9.68429634 1.99601091,10.065341 L2.09877755,10.1535358 L7.1842797,15.0167896 L17.8086633,2.3722292 Z' fill='white'/%3E %3C/svg%3E") no-repeat center;
            }
        }

        &:focus ~ ${Checkbox.Label} {
            &::before {
                -ms-high-contrast-adjust: none;
                forced-color-adjust: none;
                outline-color: Highlight;
                background-color: Background;
                border-color: InactiveBorder;
                color: InfoText;
            }
        }
    }

`;

Checkbox.Label = styled(FormLabel)`
  ${CustomRadioCheckbox}
  display: inline-block;

  ${props => props.hasError && css`
        &::before {
            border-color: ${colors.borders.error};
            box-shadow: none;
        }
    `}
`;

export default Checkbox;
