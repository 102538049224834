import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import StyledModal, { TriggerWrapper } from './Modal.styled';
import Icons from '../Icons/obsolete/index';
import { FullScreen, FullScreenOpac } from '../layout/Screen/Screen.styled';
import PrimaryButton from '../Buttons/PrimaryButton/PrimaryButton.styled';
import { getFocusableElement, trapTabKey } from '../utils';
import { SROnly } from '../GlobalStyle/utilities/utilities.styled';
import { trackMe } from '../Components/ComponentAnalytics/componentAnalytics';

const Modal = props => {

    useEffect(() => {
        trackMe('LegacyModal');
    }, []);

    const {
        open,
        onClose,
        onOpen,
        trigger,
        mobileFullScreen,
        isConfirmation,
        closeButtonLabel,
        size,
        isClosable,
        closeOnDimmerClick,
        children,
    } = props;

    const [show, setShow] = useState(false);
    const [originalButton, setOriginalButton] = useState(null);

    useEffect(() => {
        setShow(open === true ? true : false);
    }, [open]);

    const modalRef = useRef(null);

    const handleTabKey = e => {
        const focusableElems = getFocusableElement(modalRef.current);
        trapTabKey(e, focusableElems);
    };

    const onEscClose = e => {
        isClosable && closeModal();
    };

    const keyListenersMap = new Map([[27, onEscClose], [9, handleTabKey]]);

    useEffect(() => {
        const keyListener = e => {
            const listener = keyListenersMap.get(e.keyCode);
            return listener && listener(e);
        };

        if (show) {
            const modalElem = getFocusableElement(modalRef.current);

            if (modalElem.length) {
                modalElem.first.focus();
            }
            document.addEventListener('keydown', keyListener);
        } else {
            document.removeEventListener('keydown', keyListener);
        }

        return () => document.removeEventListener('keydown', keyListener);
    },[show]); // eslint-disable-line

    const showModal = e => {
        e.stopPropagation();
        setShow(true);
        setOriginalButton(document.activeElement);
        onOpen && onOpen();
    };

    const closeModal = () => {
        setShow(false);
        originalButton && originalButton.focus();
        onClose && onClose();
    };

    const handleDismissClick = e => {
        closeModal();
    };

    const childrenNode = React.Children.map(children, child => {
        if (!child) {
            return;
        }

        let childElem;

        if (typeof child.type === 'string') {
            childElem = React.cloneElement(child);
        }
        else {
            childElem = React.cloneElement(child, {
                hideModal: handleDismissClick
            });
        }

        return childElem;
    });

    return (
        <>
            {trigger &&
                <TriggerWrapper onClick={ showModal }>
                    {trigger}
                </TriggerWrapper>
            }
            {show &&
                <>
                    <FullScreenOpac onClick={ e => e.stopPropagation() } data-opac='stuff' />
                    <FullScreen
                        data-fullscreen='test'
                        onClick={ e => {
                            e.stopPropagation();
                            closeOnDimmerClick && handleDismissClick();
                        } }
                    >
                        <StyledModal
                            role='dialog'
                            aria-modal='true'
                            ref={ modalRef }
                            onClick={ e => e.stopPropagation() }
                            mobileFullScreen={ mobileFullScreen && mobileFullScreen ? true : false }
                            size={ size }
                        >
                            {childrenNode}
                            {isConfirmation &&
                                <StyledModal.Footer>
                                    <StyledModal.ConfirmationButton onClick={ handleDismissClick }>
                                        OK, I understand
                                    </StyledModal.ConfirmationButton>
                                </StyledModal.Footer>}
                            {closeButtonLabel &&
                                <StyledModal.Footer>
                                    <PrimaryButton onClick={ handleDismissClick }>
                                        {closeButtonLabel}
                                    </PrimaryButton>
                                </StyledModal.Footer>}
                            {isClosable &&
                                <StyledModal.DismissButton
                                    type='button'
                                    onClick={ handleDismissClick }
                                    data-test='modal-close-button'
                                >
                                    <Icons.Cross />
                                    <SROnly>Close</SROnly>
                                </StyledModal.DismissButton>}
                        </StyledModal>
                    </FullScreen>
                </>
            }
        </>
    );
};

Modal.propTypes = {
    open: PropTypes.bool,
    isClosable: PropTypes.bool,
    closeOnDimmerClick: PropTypes.bool,
    onClose: PropTypes.func,
    trigger: PropTypes.node,
    children: PropTypes.node,
    mobileFullScreen: PropTypes.bool,
    isConfirmation: PropTypes.bool,
    closeButtonLabel: PropTypes.string,
    size: PropTypes.string,
    onOpen: PropTypes.func,
};

Modal.Header = StyledModal.Header;
Modal.Body = StyledModal.Body;
Modal.Footer = StyledModal.Footer;

Modal.Footer.propTypes = {
    justifyContent: PropTypes.string,
};

Modal.defaultProps = {
    isClosable: true,
    closeOnDimmerClick: true
};

export default Modal;
