import React, { useEffect } from 'react';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import { trackMe } from '../Components/ComponentAnalytics/componentAnalytics';

const Picture = ({ webp, png, alt }) => {
    useEffect(() => {
        trackMe('Picture');
    }, []);

    return (
        <PictureContainer>
            { webp && <source srcSet={ webp } type='image/webp' /> }
            <img src={ png } alt={ alt } />
        </PictureContainer>
    );
};

const PictureContainer = styled.picture`
  img {
    width: 100%;
    height: auto;
  }
`;

Picture.propTypes = {
    webp: PropTypes.string,
    png: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired,
};

export default Picture;
