import * as React from 'react';
import PropTypes from 'prop-types';
import tokens from '../../Tokens/tokens';
const { spacing } = tokens;

const BrandIconPartnerships = ({ title, titleId, ...props }) => {
    const sizeArray = Object.entries(spacing.icons);
    const sizeOptions = new Map(sizeArray);
    const displaySize = sizeOptions.get(props.size);
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 48 48'
            fill={props.color && displayColor}
            aria-hidden={!title}
            role={title ? 'img' : null}
            width={props.size && displaySize}
            height={props.size && displaySize}
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <defs>
                <style>{'.Partnerships_svg__cls-1{fill:#002664}'}</style>
            </defs>
            <path
                className='Partnerships_svg__cls-1'
                d='M37 39.18l-.57.34c-.92.38-1.94.76-3-.94l-3.32-6a1 1 0 00-1.36-.38 1 1 0 00-.39 1.35l3.32 6a3.93 3.93 0 003.46 2.3 5.35 5.35 0 002.1-.5l.75-.43zm-6.33 1.61l-.68.39a1.28 1.28 0 01-.94.17 2.15 2.15 0 01-1.13-1.11l-2.16-3.75a1 1 0 00-1.73 1l2.16 3.75a3.82 3.82 0 002.4 2 3.5 3.5 0 00.7.08 3.28 3.28 0 001.73-.5l.62-.35z'
            />
            <path d='M8.21 32.83a5 5 0 11.62-7 5 5 0 01-.62 7z' fill='#d7153a' />
            <path
                className='Partnerships_svg__cls-1'
                d='M30.83 11H41c1.07 0 4.1 1.54 7 3.23v-2.31C45.42 10.47 42.48 9 41 9H30.68a9.49 9.49 0 00-6 3.51c-1.05 1.26-6.32 7.6-6.95 9.09A3.11 3.11 0 0019 25c.72.56 3.35 2.16 7-.65l5.4-5.46a1.75 1.75 0 011.5-.5 2.42 2.42 0 011.64.9l8.82 16.78a1.93 1.93 0 01-.94 2.23c-1 .51-2.09-.17-3.1-1.92l-4.69-7.33A1 1 0 0033 30.17l4.67 7.3c1.3 2.23 2.83 3 4.16 3a3.38 3.38 0 001.59-.41 3.88 3.88 0 001.8-4.88L44.61 34H48v-2h-4.45l-7.19-13.66a4.37 4.37 0 00-3.15-1.9 3.74 3.74 0 00-3.16 1.09l-5.24 5.31c-1.62 1.22-3.22 1.49-4.37.75a1.71 1.71 0 01-.87-1.2c.38-.9 4.11-5.56 6.66-8.61a7.48 7.48 0 014.6-2.78z'
            />
            <path
                className='Partnerships_svg__cls-1'
                d='M24.46 42.84l-.16.07a2.74 2.74 0 01-3.11-1.4L17.06 31H14V14h7.06l1.64-2H14V6H0v2h12v28H0v2h14v-5h1.73l3.67 9.32.07.14A4.81 4.81 0 0023.65 45a4.14 4.14 0 001.28-.2 2.39 2.39 0 00.63-.3z'
            />
        </svg>
    );
};

BrandIconPartnerships.propTypes = {
    title: PropTypes.string,
    titleId: PropTypes.string,
    size: PropTypes.oneOf(['lg', 'xl', 'xxl']),
};
BrandIconPartnerships.defaultProps = {
    size: 'xl',
    focusable: false,
};
export default BrandIconPartnerships;
