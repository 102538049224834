import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Icons from '../Icons/obsolete/index';
import SlidingModal from './SlidingModal';
import StyledModal from './Modal.styled';
import Button from '../Components/Button/Button';
import { SROnly } from '../GlobalStyle/utilities/utilities.styled';
import { getID } from '../utils';
import { MoreInfoModalIconButton } from './MoreInfoModal.styled';

export const MoreInfoModal = props => {
    const {
        id,
        title,
        subTitle,
        helpText,
        questionIconFlag,
        questionHyperLink,
        hyperLinKText,
        linkText: propLinkText,
    } = props;

    const [isOpen, setIsOpen] = useState(false);
    const [returnFocus, setReturnFocus] = useState(null);

    if (process.env.NODE_ENV !== 'production' && 'hyperLinKText' in props) {
        console.warn(
            Error(
                '"MoreInfoModal.props.hyperLinKText" prop has been deprecated as it was exposed as a typo. Please use the "linkText" prop instead.'
            ).toString()
                .replace('Error:', 'Deprecation:')
        );
    }

    const linkText = propLinkText || hyperLinKText;

    const toggleModal = () => {
        setIsOpen(!isOpen);

        if (isOpen) {
            returnFocus.focus();
        }
        else {
            setReturnFocus(document.activeElement);
        }
    };

    const genID = () => id || getID();

    const elemID = genID();
    const idTitle = `${elemID}-title`;
    const idDesc = `${elemID}-desc`;

    if (!helpText) {
        return null;
    }

    return (
        <>
            {!questionHyperLink &&
                <MoreInfoModalIconButton type='button' onClick={ toggleModal }>
                    {questionIconFlag && <Icons.Info color='#2E5299' />}
                    {!questionIconFlag && <Icons.Question color='#2E5299' />}
                    {linkText && <SROnly>{linkText}</SROnly>}
                </MoreInfoModalIconButton>
            }
            {questionHyperLink && !questionIconFlag &&
                <Button onClick={ toggleModal } theme='link'>
                    {linkText}
                </Button>
            }
            <SlidingModal open={ isOpen } onClose={ toggleModal }>
                <StyledModal.Wrapper>
                    <StyledModal.Header id={ idTitle } slidingPanel>
                        <StyledModal.Title>{title}</StyledModal.Title><br />
                        {subTitle}
                    </StyledModal.Header>
                    <StyledModal.Body id={ idDesc }>
                        {helpText}
                    </StyledModal.Body>
                </StyledModal.Wrapper>
            </SlidingModal>
        </>
    );
};

MoreInfoModal.propTypes = {
    id: PropTypes.string,
    title: PropTypes.string.isRequired,
    subTitle: PropTypes.string,
    helpText: PropTypes.node,
    questionIconFlag: PropTypes.bool,
    questionHyperLink: PropTypes.bool,
    linkText: PropTypes.string,
    hyperLinKText: PropTypes.string
};

MoreInfoModal.defaultProps = {
    questionIconFlag: false,
    questionHyperLink: false
};
