import * as React from 'react';
import PropTypes from 'prop-types';
import tokens from '../../Tokens/tokens';
const { spacing } = tokens;

const BrandIconSmallBusiness = ({ title, titleId, ...props }) => {
    const sizeArray = Object.entries(spacing.icons);
    const sizeOptions = new Map(sizeArray);
    const displaySize = sizeOptions.get(props.size);
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 48 48'
            fill={props.color && displayColor}
            aria-hidden={!title}
            role={title ? 'img' : null}
            width={props.size && displaySize}
            height={props.size && displaySize}
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <defs>
                <style>{'.SmallBusiness_svg__cls-1{fill:#002664}'}</style>
            </defs>
            <path className='SmallBusiness_svg__cls-1' d='M39 48H9a1 1 0 010-2h30a1 1 0 010 2z' />
            <path d='M45 28a5 5 0 00-4.56-5l-1.69 9.78A4.72 4.72 0 0040 33a5 5 0 005-5z' fill='#d7153a' />
            <path
                className='SmallBusiness_svg__cls-1'
                d='M39.76 13.36A1 1 0 0039 13H9a1 1 0 00-.76.36 1 1 0 00-.23.81l5 29A1 1 0 0014 44h20a1 1 0 001-.83l5-29a1 1 0 00-.24-.81zM33.16 42H14.84l-4.65-27h27.62l-1.38 8H15a1 1 0 000 2h21.09zM24.83 5.45c-1.47-2.21-.26-3.59-.12-3.75a1 1 0 000-1.41 1 1 0 00-1.42 0c-.89.9-2.06 3.35-.12 6.26 1.22 1.84 0 3.74-.62 4.45h2.39a5.26 5.26 0 00-.11-5.55zm-4 0c-1.47-2.21-.26-3.59-.12-3.75a1 1 0 000-1.41 1 1 0 00-1.42 0c-.89.9-2.06 3.35-.12 6.26 1.22 1.84 0 3.74-.62 4.45h2.39a5.26 5.26 0 00-.11-5.55zm8 0c-1.47-2.21-.26-3.59-.12-3.75a1 1 0 000-1.41 1 1 0 00-1.42 0c-.89.9-2.06 3.35-.12 6.26 1.22 1.84 0 3.74-.62 4.45h2.39a5.26 5.26 0 00-.11-5.55z'
            />
        </svg>
    );
};

BrandIconSmallBusiness.propTypes = {
    title: PropTypes.string,
    titleId: PropTypes.string,
    size: PropTypes.oneOf(['lg', 'xl', 'xxl']),
};
BrandIconSmallBusiness.defaultProps = {
    size: 'xl',
    focusable: false,
};
export default BrandIconSmallBusiness;
