import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import StyledAccordion from './Accordion.styled';
import { IconChevronDown, IconChevronUp } from '../../Icons/system';

const AccordionItem = ({ id, title, expanded, onClick, children }) => (
    <>
        <StyledAccordion.Header className='accordion-header'>
            <h2>
                <StyledAccordion.Button
                    type='button'
                    id={ `${id}-button` }
                    onClick={ onClick }
                    aria-expanded={ expanded }
                    aria-controls={ `${id}-panel` }
                >
                    <span>{title}</span>

                    {!expanded ? <IconChevronDown /> : <IconChevronUp />}
                </StyledAccordion.Button>
            </h2>
        </StyledAccordion.Header>
        <StyledAccordion.Content
            id={ `${id}-panel` }
            className={ classNames({ 'accordion-content': true, 'hide-content': !expanded }) }
            aria-labelledby={ `${id}-button` }
        >
            {children}
        </StyledAccordion.Content>
    </>
);

AccordionItem.propTypes = {
    /**
    * @ignore
    */
    id: PropTypes.string,
    /** The content of the accordion.  */
    children: PropTypes.node.isRequired,
    /** The title of the accordion item. */
    title: PropTypes.string.isRequired,
    /** If the accordion should be expanded by default. */
    expanded: PropTypes.bool,
    /**
    * @ignore
    */
    onClick: PropTypes.func,
};

AccordionItem.defaultProps = {
    expanded: false
};

export default AccordionItem;
