import * as React from 'react';
import PropTypes from 'prop-types';
import tokens from '../../Tokens/tokens';
const { spacing } = tokens;

const BrandIconCookingFood = ({ title, titleId, ...props }) => {
    const sizeArray = Object.entries(spacing.icons);
    const sizeOptions = new Map(sizeArray);
    const displaySize = sizeOptions.get(props.size);
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 48 48'
            fill={props.color && displayColor}
            aria-hidden={!title}
            role={title ? 'img' : null}
            width={props.size && displaySize}
            height={props.size && displaySize}
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <defs>
                <style>{'.CookingFood_svg__cls-1{fill:#002664}'}</style>
            </defs>
            <path
                className='CookingFood_svg__cls-1'
                d='M12.55 16.05a.92.92 0 01-.42-.1 1 1 0 01-.48-1.33 12.68 12.68 0 016.52-6.21 1 1 0 01.83 1.84 10.67 10.67 0 00-5.5 5.22 1 1 0 01-.95.58zM47 27H1a1 1 0 000 2h7.81c1.68 1.48 7.64 6.7 10.59 8.46S32.12 44 34.71 45.3l.86-1.8c-2.58-1.26-12.3-6.06-15.14-7.75A85.45 85.45 0 0111.85 29h2.22l.45.27.21.12.18.1a1.75 1.75 0 00.27.16l.2.12.31.18.22.12.34.2.23.13.39.21.22.13.51.28.11.06.64.34.16.09.47.24.23.11.39.2.25.12.36.18.24.11.35.15.21.1.36.14.16.07.46.16a16.48 16.48 0 004.34.58c2.05 0 4.1-.42 4.91-1.73l.45-.77.12-.22.32-.55a1.62 1.62 0 01.1-.18c.12-.23.24-.44.35-.64l.3-.57h2.35c-.22 1-.53 2.49-.73 3.68-.41 2.46.74 3.45 3.27 5.63l.6-.62.51-1.06c-2.31-2-2.62-2.36-2.41-3.61s.61-3.1.81-4H47a1 1 0 000-2zm-24.41 4.18l-.44-.18H22l-.32-.13-.26-.12-.29-.13-.33-.16-.26-.13-.4-.2-.24-.13-.47-.25-.14-.07-.53-.29h-.09l-.59-.39h12.53c-.14.27-.3.56-.47.86l-.08.13c-.17.3-.34.6-.53.9-.53.91-4.44 1.11-6.94.29z'
            />
            <path
                className='CookingFood_svg__cls-1'
                d='M44 25H3v-1C3 13.35 12.59 4 23.5 4S44 13.35 44 24zM5.06 23h36.88C41.37 13.83 33 6 23.5 6S5.63 13.83 5.06 23zM48 37.56l-6.51-2.88L35.59 48h2.19l4.73-10.68L48 39.75v-2.19z'
            />
            <path
                d='M53.13 5a5 5 0 01-4.68-3.28C41.14 3.81 35.39 10.43 34.91 18H71.8c-.48-7.68-6.45-14.4-14-16.38A5 5 0 0153.13 5z'
                fill='none'
            />
            <path d='M28.13 4.62a5 5 0 00-9.94.12A19.68 19.68 0 0123.4 4a19.11 19.11 0 014.73.62z' fill='#d7153a' />
        </svg>
    );
};

BrandIconCookingFood.propTypes = {
    title: PropTypes.string,
    titleId: PropTypes.string,
    size: PropTypes.oneOf(['lg', 'xl', 'xxl']),
};
BrandIconCookingFood.defaultProps = {
    size: 'xl',
    focusable: false,
};
export default BrandIconCookingFood;
