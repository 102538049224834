import * as React from 'react';
import PropTypes from 'prop-types';
import tokens from '../../Tokens/tokens';
const { spacing } = tokens;

const BrandIconQrCard = ({ title, titleId, ...props }) => {
    const sizeArray = Object.entries(spacing.icons);
    const sizeOptions = new Map(sizeArray);
    const displaySize = sizeOptions.get(props.size);
    return (
        <svg
            viewBox='0 0 48 48'
            xmlns='http://www.w3.org/2000/svg'
            fill={props.color && displayColor}
            aria-hidden={!title}
            role={title ? 'img' : null}
            width={props.size && displaySize}
            height={props.size && displaySize}
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <g stroke='none' strokeWidth={1} fill='none' fillRule='evenodd'>
                <path
                    d='M10,36 C7.23857625,36 5,33.7614237 5,31 C5,28.2385763 7.23857625,26 10,26 C12.7614237,26 15,28.2385763 15,31 C15,32.3260824 14.4732158,33.597852 13.5355339,34.5355339 C12.597852,35.4732158 11.3260824,36 10,36 Z'
                    fill='#D7153A'
                    fillRule='nonzero'
                    transform='translate(10.000000, 31.000000) rotate(-270.000000) translate(-10.000000, -31.000000) '
                />
                <g transform='translate(22.000000, 15.000000)' fill='#002664' fillRule='nonzero'>
                    <path d='M1.00003161,8.00050922 L7.00001638,8.00050922 L7.00001638,8.00050922 C7.5523065,8.00050922 8.00001638,7.55281131 8.00001638,7.00054796 L8.00001638,1.00078038 C8.00001638,0.448517029 7.5523065,0.0008191222 7.00001638,0.0008191222 L1.00003161,0.0008191222 C0.732435059,-0.0104716604 0.473203082,0.0954078808 0.290032676,0.290807888 C0.102846443,0.479519408 -0.00150364404,0.734987696 1.63761951e-05,1.00078038 L1.63761951e-05,7.00054796 C1.63761951e-05,7.55281131 0.447747689,8.00050922 1.00003161,8.00050922 Z M2.0000301,2.00074165 L6.0000301,2.00074165 L6.0000301,6.00058669 L2.0000301,6.00058669 L2.0000301,2.00074165 Z' />
                    <path d='M15.000012,8.00050922 L21.0000015,8.00050922 C21.5522854,8.00050922 22,7.55281131 22,7.00054796 L22,1.00078038 C22,0.448517029 21.5522854,0.000819122199 21.0000015,0.000819122199 L15.000012,0.000819122199 C14.4477266,0.000819122199 14.000012,0.448517029 14.000012,1.00078038 L14.000012,7.00054796 C14.000012,7.55281131 14.4477266,8.00050922 15.000012,8.00050922 Z M16.000009,2.00074165 L20.000009,2.00074165 L20.000009,6.00058669 L16.000009,6.00058669 L16.000009,2.00074165 Z' />
                    <path d='M7.00001638,14.0002601 L1.00003161,14.0002601 L1.00003161,14.0002601 C0.734229023,13.9987401 0.478751223,14.1030863 0.290032676,14.2902656 C0.102846443,14.4789771 -0.00150364404,14.7344454 1.63761951e-05,15.0002381 L1.63761951e-05,21.0000056 C1.63761951e-05,21.552269 0.447747689,21.9999836 1.00003161,21.9999836 L7.00001638,21.9999836 C7.26582516,22.0015036 7.52130296,21.8971574 7.71002151,21.7099781 C7.89720774,21.5212666 8.00155783,21.2657983 8.00003781,21.0000056 L8.00003781,15.0002381 C8.00003781,14.4479747 7.5523065,14.0002601 7.00001638,14.0002601 Z M2.0000301,20.0000444 L2.0000301,16.0001993 L6.0000301,16.0001993 L6.0000301,20.0000444 L2.0000301,20.0000444 Z' />
                    <rect x={10.0000181} y={0.000819122199} width={1.99999699} height={7.9996901} />
                    <rect x={0.0000331124477} y={10.0004317} width={4.99999247} height={1.99992252} />
                    <path d='M11.0000166,10 C11.5128524,10 11.9355237,10.3860402 11.9932888,10.8833789 L12.0000166,11 L12.0000166,14.0002437 L10.0000166,14.0002437 L10,11.999 L6.97300566,12 L6.97300566,10 L11.0000166,10 Z' />
                    <path
                        d='M13.0068366,16.006559 C13.5196725,16.006559 13.9423438,16.3925992 14.0001089,16.8899379 L14.0068366,17.006559 L14.0068366,20.0068027 L12.0068366,20.0068027 L12.006,18.006 L9.99294379,18.006559 L9.99294379,16.006559 L13.0068366,16.006559 Z'
                        transform='translate(11.999890, 18.006681) rotate(-90.000000) translate(-11.999890, -18.006681) '
                    />
                </g>
                <path
                    d='M45,7 C46.6568542,7 48,8.34314575 48,10 L48,38 C48,39.6568542 46.6568542,41 45,41 L3,41 C1.34314575,41 0,39.6568542 0,38 L0,10 C0,8.34314575 1.34314575,7 3,7 L45,7 Z M45,9 L3,9 C2.48716416,9 2.06449284,9.38604019 2.00672773,9.88337887 L2,10 L2,38 C2,38.5128358 2.38604019,38.9355072 2.88337887,38.9932723 L3,39 L45,39 C45.5128358,39 45.9355072,38.6139598 45.9932723,38.1166211 L46,38 L46,10 C46,9.48716416 45.6139598,9.06449284 45.1166211,9.00672773 L45,9 Z'
                    fill='#002664'
                    fillRule='nonzero'
                />
                <rect fill='#002664' x={4} y={11} width={40} height={2} />
                <rect fill='#002664' fillRule='nonzero' x={32.0000181} y={35.0000444} width={2} height={1.99992252} />
                <rect fill='#002664' fillRule='nonzero' x={36.0580822} y={26.717914} width={2} height={1.99992252} />
                <polygon
                    fill='#002664'
                    fillRule='nonzero'
                    points='42.0329285 25.0730826 44.0328143 25.0944583 44.0007508 28.0942869 42.000865 28.0729112'
                />
                <path
                    d='M40.0328714,26.717914 L40.032,30.826 L43.0328714,30.826563 C43.5457072,30.826563 43.9683785,31.2126032 44.0261437,31.7099419 L44.0328714,31.826563 L44.0328714,36.0837704 C44.0328714,36.5966063 43.6468312,37.0192776 43.1494925,37.0770427 L43.0328714,37.0837704 L36.0328714,37.0837704 L36.0328714,35.0837704 L42.032,35.083 L42.032,32.826 L39.0328714,32.826563 C38.5200355,32.826563 38.0973642,32.4405228 38.0395991,31.9431841 L38.0328714,31.826563 L38.0328714,26.717914 L40.0328714,26.717914 Z'
                    fill='#002664'
                    fillRule='nonzero'
                />
                <polygon
                    fill='#002664'
                    fillRule='nonzero'
                    points='40.0328714 25.0835991 40.0328714 28.0835991 38.0328714 28.0835991 38.0328714 25.0835991'
                />
            </g>
        </svg>
    );
};

BrandIconQrCard.propTypes = {
    title: PropTypes.string,
    titleId: PropTypes.string,
    size: PropTypes.oneOf(['lg', 'xl', 'xxl']),
};
BrandIconQrCard.defaultProps = {
    size: 'xl',
    focusable: false,
};
export default BrandIconQrCard;
