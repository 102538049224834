import * as React from 'react';
import PropTypes from 'prop-types';
import tokens from '../../Tokens/tokens';
const { spacing } = tokens;

const BrandIconBusiness = ({ title, titleId, ...props }) => {
    const sizeArray = Object.entries(spacing.icons);
    const sizeOptions = new Map(sizeArray);
    const displaySize = sizeOptions.get(props.size);
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 48 48'
            fill={props.color && displayColor}
            aria-hidden={!title}
            role={title ? 'img' : null}
            width={props.size && displaySize}
            height={props.size && displaySize}
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <path
                d='M44.62 45H3.38A3.39 3.39 0 010 41.62V30h2v11.62A1.4 1.4 0 003.38 43h41.24A1.4 1.4 0 0046 41.62V28.93h2v12.69A3.39 3.39 0 0144.62 45zm0-32H3.38A3.39 3.39 0 000 16.38v9.45a1 1 0 001 1h46a1 1 0 001-1v-9.45A3.39 3.39 0 0044.62 13zM46 25H2v-8.62A1.4 1.4 0 013.38 15h41.24A1.4 1.4 0 0146 16.38zm-9.9-13.86h-2v-3.6A2.54 2.54 0 0031.56 5H16.44a2.54 2.54 0 00-2.54 2.54v3.6h-2v-3.6A4.55 4.55 0 0116.44 3h15.12a4.55 4.55 0 014.54 4.54z'
                fill='#002664'
            />
            <path d='M24 30.51a5 5 0 115-5 5 5 0 01-5 5z' fill='#d7153a' />
        </svg>
    );
};

BrandIconBusiness.propTypes = {
    title: PropTypes.string,
    titleId: PropTypes.string,
    size: PropTypes.oneOf(['lg', 'xl', 'xxl']),
};
BrandIconBusiness.defaultProps = {
    size: 'xl',
    focusable: false,
};
export default BrandIconBusiness;
