import styled from 'styled-components/macro';
import { getSpacing } from '../../utils';
import Tokens from '../../Tokens/tokens';

const { colors, font } = Tokens;

export const UploadedItemContainer = styled.div`
    background-color: ${colors.grey.iceBlue};
    padding: ${getSpacing('sm')};
    font-weight: ${font.weight.medium};
    display: flex;
    align-items: center;
    justify-content: space-between;

    :not(:first-child) {
        margin-top: ${getSpacing(4)};
    }
`;

export const FileInfo = styled.span`
    margin-right: ${getSpacing('sm')};
`;

export const ButtonContainer = styled.div`
    flex-shrink: 0;
`;
