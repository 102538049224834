import * as React from 'react';
import PropTypes from 'prop-types';
import tokens from '../../Tokens/tokens';
const { colors, spacing } = tokens;

const IconThumbsUpFilled = ({ title, titleId, ...props }) => {
    const sizeArray = Object.entries(spacing.icons);
    const colorArray = Object.entries(colors.icons);
    const sizeOptions = new Map(sizeArray);
    const displaySize = sizeOptions.get(props.size);
    const colorOptions = new Map(colorArray);
    const displayColor = colorOptions.get(props.color);
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 16 16'
            fill={props.color && displayColor}
            aria-hidden={!title}
            role={title ? 'img' : null}
            width={props.size && displaySize}
            height={props.size && displaySize}
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <path d='M1 6a1 1 0 00-1 1v8a1 1 0 102 0V7a1 1 0 00-1-1zm3 8.538c0 .45.13.805.388 1.068.259.263.59.394.995.394h6.625c.502-.062.877-.156 1.126-.282.614-.311.836-.51 1.108-.817a3.42 3.42 0 00.71-1.321 3.72 3.72 0 00.112-.79l.91-4.508c.035-.36.035-.634 0-.823a4.475 4.475 0 00-.359-1.016c-.308-.583-.665-.885-.967-1.119-.293-.228-.871-.517-1.514-.619-.342-.054-.929-.054-1.76 0V3.113a4.668 4.668 0 00-.066-.481 3.949 3.949 0 00-.19-.613 3.283 3.283 0 00-.7-1.078C10.216.745 9.765.38 9.36.218A3.535 3.535 0 008.148 0a1.5 1.5 0 00-.467.086c-.224.081-.458.23-.566.375a4.03 4.03 0 00-.368.635L4.101 6.521c-.04.113-.066.203-.078.269A1.7 1.7 0 004 7.056v7.482z' />
        </svg>
    );
};

IconThumbsUpFilled.propTypes = {
    title: PropTypes.string,
    titleId: PropTypes.string,
    size: PropTypes.oneOf(['sm', 'md']),
    color: PropTypes.oneOf(['primaryBlue', 'secondaryBlue', 'darkGrey', 'white']),
};
IconThumbsUpFilled.defaultProps = {
    size: 'sm',
    color: 'darkGrey',
    focusable: false,
};
export default IconThumbsUpFilled;
