const spacing = {
    none: 0,
    xs: 8,
    sm: 16,
    md: 24,
    lg: 32,
    xl: 40,
    xxl: 48,
    xxxl: 56,
    icons: {
        sm: 16,
        md: 24,
        lg: 36,
        xl: 48,
        xxl: 60
    }
};

export default spacing;