import styled from 'styled-components/macro';

export const SROnly = styled.span`
    border: 0 !important;
    clip: rect(1px, 1px, 1px, 1px) !important;
    -webkit-clip-path: inset(50%) !important;
    clip-path: inset(50%) !important;
    height: 1px !important;
    overflow: hidden !important;
    padding: 0 !important;
    position: absolute !important;
    width: 1px !important;
    white-space: nowrap !important; 
  `;

export const SROnlyFocusable = styled.span`
    clip: auto !important;
    -webkit-clip-path: none !important;
        clip-path: none !important;
    height: auto !important;
    overflow: visible !important;
    width: auto !important;
    white-space: normal !important;
`;
