import * as React from 'react';
import PropTypes from 'prop-types';
import tokens from '../../Tokens/tokens';
const { spacing } = tokens;

const BrandIconSchool = ({ title, titleId, ...props }) => {
    const sizeArray = Object.entries(spacing.icons);
    const sizeOptions = new Map(sizeArray);
    const displaySize = sizeOptions.get(props.size);
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 48 48'
            fill={props.color && displayColor}
            aria-hidden={!title}
            role={title ? 'img' : null}
            width={props.size && displaySize}
            height={props.size && displaySize}
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <defs>
                <style>{'.School_svg__cls-1{fill:#002664}'}</style>
            </defs>
            <path
                className='School_svg__cls-1'
                d='M27 38h-6a1 1 0 00-1 1v9h2v-8h4v8h2v-9a1 1 0 00-1-1zM11.47 19.88L24 13.14l12.53 6.74a1 1 0 00.94-1.76L25 11.4V8.62l7.45-3.73A1 1 0 0033 4a1 1 0 00-.65-.89l-8-3A1 1 0 0023 1v10.4l-12.47 6.72a1 1 0 10.94 1.76zM25 2.44l4.5 1.69L25 6.38z'
            />
            <path className='School_svg__cls-1' d='M13.99 20h2v28h-2zM4 34h2v14H4z' />
            <path
                className='School_svg__cls-1'
                d='M0 26v5a1 1 0 001 1h13v-2H2v-3h12v-2H1a1 1 0 00-1 1zm9 10h2v4H9zm0 6h2v4H9zm38-17H34v-5h-2v28h2V31.94h13a1 1 0 001-1V26a1 1 0 00-1-1zm-1 5H34v-3h12z'
            />
            <path className='School_svg__cls-1' d='M37 36.02h2V40h-2zM37 42h2v4h-2zm5-8h2v14h-2z' />
            <circle cx={24} cy={27} r={5} fill='#d7153a' />
        </svg>
    );
};

BrandIconSchool.propTypes = {
    title: PropTypes.string,
    titleId: PropTypes.string,
    size: PropTypes.oneOf(['lg', 'xl', 'xxl']),
};
BrandIconSchool.defaultProps = {
    size: 'xl',
    focusable: false,
};
export default BrandIconSchool;
