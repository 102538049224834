import * as React from 'react';
import PropTypes from 'prop-types';
import tokens from '../../Tokens/tokens';
const { spacing } = tokens;

const BrandIconQuestion = ({ title, titleId, ...props }) => {
    const sizeArray = Object.entries(spacing.icons);
    const sizeOptions = new Map(sizeArray);
    const displaySize = sizeOptions.get(props.size);
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 48 48'
            fill={props.color && displayColor}
            aria-hidden={!title}
            role={title ? 'img' : null}
            width={props.size && displaySize}
            height={props.size && displaySize}
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <defs>
                <style>{'.Question_svg__cls-2{fill:#002664}'}</style>
            </defs>
            <path d='M30 35a5 5 0 11-5-5 5 5 0 015 5z' fill='#d7153a' />
            <path
                className='Question_svg__cls-2'
                d='M26.08 28h-3a1 1 0 01-1-.81l-1.4-7.39a1 1 0 01.28-.89l.18-.19a1 1 0 01.67-.29c5.58-.22 5.58-1.88 5.58-2.42 0-1.18-.82-1.75-2.44-1.75a6.69 6.69 0 00-4.89 2.3 1 1 0 01-.74.3 1 1 0 01-.71-.33l-3.44-3.8a1 1 0 010-1.34A12.82 12.82 0 0125.08 7c6.19 0 10 3.37 10 8.78 0 4.45-2.52 7.27-7.5 8.47l-.56 2.94a1 1 0 01-.94.81zm-2.18-2h1.35l.52-2.77a1 1 0 01.79-.8c4.54-.92 6.56-2.95 6.56-6.57 0-4.32-3-6.86-8-6.86a10.69 10.69 0 00-7.79 3.08l2.13 2.36A8.31 8.31 0 0125 12.26c2.74 0 4.44 1.41 4.44 3.67 0 3.63-4.22 4.29-6.56 4.45z'
            />
            <path
                className='Question_svg__cls-2'
                d='M1 47a1 1 0 01-.76-.35 1 1 0 01-.16-1L4.7 34.82a23 23 0 118.55 9L1.26 47A1.15 1.15 0 011 47zm12.4-5.31a1.08 1.08 0 01.53.15 21 21 0 10-7.24-7.57 1 1 0 010 .89l-4 9.34 10.41-2.77a.82.82 0 01.3-.04z'
            />
        </svg>
    );
};

BrandIconQuestion.propTypes = {
    title: PropTypes.string,
    titleId: PropTypes.string,
    size: PropTypes.oneOf(['lg', 'xl', 'xxl']),
};
BrandIconQuestion.defaultProps = {
    size: 'xl',
    focusable: false,
};
export default BrandIconQuestion;
