import * as React from 'react';
import PropTypes from 'prop-types';
import tokens from '../../Tokens/tokens';
const { colors, spacing } = tokens;

const IconTranscribe = ({ title, titleId, ...props }) => {
    const sizeArray = Object.entries(spacing.icons);
    const colorArray = Object.entries(colors.icons);
    const sizeOptions = new Map(sizeArray);
    const displaySize = sizeOptions.get(props.size);
    const colorOptions = new Map(colorArray);
    const displayColor = colorOptions.get(props.color);
    return (
        <svg
            viewBox='0 0 16 16'
            xmlns='http://www.w3.org/2000/svg'
            fill={props.color && displayColor}
            aria-hidden={!title}
            role={title ? 'img' : null}
            width={props.size && displaySize}
            height={props.size && displaySize}
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <path
                fillRule='evenodd'
                d='M5.9179 0.792895C6.54657 0.164214 7.34585 0 8 0C8.65414 0 9.45343 0.164214 10.0821 0.792893C10.7108 1.42157 11 2.34586 11 3.5V6.5C11 7.65414 10.7108 8.57843 10.0821 9.20711C9.45343 9.83579 8.65414 10 8 10C7.34586 10 6.54657 9.83579 5.91789 9.20711C5.28921 8.57843 4.99999 7.65414 5 6.5V3.5C5.00001 2.34586 5.28922 1.42157 5.9179 0.792895ZM7 3.50001V6.50001C7 7.34586 7.21078 7.67157 7.3321 7.79289C7.45342 7.91421 7.65414 8 8 8C8.34586 8 8.54657 7.91421 8.66789 7.79289C8.78921 7.67157 9 7.34586 9 6.5V3.5C9 2.65414 8.78921 2.32843 8.66789 2.20711C8.54657 2.08579 8.34586 2 8 2C7.65415 2 7.45343 2.08579 7.33211 2.20711C7.21079 2.32843 7.00001 2.65415 7 3.50001Z'
            />
            <path d='M2 6C2.55228 6 3 6.44772 3 7V9C3 9.09088 3.05334 9.32882 3.3024 9.68823C3.5393 10.0301 3.9033 10.4074 4.37757 10.7598C5.33018 11.4674 6.62575 12 8 12C9.37425 12 10.6698 11.4674 11.6224 10.7598C12.0967 10.4074 12.4607 10.0301 12.6976 9.68823C12.9467 9.32882 13 9.09088 13 9V7C13 6.44772 13.4477 6 14 6C14.5523 6 15 6.44772 15 7V9C15 9.65912 14.7096 10.2962 14.3415 10.8274C13.9612 11.3762 13.4346 11.9051 12.8151 12.3652C11.8037 13.1165 10.4774 13.7337 9 13.9325V15C9 15.5523 8.55228 16 8 16C7.44772 16 7 15.5523 7 15V13.9325C5.52258 13.7337 4.19629 13.1165 3.18493 12.3652C2.56545 11.9051 2.03883 11.3762 1.65854 10.8274C1.29041 10.2962 1 9.65912 1 9V7C1 6.44772 1.44772 6 2 6Z' />
        </svg>
    );
};

IconTranscribe.propTypes = {
    title: PropTypes.string,
    titleId: PropTypes.string,
    size: PropTypes.oneOf(['sm', 'md']),
    color: PropTypes.oneOf(['primaryBlue', 'secondaryBlue', 'darkGrey', 'white']),
};
IconTranscribe.defaultProps = {
    size: 'sm',
    color: 'darkGrey',
    focusable: false,
};
export default IconTranscribe;
