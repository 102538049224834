import styled from 'styled-components/macro';
import { Input } from '../..';
import tokens from '../../Tokens/tokens';

const { colors } = tokens;

export const StyledManualAddress = styled.div`
    width: 100%;
`;

export const ReadonlyInput = styled(Input)`
    opacity: 1;
    background-color: ${colors.backgrounds.disabled};
`;