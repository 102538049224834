import * as React from 'react';
import PropTypes from 'prop-types';
import tokens from '../../Tokens/tokens';
const { colors, spacing } = tokens;

const IconNotificationSuccess = ({ title, titleId, ...props }) => {
    const sizeArray = Object.entries(spacing.icons);
    const colorArray = Object.entries(colors.icons);
    const sizeOptions = new Map(sizeArray);
    const displaySize = sizeOptions.get(props.size);
    const colorOptions = new Map(colorArray);
    const displayColor = colorOptions.get(props.color);
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 16 16'
            fill={props.color && displayColor}
            aria-hidden={!title}
            role={title ? 'img' : null}
            width={props.size && displaySize}
            height={props.size && displaySize}
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <g fill='none' fillRule='evenodd'>
                <path
                    d='M7.06 12a1 1 0 00.72-.38l4-5a1 1 0 10-1.56-1.24L6.92 9.5 5.71 8.29a1.004 1.004 0 00-1.42 1.42l2 2A1 1 0 007 12h.06z'
                    fill='#FFF'
                />
                <path
                    d='M8 16A8 8 0 118 0a8 8 0 010 16zm-.94-4a1 1 0 00.72-.38l4-5a1 1 0 10-1.56-1.24L6.92 9.5 5.71 8.29a1.004 1.004 0 00-1.42 1.42l2 2A1 1 0 007 12h.06z'
                    fill='#00A908'
                />
            </g>
        </svg>
    );
};

IconNotificationSuccess.propTypes = {
    title: PropTypes.string,
    titleId: PropTypes.string,
    size: PropTypes.oneOf(['sm', 'md']),
};
IconNotificationSuccess.defaultProps = {
    size: 'sm',
    focusable: false,
};
export default IconNotificationSuccess;
