import * as React from 'react';
import PropTypes from 'prop-types';
import tokens from '../../Tokens/tokens';
const { spacing } = tokens;

const BrandIconFines = ({ title, titleId, ...props }) => {
    const sizeArray = Object.entries(spacing.icons);
    const sizeOptions = new Map(sizeArray);
    const displaySize = sizeOptions.get(props.size);
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 48 48'
            fill={props.color && displayColor}
            aria-hidden={!title}
            role={title ? 'img' : null}
            width={props.size && displaySize}
            height={props.size && displaySize}
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <defs>
                <style>{'.Fines_svg__cls-2{fill:#002664}'}</style>
            </defs>
            <path d='M33.06 40a5 5 0 115-5 5 5 0 01-5 5z' fill='#d7153a' />
            <path
                className='Fines_svg__cls-2'
                d='M14.57 48h-2.46s.81-1.14 1.14-1.58l12.32-17.15c1-1.45.92-3.45-.14-4.36s-2.93-.59-4.14.66L12 35.52a1 1 0 01-1.46-1.36l9.29-10c2-2 5.06-2.38 6.9-.8s2 4.72.47 7L14.87 47.58zm14.02-29.06a9.6 9.6 0 01-4.53-1.82l1.49-2.19a7.75 7.75 0 003.13 1.56v-3.06c-2.76-.75-4.12-1.78-4.12-4.07 0-2.12 1.61-3.52 4-3.77V4.45h2v1.17A7.8 7.8 0 0134.15 7l-1.28 2.27a8.06 8.06 0 00-2.38-1.13v2.91c2.92.79 4.16 2 4.16 4.11 0 2.15-1.65 3.53-4.07 3.78v1.9h-2zm.09-8.37V8c-.94.12-1.32.58-1.32 1.19 0 .62.27 1 1.32 1.38zm1.81 3.32v2.69c.9-.13 1.36-.54 1.36-1.23 0-.67-.33-1.07-1.36-1.46z'
            />
            <path
                className='Fines_svg__cls-2'
                d='M40 0H18a3 3 0 00-3 3v13.86C9.43 21.6 2.88 27.48 2.6 29.07c-.19 1.1 0 7.51.35 15.14L3.06 47v1h2v-1L5 44.14c-.18-4.47-.55-13.75-.4-14.65.42-1 5.39-5.63 10.45-10v7.89l2-2.15V3a1 1 0 011-1H40a1 1 0 011 1v38a1 1 0 01-1 1H20.89l-1.44 2H40a3 3 0 003-3V3a3 3 0 00-3-3z'
            />
        </svg>
    );
};

BrandIconFines.propTypes = {
    title: PropTypes.string,
    titleId: PropTypes.string,
    size: PropTypes.oneOf(['lg', 'xl', 'xxl']),
};
BrandIconFines.defaultProps = {
    size: 'xl',
    focusable: false,
};
export default BrandIconFines;
