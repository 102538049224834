import styled from 'styled-components/macro';
import { css } from 'styled-components';
import EtdbButton from '../EtdbButton.styled';

const SecondaryButton = styled(EtdbButton)`
    border-radius: 8px;
    background-color: #f9fdff;
    border: 2px solid #c4d0d6;
    color: #2e5299;

    ${props => props.hasError && css`
      border-color: #e2173d;
      background-color: rgba(184, 18, 55, 0.05);
      color: #e2173d;
    `}

    :hover {
        background-color: #2e5299;
        background-color: #ffffff;
        border-color: #deeaf0;
    }
    :active {
        color: #002664;
        background-color: #e0e4e6;
        border-color: #929fa4;
    }
    :focus {
        outline: none;
    }
`;

export default SecondaryButton;
