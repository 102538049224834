import styled, { css } from 'styled-components/macro';

export const skeletonAnimation = css`
    position: relative;
    overflow: hidden;
    background-color: #f4f7f9;
    display: block;

    @keyframes shimmer {
        100% {
            transform: translateX(100%);
        }
    }

    div {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        transform: translateX(-100%);
        background: linear-gradient(
            90deg,
            rgba(244, 247, 249, 0) 0,
            rgba(222, 227, 229, 0.3) 20%,
            rgba(222, 227, 229, 0.8) 50%,
            rgba(222, 227, 229, 0.3) 80%,
            rgba(244, 247, 249, 0)
        );
        animation: shimmer 0.7s linear infinite;
        content: '';
    }
`;

const StyledSkeletonContainer = styled.div``;

export default StyledSkeletonContainer;
