import styled, { css } from 'styled-components/macro';
import tokens from '../../Tokens/tokens';
import { pxToRem, getBreakpoint } from '../../utils';

const { colors, font } = tokens;

export const StyledButton = styled.button``;

export const StyledPagination = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
`;

const StyledPaginationButtons = css`
    width: ${pxToRem(32)};
    height: ${pxToRem(32)};
    margin: 0 ${pxToRem(3)};
    ${getBreakpoint.md`
        height: ${pxToRem(40)};
        width: ${pxToRem(40)};
        margin: 0 ${pxToRem(4)};
    `};
    svg {
        height: ${pxToRem(16)};
        width: ${pxToRem(16)};
    }
    button {
        padding-top: ${pxToRem(5)};
        width: ${pxToRem(32)};
        height: ${pxToRem(32)};
        ${getBreakpoint.md`
            height: ${pxToRem(40)};
            width: ${pxToRem(40)};
        `};
    }
    &:hover, &:focus {
        button {
            background-color: ${colors.white} !important;
            svg {
                fill: ${colors.brand.snswSecondaryBlue} !important;
            }
        }
    }
`;

StyledPagination.Prev = styled.div`
   ${StyledPaginationButtons};
   button {
       text-align: right;
   }
`;

StyledPagination.Next = styled.div`
    ${StyledPaginationButtons};
`;

StyledPagination.Separator = styled.div`
    display: inline-block;
    text-align: center;
    color: ${colors.grey.shuttle};
    width: ${pxToRem(32)};
    margin: 0 ${pxToRem(3)};
    ${getBreakpoint.md`
        width: ${pxToRem(40)};
        margin: 0 ${pxToRem(4)};
    `};
`;

StyledPagination.Number = styled(StyledButton)`
    display: flex;
    padding: unset;
    border-radius: 100px;
    justify-content: center;
    align-items: center;
    cursor: ${props => (props.current ? 'auto' : 'pointer')};
    height: ${pxToRem(32)};
    width: ${pxToRem(32)};
    border: none;
    font-size: ${pxToRem(13)};
    margin: 0 ${pxToRem(3)};
    font-weight: ${font.weight.medium};
    background-color: ${props => (props.current ? `${colors.brand.snswSecondaryBlue} !important` : 'unset !important')};
    color: ${props => (props.current ? 'white !important' : `${colors.brand.snswSecondaryBlue} !important`)};
    ${getBreakpoint.md`
        height: ${pxToRem(40)};
        width: ${pxToRem(40)};
        margin: 0 ${pxToRem(4)};
        font-size: ${pxToRem(16)};
    `};
`;
