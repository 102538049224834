import * as React from 'react';
import PropTypes from 'prop-types';
import tokens from '../../Tokens/tokens';
const { spacing } = tokens;

const BrandIconWwcc = ({ title, titleId, ...props }) => {
    const sizeArray = Object.entries(spacing.icons);
    const sizeOptions = new Map(sizeArray);
    const displaySize = sizeOptions.get(props.size);
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 48 48'
            fill={props.color && displayColor}
            aria-hidden={!title}
            role={title ? 'img' : null}
            width={props.size && displaySize}
            height={props.size && displaySize}
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <defs>
                <style>{'.WWCC_svg__cls-1{fill:#002664}'}</style>
            </defs>
            <path
                className='WWCC_svg__cls-1'
                d='M36 10a5 5 0 115-5 5 5 0 01-5 5zm0-8a3 3 0 103 3 3 3 0 00-3-3zM17.5 28a4.5 4.5 0 114.5-4.5 4.51 4.51 0 01-4.5 4.5zm0-6.75a2.25 2.25 0 102.25 2.25 2.25 2.25 0 00-2.25-2.25zm-8.4-6.86l-1-2a1 1 0 00-.8-.55 1 1 0 00-.89.38L5.13 14a1 1 0 00.67 1.61h.34a2.43 2.43 0 01.6 1.83 1.94 1.94 0 01-.63 1.43 4.46 4.46 0 00-1.58 3 4 4 0 00.7 2.81 1 1 0 00.85.47A1 1 0 006.6 25a1 1 0 00.4-1.33 2 2 0 01-.41-1.57 2.45 2.45 0 01.91-1.7 1 1 0 00.18-.17 4 4 0 001.12-2.59 4.35 4.35 0 00-.25-1.86 1 1 0 00.45-.37 1 1 0 00.1-1.02z'
            />
            <path d='M3 7a5 5 0 105-5 5 5 0 00-5 5z' fill='#d7153a' />
            <path
                className='WWCC_svg__cls-1'
                d='M20.5 48a2.5 2.5 0 01-2.5-2.5V40h2v5.5a.5.5 0 001 0V37c0-2.23 2.89-5.31 5.85-8.27a.36.36 0 000-.58.36.36 0 00-.58 0A13.69 13.69 0 0120.21 32h-4.27c-.78 0-3.63-.28-7.21-3.85a.36.36 0 00-.58 0 .36.36 0 000 .58c3 3 5.85 6 5.85 8.27v8.5a.5.5 0 001 0V40h2v5.5a2.5 2.5 0 01-5 0V37c0-1.59-3.69-5.28-5.27-6.85a2.37 2.37 0 010-3.42 2.37 2.37 0 013.42 0c3.11 3.11 5.37 3.27 5.79 3.27h3.93a12.45 12.45 0 005-3.27 2.37 2.37 0 013.42 0 2.37 2.37 0 010 3.42C26.69 31.72 23 35.41 23 37v8.5a2.5 2.5 0 01-2.5 2.5zM33 47V35h-1a3 3 0 01-3-2.77c-.63.65-1.17 1.21-1.62 1.7a5 5 0 003.6 3V47a1 1 0 00.67.93h.65A1 1 0 0033 47z'
            />
            <path
                className='WWCC_svg__cls-1'
                d='M40 12h-8a5 5 0 00-5 5v7a4.2 4.2 0 012 .73V17a2.94 2.94 0 01.89-2.11A3 3 0 0132 14h8a2.91 2.91 0 012.1.93 3.16 3.16 0 01.9 2.17V32a2.94 2.94 0 01-.89 2.11A3 3 0 0140 35h-1v12a1 1 0 00.68.93h.64A1 1 0 0041 47V36.9a5 5 0 002.53-1.38A5 5 0 0045 32V17.1a5.17 5.17 0 00-1.46-3.56A4.9 4.9 0 0040 12z'
            />
        </svg>
    );
};

BrandIconWwcc.propTypes = {
    title: PropTypes.string,
    titleId: PropTypes.string,
    size: PropTypes.oneOf(['lg', 'xl', 'xxl']),
};
BrandIconWwcc.defaultProps = {
    size: 'xl',
    focusable: false,
};
export default BrandIconWwcc;
