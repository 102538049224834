import * as React from 'react';
import PropTypes from 'prop-types';
import tokens from '../../Tokens/tokens';
const { spacing } = tokens;

const BrandIconLocation = ({ title, titleId, ...props }) => {
    const sizeArray = Object.entries(spacing.icons);
    const sizeOptions = new Map(sizeArray);
    const displaySize = sizeOptions.get(props.size);
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 48 48'
            fill={props.color && displayColor}
            aria-hidden={!title}
            role={title ? 'img' : null}
            width={props.size && displaySize}
            height={props.size && displaySize}
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <defs>
                <style>{'.Location_svg__cls-2{fill:#002664}'}</style>
            </defs>
            <path d='M29 12a5 5 0 11-5-5 5 5 0 015 5z' fill='#d7153a' />
            <path
                className='Location_svg__cls-2'
                d='M24 38a1 1 0 01-.84-.46C22.7 36.84 12 20.2 12 12A11.61 11.61 0 0124 0a11.6 11.6 0 0112 12c0 8.22-10.7 24.86-11.16 25.56A1 1 0 0124 38zm0-36a9.68 9.68 0 00-10 10c0 6.34 7.45 19 10 23.11C26.55 31 34 18.34 34 12A9.55 9.55 0 0024 2z'
            />
            <path
                className='Location_svg__cls-2'
                d='M47.94 46.66l-5-14A1 1 0 0042 32H30.49l-1.14 2h2.76a9.69 9.69 0 008.61 5.27c-5.84 3.61-14.93 4.44-18.1 1.94C19.85 39 16.65 37 12 37a22.23 22.23 0 00-6.89 1.45L6.7 34h12l-1.14-2H6a1 1 0 00-.94.66l-5 14a1 1 0 00.12.92A1 1 0 001 48h46a1 1 0 00.82-.42 1 1 0 00.12-.92zM34.42 34h6.88l1.15 3.24c-2 .19-5.82.06-8.03-3.24zm-32 12l1.85-5.17v.09S8.68 39 12 39s6 1.1 9.38 3.78C23 44 25.59 44.64 28.6 44.64c4.9 0 10.8-1.62 14.79-4.78L45.58 46z'
            />
        </svg>
    );
};

BrandIconLocation.propTypes = {
    title: PropTypes.string,
    titleId: PropTypes.string,
    size: PropTypes.oneOf(['lg', 'xl', 'xxl']),
};
BrandIconLocation.defaultProps = {
    size: 'xl',
    focusable: false,
};
export default BrandIconLocation;
