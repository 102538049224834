import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Field, { FormGroupWrapper } from '../Form/Field.styled';
import { getAriaDescribedby } from '../../utils';
import { trackMe } from '../ComponentAnalytics/componentAnalytics';

const FormGroup = props => {

    const {
        children,
        hasError,
        className,
        helpMessage,
        errorMessage,
        label,
        id,
        margin,
        isRequired,
        isOptional,
    } = props;

    useEffect(() => {
        trackMe('FormGroup');
    }, []);

    const idError = `${id}-error`;
    const idHelper = `${id}-helper`;
    const showError = hasError && !!errorMessage;

    const optional = isOptional && !isRequired;
    const required = !isOptional && isRequired;

    const showRequired =
        ((!optional || !required) && null) ||
            (required ? true : null) ||
            (optional ? false : null);

    return (
        <FormGroupWrapper className={ className } margin={ margin }>
            <Field.Label htmlFor={ id }>
                {optional ? `${label} (optional)` : label}
            </Field.Label>
            {helpMessage && <Field.GroupHelp id={ idHelper }>{helpMessage}</Field.GroupHelp>}
            {React.Children.map(children,
                child => React.cloneElement(child, {
                    id,
                    hasError: showError,
                    'aria-describedby': getAriaDescribedby(
                        !!showError && idError,
                        !!helpMessage && idHelper,
                        child.props['aria-describedby']
                    ),
                    'aria-required': showRequired,
                    label
                }, null))}
            {showError && <Field.Error id={ idError }>{errorMessage}</Field.Error>}
        </FormGroupWrapper>
    );
};

FormGroup.propTypes = {
    children: PropTypes.node,
    label: PropTypes.string.isRequired,
    /** `aria-required: true` on form element */
    isRequired: PropTypes.bool,
    /** `aria-required: false` on form element */
    isOptional: PropTypes.bool,
    hasError: PropTypes.bool,
    /** Error text displayed after input elements */
    errorMessage: PropTypes.string,
    /** Helper text displayed after the label to provide more context */
    helpMessage: PropTypes.string,
    className: PropTypes.string,
    id: PropTypes.string.isRequired,
    margin: PropTypes.shape({
        top: PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.oneOf(['none', 'xs', 'sm', 'md', 'lg', 'xl', 'xxl', 'xxxl']),
        ]),
    })
};

FormGroup.defaultProps = {
    hasError: false,
};

export default FormGroup;
