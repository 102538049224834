import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import HeadingStyle from './Heading.styled';
import { trackMe } from '../ComponentAnalytics/componentAnalytics';

const Heading = ({
    level,
    className,
    children,
    ...rest
}) => {
    const headingLevel = `h${level}`;

    useEffect(() => {
        trackMe('Heading');
    }, []);

    return (
        <HeadingStyle
            className={ className }
            as={ headingLevel }
            { ...rest }
        >
            { children }
        </HeadingStyle>
    );
};

Heading.defaultProps = {
    level: 1,
};

Heading.propTypes = {
    className: PropTypes.string,
    level: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.oneOf([1, 2, 3, 4, 5, 6]),
    ]),
    children: PropTypes.node,
};

export default Heading;
