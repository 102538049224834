import styled from 'styled-components/macro';

export const BreadCrumbStyle = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
`;

const maximumWidth = props =>
    `max-width: ${props.theme.componentWidths.screener.progressBar ?
        props.theme.componentWidths.screener.progressBar : '54rem'}`;

BreadCrumbStyle.Child = styled.div`
    ${maximumWidth};
    width: 100%;
`;

BreadCrumbStyle.Container = styled.div`
    ${maximumWidth};
    min-height: 0;
    width: 100%;
    margin: 0 auto;
`;

export const BreadCrumbContainer = styled.nav`
    a {
        font-size: 14px;
        text-decoration: underline;
        color: #242934;
    }
`;

export const BreadCrumbList = styled.ol`
    margin: 0;
    padding-left: 0;
    list-style: none;

    li {
        padding-left: 0;
    }
`;
