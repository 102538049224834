import styled from 'styled-components/macro';
import Heading from '../Headings/Heading';
import tokens from '../../Tokens/tokens';
import { pxToRem, getSpacing, getBreakpoint } from '../../utils';

const { colors, font } = tokens;

const StyledSectionHeader = styled.header`
    background: ${colors.grey.iceBlue};
    padding: ${getSpacing(['sm', 'none'])};

    ${getBreakpoint.md`
        padding: ${getSpacing(['lg', 'none', 'md', 'none'])};
    `}

    ${getBreakpoint.print`
        padding: 0;
        background: 0;
    `}

    h1 {
        margin: 0;
        max-width: ${pxToRem(768)};
    }

    p {
        margin-top: ${getSpacing('xs')};
        margin-bottom: 0;
        max-width: ${pxToRem(768)};
    }

    .notice:last-of-type {
        margin-bottom: 0;
    }

    @media print {
        .container {
            border-bottom: 2px solid ${colors.grey.geyser};
            padding: 0 0 25pt;
        }
    }

    .page-header__label {
        /* note :match PageLabel styles */
        font-size: ${pxToRem(14)};
        line-height: ${font.lineHeight.heading};
    }
`;

export const StyledFormHeader = styled(StyledSectionHeader)`
    padding: ${getSpacing(['sm', 'none', 'sm', 'none'])};

    ${getBreakpoint.md`
        padding: ${getSpacing(['md', 'none', 'md', 'none'])};
    `}

    h1 {
        margin-top: ${getSpacing('sm')};
        margin-bottom: 0;
        max-width: ${pxToRem(768)};

        ${getBreakpoint.md`
            margin-top: ${getSpacing('md')};
        `}
    }

    p {
        margin: 0;
    }
`;

export const PageNav = styled.div`
    margin-bottom: ${getSpacing('sm')};
`;

export const PageLabel = styled.span`
    font-size: ${pxToRem(14)};
    line-height: ${font.lineHeight.heading};
`;

export const PageTitle = styled(Heading).attrs({ level: 1 })`
    + * {
        margin-top: ${getSpacing('sm')};
        margin-bottom: 0;

        ${getBreakpoint.md`
            margin-top: ${getSpacing('xl')};
            margin-bottom: ${getSpacing('md')};
        `}
    }
`;

export default StyledSectionHeader;
