import styled from 'styled-components/macro';
import tokens from '../../Tokens/tokens';
import { pxToRem, getSpacing } from '../../utils';

const { font, colors } = tokens;

const StyledStatusLabel = styled.span`
    border-radius: 16px;
    color: ${colors.grey.darkGrey};
    font-size: ${pxToRem(14)};
    font-weight: ${font.weight.bold};
    text-align: center;
    padding: ${getSpacing([4, 16])};
    white-space: nowrap;

    ${props => props.variant !== 'neutral' && `
        color: ${colors.white};
    `}
    ${props => props.variant === 'neutral' && `
        background-color: ${colors.grey.geyser};
    `}
    ${props => props.variant === 'error' && `
        background-color: ${colors.borders.error};
    `}
    ${props => props.variant === 'success' && `
        background-color: ${colors.statusLabel.success};
    `}
    ${props => props.variant === 'warning' && `
        background-color: ${colors.statusLabel.warning};
    `}
    ${props => props.variant === 'info' && `
        background-color: ${colors.borders.info};
    `}
`;

export default StyledStatusLabel;
