import React from 'react';
import PropTypes from 'prop-types';
import StyledSkeletonHeading from './SkeletonHeading.styled';

const SkeletonHeading = ({ level, width, noMargin } ) => {

    const headingLevel = `h${level}`;

    return (
        <StyledSkeletonHeading
            data-testid='skeleton-heading'
            level={ headingLevel }
            width={ width }
            noMargin={ noMargin }
        >
            <div />
        </StyledSkeletonHeading>
    );
};

SkeletonHeading.propTypes = {
    level: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.oneOf([1, 2, 3, 4, 5]),
    ]),
    width: PropTypes.number,
    /** Removes default margin values */
    noMargin: PropTypes.bool,
};

SkeletonHeading.defaultProps = {
    level: 3,
    noMargin: false,
};

export default SkeletonHeading;
