import React from 'react';
import styled from 'styled-components/macro';
import { color, layout } from 'styled-system';
import tokens from '../../Tokens/tokens';

const { colors } = tokens;

// eslint-disable-next-line react/prop-types
const CrossSvg = ({ className, color, width, height }) => (
    <svg className={ className } viewBox='0 0 14 14' width={ width } height={ height } xmlns='http://www.w3.org/2000/svg' focusable='false' aria-hidden='true'>
        <path fill={ colors.legacy[color] || color }
            fillRule='evenodd'
            d='M14 1.41L12.59 0 7 5.59 1.41 0 0 1.41 5.59 7 0 12.59 1.41 14 7 8.41 12.59 14 14 12.59 8.41 7z'/>
    </svg>
);

const Cross = styled(CrossSvg)`
  ${color}
  ${layout}
`;

Cross.defaultProps = {
    color: `${colors.norwester}`,
    height: 14,
    width: 14,
};

export default Cross;
