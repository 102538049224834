import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import FormInput from '../../Components/Form/FormInput';
import validators from '../../Validators/Validator';
import PropTypes from 'prop-types';
import { trackMe } from '../../Components/ComponentAnalytics/componentAnalytics';

const FormEmail = forwardRef(({
    onChange,
    label,
    initialValue,
    helpMessage,
    errorMessage,
    requiredMessage,
    required,
    disabled,
    validateOnFly,
    onValidateOnFlyChange,
    inputWidth
}, ref) => {

    useEffect(() => {
        trackMe('FormEmail');
    }, []);

    const handleEmailChange = event => {
        const currentEmail = event.target.value;
        setEmail(currentEmail);
        setErrorText('');
    };

    const handleEmailBlur = () => {
        validateOnFly && setErrorText(validateEmail(email));
    };

    const validateEmail = value => {
        if (required && value === '') {
            return requiredMessage;
        }
        const currentErrorMessage = errorMessage || 'Invalid email address. Please use the format example@email.com.';
        return validators.email(value) ? '' : currentErrorMessage;
    };

    const isInitialMount = useRef(true);
    const [email, setEmail] = useState(initialValue);
    const [errorText, setErrorText] = useState(validateOnFly ? validateEmail(initialValue) : '');

    useImperativeHandle(ref, () => ({
        validate: () => {
            const error = validateEmail(email);
            setErrorText(error);
            return !error;
        }
    }));

    useEffect(() => {
        if (!email) {
            setEmail(initialValue);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialValue]);

    useEffect(
        () => {
            if (isInitialMount.current) {
                isInitialMount.current = false;
            } else {
                const event = { email };
                if (validateOnFly) {
                    event.emailErrorMessage = errorText;
                }
                onChange(event);
            }
        },
        [email, errorText, onChange, validateOnFly]
    );

    useEffect(
        () => {
            onValidateOnFlyChange && onValidateOnFlyChange(validateOnFly);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [validateOnFly]
    );

    return (
        <FormInput
            type='email'
            className='email-input'
            data-test='email'
            data-testid='email'
            maxLength={ 80 }
            label={ label }
            value={ email }
            hasError={ !!errorText }
            errorMessage={ errorText }
            disabled={ disabled }
            onChange={ handleEmailChange }
            onBlur={ handleEmailBlur }
            helpMessage={ helpMessage }
            inputWidth={ inputWidth }
        />
    );
});

FormEmail.propTypes = {
    onChange: PropTypes.func.isRequired,
    onValidateOnFlyChange: PropTypes.func,
    label: PropTypes.string,
    helpMessage: PropTypes.string,
    initialValue: PropTypes.string,
    errorMessage: PropTypes.string,
    disabled: PropTypes.bool,
    requiredMessage: PropTypes.string,
    required: PropTypes.bool,
    validateOnFly: PropTypes.bool
};

FormEmail.defaultProps = {
    label: 'Email',
    helpMessage: '',
    initialValue: '',
    errorMessage: '',
    disabled: false,
    requiredMessage: 'Email address is required.',
    required: false,
    validateOnFly: true,
};

export default FormEmail;
