import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { applyPolyfills, defineCustomElements } from '@duetds/date-picker/dist/loader';
import { trackMe } from '../ComponentAnalytics/componentAnalytics';
import { DatePickerContainer, DatePickerButton, DatePickerButtonMask } from './DatePicker.styled';
import Input from '../Input/Input';
import { getFormattedDate, useListener, useBoolean, SyncPromise } from '../../utils';
import { validDateFormat } from '../../Validation/Validators';
import { IconCalendar } from '../../Icons/system/';
import { SROnly } from '../../GlobalStyle/utilities/utilities.styled';

const IS_BROWSER = typeof window !== 'undefined';
const requiresPolyfill = IS_BROWSER && !('customElements' in window);

// Only load polyfills once
let loadPolyfillsPromise;
export const loadCustomElements = () => {
    // Return the load process if there already is one
    if (loadPolyfillsPromise) {
        return loadPolyfillsPromise;
    }
    if (requiresPolyfill) {
        // IE11 and Edge 17/18 polyfills
        loadPolyfillsPromise = applyPolyfills().then(() => {
            // Register Duet Date Picker
            defineCustomElements(window);
            return true;
        });
    } else {
        defineCustomElements(window);
        // syncronous promise so we can avoid the microtask delay
        loadPolyfillsPromise = new SyncPromise(true);
    }
    return loadPolyfillsPromise;
};

/*
I dont think there is a way to avoid a loading state in this component.
If we want to eventually support SSR it means we can't check browser compatability before the useEffect
has ran. (If we set polyfillLoadingState = useState(() => 'customElements' in window) there will be
conflicts between the node rendered output and the hydrated component)

So the button that opens the date picker will always be disabled for the initial render
*/

const DatePicker = ({
    id,
    name,
    value,
    disabled,
    onChange,
    onBlur,
    label,
    min,
    max,
    ...rest
}) => {
    const ref = useRef(null);
    const buttonRef = useRef(null);
    const [dateIsoValue, setDateIsoValue] = useState(value);
    const [showCalendar, open, close] = useBoolean(false);

    const [customElementsLoaded, setCustomElementsLoaded] = useState(false);

    useListener(ref, 'duetChange', e => onDuetChangeHandler(e));
    useListener(ref, 'duetClose', () => onDuetCloseHandler());

    const handleReturnDate = isoValue => {
        const australianDate = getFormattedDate(isoValue,'DD/MM/YYYY');
        const dateFormats = {
            isValid: true,
            value: australianDate,
            isoDate: isoValue, // YYYY-MM-DD,
            shortDate: getFormattedDate(isoValue,'DD/MM/YYYY'),
            longDate: getFormattedDate(isoValue,'DD MMM YYYY')
        };

        return dateFormats;
    };

    const onDuetCloseHandler = e => {
        close();
        setTimeout(() => {
            buttonRef.current.focus();
        }, 4);
    };

    const onDuetChangeHandler = e => {
        const value = e.target.value;
        const dateFormats = handleReturnDate(value);

        onChange && onChange(e, dateFormats);
    };

    const convertToIso = value => {
        const dateArray = value.split('/');
        const isoFormat = `${dateArray[2]}-${dateArray[1]}-${dateArray[0]}`;
        return isoFormat;
    };

    useEffect(() => {
        if (!customElementsLoaded) {return;}
        showCalendar && ref.current.show();
    }, [showCalendar, customElementsLoaded]);

    const onInputChangeHandler = e => {
        const value = e.target.value;
        const isValidFormat = validDateFormat(value, 'DD/MM/YYYY');

        if (isValidFormat) {
            const isoFormat = convertToIso(value);
            const dateFormats = handleReturnDate(isoFormat);

            setDateIsoValue(isoFormat);

            onChange && onChange(e, dateFormats);
        } else {
            const dateFormats = {
                isValid: false,
                value,
            };

            onChange && onChange(e, dateFormats);
        }
    };

    const onInputBlurHandler = e => {
        const value = e.target.value;
        const isValidFormat = validDateFormat(value, 'DD/MM/YYYY');
        if (isValidFormat) {
            const isoFormat = convertToIso(value);
            const dateFormats = handleReturnDate(isoFormat);

            setDateIsoValue(isoFormat);

            onBlur && onBlur(e, dateFormats);
        } else {
            const dateFormats = {
                isValid: false,
                value,
            };
            onBlur && onBlur(e, dateFormats);
        }
    };

    useEffect(() => {
        trackMe('DatePicker [GEL]');

        let cancelled = false;
        loadCustomElements().then(() => {
            if (cancelled) {return;}
            setCustomElementsLoaded(true);
        });

        return () => {
            cancelled = true;
        };
    }, []);

    useEffect(() => {
        const isValidFormat = validDateFormat(value, 'DD/MM/YYYY');
        if (isValidFormat) {
            const isoFormat = convertToIso(value);
            setDateIsoValue(isoFormat);
        }
    }, [value]);

    useEffect(() => {
        const localization = {
            buttonLabel: 'Choose date',
            placeholder: '',
            selectedDateMessage: 'Selected date is',
            prevMonthLabel: 'Previous month',
            nextMonthLabel: 'Next month',
            monthSelectLabel: 'Month',
            yearSelectLabel: 'Year',
            closeLabel: 'Close window',
            calendarHeading: label ? `${label}` : 'Choose a date',
            dayNames: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
            monthNames: ['January', 'February', 'March', 'April', 'May',
                'June', 'July', 'August', 'September', 'October', 'November', 'December'],
            monthNamesShort: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
            locale: 'en-US',
        };

        ref.current.localization = localization;
    }, [label]);

    return (
        <DatePickerContainer>
            <Input
                value={ value }
                onChange={ e => onInputChangeHandler(e) }
                onBlur={ e => onInputBlurHandler(e) }
                id={ id }
                name={ name }
                disabled={ disabled }
                { ...rest }
            />
            { showCalendar &&
                <DatePickerButtonMask>
                    <IconCalendar color='secondaryBlue' />
                </DatePickerButtonMask>
            }
            <DatePickerButton
                ref={ buttonRef }
                onClick={ open }
                className={ showCalendar && 'is-hidden' }
                disabled={ disabled || !customElementsLoaded }
                type='button'
            >
                <SROnly>Choose date</SROnly>
                <IconCalendar color='secondaryBlue' />
            </DatePickerButton>
            <duet-date-picker
                data-testid='duet-container'
                value={ dateIsoValue }
                ref={ ref }
                min={ min }
                max={ max }
            />
        </DatePickerContainer>
    );
};

DatePicker.propTypes = {
    id: PropTypes.string,
    name: PropTypes.string,
    value: PropTypes.string,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    /** Must be in IS0-8601 format: YYYY-MM-DD */
    min: PropTypes.string,
    /** Must be in IS0-8601 format: YYYY-MM-DD */
    max: PropTypes.string
};

DatePicker.defaultProps = {
    disabled: false,
};

export default DatePicker;
