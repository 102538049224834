import * as React from 'react';
import PropTypes from 'prop-types';
import tokens from '../../Tokens/tokens';
const { colors, spacing } = tokens;

const IconNotificationInfo = ({ title, titleId, ...props }) => {
    const sizeArray = Object.entries(spacing.icons);
    const colorArray = Object.entries(colors.icons);
    const sizeOptions = new Map(sizeArray);
    const displaySize = sizeOptions.get(props.size);
    const colorOptions = new Map(colorArray);
    const displayColor = colorOptions.get(props.color);
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 16 16'
            fill={props.color && displayColor}
            aria-hidden={!title}
            role={title ? 'img' : null}
            width={props.size && displaySize}
            height={props.size && displaySize}
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <g fill='none' fillRule='evenodd'>
                <path
                    d='M9 11V8a1 1 0 10-2 0v3a1 1 0 002 0zm-.81-5a.6.6 0 00.19-.06.56.56 0 00.17-.09 1 1 0 00.16-.12 1 1 0 000-1.42A1 1 0 007 5a1 1 0 001 1h.19z'
                    fill='#FFF'
                />
                <path
                    d='M8 0a8 8 0 110 16A8 8 0 018 0zm0 7a1 1 0 00-1 1v3a1 1 0 002 0V8a1 1 0 00-1-1zm.19-1a.6.6 0 00.19-.06.56.56 0 00.17-.09 1 1 0 00.16-.12 1 1 0 000-1.42A1 1 0 007 5a1 1 0 001 1h.19z'
                    fill='#2E5299'
                />
            </g>
        </svg>
    );
};

IconNotificationInfo.propTypes = {
    title: PropTypes.string,
    titleId: PropTypes.string,
    size: PropTypes.oneOf(['sm', 'md']),
};
IconNotificationInfo.defaultProps = {
    size: 'sm',
    focusable: false,
};
export default IconNotificationInfo;
