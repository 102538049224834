/* eslint-disable security/detect-object-injection */
import React, { useEffect } from 'react';
import { Input, Select, FormGroup, AutoSuggest, FormRadioGroup, Row, Col } from '../..';
import PropTypes from 'prop-types';
import usePlace from './usePlace';
import { trackMe } from '../../Components/ComponentAnalytics/componentAnalytics';
import { getHasError, getErrorMessage, getId, getRadioValue, getValue } from '../utils';

const Place = ({
    id,
    label,
    labelCountry,
    labelState,
    labelSuburb,
    helpMessage,
    onChange,
    value,
    countriesOverride,
}) => {
    useEffect(() => {
        trackMe('PatternPlace');
    }, []);

    const {
        countries,
        australianStates,
    } = usePlace(countriesOverride);

    /**
     * handles the updating of data, and calls callback function accordingly
     * @param {string} key
     * @param {string} updatedValue
     */
    const handleOnChange = (key, updatedValue) => {
        // copy existing values
        const newValue = { ...value };

        // edge case for isAustralia, where the input onChange event returns a string
        // though we need to return a boolean for the pattern onChange event.
        if ('isAustralia' === key) {
            updatedValue = 'true' === updatedValue;
        }

        // if key exists in value object, update it whilst keeping existing data
        // else create new key/value pair
        // eslint-disable-next-line security/detect-object-injection
        newValue[key] = (key in newValue) ?
            { ...newValue[key], value: updatedValue } :
            { value: updatedValue };

        // call onChange callback
        onChange && onChange(newValue, key);
    };

    return (<>
        <FormRadioGroup
            id={ getId('isAustralia', value, `${id}-radiogroup-isaustralia`) }
            errorMessage={ getErrorMessage('isAustralia', value) }
            hasError={ getHasError('isAustralia', value) }
            helpMessage={ helpMessage }
            legend={ label }
            onChange={ value => handleOnChange('isAustralia', value) }
            options={ [
                {
                    label: 'Australia',
                    value: true
                },
                {
                    label: 'Overseas',
                    value: false
                }
            ] }
            value={ getRadioValue('isAustralia', value) }
        />

        {getRadioValue('isAustralia', value) === false &&
            <AutoSuggest
                id={ getId('country', value, `${id}-autosuggest-countries`) }
                name='country'
                label={ labelCountry }
                suggestions={ countries }
                initialValue={ getValue('country', value) }
                placeholder='Select'
                onSelect={ value => handleOnChange('country', value) }
                errorMessage={ getErrorMessage('country', value) }
                hasError={ getHasError('country', value) }
            />
        }

        {getRadioValue('isAustralia', value) === true &&
            <Row>
                <Col span={ 4 }>
                    <FormGroup
                        id={ `${id}-formgroup-state` }
                        label={ labelState }
                        errorMessage={ getErrorMessage('state', value) }
                        hasError={ getHasError('state', value) }
                    >
                        <Select
                            id={ getId('state', value, `${id}-select-state`) }
                            name='state'
                            value={ getValue('state', value) }
                            options={ australianStates }
                            onChange={ e => handleOnChange(e.target.name, e.target.value) }
                            placeholder='Select'
                        />
                    </FormGroup>
                </Col>
                <Col span={ 8 }>
                    <FormGroup
                        id={ `${id}-formgroup-suburb` }
                        label={ labelSuburb }
                        errorMessage={ getErrorMessage('suburb', value) }
                        hasError={ getHasError('suburb', value) }
                    >
                        <Input
                            id={ getId('suburb', value, `${id}-input-suburb`) }
                            name='suburb'
                            value={ getValue('suburb', value) }
                            onChange={ e => handleOnChange(e.target.name, e.target.value) }
                        />
                    </FormGroup>
                </Col>
            </Row>
        }
    </>);
};

Place.propTypes = {
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    labelCountry: PropTypes.string,
    labelState: PropTypes.string,
    labelSuburb: PropTypes.string,
    value: PropTypes.shape({
        isAustralia: PropTypes.shape({
            id: PropTypes.string,
            value: PropTypes.bool,
            errorMessage: PropTypes.string,
        }),
        state: PropTypes.shape({
            id: PropTypes.string,
            value: PropTypes.string,
            errorMessage: PropTypes.string,
        }),
        suburb: PropTypes.shape({
            id: PropTypes.string,
            value: PropTypes.string,
            errorMessage: PropTypes.string,
        }),
        country: PropTypes.shape({
            id: PropTypes.string,
            value: PropTypes.string,
            errorMessage: PropTypes.string,
        })
    }).isRequired,
    onChange: PropTypes.func.isRequired,
    helpMessage: PropTypes.string,
    countriesOverride: PropTypes.arrayOf(PropTypes.string)
};

Place.defaultProps = {
    helpMessage: '',
    labelCountry: 'Country/region',
    labelState: 'State/territory',
    labelSuburb: 'Suburb/town',
};

export default Place;
