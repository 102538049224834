import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { trackMe } from '../ComponentAnalytics/componentAnalytics';
import { SROnly } from '../../GlobalStyle/utilities/utilities.styled';
import StyledSkeletonContainer from './SkeletonContainer.styled';

const SkeletonContainer = ({ className, title, children }) => {
    useEffect(() => {
        trackMe('Skeleton Loader [GEL]');
    }, []);

    return (
        <StyledSkeletonContainer className={ className }>
            <SROnly data-testid='sr-only-title'>{title}</SROnly>
            <div data-testid='hide-items' aria-hidden={ true }>{children}</div>
        </StyledSkeletonContainer>
    );
};
SkeletonContainer.propTypes = {
    className: PropTypes.string,
    title: PropTypes.string,
    children: PropTypes.node.isRequired,
};

SkeletonContainer.defaultProps = {
    title: 'loading'
};

export default SkeletonContainer;
