import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Field from './Field.styled';
import { RadioItemEditorContainer, RadioItemClarifyContainer } from './FormRadioGroup.styled';
import { FormLegend, FormFieldset, RadioCheckboxLegendLabel } from './Form.styled';
import { getID } from '../../utils';
import { trackMe } from '../ComponentAnalytics/componentAnalytics';
import Checkbox from './FormCheckbox.styled';
import { SROnly } from '../../GlobalStyle/utilities/utilities.styled';

export const FormCheckboxGroup = ({
    id,
    name,
    legend,
    options,
    hasError,
    errorMessage,
    helpMessage,
    onChange,
    inputRef,
    className,
    margin
}) => {

    useEffect(() => {
        trackMe('FormCheckboxGroup');
    }, []);

    const genID = () => id || getID();

    const elemID = genID();

    return (
        <FormFieldset
            aria-invalid={ hasError }
            className={ className }
            margin={ margin }
        >
            {legend && <FormLegend>
                <RadioCheckboxLegendLabel>{legend}</RadioCheckboxLegendLabel>
                {helpMessage && (
                    <Field.GroupHelp>{helpMessage}</Field.GroupHelp>
                )}
                {hasError && errorMessage && <SROnly>{errorMessage}</SROnly>}
            </FormLegend>}
            <>
                {options.map((option, index) =>
                    <CheckboxItem
                        key={ `${elemID}-${index}` }
                        id={ `${elemID}-${index}` }
                        name={ name }
                        option={ option }
                        onChange={ onChange }
                        hasError={ hasError }
                        inputRef={ inputRef }
                    />
                )}
            </>
            {hasError && <Field.Error aria-hidden='true'>{errorMessage}</Field.Error>}
        </FormFieldset>
    );
};

FormCheckboxGroup.propTypes = {
    id: PropTypes.string,
    name: PropTypes.string,
    className: PropTypes.string,
    legend: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.any.isRequired,
            label: PropTypes.node.isRequired,
            clarify: PropTypes.string,
        })
    ).isRequired,
    hasError: PropTypes.bool,
    errorMessage: PropTypes.string,
    helpMessage: PropTypes.string,
    onChange: PropTypes.func,
    inputRef: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.object,
    ]),
    margin: PropTypes.shape({
        top: PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.oneOf(['none', 'xs', 'sm', 'md', 'lg', 'xl', 'xxl', 'xxxl']),
        ]),
    })
};

export const CheckboxItem = ({ id, name, option, onChange, hasError, inputRef }) => {
    const editor = option.isChecked && option.editor ? option.editor : undefined;
    const clarify = option.clarify ? option.clarify : undefined;

    return (
        <>
            <Checkbox.Item>
                <Checkbox.Input
                    type='checkbox'
                    id={ id }
                    className={ hasError ? 'error' : '' }
                    name={ name }
                    value={ option.value }
                    checked={ option.isChecked }
                    disabled={ option.disabled }
                    onChange={ onChange }
                    ref={ inputRef }
                    aria-describedby={ clarify && `${id}-clarifyText` }
                />
                <Checkbox.Label htmlFor={ id } hasError={ hasError }>{option.label}</Checkbox.Label>
            </Checkbox.Item>
            {editor && (
                <RadioItemEditorContainer>
                    {editor}
                </RadioItemEditorContainer>
            )}
            {clarify && (
                <RadioItemClarifyContainer id={ `${id}-clarifyText` }>
                    {clarify}
                </RadioItemClarifyContainer>
            )}
        </>
    );
};

CheckboxItem.propTypes = {
    id: PropTypes.string,
    name: PropTypes.string,
    hasError: PropTypes.bool,
    option: PropTypes.shape({
        editor: PropTypes.node,
        clarify: PropTypes.string
    }),
    value: PropTypes.any,
    inputRef: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.object,
    ]),
};

export default FormCheckboxGroup;
