import * as React from 'react';
import PropTypes from 'prop-types';
import tokens from '../../Tokens/tokens';
const { spacing } = tokens;

const BrandIconTradies = ({ title, titleId, ...props }) => {
    const sizeArray = Object.entries(spacing.icons);
    const sizeOptions = new Map(sizeArray);
    const displaySize = sizeOptions.get(props.size);
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 48 48'
            fill={props.color && displayColor}
            aria-hidden={!title}
            role={title ? 'img' : null}
            width={props.size && displaySize}
            height={props.size && displaySize}
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <defs>
                <style>{'.Tradies_svg__cls-1{fill:#002664}'}</style>
            </defs>
            <path
                className='Tradies_svg__cls-1'
                d='M24 41.5a9.18 9.18 0 01-3.38-.59 1 1 0 01.76-1.85 8.11 8.11 0 005.24 0 1 1 0 01.76 1.85 9.18 9.18 0 01-3.38.59zM18.5 32a1.5 1.5 0 111.5-1.5 1.5 1.5 0 01-1.5 1.5zm11 0a1.5 1.5 0 111.5-1.5 1.5 1.5 0 01-1.5 1.5z'
            />
            <path d='M24 22a5 5 0 115-5 5 5 0 01-5 5z' fill='#d7153a' />
            <path
                className='Tradies_svg__cls-1'
                d='M43.09 18l-1.68-.15c-1.21-.62-1.31-1.12-1.42-2.37C39.23 6.08 31.75 3.76 29.3 3a.58.58 0 00-.19 0L29 .89A1 1 0 0028 0h-8a1 1 0 00-1 .94L18.5 10h2l.5-8h6.1l.45 8h2l-.27-4.87C33.13 6.37 37.45 8.81 38 15.6a4.19 4.19 0 002.69 4.08 1.06 1.06 0 00.35.09l1 .09v3a13.52 13.52 0 00-5.15.51 31.52 31.52 0 01-12.07 1.83H23.3a33.13 33.13 0 01-12.15-1.83A13.73 13.73 0 006 22.9v-3l1-.09a1.06 1.06 0 00.35-.09A4.19 4.19 0 0010 15.6a11.24 11.24 0 012-5.6 13 13 0 013.55-3.4c.23-.15.85-.51 1.46-.86V3.43c-.78.43-2.12 1.2-2.56 1.48s-.75.53-1.1.81h-.06a.34.34 0 01-.09.08l-.29.25c-.3.25-.59.51-.87.77a13.09 13.09 0 00-4 8.54c-.1 1.25-.21 1.75-1.42 2.37L4.91 18A1 1 0 004 19v5a1 1 0 00.77 1 2.6 2.6 0 00.9 0c.35 0 .84-.06 1.39-.07a7.47 7.47 0 00-.81 4.47 6.66 6.66 0 003 4.38c.49 2.53 3.38 14.35 14.5 14.35h.53c11.11 0 14-11.82 14.49-14.35a6.66 6.66 0 003-4.38 7.47 7.47 0 00-.81-4.47c.58 0 1.09 0 1.45.07a2.6 2.6 0 00.9 0 1 1 0 00.69-1v-5a1 1 0 00-.91-1zm-3.37 11a4.84 4.84 0 01-2.31 3.18 1 1 0 00-.58.78c0 .13-1.93 13.09-12.59 13.09h-.53c-4.43 0-7.88-2.16-10.27-6.41A21.47 21.47 0 0111.11 33a1 1 0 00-.54-.75A4.89 4.89 0 018.23 29a6 6 0 011-4 7.49 7.49 0 011.22.3 34.43 34.43 0 0012.3 1.93h2a33.54 33.54 0 0012.75-1.92 8 8 0 011.17-.31 6 6 0 011.05 4z'
            />
        </svg>
    );
};

BrandIconTradies.propTypes = {
    title: PropTypes.string,
    titleId: PropTypes.string,
    size: PropTypes.oneOf(['lg', 'xl', 'xxl']),
};
BrandIconTradies.defaultProps = {
    size: 'xl',
    focusable: false,
};
export default BrandIconTradies;
