import * as React from 'react';
import PropTypes from 'prop-types';
import tokens from '../../Tokens/tokens';
const { colors, spacing } = tokens;

const IconHome = ({ title, titleId, ...props }) => {
    const sizeArray = Object.entries(spacing.icons);
    const colorArray = Object.entries(colors.icons);
    const sizeOptions = new Map(sizeArray);
    const displaySize = sizeOptions.get(props.size);
    const colorOptions = new Map(colorArray);
    const displayColor = colorOptions.get(props.color);
    return (
        <svg
            viewBox='0 0 16 16'
            xmlns='http://www.w3.org/2000/svg'
            fill={props.color && displayColor}
            aria-hidden={!title}
            role={title ? 'img' : null}
            width={props.size && displaySize}
            height={props.size && displaySize}
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <path d='M7.41482468,0.238397643 C7.75989405,-0.0549987218 8.2546676,-0.0773584398 8.62245149,0.170077583 L8.71939979,0.244248352 L15.6557163,6.25016846 C16.0727046,6.61122446 16.1171749,7.24095016 15.7550435,7.65670017 C15.4207683,8.04046941 14.8568857,8.10776946 14.4441398,7.83187217 L14.3443224,7.75573244 L8.05701931,2.31236058 L1.64889748,7.76158315 C1.26099358,8.09139971 0.693348264,8.07416537 0.326449321,7.74002225 L0.239126991,7.64990164 C-0.0916719475,7.2631497 -0.0743862801,6.69719012 0.26075211,6.33138076 L0.351141144,6.24431775 L7.41482468,0.238397643 Z' />
            <path d='M11.9925249,7.28024806 C12.5092031,7.28024806 12.9350412,7.66628825 12.9932391,8.16362694 L13.0000173,8.28024806 L13.0000173,13.6491744 C13.0000173,14.1620102 12.6110847,14.5846815 12.1100198,14.6424466 L11.9925249,14.6491744 L4.0075097,14.6491744 C3.49083148,14.6491744 3.06499333,14.2631342 3.00679542,13.7657955 L3.00001728,13.6491744 L3.00001728,8.28024806 C3.00001728,7.72796331 3.451087,7.28024806 4.0075097,7.28024806 C4.52418792,7.28024806 4.95002607,7.66628825 5.00822397,8.16362694 L5.01500211,8.28024806 L5.01481725,12.6489263 L10.9842098,12.6489263 L10.9850324,8.28024806 C10.9850324,7.76741222 11.373965,7.3447409 11.87503,7.28697579 L11.9925249,7.28024806 Z' />
            <path d='M13.0000173,0.889880639 C13.5128531,0.889880639 13.9355244,1.30493183 13.9932895,1.83964561 L14.0000173,1.96503084 L14.0000173,4.81473044 C14.0000173,5.4085195 13.552302,5.88988064 13.0000173,5.88988064 C12.4871814,5.88988064 12.0645101,5.47482945 12.006745,4.94011566 L12.0000173,4.81473044 L12.0000173,1.96503084 C12.0000173,1.37124178 12.4477325,0.889880639 13.0000173,0.889880639 Z' />
        </svg>
    );
};

IconHome.propTypes = {
    title: PropTypes.string,
    titleId: PropTypes.string,
    size: PropTypes.oneOf(['sm', 'md']),
    color: PropTypes.oneOf(['primaryBlue', 'secondaryBlue', 'darkGrey', 'white']),
};
IconHome.defaultProps = {
    size: 'sm',
    color: 'darkGrey',
    focusable: false,
};
export default IconHome;
