import * as React from 'react';
import PropTypes from 'prop-types';
import tokens from '../../Tokens/tokens';
const { colors, spacing } = tokens;

const IconFeedback = ({ title, titleId, ...props }) => {
    const sizeArray = Object.entries(spacing.icons);
    const colorArray = Object.entries(colors.icons);
    const sizeOptions = new Map(sizeArray);
    const displaySize = sizeOptions.get(props.size);
    const colorOptions = new Map(colorArray);
    const displayColor = colorOptions.get(props.color);
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 16 16'
            fill={props.color && displayColor}
            aria-hidden={!title}
            role={title ? 'img' : null}
            width={props.size && displaySize}
            height={props.size && displaySize}
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <path d='M4.18 15a1 1 0 01-.45-.1 1 1 0 01-.55-.9v-3.1C1.17 9.88 0 8.26 0 6.5 0 3.42 3.51 1 8 1s8 2.42 8 5.5-3.29 5.33-7.54 5.5l-3.68 2.8a1 1 0 01-.6.2zM8 3C4.75 3 2 4.6 2 6.5c0 1.11 1 2.17 2.56 2.84a1 1 0 01.62.93V12l2.32-1.8a1 1 0 01.6-.2c3.2 0 5.9-1.64 5.9-3.5S11.25 3 8 3z' />
        </svg>
    );
};

IconFeedback.propTypes = {
    title: PropTypes.string,
    titleId: PropTypes.string,
    size: PropTypes.oneOf(['sm', 'md']),
    color: PropTypes.oneOf(['primaryBlue', 'secondaryBlue', 'darkGrey', 'white']),
};
IconFeedback.defaultProps = {
    size: 'sm',
    color: 'darkGrey',
    focusable: false,
};
export default IconFeedback;
