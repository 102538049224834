import * as React from 'react';
import PropTypes from 'prop-types';
import tokens from '../../Tokens/tokens';
const { spacing } = tokens;

const BrandIconLegalPolice = ({ title, titleId, ...props }) => {
    const sizeArray = Object.entries(spacing.icons);
    const sizeOptions = new Map(sizeArray);
    const displaySize = sizeOptions.get(props.size);
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 48 48'
            fill={props.color && displayColor}
            aria-hidden={!title}
            role={title ? 'img' : null}
            width={props.size && displaySize}
            height={props.size && displaySize}
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <path
                d='M24 0a4.17 4.17 0 00-4.17 4.17 4.06 4.06 0 00.48 1.9c1.15-.11 2.33-.19 3.59-.19a37.7 37.7 0 013.78.2 4.1 4.1 0 00.49-1.91A4.18 4.18 0 0024 0z'
                fill='#d7153a'
            />
            <path
                d='M48 24.9c0-3.86-3.72-10.35-5.53-13.29a6.5 6.5 0 005.32-2.67 1 1 0 00-.42-1.37A1 1 0 0046 8s-1.93 2.8-6.62 1.14a41.42 41.42 0 00-11.71-3 37.7 37.7 0 00-3.78-.2c-1.26 0-2.44.08-3.59.19a41 41 0 00-11.8 3C3.92 10.74 2 8.14 1.86 8a1 1 0 00-1.32-.4A1 1 0 00.11 9a6.53 6.53 0 005.46 2.67C3.75 14.57 0 21 0 24.9v.17c0 .06 0 0 0 0 .1 4.57 2.82 7.29 7.33 7.29s7.23-2.72 7.33-7.29 0-.11 0-.17c0-4.11-4.2-11.18-5.86-13.81l.4-.12a38.08 38.08 0 0113-3h.76v29.71h2V7.91h.77A38.26 38.26 0 0138.71 11l.44.14c-1.66 2.65-5.83 9.68-5.83 13.77v.17c0 .06 0 0 0 0 .1 4.57 2.82 7.29 7.33 7.29s7.23-2.72 7.33-7.29.02-.08.02-.18zM7.38 30.44c-3.13 0-4.89-1.46-5.26-4.34h10.52c-.37 2.9-2.13 4.34-5.26 4.34zm5.27-6.34H2.11c.45-3.28 3.42-8.63 5.27-11.61 1.86 2.98 4.82 8.33 5.27 11.61zm28 6.34c-3.13 0-4.89-1.46-5.26-4.34h10.53c-.37 2.9-2.13 4.34-5.26 4.34zm-5.26-6.34c.45-3.28 3.42-8.63 5.27-11.61 1.86 3 4.82 8.33 5.27 11.61zM39.87 48H8.18a1 1 0 01-.89-.54 1 1 0 01.07-1c3.23-4.65 10.89-6.77 16.65-6.77s13.43 2.12 16.68 6.77a1 1 0 01.07 1 1 1 0 01-.89.54zM10.4 46h27.22c-3.26-2.86-9-4.34-13.61-4.34S13.72 43 10.4 46z'
                fill='#002664'
            />
        </svg>
    );
};

BrandIconLegalPolice.propTypes = {
    title: PropTypes.string,
    titleId: PropTypes.string,
    size: PropTypes.oneOf(['lg', 'xl', 'xxl']),
};
BrandIconLegalPolice.defaultProps = {
    size: 'xl',
    focusable: false,
};
export default BrandIconLegalPolice;
