import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Dimmer from './Dimmer';
import Loader from './Loader';
import { trackMe } from '../Components/ComponentAnalytics/componentAnalytics';
import { SROnly } from '../GlobalStyle/utilities/utilities.styled';

const ComponentLoader = ({ className, active, label, fullPage }) => {

    useEffect(() => {
        trackMe('ComponentLoader');
    }, []);

    return (
        <Dimmer
            className={ className }
            active={ active }
            fullPage={ fullPage }
        >
            <SROnly role='status'>{label}</SROnly>
            <Loader content={ label } fullPage={ fullPage } />
        </Dimmer>
    );
};

ComponentLoader.propTypes = {
    className: PropTypes.string,
    active: PropTypes.bool,
    label: PropTypes.string,
    fullPage: PropTypes.bool
};

ComponentLoader.defaultProps = {
    active: true,
    label: Loader.defaultProps.content,
    fullPage: false
};

export default ComponentLoader;
