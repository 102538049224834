import * as React from 'react';
import PropTypes from 'prop-types';
import tokens from '../../Tokens/tokens';
const { spacing } = tokens;

const BrandIconCheckRego = ({ title, titleId, ...props }) => {
    const sizeArray = Object.entries(spacing.icons);
    const sizeOptions = new Map(sizeArray);
    const displaySize = sizeOptions.get(props.size);
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 48 48'
            fill={props.color && displayColor}
            aria-hidden={!title}
            role={title ? 'img' : null}
            width={props.size && displaySize}
            height={props.size && displaySize}
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <defs>
                <style>{'.CheckRego_svg__cls-1{fill:#002664}'}</style>
            </defs>
            <path
                className='CheckRego_svg__cls-1'
                d='M46.21 41.42l-5-5a7.24 7.24 0 00.57-2.84 7.35 7.35 0 10-4.51 6.78l5 5a1 1 0 00.71.3 1 1 0 00.7-.3l2.53-2.52a1 1 0 000-1.42zm-17.16-7.87a5.35 5.35 0 115.35 5.35 5.35 5.35 0 01-5.35-5.35zM43 43.24l-4-4a7.76 7.76 0 001.11-1.11l4 4z'
            />
            <path d='M14 20.07a5 5 0 11-5-5 5 5 0 015 5z' fill='#d7153a' />
            <path
                className='CheckRego_svg__cls-1'
                d='M15.93 16.22h1.58L21.15 21v-4.78h1.69V24h-1.45l-3.77-4.94V24h-1.69zm8.8 6.65l1-1.21a3.6 3.6 0 002.32.94c.7 0 1.12-.28 1.12-.73 0-.43-.27-.65-1.57-1-1.55-.41-2.6-.87-2.6-2.39a2.43 2.43 0 012.72-2.35 4.53 4.53 0 012.89 1l-.89 1.29a3.65 3.65 0 00-2-.77c-.66 0-1 .3-1 .68 0 .51.33.68 1.67 1 1.58.41 2.47 1 2.47 2.33 0 1.55-1.18 2.41-2.85 2.41a5 5 0 01-3.28-1.2zM32 16.22h1.84l1.61 5.27 1.74-5.29h1.51l1.74 5.29 1.61-5.27h1.79l-2.65 7.84H39.7L37.94 19l-1.75 5.09H34.7z'
            />
            <path
                className='CheckRego_svg__cls-1'
                d='M45 30h-2v-2h2a1 1 0 001-1V13a1 1 0 00-1-1H3a1 1 0 00-1 1v14a1 1 0 001 1h23v2H3a3 3 0 01-3-3V13a3 3 0 013-3h42a3 3 0 013 3v14a3 3 0 01-3 3z'
            />
        </svg>
    );
};

BrandIconCheckRego.propTypes = {
    title: PropTypes.string,
    titleId: PropTypes.string,
    size: PropTypes.oneOf(['lg', 'xl', 'xxl']),
};
BrandIconCheckRego.defaultProps = {
    size: 'xl',
    focusable: false,
};
export default BrandIconCheckRego;
