import { removeObjectProperty } from '../utils';

/**
 * helper function to get ID from a pattern's value object
 * @param {string} key
 * @param {object} value
 * @param {string} fallbackId
 * @returns an ID extracted from the value object by key, or the fallback ID
 */
export const getId = (key, value, fallbackId) =>
    // eslint-disable-next-line security/detect-object-injection
    (key && value && value[key]?.id) ? value[key].id : fallbackId
;

/**
 * helper function to get label from a pattern's value object
 * @param {string} key
 * @param {object} value
 * @param {string} fallbackLabel
 * @returns a label extracted from the value object by key, or the fallback label
 */
export const getLabel = (key, value, fallbackLabel) =>
    // eslint-disable-next-line security/detect-object-injection
    (key && value && value[key]?.label) ? value[key].label : fallbackLabel
;

/**
 * helper function to get value from a pattern's value object
 * @param {string} key
 * @param {object} value
 * @returns a value extracted from the value object by key, or an empty string
 */
export const getValue = (key, value) =>
    // eslint-disable-next-line security/detect-object-injection
    (key && value && value[key]?.value) ? value[key].value : ''
;

/**
 * helper function to get ref from a pattern's value object
 * @param {string} key
 * @param {object} value
 * @returns a ref extracted from the value object by key, or null
 */
export const getRef = (key, value) =>
    // eslint-disable-next-line security/detect-object-injection
    (key && value && value[key]?.inputRef) ? value[key].inputRef : null
;

/**
 * helper function to get radio input value from a pattern's value object
 * @param {string} key
 * @param {object} value
 * @returns value of object by key, or null
 */
export const getRadioValue = (key, value) =>
    // eslint-disable-next-line security/detect-object-injection
    (!value || !value[key] || value[key]?.value === '' || value[key]?.value === undefined) ?
        null :
        // eslint-disable-next-line security/detect-object-injection
        value[key].value
;

/**
 * helper function to get component error state from a pattern's value object
 * @param {string} key
 * @param {object} value
 * @returns an error boolean extracted from the value object by key
 */
export const getHasError = (key, value) =>
    // eslint-disable-next-line security/detect-object-injection
    value && !!value[key]?.errorMessage && '' !== value[key].errorMessage
;

/**
 * helper function to get component error message from a pattern's value object
 * @param {string} key
 * @param {object} value
 * @returns an error message extracted from the value object by key
 */
export const getErrorMessage = (key, value) =>
    // eslint-disable-next-line security/detect-object-injection
    (key && value && !!value[key]?.errorMessage) ? value[key].errorMessage : ''
;

/**
 * Remove a given key from the pattern's value object.
 * @param {string} keyToStrip the key to locate and remove.
 * @param {object} value the pattern's value object.
 * @returns {object} the value object sans any instances of the key.
 */
export const stripKeyFromValue = (keyToStrip, value) => {
    const newValue = { ...value };
    Object.entries(newValue).forEach(([key, val]) => {
        newValue[`${key}`] = removeObjectProperty(keyToStrip, newValue[`${key}`]);
    });
    return newValue;
};

const utils = {
    getId,
    getLabel,
    getValue,
    getRadioValue,
    getHasError,
    getErrorMessage,
    stripKeyFromValue,
};

export default utils;
