import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

const GlobalNavigation = ({
    apiKey = '',
    disable = false,
}) => {
    useEffect(() => {
        if (!disable) {
            // Make sure script hasn't already been injected
            if (!document.getElementById('idGlobalNavigation')) {
                const script = document.createElement('script');
                script.type = 'text/javascript';
                script.src = `https://www.service.nsw.gov.au/assets/global-nav-embed-api.js?apikey=${apiKey}`;
                script.id = 'idGlobalNavigation';
                document.body.appendChild(script);
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <></>
    );
};

GlobalNavigation.defaultProps = {
    apiKey: '',
    disable: false,
};

GlobalNavigation.propTypes = {
    apiKey: PropTypes.string,
    disable: PropTypes.bool,
};

export default GlobalNavigation;
