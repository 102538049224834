import React from 'react';
import styled from 'styled-components/macro';
import { color, layout } from 'styled-system';
import tokens from '../../Tokens/tokens';

const { colors } = tokens;

// eslint-disable-next-line react/prop-types
const CostSvg = ({ className, color, width, height }) => (
    <svg className={ className } viewBox='0 0 44 44' fill={ colors.legacy[color] || color } width={ width } height={ height } xmlns='http://www.w3.org/2000/svg' focusable='false' aria-hidden='true'>
        <path d='M19.1 16.2v-2.9c.8.2 1.6.6 2.3 1.1l1.3-2.2c-1-.7-2.2-1.2-3.5-1.4v-1h-2v1.1c-2.4.2-4 1.6-4 3.7 0 2.2 1.3 3.2 4 4v3c-1.1-.2-2-.8-3.1-1.5l-1.5 2.1c1.2.9 2.8 1.6 4.4 1.8v1.9h2V24c2.4-.2 4-1.6 4-3.7.1-2.2-1.1-3.3-3.9-4.1zm-1.8-.4c-1-.4-1.3-.8-1.3-1.4 0-.6.4-1.1 1.3-1.2v2.6zm3.1 4.6c0 .7-.5 1.1-1.3 1.2V19c1 .4 1.3.8 1.3 1.4z'/>
        <path d='M39.8 34.1c0-.1-.1-.1-.1-.2l-9.1-8.8C32.1 22.8 33 20 33 17c0-8.3-6.7-15-15-15S3 8.7 3 17s6.7 15 15 15c2.3 0 4.5-.6 6.5-1.5l9.6 9.3c0 .1.1.1.2.1l.2.1s.1 0 .1.1c.7.5 1.5.9 2.5.9 2.2 0 4-1.8 4-4-.1-1.1-.6-2.2-1.3-2.9zM5 17C5 9.8 10.8 4 18 4s13 5.8 13 13-5.8 13-13 13S5 24.2 5 17zm29.1 20l-7.8-7.5c1.1-.8 2.1-1.7 3-2.7l7.6 7.4c0 1.5-1.2 2.7-2.8 2.8z'/>
    </svg>
);

export const Cost = styled(CostSvg)`
  ${color}
  ${layout}
`;

Cost.defaultProps = {
    color: `${colors.brand.snswSecondaryBlue}`,
    height: 44,
    width: 44,
};

export default Cost;
