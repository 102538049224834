import * as React from 'react';
import PropTypes from 'prop-types';
import tokens from '../../Tokens/tokens';
const { spacing } = tokens;

const BrandIconActiveKids = ({ title, titleId, ...props }) => {
    const sizeArray = Object.entries(spacing.icons);
    const sizeOptions = new Map(sizeArray);
    const displaySize = sizeOptions.get(props.size);
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 48 48'
            fill={props.color && displayColor}
            aria-hidden={!title}
            role={title ? 'img' : null}
            width={props.size && displaySize}
            height={props.size && displaySize}
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <defs>
                <style>{'.ActiveKids_svg__cls-1{fill:#002664}'}</style>
            </defs>
            <path
                className='ActiveKids_svg__cls-1'
                d='M36 47a1.51 1.51 0 01-.3 0 3.08 3.08 0 01-2.12-1.09 4.42 4.42 0 01-.58-3c.22-2.88-.11-6.54-1.16-7.63a24.06 24.06 0 00-5.49-2.62c-1.51 1.87-5 5.42-7.35 5.42-1.35 0-6.14-1.5-11.28-4.2a3.21 3.21 0 01-1.35-4.33 3.23 3.23 0 014.33-1.35 67.58 67.58 0 006.88 3.18 14.83 14.83 0 002.17-2.25 85.37 85.37 0 006.65-10.96 50.27 50.27 0 00-6.07 1.63c-.8.26-1.48.47-1.94.59a2.9 2.9 0 01-3.54-2.06 2.89 2.89 0 012.06-3.53l.38-.11c.36-.09.92-.29 1.61-.52 2.45-.83 6.15-2.07 8.87-2.12a9.41 9.41 0 016.12 2l1.09.79.61.44c1.09.8 3.56.8 4.37.8a13.59 13.59 0 002.58-.39c1.77-.37 3.44-.73 4.56.18a2.68 2.68 0 01.9 2.22c0 1.32-.69 2.22-2.37 3.11a8.72 8.72 0 01-4.63.89 24.64 24.64 0 01-5.94-.73 28.32 28.32 0 01-3.67 5.59l-.07.09c2.88 1.51 4.82 3 5.78 4.33a.64.64 0 01.08.14l.31.65a.49.49 0 01.06.17c.36 1.3 1.75 7.5 1.45 11.74A3 3 0 0136 47zM26 30.46a1 1 0 01.33.06c.06 0 5.62 2 6.94 3.36 2.18 2.26 1.77 8.48 1.72 9.18a4 4 0 00.11 1.55 1.15 1.15 0 00.79.38H36a1 1 0 001-1.09c.26-3.62-.88-9.22-1.35-11l-.24-.49c-.88-1.2-3-2.68-6.07-4.16a1 1 0 01-.53-.67 1 1 0 01.17-.83l.28-.37.56-.71a23.05 23.05 0 003.74-6 1 1 0 01.57-.59 1 1 0 01.82 0c1.34.66 4.42.91 6 .91a6.72 6.72 0 003.7-.66c1.3-.68 1.3-1 1.3-1.34a1 1 0 00-.16-.67c-.38-.3-1.89 0-2.89.23a14.88 14.88 0 01-3 .43c-2.73 0-4.44-.37-5.56-1.19l-.6-.43-1.15-.83a7.37 7.37 0 00-4.86-1.56c-2.4 0-6.07 1.28-8.26 2-.74.24-1.34.45-1.73.55l-.38.11a.87.87 0 00-.55.42.83.83 0 00-.09.67.87.87 0 001.09.64c.44-.12 1.09-.32 1.85-.56 2.47-.78 5.86-1.85 8-1.85H28a1 1 0 01.81.46 1 1 0 01.08.92c-2.11 5-6.23 11-7.58 12.89a17 17 0 01-2.95 3 1 1 0 01-1 .16A68.6 68.6 0 019.77 30a1.25 1.25 0 00-1.63.51 1.18 1.18 0 00-.08.92 1.16 1.16 0 00.59.71c5.27 2.77 9.67 4 10.35 4 1.33 0 4.8-3.23 6.19-5.19a1 1 0 01.81-.49z'
            />
            <path
                className='ActiveKids_svg__cls-1'
                d='M12 23H3a1 1 0 000 2h16.77c.39-.63.81-1.3 1.22-2zm6-15H6a1 1 0 000 2h20V8zm-5.4 11a4.59 4.59 0 01-.16-1H7a1 1 0 000 2h6a4.28 4.28 0 01-.4-1zM9 13H1a1 1 0 000 2h12.2a5 5 0 012.37-2z'
            />
            <path d='M48 42a5 5 0 11-5-5 5 5 0 015 5z' fill='#d7153a' />
            <path className='ActiveKids_svg__cls-1' d='M34 11a5 5 0 115-5 5 5 0 01-5 5zm0-8a3 3 0 103 3 3 3 0 00-3-3z' />
        </svg>
    );
};

BrandIconActiveKids.propTypes = {
    title: PropTypes.string,
    titleId: PropTypes.string,
    size: PropTypes.oneOf(['lg', 'xl', 'xxl']),
};
BrandIconActiveKids.defaultProps = {
    size: 'xl',
    focusable: false,
};
export default BrandIconActiveKids;
