/**
 * From https://ant.design/components/layout/#breakpoint-width
 */
const media = {
    xs: 480,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
    xxl: 1600,
};

export default media;