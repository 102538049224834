import * as React from 'react';
import PropTypes from 'prop-types';
import tokens from '../../Tokens/tokens';
const { colors, spacing } = tokens;

const IconSort = ({ title, titleId, ...props }) => {
    const sizeArray = Object.entries(spacing.icons);
    const colorArray = Object.entries(colors.icons);
    const sizeOptions = new Map(sizeArray);
    const displaySize = sizeOptions.get(props.size);
    const colorOptions = new Map(colorArray);
    const displayColor = colorOptions.get(props.color);
    return (
        <svg
            viewBox='0 0 16 16'
            xmlns='http://www.w3.org/2000/svg'
            fill={props.color && displayColor}
            aria-hidden={!title}
            role={title ? 'img' : null}
            width={props.size && displaySize}
            height={props.size && displaySize}
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <path d='M15,2 L1,2 C0.447715,2 0,1.552283 0,1 C0,0.447715 0.447715,0 1,0 L15,0 C15.5523,0 16,0.447715 16,1 C16,1.552283 15.5523,2 15,2 Z' />
            <path d='M7,11.999993 L1,11.999993 C0.447715,11.999993 0,11.552293 0,10.999993 C0,10.447693 0.447715,9.999993 1,9.999993 L7,9.999993 C7.55228,9.999993 8,10.447693 8,10.999993 C8,11.552293 7.55228,11.999993 7,11.999993 Z' />
            <path d='M11,7.000003 L1,7.000003 C0.447715,7.000003 0,6.552283 0,6.000003 C0,5.447713 0.447715,5.000003 1,5.000003 L11,5.000003 C11.5523,5.000003 12,5.447713 12,6.000003 C12,6.552283 11.5523,7.000003 11,7.000003 Z' />
        </svg>
    );
};

IconSort.propTypes = {
    title: PropTypes.string,
    titleId: PropTypes.string,
    size: PropTypes.oneOf(['sm', 'md']),
    color: PropTypes.oneOf(['primaryBlue', 'secondaryBlue', 'darkGrey', 'white']),
};
IconSort.defaultProps = {
    size: 'sm',
    color: 'darkGrey',
    focusable: false,
};
export default IconSort;
