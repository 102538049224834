import styled, { css } from 'styled-components/macro';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import tokens from '/Tokens/tokens';
import { pxToRem, getSpacing, getBreakpoint } from '../utils';

const { colors, font } = tokens;

const Notice = styled.div.attrs(
    props => ({
        className: classNames(props.className)
    })
)`
    line-height: ${font.lineHeight.default};
    border-left: 6px solid ${colors.borders.info};
    padding: ${getSpacing(['sm', 'sm', 'sm', 12])};
    ${props => !props.variant && `
        background-color: ${colors.backgrounds.info};
        background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='24px' height='24px' viewBox='0 0 24 24' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3EA9576F77-41C7-47ED-93C0-FC5242564B45@1x%3C/title%3E%3Cg id='Symbols' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg transform='translate(-22.000000, -16.000000)' fill='%232E5299'%3E%3Cg id='Info-icon' transform='translate(22.000000, 16.000000)'%3E%3Cpath d='M12,0 C8.81750013,0 5.76550026,1.26464995 3.51475035,3.51474985 C1.26474995,5.76572476 0,8.81749963 0,12 C0,15.1824994 1.26464995,18.2344992 3.51475035,20.4852491 C5.76572526,22.7352501 8.81750013,24 12,24 C15.1824999,24 18.2344997,22.7353501 20.4852496,20.4852491 C22.7352496,18.2342742 24,15.1824994 24,12 C24,8.81749963 22.7353496,5.76549976 20.4852496,3.51474985 C18.2342747,1.26474995 15.1824999,0 12,0 Z M12,18.7499992 C11.39355,18.7499992 10.846675,18.3847742 10.6142501,17.8242243 C10.3818276,17.2636743 10.5107351,16.6181743 10.93945,16.1894493 C11.36815,15.7607493 12.013675,15.6318243 12.574225,15.8642493 C13.134775,16.0966718 13.4999999,16.6435493 13.4999999,17.2499993 C13.4999999,18.0781242 12.828125,18.7499992 12,18.7499992 Z M13.5000001,13.4999994 L10.5000001,13.4999994 L10.5000001,5.24999978 L13.5000001,5.24999978 L13.5000001,13.4999994 Z' transform='translate(12.000000, 12.000000) rotate(180.000000) translate(-12.000000, -12.000000) '%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-position: ${getSpacing([12, 'sm'])};
        padding: ${getSpacing([48, 'sm', 'sm', 12])};
    `}
    margin: ${getSpacing(['sm', 'none'])};


    a {
        color: ${colors.text.body};
        cursor: pointer;
        text-decoration: underline;
    }

    h2 {
        font-size: ${pxToRem(font.size.base)};
        line-height: ${font.lineHeight.default};
        font-weight: ${font.weight.bold};
    }

    h2, li, ol, p, ul {
        margin: ${getSpacing(['none', 'none', 4])};
        max-width: 100%;
    }

    li:last-child,
    ol:last-child,
    p:last-child,
    ul:last-child {
        margin-bottom: 0;
    }

    ${props => props.info && infoNoticeStyle};

    ${props => props.error && errorNoticeStyle}

    ${props => props.warning && warningNoticeStyle};

    ${props => props.success && successNoticeStyle};

    ${props => props.plain && plainNoticeStyle};

    ${getBreakpoint.md`
        padding: ${getSpacing(['md', 'md', 'md', 'sm'])};
        ${props => !props.variant && `
            padding: ${getSpacing(['md', 'md', 'md', 54])};
        `}
        background-position: ${getSpacing([12, 'md'])};
    `}

    ${getBreakpoint.print`
        background: none !important;
        padding: ${getSpacing(['sm', 'none', 'sm', 'sm'])};
    `}
`;

const infoNoticeStyle = css`
    background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='24px' height='24px' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3EA9576F77-41C7-47ED-93C0-FC5242564B45@1x%3C/title%3E%3Cg  stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg transform='translate(-22.000000, -16.000000)' fill='%232E5299'%3E%3Cg transform='translate(22.000000, 16.000000)'%3E%3Cpath d='M12,0 C8.81750013,0 5.76550026,1.26464995 3.51475035,3.51474985 C1.26474995,5.76572476 0,8.81749963 0,12 C0,15.1824994 1.26464995,18.2344992 3.51475035,20.4852491 C5.76572526,22.7352501 8.81750013,24 12,24 C15.1824999,24 18.2344997,22.7353501 20.4852496,20.4852491 C22.7352496,18.2342742 24,15.1824994 24,12 C24,8.81749963 22.7353496,5.76549976 20.4852496,3.51474985 C18.2342747,1.26474995 15.1824999,0 12,0 Z M12,18.7499992 C11.39355,18.7499992 10.846675,18.3847742 10.6142501,17.8242243 C10.3818276,17.2636743 10.5107351,16.6181743 10.93945,16.1894493 C11.36815,15.7607493 12.013675,15.6318243 12.574225,15.8642493 C13.134775,16.0966718 13.4999999,16.6435493 13.4999999,17.2499993 C13.4999999,18.0781242 12.828125,18.7499992 12,18.7499992 Z M13.5000001,13.4999994 L10.5000001,13.4999994 L10.5000001,5.24999978 L13.5000001,5.24999978 L13.5000001,13.4999994 Z' transform='translate(12.000000, 12.000000) rotate(180.000000) translate(-12.000000, -12.000000) '%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
`;

const errorNoticeStyle = css`
    background-color: ${colors.backgrounds.error};
    background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='24px' height='24px' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3EAE89F5A1-5702-4D79-A4DB-AB384EE747CB@1x%3C/title%3E%3Cg  stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg transform='translate(-22.000000, -24.000000)'%3E%3Cg id='Error-icon' transform='translate(22.000000, 24.000000)'%3E%3Cpath d='M12,0 C8.81750032,0 5.76550038,1.26464995 3.51475043,3.51474985 C1.26474997,5.76572476 2.84217094e-14,8.81749963 2.84217094e-14,12 C2.84217094e-14,15.1824994 1.26464997,18.2344992 3.51475043,20.4852491 C5.76572538,22.7352501 8.81750032,24 12,24 C15.1825002,24 18.2345001,22.7353501 20.4852501,20.4852491 C22.73525,18.2342742 24,15.1824994 24,12 C24,8.81749963 22.73535,5.76549976 20.4852501,3.51474985 C18.2342751,1.26474995 15.1825002,0 12,0 Z'  fill='%23B81237' transform='translate(12.000000, 12.000000) rotate(180.000000) translate(-12.000000, -12.000000) '%3E%3C/path%3E%3Cpath d='M13.5,19.1470588 C13.5,19.3418824 13.355027,19.5 13.1756757,19.5 L10.8243243,19.5 C10.644973,19.5 10.5,19.3418824 10.5,19.1470588 L10.5,16.8529412 C10.5,16.6581176 10.644973,16.5 10.8243243,16.5 L13.1756757,16.5 C13.355027,16.5 13.5,16.6581176 13.5,16.8529412 L13.5,19.1470588 Z'  fill='%23FFFFFF'%3E%3C/path%3E%3Cpath d='M13.1756757,15 L10.8243243,15 C10.644973,15 10.5,14.8405424 10.5,14.6440678 L10.5,4.8559322 C10.5,4.65945763 10.644973,4.5 10.8243243,4.5 L13.1756757,4.5 C13.355027,4.5 13.5,4.65945763 13.5,4.8559322 L13.5,14.6440678 C13.5,14.8405424 13.355027,15 13.1756757,15 Z'  fill='%23FFFFFF'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
    border-color: ${colors.borders.error};
`;

const warningNoticeStyle = css`
    background-color: ${colors.backgrounds.warning};
    background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='24px' height='24px'  version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3EED87D8EB-DC6B-4F1A-8D3E-B99EF5955705@1x%3C/title%3E%3Cg stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg transform='translate(-22.000000, -16.000000)' fill='%23DC5800'%3E%3Cg  transform='translate(22.000000, 16.000000)'%3E%3Cpath d='M12,0 C8.81750013,0 5.76550026,1.26464995 3.51475035,3.51474985 C1.26474995,5.76572476 0,8.81749963 0,12 C0,15.1824994 1.26464995,18.2344992 3.51475035,20.4852491 C5.76572526,22.7352501 8.81750013,24 12,24 C15.1824999,24 18.2344997,22.7353501 20.4852496,20.4852491 C22.7352496,18.2342742 24,15.1824994 24,12 C24,8.81749963 22.7353496,5.76549976 20.4852496,3.51474985 C18.2342747,1.26474995 15.1824999,0 12,0 Z M12,18.7499992 C11.39355,18.7499992 10.846675,18.3847742 10.6142501,17.8242243 C10.3818276,17.2636743 10.5107351,16.6181743 10.93945,16.1894493 C11.36815,15.7607493 12.013675,15.6318243 12.574225,15.8642493 C13.134775,16.0966718 13.4999999,16.6435493 13.4999999,17.2499993 C13.4999999,18.0781242 12.828125,18.7499992 12,18.7499992 Z M13.5000001,13.4999994 L10.5000001,13.4999994 L10.5000001,5.24999978 L13.5000001,5.24999978 L13.5000001,13.4999994 Z' %3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
    border-color: ${colors.borders.warning};
`;

const successNoticeStyle = css`
    background-color: ${colors.backgrounds.success};
    background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='24px' height='24px' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3E73AEF9C7-BEB9-45E8-97B2-A4D62855FBA2@1x%3C/title%3E%3Cg stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg transform='translate(-22.000000, -24.000000)' fill='%2300A908'%3E%3Cg  transform='translate(22.000000, 24.000000)'%3E%3Cpath d='M12,0 C18.627417,0 24,5.372583 24,12 C24,18.627417 18.627417,24 12,24 C5.372583,24 0,18.627417 0,12 C0,5.372583 5.372583,0 12,0 Z M16.6378468,7.2 L10.2402571,13.5959273 L7.28342202,10.8905167 L5.6,12.7297804 L10.3165988,17.0455545 L18.4,8.96215321 L16.6378468,7.2 Z' %3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
    border-color: ${colors.borders.success};
`;

const plainNoticeStyle = css`
    background-image: none;
    padding-top: ${getSpacing('sm')};
`;

Notice.propTypes = {
    info: PropTypes.bool,
    error: PropTypes.bool,
    warning: PropTypes.bool,
    success: PropTypes.bool,
    plain: PropTypes.bool,
    className: PropTypes.string,
    variant: PropTypes.string
};

Notice.Wrapper = styled.div`
    display: flex;
    flex-flow: row wrap;

    ${getBreakpoint.sm`
        flex-flow: nowrap;
    `}
`;

Notice.Title = styled.h6.attrs(props => ({
    as: (
        props.headingLevel === 'h2' ||
        props.headingLevel === 'h3' ||
        props.headingLevel === 'h4' ||
        props.headingLevel === 'h5' ||
        props.headingLevel === 'h6'
    ) && props.headingLevel
}))`
    font-size: ${pxToRem(font.size.base)};
    line-height: ${font.lineHeight.default};
    font-weight: ${font.weight.bold};
    margin: ${getSpacing(['none', 'none', 4])};
    max-width: 100%;
`;

Notice.Icon = styled.div `
    padding: ${getSpacing(['none', 'sm', 'none', 'none'])};
`;

Notice.Children = styled.div `
    ${getBreakpoint.sm`
        margin: ${getSpacing(['none', 'none', 'none', 40])};
    `}
`;

Notice.Copy = styled.p``;

export default Notice;
