import * as React from 'react';
import PropTypes from 'prop-types';
import tokens from '../../Tokens/tokens';
const { spacing } = tokens;

const BrandIconWorkersCompensation = ({ title, titleId, ...props }) => {
    const sizeArray = Object.entries(spacing.icons);
    const sizeOptions = new Map(sizeArray);
    const displaySize = sizeOptions.get(props.size);
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 48 48'
            fill={props.color && displayColor}
            aria-hidden={!title}
            role={title ? 'img' : null}
            width={props.size && displaySize}
            height={props.size && displaySize}
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <defs>
                <style>{'.WorkersCompensation_svg__cls-1{fill:#002664}'}</style>
            </defs>
            <path
                className='WorkersCompensation_svg__cls-1'
                d='M30 22H10a1 1 0 010-2h20a1 1 0 010 2zm0 7H10a1 1 0 010-2h20a1 1 0 010 2z'
            />
            <path
                className='WorkersCompensation_svg__cls-1'
                d='M32 0H14a1 1 0 00-.67.26l-10 9.13a1 1 0 00-.33.74V43a5 5 0 005 5h24a5 5 0 005-5V5a5 5 0 00-5-5zm3 43a3 3 0 01-3 3H8a3 3 0 01-3-3V10.57l8-7.3V11H8v2h7V2h17a3 3 0 013 3z'
            />
            <path d='M32 38a5 5 0 10-5 5 5 5 0 005-5z' fill='#d7153a' />
            <path
                className='WorkersCompensation_svg__cls-1'
                d='M43.87 18.5l-.87-.57 2.66-7.31a5 5 0 00-3-6.4L39 2.87V5l3 1.1a3 3 0 011.8 3.84L39 23.18V29l3.33-9.16.39.27a3 3 0 01.74 4.18L39 30.69v3.48l6.1-8.7a5 5 0 00-1.23-6.97z'
            />
        </svg>
    );
};

BrandIconWorkersCompensation.propTypes = {
    title: PropTypes.string,
    titleId: PropTypes.string,
    size: PropTypes.oneOf(['lg', 'xl', 'xxl']),
};
BrandIconWorkersCompensation.defaultProps = {
    size: 'xl',
    focusable: false,
};
export default BrandIconWorkersCompensation;
