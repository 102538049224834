import * as React from 'react';
import PropTypes from 'prop-types';
import tokens from '../../Tokens/tokens';
const { spacing } = tokens;

const BrandIconManagingRisks = ({ title, titleId, ...props }) => {
    const sizeArray = Object.entries(spacing.icons);
    const sizeOptions = new Map(sizeArray);
    const displaySize = sizeOptions.get(props.size);
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 48 48'
            fill={props.color && displayColor}
            aria-hidden={!title}
            role={title ? 'img' : null}
            width={props.size && displaySize}
            height={props.size && displaySize}
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <defs>
                <style>{'.ManagingRisks_svg__cls-2{fill:#002664}'}</style>
            </defs>
            <path d='M45 8a5 5 0 11-5-5 5 5 0 015 5z' fill='#d7153a' />
            <path
                className='ManagingRisks_svg__cls-2'
                d='M24 37a1 1 0 01-1-1v-5a1 1 0 012 0v5a1 1 0 01-1 1zm0 4a1 1 0 01-.38-.08 1 1 0 01-.33-.21 1.58 1.58 0 01-.12-.15.76.76 0 01-.09-.18.64.64 0 01-.08-.18 1.36 1.36 0 010-.2 1 1 0 011.71-.71.93.93 0 01.21.33.84.84 0 01.08.38 1.36 1.36 0 010 .2.64.64 0 01-.06.18.76.76 0 01-.09.18 1.58 1.58 0 01-.12.15 1.15 1.15 0 01-.33.21 1 1 0 01-.4.08z'
            />
            <path
                className='ManagingRisks_svg__cls-2'
                d='M36 45H13a1 1 0 01-.86-.49 1 1 0 010-1l11-20A1 1 0 0124 23a1 1 0 01.88.49l12 20A1 1 0 0136 45zm-21.31-2h19.54L24 26zM1 28a1 1 0 01-.29-2l46-14a1 1 0 11.58 2l-46 14A1 1 0 011 28zm8-6a9 9 0 119-9 9 9 0 01-9 9zM9 6a7 7 0 107 7 7 7 0 00-7-7z'
            />
        </svg>
    );
};

BrandIconManagingRisks.propTypes = {
    title: PropTypes.string,
    titleId: PropTypes.string,
    size: PropTypes.oneOf(['lg', 'xl', 'xxl']),
};
BrandIconManagingRisks.defaultProps = {
    size: 'xl',
    focusable: false,
};
export default BrandIconManagingRisks;
