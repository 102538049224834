import React, { forwardRef, useEffect } from 'react';
import { IconExternalLink, IconChevronLeft, IconArrowRight } from '../../Icons/system';
import PropTypes from 'prop-types';
import StyledButton from './Button.styled';
import { trackMe } from '../ComponentAnalytics/componentAnalytics';
import classNames from 'classnames';

const Button = forwardRef(({
    theme,
    variant,
    size,
    href,
    disabled,
    external,
    onClick,
    children,
    type,
    className,
    as,
    iconStart,
    iconEnd,
    ...otherOptions
}, ref) => {

    useEffect(() => {
        trackMe('Button');
    }, []);

    const hideExternalIcon = (iconStart || iconEnd);

    if (iconStart === undefined) {
        if ((variant || theme) === 'back') {
            iconStart = <IconChevronLeft />;
        }
    }

    if (iconEnd === undefined) {
        if (
            external &&
            !hideExternalIcon &&
            ((variant || theme) !== 'back') &&
            ((variant || theme) !== 'arrow') &&
            typeof Component !== 'object'
        ) {
            iconEnd = <IconExternalLink title='Opens in a new tab' />;
        } else if ((variant || theme) === 'arrow') {
            iconEnd = <IconArrowRight />;
        }
    }

    if (typeof as !== 'object' && href) {
        otherOptions.href = href;
        otherOptions.as = 'a';

        if (external) {
            otherOptions.external = external ? 1 : 0;
            otherOptions.target = '_blank';
            otherOptions.rel = 'noopener noreferrer';
        }
    }
    else if (typeof as === 'object' && href) {
        otherOptions.to = href;
        otherOptions.as = as;
    }
    else {
        otherOptions.type = type;
        otherOptions.disabled = disabled;
        otherOptions.external = external ? 1 : 0;
    }
    return (
        <StyledButton
            className={ className }
            theme={ theme }
            variant={ variant }
            size={ size }
            onClick={ onClick }
            ref={ ref }
            { ...otherOptions }
        >
            {iconStart && (
                <span className='iconStart iconFlex'>{iconStart}</span>
            )}
            {((variant || theme) === 'back') ? 'Back' : children}
            {iconEnd && (
                <span
                    className={ classNames(
                        'iconFlex',
                        (hideExternalIcon || ((variant || theme) === 'arrow')) ? 'iconEnd' : 'iconExt',
                    ) }
                >
                    {iconEnd}
                </span>
            )}
        </StyledButton>
    );
});

Button.defaultProps = {
    theme: 'primary',
    // variant: 'primary', //TODO: add default prop for variant when the theme prop is removed
    size: 'default',
    disabled: false,
    external: false,
    type: 'button',
};

Button.propTypes = {
    className: PropTypes.string,
    theme: PropTypes.oneOf(['primary', 'secondary', 'tertiary', 'link', 'arrow', 'back']),
    variant: PropTypes.oneOf(['primary', 'secondary', 'tertiary', 'link', 'arrow', 'back']),
    href: PropTypes.string,
    disabled: PropTypes.bool,
    external: PropTypes.bool,
    onClick: PropTypes.func,
    children: PropTypes.node,
    type: PropTypes.oneOf(['button', 'submit', 'reset']),
    as: PropTypes.object
};

export default Button;
