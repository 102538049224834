import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Field from '../Form/Field.styled';
import { trackMe } from '../ComponentAnalytics/componentAnalytics';

const Select = ({ id, name, type, value, onChange, disabled, hasError, inputWidth, inputRef, placeholder, options, ...rest }) => {

    useEffect(() => {
        trackMe('Select');
    }, []);

    return (
        <Field.Select
            id={ id }
            name={ name }
            type={ type }
            value={ value }
            onChange={ onChange }
            disabled={ disabled }
            hasError={ hasError }
            inputWidth={ inputWidth }
            ref={ inputRef }
            { ...rest }
        >
            {placeholder && <option value=''>{placeholder}</option>}
            {options.map((option, index) =>
                <option key={ index } value={ option.value }>
                    {option.text}
                </option>)}
        </Field.Select>
    );
};

Select.propTypes = {
    id: PropTypes.string,
    name: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    hasError: PropTypes.bool,
    inputWidth: PropTypes.oneOf(['xxs', 'xs', 'sm', 'md', 'lg', 'xl']),
    inputRef: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.object,
    ]),
    options: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.string.isRequired,
            text: PropTypes.string.isRequired,
        })
    ).isRequired,
    placeholder: PropTypes.string,
};

Select.defaultProps = {
    hasError: false,
    disabled: false,
    placeholder: 'Select',
};

export default Select;
