import styled from 'styled-components/macro';
import tokens from '../../Tokens/tokens';
import { getBreakpoint, pxToRem } from '../../utils';

const { containers } = tokens.layouts;

export const Container = styled.div`
    max-width: ${pxToRem(containers.maxWidth)};
    padding-left: ${pxToRem(containers.gutters.mobile)};
    padding-right: ${pxToRem(containers.gutters.mobile)};
    margin-left: auto;
    margin-right: auto;

    a {
        text-decoration: underline;
    }

    ${getBreakpoint.md`
        padding-left: ${pxToRem(containers.gutters.desktop)};
        padding-right: ${pxToRem(containers.gutters.desktop)};
    `}

    ${getBreakpoint.print`
        max-width: none;
        padding-left: 0;
        padding-right: 0;
        margin-left: 0;
        margin-right: 0
    `}
`;

export const PageWrapper = styled.div`
    padding: 2rem 0;

    ${getBreakpoint.md`
        padding: 3.5rem 0;
    `}
`;
