import * as React from 'react';
import PropTypes from 'prop-types';
import tokens from '../../Tokens/tokens';
const { spacing } = tokens;

const BrandIconBusinessGroup = ({ title, titleId, ...props }) => {
    const sizeArray = Object.entries(spacing.icons);
    const sizeOptions = new Map(sizeArray);
    const displaySize = sizeOptions.get(props.size);
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 48 48'
            fill={props.color && displayColor}
            aria-hidden={!title}
            role={title ? 'img' : null}
            width={props.size && displaySize}
            height={props.size && displaySize}
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <defs>
                <style>{'.BusinessGroup_svg__cls-1{fill:#002664}'}</style>
            </defs>
            <path
                className='BusinessGroup_svg__cls-1'
                d='M30 22.12l-.7-6A2.19 2.19 0 0027 14h-6a2.18 2.18 0 00-2.33 2.08l-.7 6-2-.24.69-6A4.18 4.18 0 0121 12h6a4.18 4.18 0 014.32 3.92l.69 6zM16 48a1 1 0 01-.95-.68l-3.24-9.62A3.37 3.37 0 008.57 35H4.79C1.61 35 0 33.53 0 30.63V18.54C0 15.7 1.5 14 4 14h2c3.59 0 5.34 6.6 5.89 9.13l5.25.88a1 1 0 01-.34 2L10.9 25a1 1 0 01-.81-.81C9.47 20.88 7.76 16 6 16H4c-.55 0-2 0-2 2.54v12.09C2 32.17 2.45 33 4.79 33h3.78a5.33 5.33 0 015.15 4.11L17 46.68a1 1 0 01-.68 1.32 1.19 1.19 0 01-.32 0z'
            />
            <path
                className='BusinessGroup_svg__cls-1'
                d='M34 28H13v2h10v17a1 1 0 002 0V30h9zM24 10a5 5 0 115-5 5 5 0 01-5 5zm0-8a3 3 0 103 3 3 3 0 00-3-3zM5 12a5 5 0 115-5 5 5 0 01-5 5zm0-8a3 3 0 103 3 3 3 0 00-3-3zm38 8a5 5 0 115-5 5 5 0 01-5 5zm0-8a3 3 0 103 3 3 3 0 00-3-3z'
            />
            <path
                className='BusinessGroup_svg__cls-1'
                d='M32 48a1.19 1.19 0 01-.32 0 1 1 0 01-.63-1.27l3.24-9.62A5.32 5.32 0 0139.43 33h3.78c2.34 0 2.79-.83 2.79-2.37V18.54C46 16 44.55 16 44 16h-2c-2 0-3.17 4.66-3.72 6.9-.14.57-.26 1.06-.37 1.4a1 1 0 01-.78.69l-5.93 1a1 1 0 11-.34-2l5.31-.89.17-.7c.74-3.13 2.04-8.4 5.66-8.4h2c2.5 0 4 1.7 4 4.54v12.09c0 2.9-1.61 4.37-4.79 4.37h-3.78a3.33 3.33 0 00-3.22 2.64L33 47.32a1 1 0 01-1 .68z'
            />
            <path d='M27.24 24.16a5 5 0 00-7.07.59A4.94 4.94 0 0019 28h10a5 5 0 00-1.76-3.84z' fill='#d7153a' />
        </svg>
    );
};

BrandIconBusinessGroup.propTypes = {
    title: PropTypes.string,
    titleId: PropTypes.string,
    size: PropTypes.oneOf(['lg', 'xl', 'xxl']),
};
BrandIconBusinessGroup.defaultProps = {
    size: 'xl',
    focusable: false,
};
export default BrandIconBusinessGroup;
