import * as React from 'react';
import PropTypes from 'prop-types';
import tokens from '../../Tokens/tokens';
const { colors, spacing } = tokens;

const IconEdit = ({ title, titleId, ...props }) => {
    const sizeArray = Object.entries(spacing.icons);
    const colorArray = Object.entries(colors.icons);
    const sizeOptions = new Map(sizeArray);
    const displaySize = sizeOptions.get(props.size);
    const colorOptions = new Map(colorArray);
    const displayColor = colorOptions.get(props.color);
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 16 16'
            fill={props.color && displayColor}
            aria-hidden={!title}
            role={title ? 'img' : null}
            width={props.size && displaySize}
            height={props.size && displaySize}
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <path d='M3 13h3a1 1 0 00.71-.29l8-8a1 1 0 000-1.42l-3-3a1 1 0 00-1.42 0l-8 8A1 1 0 002 9v3a1 1 0 001 1zm8-10.59L12.59 4 11 5.59 9.41 4zm-7 7l4-4L9.59 7l-4 4H4zM15 14H1a1 1 0 000 2h14a1 1 0 000-2z' />
        </svg>
    );
};

IconEdit.propTypes = {
    title: PropTypes.string,
    titleId: PropTypes.string,
    size: PropTypes.oneOf(['sm', 'md']),
    color: PropTypes.oneOf(['primaryBlue', 'secondaryBlue', 'darkGrey', 'white']),
};
IconEdit.defaultProps = {
    size: 'sm',
    color: 'darkGrey',
    focusable: false,
};
export default IconEdit;
