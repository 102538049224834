import * as React from 'react';
import PropTypes from 'prop-types';
import tokens from '../../Tokens/tokens';
const { colors, spacing } = tokens;

const IconThumbsDownFilled = ({ title, titleId, ...props }) => {
    const sizeArray = Object.entries(spacing.icons);
    const colorArray = Object.entries(colors.icons);
    const sizeOptions = new Map(sizeArray);
    const displaySize = sizeOptions.get(props.size);
    const colorOptions = new Map(colorArray);
    const displayColor = colorOptions.get(props.color);
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 16 16'
            fill={props.color && displayColor}
            aria-hidden={!title}
            role={title ? 'img' : null}
            width={props.size && displaySize}
            height={props.size && displaySize}
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <path d='M1 0a1 1 0 00-1 1v8a1 1 0 102 0V1a1 1 0 00-1-1zm3 1.462c0-.45.13-.805.388-1.068C4.647.13 4.978 0 5.383 0h6.625c.502.062.877.156 1.126.282.614.311.836.51 1.108.817.273.307.536.734.71 1.321.055.187.092.45.112.79l.91 4.508c.035.36.035.634 0 .823a4.478 4.478 0 01-.359 1.016c-.308.583-.665.885-.967 1.119-.293.228-.871.517-1.514.619-.342.054-.929.054-1.76 0v1.592a4.668 4.668 0 01-.066.481c-.051.24-.126.449-.19.613a3.283 3.283 0 01-.7 1.078c-.202.196-.653.56-1.058.723A3.535 3.535 0 018.148 16a1.5 1.5 0 01-.467-.086c-.224-.081-.458-.23-.566-.375a3.985 3.985 0 01-.368-.635L4.101 9.479a1.957 1.957 0 01-.078-.269A1.7 1.7 0 014 8.944V1.462z' />
        </svg>
    );
};

IconThumbsDownFilled.propTypes = {
    title: PropTypes.string,
    titleId: PropTypes.string,
    size: PropTypes.oneOf(['sm', 'md']),
    color: PropTypes.oneOf(['primaryBlue', 'secondaryBlue', 'darkGrey', 'white']),
};
IconThumbsDownFilled.defaultProps = {
    size: 'sm',
    color: 'darkGrey',
    focusable: false,
};
export default IconThumbsDownFilled;
