import * as React from 'react';
import PropTypes from 'prop-types';
import tokens from '../../Tokens/tokens';
const { spacing } = tokens;

const BrandIconPhotos = ({ title, titleId, ...props }) => {
    const sizeArray = Object.entries(spacing.icons);
    const sizeOptions = new Map(sizeArray);
    const displaySize = sizeOptions.get(props.size);
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 48 48'
            fill={props.color && displayColor}
            aria-hidden={!title}
            role={title ? 'img' : null}
            width={props.size && displaySize}
            height={props.size && displaySize}
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <defs>
                <style>{'.Photos_svg__cls-2{fill:#002664}'}</style>
            </defs>
            <path d='M33 19a5 5 0 11-5-5 5 5 0 015 5z' fill='#d7153a' />
            <path className='Photos_svg__cls-2' d='M5 5v2h41v31h2V5H5z' />
            <path
                className='Photos_svg__cls-2'
                d='M0 43h43V10H0zm41-31v22.39l-7.26-8.06A1 1 0 0033 26a1.13 1.13 0 00-.74.29l-5 5 1.42 1.42L33 28.45l8 8.93V41H2v-2.61l12.94-14.2 13.21 14a1 1 0 001.41 0 1 1 0 000-1.42L15.65 22a1 1 0 00-.73-.32 1 1 0 00-.73.33L2 35.42V12z'
            />
        </svg>
    );
};

BrandIconPhotos.propTypes = {
    title: PropTypes.string,
    titleId: PropTypes.string,
    size: PropTypes.oneOf(['lg', 'xl', 'xxl']),
};
BrandIconPhotos.defaultProps = {
    size: 'xl',
    focusable: false,
};
export default BrandIconPhotos;
