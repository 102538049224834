import * as React from 'react';
import PropTypes from 'prop-types';
import tokens from '../../Tokens/tokens';
const { spacing } = tokens;

const BrandIconAddLicence = ({ title, titleId, ...props }) => {
    const sizeArray = Object.entries(spacing.icons);
    const sizeOptions = new Map(sizeArray);
    const displaySize = sizeOptions.get(props.size);
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 48 48'
            fill={props.color && displayColor}
            aria-hidden={!title}
            role={title ? 'img' : null}
            width={props.size && displaySize}
            height={props.size && displaySize}
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <defs>
                <style>{'.AddLicence_svg__cls-2{fill:#002664}'}</style>
            </defs>
            <path d='M11 22a5 5 0 105-5 5 5 0 00-5 5z' fill='#d7153a' />
            <path
                className='AddLicence_svg__cls-2'
                d='M41 25.23V14a3 3 0 00-3-3H10a3 3 0 00-3 3v17a3 3 0 003 3h20a9 9 0 1011-8.77zM10 32a1 1 0 01-1-1V14a1 1 0 011-1h28a1 1 0 011 1v11a9 9 0 00-8.77 7zm29 9a7 7 0 117-7 7 7 0 01-7 7z'
            />
            <path
                className='AddLicence_svg__cls-2'
                d='M43 33h-3v-3a1 1 0 00-2 0v3h-3a1 1 0 000 2h3v3a1 1 0 002 0v-3h3a1 1 0 000-2zM24 21h10a1 1 0 000-2H24a1 1 0 000 2zm11 3a1 1 0 00-1-1H24a1 1 0 000 2h10a1 1 0 001-1zM2 24V7a1 1 0 011-1h28a1 1 0 011 1v2h2V7a3 3 0 00-3-3H3a3 3 0 00-3 3v17a3 3 0 003 3h2v-2H3a1 1 0 01-1-1z'
            />
        </svg>
    );
};

BrandIconAddLicence.propTypes = {
    title: PropTypes.string,
    titleId: PropTypes.string,
    size: PropTypes.oneOf(['lg', 'xl', 'xxl']),
};
BrandIconAddLicence.defaultProps = {
    size: 'xl',
    focusable: false,
};
export default BrandIconAddLicence;
