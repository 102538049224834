import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Container, PageWrapper } from './ContentContainer.styled';
import { trackMe } from '../ComponentAnalytics/componentAnalytics';

const ContentContainer = ({ className, children }) => {
    useEffect(() => {
        trackMe('ContentContainer');
    }, []);

    return (
        <PageWrapper className={ className }>
            <Container>
                {children}
            </Container>
        </PageWrapper>
    );
};

ContentContainer.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node,
};

export default ContentContainer;
