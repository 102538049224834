import Button from '../../Components/Button/Button';
import { ButtonWithSpacing } from './ErrorSystemPage.styled';
import HeadingWithIcon from '../../Components/Headings/HeadingWithIcon';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { trackMe } from '../../Components/ComponentAnalytics/componentAnalytics';

const ErrorSystemPage = ({
    detail,
    heading,
    tryAgainOnClick
}) => {
    useEffect(() => {
        trackMe('TemplateErrorSystemPage');
    }, []);

    return (
        <>
            <HeadingWithIcon level={ 2 } type='error'>{ heading }</HeadingWithIcon>
            <p>{ detail }</p>
            <ButtonWithSpacing onClick={ tryAgainOnClick }>Try again</ButtonWithSpacing>
            <Button theme='secondary' href='https://www.service.nsw.gov.au/'>Service NSW homepage</Button>
        </>
    );
};

ErrorSystemPage.propTypes = {
    detail: PropTypes.string,
    heading: PropTypes.string,
    tryAgainOnClick: PropTypes.func
};

ErrorSystemPage.defaultProps = {
    detail: '',
    heading: '',
};

export default ErrorSystemPage;
